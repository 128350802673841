import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { CommitmentDelegateService } from 'src/app/services/commitment-delegate.service';
import { MasterService } from 'src/app/services/master.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { UserFilterService } from 'src/app/services/user.filter.service';
import { UserService } from 'src/app/services/user.service';
import { FdlFilterUIHepler } from 'src/app/shared/fdl-filter-ui-helper';
import { IPager } from 'src/app/shared/pagination';
import { IQuery } from 'src/app/shared/query';
import { ISuggestion, ISuggestions } from 'src/app/shared/suggestion';
import { IUser, IUsersList } from 'src/app/shared/user';
import { environment } from 'src/environments/environment';
import { CommitmentDelegateSelectPopupComponent } from './commitment-delegate-select-popup/commitment-delegate-select-popup.component';
import { IPageTemplate } from 'src/app/shared/page-template';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
declare var $: any;

@Component({
  selector: 'app-commitment-delegate-list',
  templateUrl: './commitment-delegate-list.component.html',
  styleUrls: ['./commitment-delegate-list.component.css']
})
export class CommitmentDelegateListComponent implements OnInit, OnDestroy {
  isError: boolean;
  isProjectCreated: boolean = false;
  regexValidationMessage: any;
  userData: any;
  selectedOrgId: number;
  filterForm: FormGroup;
  showComment: boolean = true;
  queryParam: IQuery;
  currentPageNumber: number = 1;
  pagerObject: IPager;
  projectName: string;
  displayColumns = ['Name', 'Email', 'Created By', 'Created Date', 'Last Updated User', 'Last Updated Date'];
  keys = ['fromUserName', 'fromEmailId', 'createdByName', 'createdOn', 'modifiedByName', 'modifiedOn'];

  sortingObject;
  errorMsg: string = "";
  deletedMsg: string = "";
  filterExists: boolean = false;
  filtered: boolean = false;
  isSubmitted = false;
  isEditAllowed: boolean;
  isDeleteAllowed: boolean;
  isCreateAllowed: boolean;
  users: IUser[];
  listName = '';
  userDelegate: any = {};
  userIdSuggestion: ISuggestion[];
  emailSuggestion: ISuggestion[];
  userSuggestion: ISuggestions;
  roleSuggestions: ISuggestions;
  delegateList: any[];
  successMsg: string;
  isCCTAdmin = false;
  private pageTemplateId = environment.clientCommitmentPageTemplateId;
  public pageTemplate: IPageTemplate = {};
  @ViewChild('commitmentSelectPopup') commitmentDelegateSelectPopupComponent: CommitmentDelegateSelectPopupComponent;

  constructor(
    private router: Router,
    private permissionService: PermissionsService,
    private userService: UserService,
    private commitmentDelegateService: CommitmentDelegateService,
    private projectService: ProjectsService,
    private route: ActivatedRoute,
    private masterService: MasterService,
    private fdlFilterHelper: FdlFilterUIHepler,
    private userFilterService: UserFilterService,
    private clientCommitmentsService: ClientCommitmentsService) { }

  ngOnInit(): void {
    const user = this.userService.getUserListData();
    this.userData = user;
    this.userDelegate.organizationId = user.organization;
    console.log(user);
    this.initFilterForm();
    this.initQueryParams();
    this.filterExists = this.userFilterService.loadAndApplyFilters(this.route, this.filterForm, this.queryParam);

    this.refresh();
    this.getSuggestions();
    this.getRoleSuggestions();
    this.getPageTemplateData();

    this.filtered = this.filterExists;
  }

  refresh() {
    this.getList(true);
  }

  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      name: new FormControl('', [Validators.pattern(REGEX_PATTERNS.user_name_validator)]),
      email: new FormControl('', [Validators.pattern(REGEX_PATTERNS.Email)]),
      userId: new FormControl('')
    });

  }

  getSuggestions() {
    this.commitmentDelegateService.getSuggestions(this.queryParam)
      .subscribe(suggestions => {
        console.log(suggestions)
        this.userSuggestion = suggestions;
        this.sortAndFilterEmailSuggestions(suggestions);
        this.sortAndFilterUserIdSuggestions(suggestions);
      },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  getRoleSuggestions() {
    this.projectService.getSuggestions("roles", "").subscribe(
      data => this.roleSuggestions = data,
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  sortAndFilterEmailSuggestions(suggestions) {
    const emailsuggestion = JSON.parse(JSON.stringify(suggestions));
    this.emailSuggestion = (emailsuggestion as unknown as ISuggestion[]);
    this.emailSuggestion = this.emailSuggestion.filter((v, i, a) => a.findIndex(t => (t['email'].toLowerCase() === v['email'].toLowerCase())) === i);
    this.emailSuggestion.sort((a, b) => a['email'].toLowerCase() < b['email'].toLowerCase() ? -1 : a['email'].toLowerCase() > b['email'].toLowerCase() ? 1 : 0);
  }

  sortAndFilterUserIdSuggestions(suggestions) {
    const userIdSuggestion = JSON.parse(JSON.stringify(suggestions));
    this.userIdSuggestion = (userIdSuggestion as unknown as ISuggestion[]);
    this.userIdSuggestion = this.userIdSuggestion.filter((v, i, a) => a.findIndex(t => (t['id'] === v['id'])) === i);
    this.userIdSuggestion.sort((a, b) => a['id'] < b['id'] ? -1 : a['id'] > b['id'] ? 1 : 0);
  }

  getList(refresh = false) {
    $('#refreshTask').addClass('fa-rotation');
    this.commitmentDelegateService.getList(this.queryParam, refresh)
      .pipe(take(1))
      .subscribe(
        res => {
          console.log('List', res);
          this.isError = false;
          this.delegateList = res.body as [];
          if (res.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(res.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  filterList() {
    this.isSubmitted = true;
    if (this.filterForm.valid) {

      this.userFilterService.saveFilter(this.route, this.filterForm);
      this.initQueryParams();
      let filterValues = this.filterForm.value;

      if (filterValues['name'])
        this.queryParam.fromName = filterValues['name']
      if (filterValues['email'])
        this.queryParam.fromEmailId = filterValues['email']
      if (filterValues['userId'])
        this.queryParam.fromUserId = filterValues['userId'];

      this.setOrderBy(this.sortingObject);
      this.getList();
      this.filtered = true
    }
  }

  clearFilterList() {
    this.filtered = false;
    this.initQueryParams();
    this.initFilterForm();
    this.userFilterService.clearFilter();
    this.getList();
    this.fdlFilterHelper.showSearchIconWhenResetFilter();
  }

  navigate(event) {
    this.userDelegate.delegateGUID = event.data;
    if (event.action === "view" || event.action === "edit") {
      if (event.action === "edit") {
        this.commitmentDelegateSelectPopupComponent.isEdit = true;
        this.commitmentDelegateSelectPopupComponent.isReadOnly = false;
        this.commitmentDelegateSelectPopupComponent.isCreate = false;
      } else {
        this.commitmentDelegateSelectPopupComponent.isReadOnly = true;
        this.commitmentDelegateSelectPopupComponent.isCreate = false;
        this.commitmentDelegateSelectPopupComponent.isEdit = false;
      }

      this.commitmentDelegateService.get(event.data).subscribe(
        res => {
          console.log(res)
          console.log(this.commitmentDelegateSelectPopupComponent.delegateForm)
          this.commitmentDelegateSelectPopupComponent.reponderDataUpdate(res);
          this.openDelegatePopup();
        },
        error => {
          this.isError = true; this.errorMsg = error;
        }
      )
     
    } else if (event.action === "delete") {
      this.recentSelectedItemToDeleteGuid = event.data;
      $('#deleteDelegateConfirmModal').modal('show');
    }
  }

  recentSelectedItemToDeleteGuid: string;

  doDeleteDelegate(): any {
    this.successMsg = '';
      this.commitmentDelegateService.delete(this.recentSelectedItemToDeleteGuid).subscribe(
        _ => {
          this.getList(true);
          this.successMsg = `The delegate has been deleted.`;
          $('#deleteDelegateConfirmModal').modal('hide');
        },
        error => {
          this.isError = true; this.errorMsg = error;
        }
      )
  }

  closeDeleteDelegate(): any {
    $('#deleteDelegateConfirmModal').modal('hide');
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.getList();
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.getList();
  }

  setOrderBy(sortingData) {
    if (sortingData) {
      this.queryParam.orderBy = (sortingData.isAsc) ? sortingData.key : sortingData.key + " desc";
    }
  }

  downLoadType(event: any) {
    if (event.type == 'xlsx') {
      this.exportExcel();
    }
  }

  exportExcel() {
    this.userService.exportUsersList(this.queryParam).subscribe(
      res => {
        console.log(res.body);
        let newBlob = new Blob([res.body], { type: "application/vnd.ms-excel" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
          const data = window.URL.createObjectURL(newBlob);
          const filename = 'Users.xlsx';
          const link = document.createElement('a');
          link.href = data;
          link.download = filename;
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  downloadFile(response) {
    let url = window.URL.createObjectURL(response);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }


  openDelegatePopup(isCreate = false): any {
    this.commitmentDelegateSelectPopupComponent.isCreate = isCreate;
    $('#delegateForms').modal('show');
  }

  successDelegateClick(evt) {
    this.refresh();
    this.successMsg = evt.delegateSuccessMsg;
  }

  private getPageTemplateData(): void {
    this.clientCommitmentsService
      .getPageTemplateById(this.pageTemplateId)
      .pipe(take(1))
      .subscribe(
        (page) => {
          this.pageTemplate = page.body as IPageTemplate;
          this.isCCTAdmin = this.pageTemplate.pageTemplateAdmins?.some(a => a.userId === this.userData.id);
          this.isCreateAllowed =  this.isEditAllowed = this.isDeleteAllowed = this.isCCTAdmin;
          if (!this.isCCTAdmin) {
            this.router.navigate(['/']);
          }
        },
        (error) => {
        }
      );
  }

  ngOnDestroy(): void {
  }
}
