<div class="form-container mt-3 mb-3">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12">
                <div id="clientName" class="part">
                    <h4>{{clientName}}</h4>
                </div>
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="part">
                    <h2>Client Dashboard Review Trail</h2>
                    <div class="apland-timeline-area">
                        <div class="single-timeline-area blue header-timeline">
                            <div class="row timeline-header">
                                <div class="col-md-3 col-lg-3">
                                    <div class="single-timeline-content d-flex">
                                        <div class="timeline-text">
                                            <p>Modified Date</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3">
                                    <div class="single-timeline-content d-flex">
                                        <div class="timeline-text">
                                            <p>Project Id</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3">
                                    <div class="single-timeline-content d-flex">
                                        <div class="timeline-text">
                                            <p>Title</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-lg-2">
                                    <div class="single-timeline-content d-flex">
                                        <div class="timeline-text">
                                            <p>Modified By</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="noData" class="single-timeline-area grey">
                            There is no update
                        </div>
                        <div id="clientReviewTrail" *ngFor="let timeline of data" class="single-timeline-area grey">
                            <div class="row" (click)="selectProjectNumberId(timeline.pageTemplateResponseId)"
                                data-toggle="modal" data-target="#reviewTrail">
                                <div class="timeline-date"></div>
                                <div class="col-md-3 col-lg-3">
                                    <div class="single-timeline-content d-flex">
                                        <div class="timeline-text">
                                            <p class="h6">{{timeline.modifiedOn | dateFormat}}</p>
                                            <p>{{timeline.modifiedOn | formatDateTime}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3">
                                    <div class="single-timeline-content d-flex">
                                        <div class="timeline-text">
                                            <p class="h6">{{timeline.projectNumber}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3">
                                    <div class="single-timeline-content d-flex">
                                        <div class="timeline-text">
                                            <p class="h6">{{timeline.title}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3">
                                    <div class="single-timeline-content d-flex">
                                        <div class="timeline-text">
                                            <p class="h6">{{timeline.modifiedByName}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="part">
                    <h2>Client Dashboard Note Review Trail</h2>
                    <div id="noteReviewTrail" class="apland-timeline-area">
                        <div class="single-timeline-area blue header-timeline">
                            <div class="row timeline-header">
                                <div class="col-md-3 col-lg-3">
                                    <div class="single-timeline-content d-flex">
                                        <div class="timeline-text">
                                            <p>Modified Date</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="single-timeline-content d-flex">
                                        <div class="timeline-text">
                                            <p>Note</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-lg-2">
                                    <div class="single-timeline-content d-flex">
                                        <div class="timeline-text">
                                            <p>Modified By</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="noteNoData" class="single-timeline-area grey">
                            There is no update
                        </div>
                        <div *ngFor="let timeline of noteData" class="single-timeline-area grey">
                            <div class="row">
                                <div class="timeline-date"></div>
                                <div class="col-md-3 col-lg-3">
                                    <div class="single-timeline-content d-flex">
                                        <div class="timeline-text">
                                            <p class="h6">{{timeline.createdOn | dateFormat}}</p>
                                            <p>{{timeline.createdOn | formatDateTime}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="single-timeline-content d-flex">
                                        <div class="timeline-text">
                                            <pre>{{timeline.note}}</pre>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3">
                                    <div class="single-timeline-content d-flex">
                                        <div class="timeline-text">
                                            <p class="h6">{{timeline.createdByName}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-client-review-trail [id]="id" (modalClosed)="modalClosed()"></app-client-review-trail>