import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';
import { PermissionsService } from '../services/permissions.service';
import { map } from 'rxjs/operators';
import { allowedPermissions, ROLES } from './constants';
import { IUser } from '../shared/user';
import { LogoutService } from '../SharedModule/services/logout.service';

@Injectable({
  providedIn: 'root'
})
export class UserInitializeGuard implements CanActivate, CanLoad {
  constructor(private userService: UserService, private permissionService: PermissionsService, private router: Router, private logOutService: LogoutService) { };
  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    const user = this.userService.getUserListData();
    if (!user) {
      return this.userService.callUserListData().pipe(map((data) => {
        if (data.isMigratedToNewCCT != undefined && data.isMigratedToNewCCT) {
          this.logOutService.signOutAccessDeniedMigratedUser();
          return false;
        }

        this.userService.setUserListData(data);
        this.permissionService.setUserData(data);
        let usersPermission = [];
        data.permissions.forEach((ele) => {
          let permission: any = allowedPermissions[ele];
          for (let perm in permission) {
            if (permission[perm])
              usersPermission[perm] = permission[perm]
          }
        });

        this.userService.setPermissions(usersPermission);
        return true;

      },
        (error) => {
          console.log(error);
          
        }));
    }
    else {
      return of(true);
    }
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    const user = this.userService.getUserListData();
    if (!user) {
      return this.userService.callUserListData().pipe(map((data) => {
        // #274199: If a client user has only ‘Client Executive’ role, then user should re-direct to ‘Client Dashboard’ page

        if (data.isMigratedToNewCCT != undefined && data.isMigratedToNewCCT) {
          this.logOutService.signOutAccessDeniedMigratedUser();
          return false;
        }

        if (this.isClientExecutiveUserOnly(data)) {
          this.router.navigate(['/commitmentdashboard']);
          return true;
        }
       
        this.userService.setUserListData(data);
        this.permissionService.setUserData(data);
        let usersPermission = [];
        data.permissions.forEach((ele) => {
          let permission: any = allowedPermissions[ele];
          for (let perm in permission) {
            if (permission[perm])
              usersPermission[perm] = permission[perm]
          }
        });

        this.userService.setPermissions(usersPermission);
        return true;
      },
        (error) => {
          console.log(error);

        }));
    }
    else {
      return of(true);
    }
  }

  isClientExecutiveUserOnly(user: IUser): boolean {
    return (user.roles.length === 1 && user.roles[0].roleTitle === ROLES.Client_Executive);
  }
}
