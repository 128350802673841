import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterViewChecked,
} from '@angular/core';
import { ProjectsService } from '../services/projects.service';
import { FormsService } from '../services/forms.service';
import { UserService } from '../services/user.service';
import { forkJoin, Subject } from 'rxjs';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DateFormatPipe } from '../SharedModule/Pipes/date-format.pipe';
import { IPager } from '../shared/pagination';
import { IQuery } from '../shared/query';
import { environment } from 'src/environments/environment';
import { IClients } from 'src/app/shared/client';
import { ISubmitRequest } from '../shared/submit-request';
import { Sort } from 'src/app/shared/sort';
import { ProjectDrawerService } from '../SharedModule/Components/project-drawer-container/project-drawer.service';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { PermissionsService } from '../services/permissions.service';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE, MAX_LENGTHS } from '../core/constants';
import { ProjectsDTOService } from '../Projects/Services/projectsDTO.service';
import { MutipleaddComponent } from '../SharedModule/Components/mutipleadd/mutipleadd.component';
import { IResponseStatusResloved } from '../shared/status';
import { ClientsService } from '../services/clients.service';
import { PreferenceService } from '../services/preference.service';
import { ContentService } from '../services/content.service';
import { IForm, IForms } from '../shared/form';
import { HttpResponse } from '@angular/common/http';

declare var $: any;
@Component({
  selector: 'app-submit-request',
  templateUrl: './submit-request.component.html',
  styleUrls: ['./submit-request.component.css'],
  providers: [DateFormatPipe],
})
export class SubmitRequestComponent implements OnInit, AfterViewChecked {
  clientText: string;
  clientList: IClients;
  assignIntakeRequestForm: FormGroup;
  initiateIntakeRequestForm: FormGroup;
  initatorDrawer: boolean = false;
  isError: boolean;
  errorMsg: string;
  filedJson: any;
  guestfieldJson: any;
  productSuggestion: any = {};
  orgSuggestion: any = {};
  filteredClientList: any = [];
  _maxLength: any;
  regexValidationMessage: any;
  isClient: boolean = true;
  sortingObject;
  todaysdate: string;
  displayColumns = ['Form Name', 'Description', 'Product Name'];
  keys = ['title', 'description', 'productTitle'];
  typeofelements = ['fdl'];
  responseStatusData;
  pageOfItems: Array<any>;
  clientSuggestion: any = {};
  pagerObject: IPager;
  paginationArr: any;
  queryParam: IQuery;
  currentPageNumber: number = 1;
  submitRequestList: IForm[];
  filteredSubmitRequestList: ISubmitRequest[];
  sortedSubmitRequestList: ISubmitRequest[];
  loadDrawer: boolean;
  createClientAllowed: boolean;
  private _destroySubject = new Subject();
  successMsg: string;
  showSuccessMsg: boolean;
  clientNameFromDrawer: string;
  orgUsersList: any = { data: '' };
  orgUsersListUses: any = { data: '' };
  isSubmitted: boolean;
  batchNameError: string = 'Please Select package Name.';
  requestor: any;
  selectedForm: any;
  filedDetails: any[] = [];
  allPackagesData: any[] = [];
  guestUserForm: any[] = [];
  columns = ['Form Name', 'Description', 'Due Date (mm/dd/yyyy)'];
  intakeKeys : any[] = [];
  invalidClientName: boolean = false;
  isFiservUser: boolean = true;
  requestorJson: any = {
    name: ['', [Validators.required]],
    email: '',
  };
  isChannelPartner: boolean = false;
  formSets: any = [];
  @ViewChild('addmultiple') callFunction: MutipleaddComponent;
  @ViewChild('addmultiple2') callFunction2: MutipleaddComponent;
  registerDrawer: boolean;
  showSuccessMsgClient: boolean;
  successMsgClient: string;
  userData: any;
  isBulkRequest: boolean;
  batchrequesterror: boolean = false;
  packageName: any = '';
  listname: string = PreferenceService.intakeRequestList;
  content: any[];
  filtered: boolean = false;
  title: string;
  selectedClientValue: string = '';
  productText: string;
  orgText: string;
  selectedClientId: number = 0;
  isAdmin: boolean = false;
  submitRequestListEdit: any = [];


  constructor(
    private _projectService: ProjectsService,
    private clientsService: ClientsService,
    private _formService: FormsService,
    private _userService: UserService,
    private _router: Router,
    private _datePipe: DateFormatPipe,
    private _drawerService: ProjectDrawerService,
    private _permissionService: PermissionsService,
    private _projectDto: ProjectsDTOService,
    private fb: FormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private userService: UserService,
    private contentService: ContentService,
    private projectService: ProjectsService
  ) {}

  ngOnInit(): void {
    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then((data) => {
        this.content = this.contentService.getcontent();
        this.displayColumns = [
          this.content['formNameColumnDashboard'],
          this.content['descriptionColumnIntakeRequest'],
          this.content['productNameColumnIntakeRequest'],
        ];
      });
    } else {
      this.displayColumns = [
        this.content['formNameColumnDashboard'],
        this.content['descriptionColumnIntakeRequest'],
        this.content['productNameColumnIntakeRequest'],
      ];
    }
    this.todaysdate = this._projectService.getTodaysDate();
    this.isClient = this.userService.isClient;
    this.userData = this._permissionService.userData;
    this.isAdmin = this._permissionService.userData.isAdmin;
    for (let p in this.userData.roles) {
      let role = this.userData.roles[p].roleTitle.toLowerCase();
      if (role.indexOf('channel') != -1) {
        this.isChannelPartner = true;
      }
    }
    this.formSets[0] = {
      assignedFormsList: [],
    };
    this._maxLength = MAX_LENGTHS;
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    const resolvedData: IResponseStatusResloved =
      this.route.snapshot.data['resolveData'];
    this.responseStatusData = resolvedData.responseStatus.find(
      (data) => data.title.trim().toLowerCase() === 'not started'
    );
    this.initFilterSubmitRequest();
    this.apiFormData();
    this.filedJson = {
      json: {
        name: ['', this.formValidators['requestor']],
        emails: '',
        id: '',
      },
    };
    this.guestfieldJson = {
      json: {
        emailAddress: ['', this.formValidators['emailAddress']],
        firstName: ['', this.formValidators['firstName']],
        lastName: ['', this.formValidators['lastName']],
      },
    };
  }

  initFilterSubmitRequest() {
    this.assignIntakeRequestForm = this.fb.group({
      title: new FormControl('', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),
      productTitle: new FormControl(''),
      organizationTitle: new FormControl(''),
      formName: new FormControl('', [Validators.required, Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),
      clientName: new FormControl(''),
      dueDate: new FormControl(''),
      requestorType: ['user', [Validators.required]],
      requestor: this.fb.array([]),
      guestRequestor: this.fb.array([]),
      initiatorName: new FormControl(''),
      description: '',
      batchrequest: new FormControl(''),
      package: new FormControl('no'),
      requestorPackage: new FormControl('no'),
    });

    this.initiateIntakeRequestForm = this.fb.group({
      formName: new FormControl('', [Validators.required, Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),
      clientName: new FormControl(''),
      dueDate: new FormControl(''),
      initiatorName: new FormControl(''),
      description: '',
      batchrequest: new FormControl(''),
      package: new FormControl('no'),
      requestorPackage: new FormControl('no'),
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  typeaheadLoadingClients() {
    if (this.clientText.length >= 3) {
      this.getClientSuggestions();
    }
  }

  getClientSuggestions() {
    let suggestionSubscriber = this.projectService.getClientSuggestions(
      'clients',
      null,
      100
    );
    let params = [];
    if (this.clientText && this.clientText.length >= 3) {
      params.push({ paramName: 'titleWithDuns', value: this.clientText });
      suggestionSubscriber = this.projectService.getClientSuggestions(
        'clients',
        params,
        100
      );
    }
    suggestionSubscriber.subscribe(
      (data) => {
        this.clientSuggestion = data;
      },
      (error) => {
        this.isError = true;
        this.errorMsg = error;
      }
    );
  }


  getProductSuggestions(){
    let suggestionSubscriber: any;
    let params = [];
    if(this.productText && this.productText.length > 1) {
      params.push({paramName: "title", value: this.productText});
      suggestionSubscriber = this.projectService.getProductSuggestions("products", params, 100);
    }
    else {
      suggestionSubscriber = this.projectService.getProductSuggestions("products", null, 100);
    }

    suggestionSubscriber.subscribe(
      data=> {
        this.productSuggestion = data;
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  typeaheadLoadingProducts() {
    if (this.productText.length > 1 || this.productText.length == 0) {
      this.getProductSuggestions();
    }
  }

  mapForms(formData: HttpResponse<IForms>) {
    if (formData.body?.forms && formData.body.forms.length > 0) {
      for (let i = 0; i < formData.body.forms.length; i++) {
        formData.body.forms[i].cDueDate = 'mm/dd/yyyy';
      }
    }
    return formData;
  }

  apiFormData() {
    let apiCall = [];
    apiCall.push(this.projectService.getProductSuggestions("products", null, 100));
    //
    apiCall.push(
      this._formService.getInTakeFormsByClientId(null).pipe(map((formData) => this.mapForms(formData)))
    );
    apiCall.push(this._formService.getUsersAll({ includeClientUsers: false }));
    apiCall.push(this._projectService.getClientSuggestions('clients', [], 100));
    apiCall.push(this._formService.getPackagesAll(true));
    apiCall.push(this.projectService.getSuggestions("organizations", ""));
    forkJoin(apiCall).subscribe(
      (data) => {
        let allPackages: any = data[4];
        this.productSuggestion = data[0];
        this.clientSuggestion = data[3];
        this.orgUsersList = { data: data[2] };
        this.orgSuggestion = data[5];
        let formsData = data[1] as HttpResponse<IForms>;
        let submitRequests = formsData.body?.forms;
        if (formsData.headers.get('X-pagination')) {
          this.pagerObject = Object.assign({}, JSON.parse(formsData.headers.get('X-pagination')) as IPager);
          this.pagerObject.pageSize = environment.pageSize;
        }

        if(this.pagerObject.totalPages === 1){
          this.getFilterList(submitRequests);
        }
        else if(this.pagerObject.totalPages > 1) {
          for (let page = this.pagerObject.currentPage + 1; page <= this.pagerObject.totalPages; page++){
            this.getMoreForms(page).pipe(map((formData) => this.mapForms(formData))).subscribe(data => {
              submitRequests = [...submitRequests, ...data.body?.forms];
              this.getFilterList(submitRequests);
            });
          }
        }

        this.filedDetails = [
          {
            name: 'name',
            type: 'fdl',
            label: 'Form Respondent',
            isRequired: true,
            fdldata: this.orgUsersList,
            emailField: 'emails',
            showEmailAsLabel: 'no',
            className: 'col-md-10',
            erroeMessage: { valid: 'Please select Respondent.' },
          },
          {
            name: 'emails',
            type: 'text',
            isDisabled: 'yes',
            label: 'Email',
            className: 'col-md-10',
          },
          { name: 'id', type: 'fdlid' },
        ];

        this.guestUserForm = [
          {
            name: 'emailAddress',
            type: 'email',
            label: 'Respondent Email',
            isRequired: true,
            className: 'col-md-10',
            erroeMessage: { valid: this.regexValidationMessage.EMAIL_VALIDATION_MESSAGE },
          },
          {
            name: 'firstName',
            type: 'text',
            label: 'Respondent First Name',
            isRequired: true,
            className: 'col-md-10',
            erroeMessage: { valid: this.regexValidationMessage.USERNAME_VALIDATION_MESSAGE },
          },
          {
            name: 'lastName',
            type: 'text',
            label: 'Respondent Last Name',
            isRequired: true,
            className: 'col-md-10',
            erroeMessage: { valid: this.regexValidationMessage.USERNAME_VALIDATION_MESSAGE },
          },
        ];

        for (let k = 0; k < allPackages.length; k++) {
          this.allPackagesData.push({
            id: k,
            title: allPackages[k].package,
            clientName: allPackages[k].clientName,
            clientId: allPackages[k].clientId,
          });
        }
      },
      (error) => {
        this.isError = true;
        this.errorMsg = error;
      }
    );
  }

  private getFilterList(submitRequests){
    this.submitRequestList = submitRequests;
    this.filteredSubmitRequestList = JSON.parse(
      JSON.stringify(this.submitRequestList)
    );
    this.sortedSubmitRequestList = JSON.parse(
      JSON.stringify(this.submitRequestList)
    );

    for (let i in this.submitRequestList) {
      this.filteredSubmitRequestList[i].dueDate = this._datePipe.transform(
        this.filteredSubmitRequestList[i].dueDate
      );
      this.sortedSubmitRequestList[i].dueDate = this._datePipe.transform(
        this.sortedSubmitRequestList[i].dueDate
      );
    }
    this.filteredSubmitRequestList = this.filteredSubmitRequestList.slice(
      0,
      this.pagerObject.pageSize
    );

    const isFilterRequired = Object.keys(this.assignIntakeRequestForm.value).every((filter) => this.assignIntakeRequestForm.value[filter] === '');
    if (!isFilterRequired) {
      this.filterSubmitRequest();
    }
  }

  private getMoreForms(pageNumber: number){
    return this._formService.getInTakeFormsByClientId({
      pageNumber: pageNumber
    })
  }

  filterSubmitRequest(checkSearch = false) {
    if (checkSearch === true) {
      this.pagerObject.currentPage = 1;
      this.filtered = true;
    }

    let filteredSubmitRequestList = [...this.sortedSubmitRequestList];
    const filterValues = this.assignIntakeRequestForm.value;

    if (filterValues['title']) {
      filteredSubmitRequestList = filteredSubmitRequestList.filter(
        (response) => response.title.toLowerCase().indexOf(filterValues['title'].toLowerCase()) > -1
      );
    }
    if (filterValues['organizationTitle']) {
      filteredSubmitRequestList = filteredSubmitRequestList.filter(
        (response) => response.organizationTitle.toLowerCase().indexOf(filterValues['organizationTitle'].toLowerCase()) > -1
      );
    }
    if (filterValues['productTitle']) {
      filteredSubmitRequestList = filteredSubmitRequestList.filter(
        (response) => response.productTitle.toLowerCase().indexOf(filterValues['productTitle'].toLowerCase()) > -1
      );
    }

    //this.formSets[0].assignedFormsList = [];
    this.reloadPageSelectedItems(filteredSubmitRequestList);
  }

  clearFilterList() {
    this.filtered = false;

    this.initFilterSubmitRequest();
    const submitRequestList = [...this.sortedSubmitRequestList];
    this.reloadPageSelectedItems(submitRequestList);
  }

  private reloadPageSelectedItems(filteredSubmitRequestList) {
    for (let k in filteredSubmitRequestList) {
      filteredSubmitRequestList[k].selected = false;
      this.formSets[0].assignedFormsList.forEach((row) => {
        if (row.id == filteredSubmitRequestList[k].id) {
          filteredSubmitRequestList[k].selected = true;
        }
      });
    }

    this.pagerObject.totalCount = filteredSubmitRequestList.length;
    this.pagerObject.totalPages = Math.ceil(
      filteredSubmitRequestList.length / environment.pageSize
    );
    this.pagerObject = JSON.parse(JSON.stringify(this.pagerObject));
    const startIdx = this.pagerObject.pageSize * (this.pagerObject.currentPage - 1);
    const endIdx = this.pagerObject.pageSize * this.pagerObject.currentPage;
    this.filteredSubmitRequestList = [...filteredSubmitRequestList].slice(startIdx, endIdx);
  }

  changePage(page) {
    this.pagerObject.currentPage = page;
    this.filterSubmitRequest();
  }

  applySorting(event) {
    this.sortingObject = event;
    const sort = new Sort();
    const order = this.sortingObject.isAsc ? 'asc' : 'desc';
    this.sortedSubmitRequestList.sort(
      sort.startSort(this.sortingObject.key, order, this.sortingObject.key)
    );
    this.filterSubmitRequest();
  }
  getTodaysDate() {
    const today =
      new Date().getFullYear() +
      '-' +
      ('0' + (new Date().getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + new Date().getDate()).slice(-2);
    return today;
  }
  populateClientName(currentUser) {
    if (currentUser.type == 'Client') {
      this.isFiservUser = false;
      const clientName = currentUser.organizationTitle;
      this.initiateIntakeRequestForm.patchValue({ clientName: clientName });
      let item = { id: currentUser.organization };
      this.onInitiateIntakeClientSelect({ item });
    }
  }
  takeAction(event) {
    this.selectedForm = event.data;
    this.invalidClientName = false;

    if (event.action == 'Initiate') {
      this.openInitiateIntakeRequestSidebar(event);
    } else if (event.action == 'User') {
      this.openAssignIntakeRequestSidebar(event);
    }
  }
  initiateInakeButton(type) {
    if (type == 'User') {
      this.openAssignIntakeRequestSidebar({ action: '', data: '' });
    } else {

      this.openInitiateIntakeRequestSidebar({ action: '', data: '' })
    }
    $('#containerPage').scrollTop(0);
    $('.container-page').addClass('overflow-hidden');
  }
  private openInitiateIntakeRequestSidebar(event) {
    this.isSubmitted = false;
    const currentUser = this._userService.getUserListData();
    if (currentUser.type == 'Client') {
      this.isFiservUser = false;
      this.populateClientName(currentUser);
    }
    this.formSets[0].assignedFormsList = JSON.parse(
      JSON.stringify(this.formSets[0].assignedFormsList)
    );

    let selectPackageValue = 'no';
    if (this.formSets[0].assignedFormsList.length > 1) {
      selectPackageValue = 'yes';
    }

    this.formSets[0].assignedFormsList.title;

    this.columns = ['Form Name', 'Description']
    this.intakeKeys = ['title', 'description'];

    if (this.formSets[0].assignedFormsList.length == 1) {
      this.title = this.formSets[0].assignedFormsList[0].title;
    } else if (this.formSets[0].assignedFormsList.length == 0) {
      this.title = event.data.title;
    }

    const titleFormControl = this.formSets[0].assignedFormsList.length > 1 ? new FormControl(this.title) : new FormControl(this.title, [Validators.required, Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]);
    this.initiateIntakeRequestForm = this.fb.group({
      dueDate: new FormControl(''),
      formName: titleFormControl,
      clientName: new FormControl(''),
      initiatorName: new FormControl(currentUser.name),
      description: new FormControl(),
      initiateDate: new FormControl(this.getTodaysDate()),
      batchrequest: new FormControl('', [ Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),
      requestorPackage: new FormControl('no'),
      package: new FormControl(selectPackageValue),
    });
    this.initatorDrawer = true;
    this.onSubmitIntakeFormChanges();
  }
  private formatDate(date: Date): string{
    const day=(date.getDate()+21).toString().padStart(2,'0');
    const month=(date.getMonth()+1).toString().padStart(2,'0');
    const year=date.getFullYear();
    return `${month}/${day}/${year}`;
  }
  private openAssignIntakeRequestSidebar(event) {
    const currentDate = new Date();
    let dueDate = this.formatDate(currentDate);
    this.columns = ['Form Name', 'Description', 'Due Date (mm/dd/yyyy)'];
    this.intakeKeys = ['title', 'description', 'dueDate'];
    this.isSubmitted = false;
    let currentUser = this._userService.getUserListData();
    let selectPackageValue = 'no';
    if (this.formSets[0].assignedFormsList.length > 1) {
      selectPackageValue = 'yes';
    }
    // this.formSets[0].assignedFormsList = JSON.parse(
    //   JSON.stringify(this.formSets[0].assignedFormsList)
    //     .split('12/31/0001')
    //     .join(dueDate)
    // );

    this.formSets[0].assignedFormsList.forEach((row) => {
      row.dueDate=dueDate;
    });

    if (this.formSets[0].assignedFormsList.length == 1) {
      this.title = this.formSets[0].assignedFormsList[0].title;
    } else if (this.formSets[0].assignedFormsList.length == 0) {
      this.title = event.data.title;
    }
    else {
      this.title = event.data.title;
    }
    const titleFormControl = this.formSets[0].assignedFormsList.length > 1 ? new FormControl(this.title) : new FormControl(this.title, [Validators.required, Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]);
    this.assignIntakeRequestForm = this.fb.group({
      requestorType: ['user', [Validators.required]],
      requestor: this.fb.array([]),
      guestRequestor: this.fb.array([]),
      dueDate:new FormControl(''),
      formName: titleFormControl,
      clientName: new FormControl(''),
      initiatorName: new FormControl(currentUser.name),
      batchrequest: new FormControl('', [ Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),
      package: new FormControl(selectPackageValue),
      requestorPackage: new FormControl('no'),
    });

    this.initiateIntakeRequestForm = this.fb.group({
      dueDate: new FormControl(''),
      formName: new FormControl(this.title, [Validators.required, Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),
      clientName: new FormControl(''),
      initiatorName: new FormControl(currentUser.name),
      batchrequest: new FormControl(''),
      requestorPackage: new FormControl('no'),
      package: new FormControl('no'),
    });

    this.registerDrawer = true;
  }

  /********************DRAWER CHANGES BEGIN*************************/
  openAddClientDrawer() {
    //$('#initateUser').modal('hide')
    //  $('#requestUser').modal('hide')
    this.registerDrawer = false;
    this.initatorDrawer = false;
    this.loadDrawer = true;
  }
  ngAfterViewInit() {
    this.createClientAllowed = this._permissionService.isCreateClientAllowed();
    this.subscribeDrawerService();
  }
  private async subscribeDrawerService() {
    this._drawerService.closeDrawer$
      .pipe(takeUntil(this._destroySubject))
      .subscribe((postData) => {
        if (postData) {
          this.loadDrawer = false;
          this.initatorDrawer = true;
          if (postData['submitPressed']) {
            this.successMsgClient = `Client has been added successfully.`;
            this.showSuccessMsgClient = true;
            $('.container-page').addClass('overflow-hidden');
            this.initatorDrawer = true;
            // $('#initateUser').modal('show')
            this._projectService.getSuggestions('clients', '').subscribe(
              (data) => {
                this.isError = false;
                this.clientSuggestion = data;
                this.setClientName(postData);
              },
              (error) => {
                (this.isError = true), (this.errorMsg = error);
              }
            );
            if (postData['entityName']) {
              this.clientNameFromDrawer = postData;
            }
          }
        }
      });
  }
  ngOnDestroy() {
    $('.container-page').removeClass('overflow-hidden');
    this._drawerService.clear();
    this._destroySubject.next();
    this._destroySubject.complete();
    this.selectedClientValue = '';
  }

  setClientName(postData: any) {
    this.initiateIntakeRequestForm.controls['clientName'].setValue(
      postData['entityName']
    );
    this.initiateIntakeRequestForm.controls['description'].setValue(
      postData['additionalData']
    );

    this.clientNameFromDrawer = '';
  }

  /********************DRAWER CHANGES END*************************/
  /************************Modal Close****************************/
  closeModelForm(id: any) {}
  /***********************************************************/

  /**********************Form Validators***********************/
  requiredIfValidator(predicate) {
    return (formControl) => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    };
  }
  formValidators = {
    requestor: [
      this.requiredIfValidator(
        () => this.assignIntakeRequestForm.get('requestorType').value == 'user'
      ),
    ],
    emailAddress: [
      this.requiredIfValidator(
        () => this.assignIntakeRequestForm.get('requestorType').value == 'guest'
      ),
      Validators.pattern(REGEX_PATTERNS.Email),
      Validators.maxLength(MAX_LENGTHS.User.Email),
    ],
    firstName: [
      this.requiredIfValidator(
        () => this.assignIntakeRequestForm.get('requestorType').value == 'guest'
      ),
      Validators.pattern(REGEX_PATTERNS.UserName),
      Validators.maxLength(MAX_LENGTHS.User.Name),
    ],
    lastName: [
      this.requiredIfValidator(
        () => this.assignIntakeRequestForm.get('requestorType').value == 'guest'
      ),
      Validators.pattern(REGEX_PATTERNS.UserName),
      Validators.maxLength(MAX_LENGTHS.User.Name),
    ],
  };

  respondentEmailValidator(control: AbstractControl): { [key: string]: boolean } {

    return;

  }

  formValidatorsDynamic(fieldName) {
    let formValidators = {
      requestor: [this.requiredIfValidator(() => this.assignIntakeRequestForm.get('requestorType').value === "user"), this.respondentEmailValidator.bind(this)],
      emailAddress: [this.requiredIfValidator(() => this.assignIntakeRequestForm.get('requestorType').value === "guest"), Validators.pattern(REGEX_PATTERNS.Email), Validators.maxLength(MAX_LENGTHS.User.Email)],
      firstName: [this.requiredIfValidator(() => this.assignIntakeRequestForm.get('requestorType').value === "guest"), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],
      lastName: [this.requiredIfValidator(() => this.assignIntakeRequestForm.get('requestorType').value === "guest"), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],

    }

    return formValidators[fieldName];

  }


  updateFormValidators() {

    let formValidators = {
      requestor: [this.requiredIfValidator(() => this.assignIntakeRequestForm.get('requestorType').value === "user"), this.respondentEmailValidator.bind(this)],
      emailAddress: [this.requiredIfValidator(() => this.assignIntakeRequestForm.get('requestorType').value === "guest"), Validators.pattern(REGEX_PATTERNS.Email), Validators.maxLength(MAX_LENGTHS.User.Email)],
      firstName: [this.requiredIfValidator(() => this.assignIntakeRequestForm.get('requestorType').value === "guest"), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],
      lastName: [this.requiredIfValidator(() => this.assignIntakeRequestForm.get('requestorType').value === "guest"), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],

    }
    switch (this.assignIntakeRequestForm.get('requestorType').value) {
      case 'user':
        this.reEvaluateValidators('requestor');
        break;
      case 'guest':
        this.reEvaluateValidators('guestRequestor');
        break;
    }
  }

  reEvaluateValidators(fieldName, isNested: any = 'no') {
    if (isNested != 'no') {

      if (this.assignIntakeRequestForm.controls[isNested]) {
        let fieldCtrlArray = this.assignIntakeRequestForm.controls[isNested] as FormArray;

        let fieldCtrl = fieldCtrlArray.controls[fieldName]
        fieldCtrl.setValidators(this.formValidatorsDynamic(fieldName));
        fieldCtrl.updateValueAndValidity();

      }
    }
    else {
      let fieldCtrl: any;
      fieldCtrl = this.assignIntakeRequestForm.controls[fieldName];

      fieldCtrl.setValidators(this.formValidatorsDynamic(fieldName));
      if (fieldName != 'formTemplate')
        fieldCtrl.setValidators(this.validation(fieldName, this.assignIntakeRequestForm.controls[fieldName] ? this.assignIntakeRequestForm.controls[fieldName].get(fieldName).value : []));
      fieldCtrl.updateValueAndValidity();
    }
  }
  /********************************************************/

  /*************************** Select Requestor **************************************/
  /********** allow multiple forms to publish  **********/
  noRequestorMatched(event) {
    if (event === true) {
      // this.formSets[i].approverEmail = '';
    }
  }

  onClientRequesterClear() {
    const formArray = this.assignIntakeRequestForm.get("requestor").get("requestor") as FormArray;
    const orgUsersList = this.orgUsersList?.data;
    if (!orgUsersList || orgUsersList.length === 0) {
      return;
    }
    
    let removedIndexs = [];
    formArray.controls.forEach((ctrl, index) => {
      const respondentUserId = ctrl.get("id").value;
      if (!respondentUserId) {
        return;
      }
  
      const respondentUser = orgUsersList.find(u => u.id === respondentUserId);
      if (respondentUser?.isClientUser) {
        if (formArray.controls.length > (removedIndexs.length + 1)) {
          removedIndexs.push(index);
        }
        else {
          formArray.controls[index].reset();
        }
      }
    });

    if (removedIndexs.length > 0) {
      formArray.controls = [...formArray.controls.filter((v, i) => removedIndexs.indexOf(i) < 0)];
    }
  }

  onRequesterClear(event: { data: any, fieldName: string, selectRespondent: number }) {
    const formArray = this.assignIntakeRequestForm.get("requestor").get("requestor") as FormArray;
    formArray.controls[event.selectRespondent].reset();
  }

  selectRequestor(data: any) {
    if (data.item && data.item.id) {
      /*  this.selectedRespondentName[setNo] = data.item.name;
        this.selectedRespondentEmail[setNo] = data.item.email;
        this.formSets[setNo].respondentEmail = this.selectedRespondentEmail[setNo];
        this.respondentIndex = setNo;*/
    }
  }

  /*************************************************************************/
  /*****************************API call for request new Form************************************/
  changeUserType() {
    switch (this.assignIntakeRequestForm.get('requestorType').value) {
      case 'user':
        this.reEvaluateValidators('requestor');
        break;
      case 'guest':
        this.reEvaluateValidators('emailAddress', 'guestRequestor');
        this.reEvaluateValidators('firstName', 'guestRequestor');
        this.reEvaluateValidators('lastName', 'guestRequestor');
        break;
    }
  }
  intakeUserApiCall(
    launchFormRequest: any,
    type: any = 'form',
    redirect: boolean = true
  ) {
    this._formService.publishForms_user(launchFormRequest, 0).subscribe(
      (response) => {
        const ids: number[] = [];
        for (var i = 0; i < launchFormRequest.responses.length; i++) {          
          ids.push(launchFormRequest.responses[i].formId);          
        }
        this._formService.updateFormPublishCount(ids).subscribe(
          () => {
            this.isError = false;

          },
          (error) => { this.isError = true; this.errorMsg = error; }
        );


        if (redirect) {
          this.registerDrawer = false;
          this.initatorDrawer = false;
          if (type == 'form')
            this._router.navigate([
              '/response/view/responses/',
              response[0].id,
            ]);
          else {
            this._router.navigate(['response/list']);
          }
        }
      },

      (error) => {
        this.batchrequesterror = true;
        this.batchNameError = error;
      }
    );
  }
  intakeSurveyApiCall(
    launchFormRequest: any,
    type: any = 'form',
    redirect: boolean = true
  ) {
    let api_call = this._formService.publishForms_nonuser_group(launchFormRequest);
    api_call.subscribe((response) => {
      const ids: number[] = [];
      for (var i = 0; i < launchFormRequest.surveys.length; i++) {
        ids.push(launchFormRequest.surveys[i].formId);
      }
        if (redirect) {
          this.registerDrawer = false;
          this.initatorDrawer = false;
          this._projectDto.setPublishRequestForms(true);
          this.updatePublishCount(ids);
          this._router.navigate(['response/list']);
        }
      },
      (error) => {
        this.batchrequesterror = true;
        this.batchNameError = error;
      }
    );
  }

  updatePublishCount(ids: any[]) {

    this._formService.updateFormPublishCount(ids).subscribe(
      () => {
        this.isError = false;

      },
      (error) => { this.isError = true; this.errorMsg = error; }
    );
  }

  getPackageName(formName: any) {
    this.batchrequesterror = false;
    this.batchNameError = 'Please Select package Name.';
    const requestorPackage = formName.value['requestorPackage'];
    const batchrequest = formName.value['batchrequest'];
    if (requestorPackage == 'yes') {
      if (!batchrequest) {
        this.batchrequesterror = true;
      }
      else {
        let packageId = this._projectService.fetchIdForRequest(
          this.allPackagesData,
          batchrequest
        );

        if (packageId < 0) {
          this.batchrequesterror = true;
          this.batchNameError = 'Please Select package Name.';
        } else {
          this.packageName = batchrequest;
        }
      }
    } else {
      if (!batchrequest.trim()) {
        this.batchrequesterror = true;
      } else {
        let packageId = this._projectService.fetchIdForRequest(
          this.allPackagesData,
          batchrequest
        );
        if (packageId > -1) {
          this.batchrequesterror = true;
          this.batchNameError = 'Please enter unique package name';
        }
        this.packageName = batchrequest; //+ (date.getMonth()+1) + date.getDate() + date.getFullYear() + date.getHours() + date.getMinutes() + date.getSeconds()
      }
    }
    return this.batchrequesterror;
  }
  onInitatedIntakeRequest() {
    this.batchrequesterror = false;
    if (!this.initiateIntakeRequestForm.valid) {
      this.isSubmitted = true;
      this.getPackageName(this.initiateIntakeRequestForm);
      $('.container-page').removeClass('overflow-hidden');
      return;
    }

    $('.container-page').removeClass('overflow-hidden');

    const intakeFormValues = this.initiateIntakeRequestForm.value;
    const clientName = intakeFormValues['clientName'];
    if (!clientName) {
      this.initateIntakeRequest(0, intakeFormValues, clientName);
      return;
    }

    this.projectService.getClientSuggestions('clients', [{ paramName: 'title', value: clientName }], 1).subscribe((clients) =>
    {
      let clientId = -1;
      /*
      if (clients && clients["length"] > 0)
      {
        clientId = clients[0].id;
      }
      */
      clientId = this.selectedClientId;
      this.initateIntakeRequest(clientId, intakeFormValues, clientName);
    },(error) => {
      this.isError = true;
      this.errorMsg = error.message;
    });
  }

  initateIntakeRequest(clientId: number, intakeFormValues: any, clientName: string) {
    if (!!clientName && clientId <= 0) {
      this.invalidClientName = true;
      return;
    }

    if (this.formSets[0].assignedFormsList.length >= 1) {
      let fromTitle: string;
      if (this.formSets[0].assignedFormsList.length == 1) {
        if (intakeFormValues['package'].trim() == 'yes') {
          if (this.getPackageName(this.initiateIntakeRequestForm)) {
            return;
          }
        }
        fromTitle = intakeFormValues['formName'];
      } else if (this.getPackageName(this.initiateIntakeRequestForm)) {
        return;
      }

      let launchForms = [];
      for (let i = 0; i < this.formSets[0].assignedFormsList.length; i++) {
        launchForms.push({
          assignedToId: this._userService.getUserListData().id,
          responseStatusId: this.responseStatusData.id,
          startDate: new Date().toISOString(),
          formId: this.formSets[0].assignedFormsList[i].id,
          title: fromTitle ?? this.formSets[0].assignedFormsList[i].title,
          description: this.formSets[0].assignedFormsList[i].description,
        });
      }
      const isPackageExist = intakeFormValues['requestorPackage'];
      let isExistingPackage = isPackageExist == 'yes' ? 1 : 0;
      const launchFormRequest = {
        clientId: clientId,
        responses: launchForms,
        package: intakeFormValues['batchrequest'],
        isExistingPackage: isExistingPackage,
      };

      this.intakeUserApiCall(launchFormRequest, true);
    } else {
      if (intakeFormValues['package'].trim() == 'yes') {
        if (this.getPackageName(this.initiateIntakeRequestForm)) {
          return;
        }
      }

      const formTitle = intakeFormValues['formName'];
      const launchForms = [
        {
          assignedToId: this._userService.getUserListData().id,
          responseStatusId: this.responseStatusData.id,
          startDate: new Date().toISOString(),
          formId: this.selectedForm.id,
          title: formTitle,
          description: this.selectedForm.description,
        },
      ];

      const isPackageExist = intakeFormValues['requestorPackage'];
      const isExistingPackage = isPackageExist == 'yes' ? 1 : 0;
      const launchFormRequest = {
        clientId: clientId,
        responses: launchForms,
        package: this.packageName,
        isExistingPackage: isExistingPackage,
      };

      this.intakeUserApiCall(launchFormRequest, 'form', true);
    }
  }

  onAssignedIntakeRequest() {
    this.batchrequesterror = false;
    if (!this.assignIntakeRequestForm.valid) {
      this.isSubmitted = true;
      if (this.getPackageName(this.assignIntakeRequestForm)) {
        return;
      }
      this.callFunction.isInputValid();
      return;
    }

    // let clientId = -1;
    const assignFormValues = this.assignIntakeRequestForm.value;
    const clientName = assignFormValues['clientName'];
    if (!clientName) {
      this.assignIntakeRequest(assignFormValues, 0, clientName);
      return;
    }

    this.projectService.getClientSuggestions('clients', [{ paramName: 'titleWithDuns', value: clientName }], 1).subscribe((clients) =>
    {
      let clientId = -1;
      if (clients && clients["length"] > 0)
      {
        clientId = clients[0].id;
      }
      this.assignIntakeRequest(assignFormValues, clientId, clientName);
    },(error) => {
      this.isError = true;
      this.errorMsg = error.message;
    }, () => {
      $('.container-page').removeClass('overflow-hidden');
    });
  }

  private assignIntakeRequest(assignFormValues, clientId: number, clientName: string){
    if (!!clientName && clientId <= 0) {
      this.invalidClientName = true;
      return;
    }

    const requestorType = assignFormValues['requestorType'];
    if (requestorType == 'user' && !this.callFunction.isInputValid()) {
      console.log('error');
      return;
    }
    if (requestorType == 'user') {
      this.assignIntakeRequestForUser(assignFormValues, clientId);
    } else if (requestorType == 'guest') {
      this.assignIntakeRequestForGuest(assignFormValues, clientId);
    }
  }

  private assignIntakeRequestForUser(assignFormValues, clientId) {
    debugger;
    let launchForms = [];
    if (this.formSets[0].assignedFormsList.length >= 1) {
      let fromTitle: string;
      let dueDate: string;
      if (this.formSets[0].assignedFormsList.length == 1) {
        if (assignFormValues['package'].trim() == 'yes') {
          if (this.getPackageName(this.assignIntakeRequestForm)) {
            return;
          }
        }
        fromTitle = assignFormValues['formName'];
        dueDate = assignFormValues['cDueDate'];
      } else if (this.getPackageName(this.assignIntakeRequestForm)) {
        return;
      }
      var formRespondentValue = assignFormValues['requestor']['requestor'];

      var respondents = this.getResponderArray(formRespondentValue);

      for (let i = 0; i < this.formSets[0].assignedFormsList.length; i++) {
        if (this.formSets[0].assignedFormsList[i].cDueDate != 'mm/dd/yyyy') {
          launchForms.push({
            assignedToId: assignFormValues['requestor']['requestor'][0].id,
            responseStatusId: this.responseStatusData.id,
            startDate: new Date().toISOString(),
            formId: this.formSets[0].assignedFormsList[i].id,
            title: fromTitle ?? this.formSets[0].assignedFormsList[i].title,
            description: this.formSets[0].assignedFormsList[i].description,
            dueDate: dueDate ?? this.formSets[0].assignedFormsList[i].cDueDate,
            respondents: respondents,
          });
        } else {
          launchForms.push({
            assignedToId: assignFormValues['requestor']['requestor'][0].id,
            responseStatusId: this.responseStatusData.id,
            startDate: new Date().toISOString(),
            formId: this.formSets[0].assignedFormsList[i].id,
            title: fromTitle ?? this.formSets[0].assignedFormsList[i].title,
            description: this.formSets[0].assignedFormsList[i].description,
            respondents: respondents,
          });
        }
      }

      const isPackageExist = assignFormValues['requestorPackage'];
      const isExistingPackage = isPackageExist == 'yes' ? 1 : 0;
      const launchFormRequest = {
        clientId: !Number.isInteger(clientId) ? 0 : clientId,
        responses: launchForms,
        package: this.packageName,
        isExistingPackage: isExistingPackage,
      };
      this.intakeUserApiCall(launchFormRequest, true);
    } else {
      if (assignFormValues['package'].trim() == 'yes') {
        if (this.getPackageName(this.assignIntakeRequestForm)) {
          return;
        }
      }
      var formRespondentValue = assignFormValues['requestor']['requestor'];
      var respondents = this.getResponderArray(formRespondentValue);

      const formTitle = assignFormValues['formName'];
      if (assignFormValues.dueDate != '') {
        launchForms.push({
          assignedToId: assignFormValues['requestor']['requestor'][0].id,
          responseStatusId: this.responseStatusData.id,
          startDate: new Date().toISOString(),
          formId: this.selectedForm.id,
          title: formTitle,
          description: this.selectedForm.description,
          dueDate: assignFormValues.dueDate,
          respondents: respondents,
        });
      } else {
        launchForms.push({
          assignedToId: assignFormValues['requestor']['requestor'][0].id,
          responseStatusId: this.responseStatusData.id,
          startDate: new Date().toISOString(),
          formId: this.selectedForm.id,
          title: formTitle,
          description: this.selectedForm.description,
          respondents: respondents,
        });

        $('.container-page').removeClass('overflow-hidden');
      }

      const isPackageExist = assignFormValues['requestorPackage'];
      const isExistingPackage = isPackageExist == 'yes' ? 1 : 0;
      let launchFormRequest = {
        clientId: !Number.isInteger(clientId) ? 0 : clientId,
        responses: launchForms,
        package: this.packageName,
        isExistingPackage: isExistingPackage,
      };
      this.intakeUserApiCall(launchFormRequest, 'list', true);
    }
  }

  private assignIntakeRequestForGuest(assignFormValues, clientId) {
    const currentUser = this._userService.getUserListData();
    const surveyClientId = clientId == !Number.isInteger(clientId) ? 0 : clientId;
    const surveyOrganizationId =
      currentUser.type.toLowerCase() == 'fiserv'
        ? currentUser.organization
        : currentUser.clientOrgId;
    const guestRequestor =
      assignFormValues['guestRequestor']['guestRequestor'];
    let recepients = this.getGuestrArray(guestRequestor);
    let suveyList = [];

    let formTitle: string;
    if (this.formSets[0].assignedFormsList.length >= 1) {
      if (this.formSets[0].assignedFormsList.length == 1) {
        if (assignFormValues['package'].trim() == 'yes') {
          if (this.getPackageName(this.assignIntakeRequestForm)) {
            return;
          }
        }
        formTitle = assignFormValues['formName'];
      } else if (this.getPackageName(this.assignIntakeRequestForm)) {
        return;
      }

      for (let i = 0; i < this.formSets[0].assignedFormsList.length; i++) {
        suveyList.push({
          name: formTitle ?? this.formSets[0].assignedFormsList[i].title,
          description: this.formSets[0].assignedFormsList[i].description,
          formId: this.formSets[0].assignedFormsList[i].id,
          package: this.packageName,
          recipients: recepients,
        });
        if (this.formSets[0].assignedFormsList[i].cDueDate != 'mm/dd/yyyy') {
          suveyList[i].dueDate = this.formSets[0].assignedFormsList[i].cDueDate;
        }
      }
      const survey2 = {
        projectId: 0,
        clientId: surveyClientId,
        organizationId: surveyOrganizationId,
        initiator: currentUser.email,
        fiservImplementationManager: currentUser.email,
        surveys: suveyList,
        package: this.packageName,
      };

      this.intakeSurveyApiCall(survey2, 'list', true);
      return;
    }

    if (assignFormValues['package'].trim() == 'yes') {
      if (this.getPackageName(this.assignIntakeRequestForm)) {
        return;
      }
    }

    let survey: any;
    formTitle = assignFormValues['formName'];
    if (assignFormValues.dueDate != '') {
      survey = {
        projectId: 0,
        clientId: surveyClientId,
        organizationId: surveyOrganizationId,
        initiator: currentUser.email,
        fiservImplementationManager: currentUser.email,
        package: this.packageName,
        surveys: [
          {
            name: formTitle,
            description: this.selectedForm.description,
            formId: this.selectedForm.id,
            dueDate: assignFormValues.dueDate,
            recipients: recepients,
          },
        ],
      };
    } else {
      survey = {
        projectId: 0,
        clientId: surveyClientId,
        organizationId: surveyOrganizationId,
        initiator: currentUser.email,
        fiservImplementationManager: currentUser.email,
        package: this.packageName,
        surveys: [
          {
            name: formTitle ?? this.formSets[0].assignedFormsList[0].title,
            description: this.selectedForm.description,
            formId: this.selectedForm.id,
            recipients: recepients,
          },
        ],
      };
    }

    this.intakeSurveyApiCall(survey, 'list', true);
  }

  onAssignIntakeRequestPackageChanges(value) {
    if (value === 'yes') {
      this.clientText = '';
    }

    this.assignIntakeRequestForm.patchValue({ batchrequest: '' });
    this.assignIntakeRequestForm.patchValue({ clientName: '' });
    this.assignIntakeRequestForm.patchValue({ description: '' });

    if (value == 'parent') {
      this.assignIntakeRequestForm.patchValue({ requestorPackage: 'no' });
    }
  }

  onAssignIntakePackageChange(event) {
    if (event === '') {
      this.assignIntakeRequestForm.get('clientName').setValue('');
      this.clientText = '';
      this.assignIntakeRequestForm.patchValue({ description: '' });
      this.onClientNameChange("");
    }
  }

  onAssignIntakePackageSelect(event): any {
    this.batchrequesterror = false;
    if (event.item && event.item.clientId != null && event.item.clientId != '') {
      this.assignIntakeRequestForm.patchValue({ clientName: event.item.clientName });
      this.onAssignIntakeClientSelect({ item: { id: event.item.clientId } });
    } else {
      this.assignIntakeRequestForm.patchValue({ clientName: '' });
      this.assignIntakeRequestForm.patchValue({ description: '' });
    }
    this.onClientRequesterClear();
  }

  getResponderArray(reponderTemp: any) {
    let responders = [];
    for (let k in reponderTemp) {
      responders.push({
        'assignedToId': reponderTemp[k].id
      })
    }
    return responders;
  }

  getGuestrArray(guestTemp: any) {

    let guestTempUserId = [];
    for (let k in guestTemp) {

      guestTempUserId.push({
        "firstName": guestTemp[k].firstName,
        "lastName": guestTemp[k].lastName,
        "email": guestTemp[k].emailAddress,
      })
    }
    return guestTempUserId;

  }
  
  clearForm() {
    $('.container-page').removeClass('overflow-hidden');
    this.assignIntakeRequestForm.reset();
    this.initiateIntakeRequestForm.reset();
    this.initFilterSubmitRequest();
    this.initatorDrawer = false;
    this.registerDrawer = false;
  }
  formInitialized(name: string, form: any) {
    this.assignIntakeRequestForm.setControl(name, form);
    this.updateFormValidators();
  }
  /******************************************************************************************/
  HaveClientPartnerRole() {
    let roles = this._permissionService.userData?.roles;
    let isClientPartner: boolean = false;
    for (let i in roles) {
      let role = roles[i].roleTitle.toLowerCase();
      if (role.indexOf('partner') >= 0) {
        isClientPartner = true;
      }
    }

    return isClientPartner;
  }

  onInitiateIntakePackageChange(event) {
    if (event === '') {
      this.initiateIntakeRequestForm.get('clientName').setValue('');
      this.clientText = '';
      this.initiateIntakeRequestForm.patchValue({ description: '' });
    }
  }

  onInitiateIntakePackageSelect(event): any {
    this.batchrequesterror = false;
    if (event.item && event.item.clientId != null && event.item.clientId != '') {
      this.initiateIntakeRequestForm.patchValue({ clientName: event.item.clientName });
      this.onInitiateIntakeClientSelect({ item: { id: event.item.clientId } });
    } else {
      this.initiateIntakeRequestForm.patchValue({ clientName: '' });
      this.initiateIntakeRequestForm.patchValue({ description: '' });
    }
  }
  onInitiateIntakeRequestorPackageChanges(value) {
    if (value === 'yes') {
      this.initiateIntakeRequestForm.patchValue({ batchrequest: '' });
      return;
    }

    this.initiateIntakeRequestForm.patchValue({ batchrequest: '' });
    this.initiateIntakeRequestForm.patchValue({ clientName: '' });
    this.initiateIntakeRequestForm.patchValue({ description: '' });

    if (value == 'parent')
      this.initiateIntakeRequestForm.patchValue({ requestorPackage: 'no' });

    let currentUser = this._userService.getUserListData();
    if (currentUser.type == 'Client') {
      this.isFiservUser = false;
      this.populateClientName(currentUser);
    }
  }
  /**************************** Client Request Change*******************************************/
  onClientNameChange(event) {
    if (event === "") {
      this._formService.getUsersAll({ includeClientUsers: false }).subscribe((data) => {
        this.orgUsersList = { data: data }
      });

      this.onClientRequesterClear();
    }
  }
  
  onInitiateIntakeClientSelect(event: any){
    this.selectedClientValue = event.item.titleWithDuns;
    this.selectedClientId = event.item.id;
    
    this.clientsService
      .getClientDataById(event.item.id)
      .subscribe(
        (data) => {
          // this.clientData = data;
          this.initiateIntakeRequestForm.patchValue({
            description: data.description,
          });
          this.initiateIntakeRequestForm.patchValue({
            clientName: data.titleWithDuns,
          });
          this._formService.getUsersAll({ includeClientUsers: true, clientId: this.selectedClientId }).subscribe((data) => {
            this.orgUsersList = { data: data }
          });
        },
        (error) => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
  };

  onAssignIntakeClientSelect(event: any){
    this.selectedClientValue = event.item.titleWithDuns;
    this.selectedClientId = event.item.id;
    
    this.clientsService
      .getClientDataById(event.item.id)
      .subscribe(
        (data) => {
          // this.clientData = data;
          this.assignIntakeRequestForm.patchValue({
            description: data.description,
          });
          this.assignIntakeRequestForm.patchValue({
            clientName: data.titleWithDuns,
          });
          this._formService.getUsersAll({ includeClientUsers: true, clientId: this.selectedClientId }).subscribe((data) => {
            this.orgUsersList = { data: data }
          });
        },
        (error) => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
  };

  /*******************************************************************************/

  // Reset the validation status for client name field.
  onSubmitIntakeFormChanges(): void {
    this.initiateIntakeRequestForm
      .get('clientName')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        if (this.invalidClientName) {
          this.invalidClientName = false;
        }
        if (value == '') {
          this.initiateIntakeRequestForm.patchValue({ clientName: '' });
          this.initiateIntakeRequestForm.patchValue({ description: '' });
        }
      });
  }
  changeBatchType(value: string) {
    this.isBulkRequest = false;
    if (value == 'yes') {
      this.isBulkRequest = true;
    }
  }

  myAssignNavigate(event) {
    this.formSets[0].assignedFormsList.splice(this.formSets[0].assignedFormsList.indexOf(event), 1)
    for (let k in this.filteredSubmitRequestList) {
      this.filteredSubmitRequestList[k].selected = false;
      this.formSets[0].assignedFormsList.forEach((row) => {
        if (row.id == this.filteredSubmitRequestList[k].id) {
          this.filteredSubmitRequestList[k].selected = true;
        }
      });
    }

    if (this.formSets[0].assignedFormsList.length == 1) {
      this.title = this.formSets[0].assignedFormsList[0].title;
    } 

    this.assignIntakeRequestForm.controls["formName"].setValue(this.title);
    this.initiateIntakeRequestForm.controls["formName"].setValue(this.title);
  }

  validation(controlName: string, values: FormArray): ValidationErrors {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      //  return { 'duplicate': true };

      let array: any = [];

      if (controlName != 'requestor' && controlName != 'guestRequestor') {


        return null;
      }
      for (let k in values) {
        let keyName = '';
        let fieldValue = values[k];
        if (controlName == 'requestor') {
          keyName = fieldValue.id
        } else if (controlName == 'guestRequestor') {
          keyName = fieldValue.emailAddress
          keyName = keyName.toLocaleLowerCase();
        } else {
          return null;
        }


        if (fieldValue.name != '') {
          if (array[keyName])
            array[keyName].push({ "index": k });
          else {
            array[keyName] = [];
            array[keyName].push({ "index": k });


          }


        }
      }
      for (let i in array) {
        if (array[i].length > 1) {
          return { 'duplicate': true };
        }
      }
      return null;
    }

  }

  rowsChanged(event){
    this.formSets[0].assignedFormsList = event.data;
  }

}
