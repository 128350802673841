export enum CommitmentsIntakeSearchType {
    ClientName,
    Product,
    ClarityProjectNumber,
    CommitmentTitle,
    CommitmentStatus,
    CommittedDeliveryDate,
    CommitmentType,
    OpportunityID,
    OpportunityTCV,
    DUNS,
    MC,
    RelationshipManager,
    ClarityProjectStatus,
    DataSource
}