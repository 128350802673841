import { UserFilterResolver } from './../services/user.filter.resolver.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormListComponent } from './manageForms/form-list/form-list.component';
import { CreateFormComponent } from './manageForms/create-form/create-form.component';
import { AdminComponent } from './admin.component';
import { FormTemplateComponent } from './manageForms/form-template/form-template.component';
import { MsalGuard } from '@azure/msal-angular';
import { DirectAccessGuard } from '../SharedModule/Auth/direct-access.guard';
import { ViewUserComponent, } from './manageUsers/view-user/view-user.component';
import { UserListComponent } from './manageUsers/user-list/user-list.component';
import { UserFormComponent } from './manageUsers/user-form/user-form.component';
import { AdminRouteGuard } from './admin-route.guard';
import { ClientListComponent } from './manageClients/client-list/client-list.component';
import { ClientFormComponent } from './manageClients/client-form/client-form.component';
import { ViewClientComponent } from './manageClients/view-client/view-client.component';
import { ViewComponent } from '../formui/view/view.component';
import { SetOrganizationComponent } from './manageOrganization/set-organization/set-organization.component';
import { FormsHistoryComponent } from './forms-history/forms-history.component';
import { UserInitializeGuard } from '../core/user-initialize.guard';
import { ADMIN_MODULES } from '../core/constants';
import { AdminModuleAccessGuard } from './admin-module-access.guard';
import { OnlyAdminAccessGuard } from '../core/only-admin-access.guard';
import { MultipleExecutionGuard } from '../services/multiple-execution.guard';
import { PowerBIInternalComponent } from './powerBI/powerBI-internal-dashboard-component';
import { FormsCompareComponent } from '../formui/compare/compare.component';
import { CreateTopicComponent } from './help/create-topic/create-topic.component';
import { CreateTopicTemplateComponent } from './help/create-topic-template/create-topic-template.component';
import { SelfServiceModule } from '../self-service/self-service.module';
import { UserGroupListComponent } from './manageUserGroups/user-group-list/user-group-list.component';
import { ViewUserGroupComponent } from './manageUserGroups/view-user-group/view-user-group.component';
import { UserGroupFormComponent } from './manageUserGroups/user-group-form/user-group-form.component';
import { RoleListComponent } from './manageRoles/components/role-list/role-list.component';
import { RoleViewComponent } from './manageRoles/components/role-view/role-view.component';
import { RoleFormComponent } from './manageRoles/components/role-form/role-form.component';
import { FeatureListComponent } from './manageFeature/feature-list/feature-list.component';
import { FeatureFormComponent } from './manageFeature/feature-form/feature-form.component';
import { ViewFeatureComponent } from './manageFeature/feature-view/feature-view.component';
import { EditFeatureComponent } from './manageFeature/feature-edit/feature-edit.component';
import { SubfeatureViewComponent } from './manageFeature/subfeature-view/subfeature-view.component';
import { CustomClientListComponent } from './custom-clients/custom-client-list/custom-client-list.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivateChild: [MsalGuard],
    children: [
      {
        path: '', redirectTo: 'form/list', pathMatch: 'full'
      },
      {
        path: 'form', redirectTo: 'form/list', pathMatch: 'full'
      },
      {
        path: 'form/list',
        component: FormListComponent,
        data: { title: 'Forms List', guards: [UserInitializeGuard, AdminRouteGuard] },
        resolve: {
          userFilter: UserFilterResolver
        },
        canActivate: [MultipleExecutionGuard]
      },
      {
        path: 'form/create',
        component: CreateFormComponent,
        data: { title: 'Create New Form', module: ADMIN_MODULES.Forms, guards: [UserInitializeGuard,AdminModuleAccessGuard] },
        canActivate: [MultipleExecutionGuard]
      },

      {
        path: 'form/template',
        component: FormTemplateComponent,
        data: { title: 'Manage Form Template', module: ADMIN_MODULES.Forms, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]
      },
      {
        path: 'form/response/:id',
        component: FormTemplateComponent,
        data: { title: 'Manage Form Template' },
        canActivate: [AdminModuleAccessGuard]
      },
      {
        path: 'form/view/:id',
        component: ViewComponent,
        data: { title: 'view', module: ADMIN_MODULES.Forms, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]
      },
      {
        path: 'intake/view/:id',
        component: ViewComponent,
        data: { title: 'intake', module: ADMIN_MODULES.Intake, guards: [AdminModuleAccessGuard] },
        canActivate: [MultipleExecutionGuard]
      },
      {
        path: "form/history/list/:id",
        component: FormsHistoryComponent,
        data: { title: "Forms History List", module: ADMIN_MODULES.Forms, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]

      },
      {
        path: "form/compare/:id",
        component: FormsCompareComponent,
        data: { title: "Forms Compare", module: ADMIN_MODULES.Forms, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]

      },
      {
        path: "help",
        component: CreateTopicComponent,
        data: { title: "help", module: ADMIN_MODULES.Help, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]

      },
      {
        path: "help/edit/:id",
        component: CreateTopicComponent,
        data: { title: "help", module: ADMIN_MODULES.Help, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]

      },
      {
        path: "help/template",
        component: CreateTopicTemplateComponent,
        data: { title: "help", module: ADMIN_MODULES.Help, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]

      },
      {
        path: "help/templateedit/:id",
        component: CreateTopicTemplateComponent,
        data: { title: "help", module: ADMIN_MODULES.Help, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]

      },
      {
        path: "help/edit/:id/:flowname",
        component: CreateTopicComponent,
        data: { title: "help", module: ADMIN_MODULES.Help, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]


      },

      {
        path: 'form/:edit/view/:id',
        component: ViewComponent,
        data: { title: 'FormTemplate View', module: ADMIN_MODULES.Forms, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]
      },


      {
        path: 'usergroup', redirectTo: 'usergroup/list', pathMatch: 'full'
      },
      {
        path: 'usergroup/list',
        component: UserGroupListComponent,
        data: { title: 'User Groups List', module: ADMIN_MODULES.Users, guards: [OnlyAdminAccessGuard] },
        canActivate: [DirectAccessGuard]
      },
      {
        path: 'usergroup/view/:id',
        component: ViewUserGroupComponent,
        data: { title: 'View User', module: ADMIN_MODULES.Users, guards: [OnlyAdminAccessGuard] },
        canActivate: [DirectAccessGuard]

      },
      {
        path: 'usergroup/create',
        component: UserGroupFormComponent,
        data: { title: 'Create User Group', module: ADMIN_MODULES.Users, guards: [OnlyAdminAccessGuard] },
        canActivate: [DirectAccessGuard]
      },
      {
        path: 'usergroup/edit',
        component: UserGroupFormComponent,
        data: { title: 'Edit User Group', module: ADMIN_MODULES.Users, guards: [OnlyAdminAccessGuard] },
        canActivate: [DirectAccessGuard]
      },


      {
        path: 'user', redirectTo: 'user/list', pathMatch: 'full'
      },
      {
        path: 'user/list',
        component: UserListComponent,
        data: { title: 'Users List', module: ADMIN_MODULES.Users, guards: [AdminModuleAccessGuard] },
        resolve: {
          userFilter: UserFilterResolver
        },
        canActivate: [AdminModuleAccessGuard]
      },
      {
        path: 'user/view/:id',
        component: ViewUserComponent,
        data: { title: 'View User', module: ADMIN_MODULES.Users, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]

      },
      {
        path: 'user/create',
        component: UserFormComponent,
        data: { title: 'Create User', module: ADMIN_MODULES.Users, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]
      },
      {
        path: 'user/edit',
        component: UserFormComponent,
        data: { title: 'Edit User', module: ADMIN_MODULES.Users, guards: [AdminModuleAccessGuard] },
        canActivate: [DirectAccessGuard]
      },
      {
        path: 'user/clone',
        component: UserFormComponent,
        data: { title: 'Clone User', module: ADMIN_MODULES.Users, guards: [AdminModuleAccessGuard] },
        canActivate: [DirectAccessGuard]
      },
      {
        path: 'role', redirectTo: 'role/list', pathMatch: 'full'
      },
      {
        path: 'role/list',
        component: RoleListComponent,
        data: { title: 'Role List', module: ADMIN_MODULES.Roles, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard],
        resolve: {
          userFilter: UserFilterResolver,
        },
      },
      {
        path: 'role/view/:id',
        component: RoleViewComponent,
        data: { title: 'View Role', module: ADMIN_MODULES.Roles, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]
      },
      {
        path: 'role/edit/:id',
        component: RoleFormComponent,
        data: { title: 'Edit Role', module: ADMIN_MODULES.Roles, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]
      },
      {
        path: 'role/clone/:id',
        component: RoleFormComponent,
        data: { title: 'Clone Role', module: ADMIN_MODULES.Roles, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]
      },
      {
        path: 'role/create',
        component: RoleFormComponent,
        data: { title: 'Create Role', module: ADMIN_MODULES.Roles, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]
      },
      {
        path: 'client', redirectTo: 'client/list', pathMatch: 'full'
      },
      {
        path: 'client/list',
        component: ClientListComponent,
        data: { title: 'Clients List', module: ADMIN_MODULES.Clients, guards: [AdminModuleAccessGuard] },
        resolve: {
          userFilter: UserFilterResolver
        },
        canActivate: [AdminModuleAccessGuard]
      },
      {
        path: 'client/create',
        component: ClientFormComponent,
        data: { title: 'Create Client', module: ADMIN_MODULES.Clients, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]
      },
      {
        path: 'client/edit',
        component: ClientFormComponent,
        data: { title: 'Edit Client', module: ADMIN_MODULES.Clients, guards: [AdminModuleAccessGuard] },
        canActivate: [DirectAccessGuard]

      },
      {
        path: 'client/view/:id',
        component: ViewClientComponent,
        data: { title: 'View Client', module: ADMIN_MODULES.Clients, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]
      },
      {
        path: 'product',
        loadChildren: () => import('../product/product.module').then(m => m.ProductModule)
      },
      {
        path: 'form/fileUploads',
        loadChildren: () => import('../self-service/components/manageFileUploads/file-upload.module').then(m => m.FileUploadModule),
        data: { showContinue: true },
      },
      {
        path: 'setOrganization',
        component: SetOrganizationComponent,
        data: { title: 'Set Organization', guards: [OnlyAdminAccessGuard] },
        canActivate: [DirectAccessGuard]
      },
      {

        path: 'emailGroups',
        loadChildren: () => import('./manageEmailGroups/email-group.module').then(m => m.EmailGroupModule),
      },
      {
        path: 'organization',
        loadChildren: () => import('./manageOrganization/organization.module').then(m => m.OrganizationModule),
        data: { guards: [OnlyAdminAccessGuard] },
        resolve: {
          userFilter: UserFilterResolver
        },
        canActivate: [DirectAccessGuard]
      },
      {
        path: 'pages',
        loadChildren: () => import('./managePages/pages.module').then(m => m.PagesModule),
        data: {},
        canActivate: [DirectAccessGuard],
        resolve: {
          userFilter: UserFilterResolver
        },

      },
      {
        path: 'configuration',
        loadChildren: () => import('./manageConfigurations/configuration.module').then(m => m.ConfigurationModule),
        canActivate: [DirectAccessGuard]
      },
      {
        path: 'powerDashboard',
        component: PowerBIInternalComponent,
        data: { title: 'i360 Report' },
        canActivate: [DirectAccessGuard]
      }, 
      {
        path: 'feature/list',
        component: FeatureListComponent,
        data: { title: 'Self Service Feature', module: ADMIN_MODULES.Feature, guards: [AdminModuleAccessGuard] },
        canActivate: [AdminModuleAccessGuard]
      },
      {
        path: 'feature/create',
        component: FeatureFormComponent,
        data: { title: 'Self Service Feature' },
      },
      {
        path: 'feature/view/:id',
        component: ViewFeatureComponent,
        data: { title: 'Self Service Feature', module: ADMIN_MODULES.Feature, guards: [AdminModuleAccessGuard]},
        canActivate: [AdminModuleAccessGuard]
      },
      {
        path: 'feature/edit/:id',
        component: EditFeatureComponent,
        data: { title: 'Self Service Feature', module: ADMIN_MODULES.Feature, guards: [AdminModuleAccessGuard]},
        canActivate: [AdminModuleAccessGuard]
      },
      {
        path: 'feature/view/:id1/:id2',
        component: SubfeatureViewComponent,
        data: { title: 'Self Service Feature', module: ADMIN_MODULES.Feature, guards: [AdminModuleAccessGuard]},
        canActivate: [AdminModuleAccessGuard]
      },
      {
        path: 'custom-client/list',
        component: CustomClientListComponent,
        data: { title: 'Custom Clients', module: ADMIN_MODULES.Feature, guards: [AdminModuleAccessGuard]},
        canActivate: [AdminModuleAccessGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {

}
