<div [formGroup]="formControlData">

    <div formGroupName="{{elementName}}">
        <div formArrayName="{{elementName}}">

            <!-- Rows data in a form array -->
            <div *ngFor="let element of elements().controls; let elementIndex=index"
                [ngClass]="{ 'd-flex': overrideRightMargin }">
                <br>
                <div class="mb-1" *ngIf="elementIndex == 0"><span style="color: unset !important;"><b>From</b></span>
                </div>
                <div class="mb-1" *ngIf="elementIndex == 1"><span style="color: unset !important;"><b>To</b></span>
                </div>
                <div [formGroupName]="elementIndex" class="row "
                    [ngClass]="{'has-error': (duplicated.includes(elementIndex) && duplicated.length >= 2), 'override-right-margin': overrideRightMargin, 'flex-fill': overrideRightMargin }">

                    <div class=" typeahead-container mb-1 mr-sm-0" style="width: {{cssWidth}} ">
                        <div class="child">

                            <!-- Column fields in a row -->
                            <div *ngFor="let fields of fieldDetails; let childElementIndex=index"
                                class=" {{fields.className}}">

                                <div *ngIf="fields.type != 'emailshow' && fields.type!='fdlid'"
                                    [ngClass]="{'has-error': fields.isRequired && element.get(fields.name) && !element.get(fields.name).valid &&  (!element.get(fields.name).pristine || isSubmitted) }">

                                    <label for="element"
                                        [ngStyle]="{'display' : (!(elementIndex == 0 || elementIndex == 1) || (fields.type ==='date' && elementIndex == 0) ? 'none' : 'unset')}"
                                        [ngClass]="{'asterisk':fields.isRequired,'visibility-hidden':!((elementIndex==0 || elementIndex==1) && fields.label),'invisible-text':!((elementIndex==0 || elementIndex==1) && fields.label)}">
                                        {{fields.label}}:

                                        <span *ngIf="fields.tooltip && elementIndex==0" [ngClass]="fields.tooltip.class"
                                            data-toggle="tooltip" [title]="fields.tooltip.title">
                                            {{fields.tooltip.icon}}</span>
                                    </label>

                                    <!-- If create new: Show dropdown control for From user
                                    If view or edit: Hide dropdown control and show input control for From user -->
                                    <fdl-type-ahead id="{{fields.name + elementIndex}}"
                                        (ngModelChange)="onRespondentChange($event, fields.name, elementIndex)"
                                        *ngIf="!isReadOnlyMode && (isCreate || elementIndex > 0) && (fields.type=='fdl' &&  !(isDisable &&  (element.get('isDisable').value=='true'))) "
                                        [collection]="fdlData.data" formControlName="{{fields.name}}"
                                        optionField="{{fields.name === 'name' ? 'name' : fields.name === 'emails' ? 'email' : 'role'}}"
                                        placeholder="Type to search"
                                        (onSelect)="selectRespondent($event, fields.name, elementIndex)"
                                        [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>

                                    <!-- Disabled from user (index 0) when edit or in view mode-->
                                    <input disabled="true" *ngIf="(elementIndex == 0 || isReadOnlyMode) && fields.type=='fdl' && !isCreate" type="text"
                                        class="form-control" formControlName="{{fields.name}}" id="{{fields.name}}" style="border: 1px solid #9e9e9e;">
                                      
                                    <!-- For Role always disabled -->
                                    <input
                                        [attr.disabled]="((fields.isDisabled && fields.isDisabled == 'yes') || (isDisable && element.get('isDisable').value == 'true')) ? true : null"
                                        *ngIf="(fields.type != 'fdl' && fields.type != 'date' || (isDisable &&  (element.get('isDisable').value == 'true')))"
                                        type="{{fields.type}}" class="form-control" style="border: 1px solid #9e9e9e; background-color: #f2f2f2;" formControlName="{{fields.name}}" />

                                    <!-- For Date -->
                                    <input [ngStyle]="{'pointer-events': isReadOnlyMode ?  'none':'initial'}"  *ngIf="elementIndex > 0 && fields.type ==='date'" type="date"
                                        class="form-control" style="border: 1px solid #9e9e9e;" [min]="todaysdate" formControlName="{{fields.name}}"
                                        id="{{fields.name}}">

                                    <span
                                        *ngIf="(element.get(fields.name) && !element.get(fields.name).valid &&  (!element.get(fields.name).pristine || isSubmitted))">
                                        <i class="material-icons">warning</i> {{fields.erroeMessage.valid}}
                                    </span>

                                    <span
                                        *ngIf="fields.type=='fdl' && fields.showEmailAsLabel!='no' && element.get(fields.emailField).value!='' && element.get(fields.name).value!=''"
                                        style="display: block;padding-top: 5px;color: #4b4a4a;padding-bottom: 5px;padding-left: 10px;font-weight: 500;font-size: 13px;">
                                        {{ element.get(fields.emailField).value}}
                                    </span>
                                    <!-- <span
                                        *ngIf="(fields.type=='fdl' && fields.showEmailAsLabel!='no' && element.get(fields.emailField).value=='') || fields.type!='fdl'  "
                                        style="display: block;padding-top: 5px;color: #4b4a4a;padding-bottom: 5px;padding-left: 10px;font-weight: 500;font-size: 13px;">
                                        &nbsp;
                                    </span> -->
                                </div>
                            </div>
                           
                            <div *ngIf="!isReadOnlyMode" class="ml-2 pr-2 action-link"
                                [ngClass]="{'d-none':!(elementIndex > 1 && !(isDisable && element.get('isDisable').value=='true') )}">
                                <a [ngStyle]="{'margin-top' : (elementIndex == 1 ? '17px' : 'unset')}"
                                    (click)="removeSkill(elementIndex)" href="javascript:void(0)"
                                    data-toggle="tooltip" data-placement="top" title="Delete">

                                    <span class="material-icons typeahead-container-icon"
                                        style="display:inline !important">
                                        highlight_off
                                    </span>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isReadOnlyMode" class="row mt-2 mb-2">
        <div class="col-md-3">
            <button (click)="addElements()" tabindex="0" class=" btn fdl-btn btn-primary-dark formio-button-add-row">
                <span class="material-icons">
                    add
                </span>
                {{buttonText}}
            </button>
        </div>
    </div>
</div>