import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SubHeaderService } from '../SharedModule/services/subHeader.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit {

  constructor(private _subHeaderService:SubHeaderService, private cdr: ChangeDetectorRef) { }
  showSideBarNav: boolean;
  formHeading : string = "";
  isCCT: boolean = environment.isCCT;
  
  ngOnInit(): void {
    this._subHeaderService.currentSideBarNav.subscribe(
      data => {
        this.showSideBarNav=data;
        //this.cdr.detectChanges();
      }
    )

    this._subHeaderService.currentStatusBar.subscribe(
      data => {
        this.formHeading=data;
        //this.cdr.detectChanges();
      }
    )
    
  }

}
