import { Component, Input, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-commitment-dashboard-contacts',
  templateUrl: './commitment-dashboard-contacts.component.html',
  styleUrls: ['./commitment-dashboard-contacts.component.css']
})
export class CommitmentDashboardContactsComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  public summary: any;
  public contacts: any[];
  @Input() isExporting: boolean = false;
  private _roleLabels: string[] = ['Relationship Manager', 'Account Manager', 'Strategic Account Manager'];

  ngOnInit(): void {
    $(document).on('click', '.view-contacts-dropdown .dropdown-menu', function (e) {
      e.stopPropagation();
    });
    const dropdown = $('.view-contacts-dropdown');
    dropdown.find('.dropdown-menu').on('click', function (e) {
      const dropdownMenu = $(this);
      if (dropdownMenu.is(e.target) || dropdownMenu.has(e.target).length > 0 ||  dropdown.find('.show').has(e.target).length > 0) {
        e.stopPropagation();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.summary && changes.summary.currentValue && this.summary.clientDashboardContacts && this.summary.clientDashboardContacts.length > 0) {
      const contacts = this.summary.clientDashboardContacts;
      this.contacts = contacts.sort((a, b) => { return this._roleLabels.indexOf(a.role) - this._roleLabels.indexOf(b.role); });
    }
    else {
      this.contacts = [];
    }
  }

  ngOnDestroy() {
    $('.view-contacts-dropdown .dropdown-menu').off('click');
  }

  closeModal() {
    $('.view-contacts-dropdown .btn-contacts').dropdown('toggle');
  }
}
