import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { CurrentLoggedInUser, ROLES } from '../core/constants';
import { PermissionsService } from '../services/permissions.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManualCommitmentAccessGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private permissionService: PermissionsService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const module = next.data.module;
    const currentUser = localStorage.getItem(CurrentLoggedInUser);
    if (currentUser) {
      const user = JSON.parse(currentUser);
      const canAccess = this.canAccess(user.roles);
      return this.accessProgressing(canAccess);

    } else {
      this.userService.callUserListData().pipe(map((data) => {
        const user = data;
        const canAccess = this.canAccess(user.roles);
        return this.accessProgressing(canAccess);
      })).subscribe();
    }
  }

  accessProgressing(canAccess: boolean): any {
    if (canAccess) return canAccess;

    this.router.navigate(['']);
    return false;
  }

  canAccess(userRoles: any[]): any {
    const userRole = userRoles.find(el => el.roleTitle === ROLES.Account_Manager
      || el.roleTitle === ROLES.Relationship_Manager
      || el.roleTitle === ROLES.Sales_Executive
      || el.roleTitle === ROLES.CCT_Admin
      || el.roleTitle === ROLES.Admin
      || el.roleTitle === ROLES.Account_Executive);

    return !!userRole;
  }
}


