import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAX_LENGTHS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { UserService } from 'src/app/services/user.service';
import { ISuggestion } from 'src/app/shared/suggestion';
import { ProjectDrawerService } from 'src/app/SharedModule/Components/project-drawer-container/project-drawer.service';
import * as $ from 'jquery';
import { IGroup } from 'src/app/shared/group';
import { GroupService } from 'src/app/services/groups.service';
import { IQuery } from 'src/app/shared/query';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'user-group-form',
  templateUrl: './user-group-form.component.html'
})
export class UserGroupFormComponent implements OnInit, OnDestroy {
  groupForm: FormGroup
  FormData: IGroup;
  errorMsg: string = "";

  showDrawer: boolean = false;
  isSubmitted: boolean = false;
  isError: boolean = false;
  isEdit: boolean = false;
  isAdmin:boolean = false;
  isDrawerType: boolean = false;
  _maxLength: any;
  isRAAllowedError: boolean = false;
  isRAAllowedErrorMessage: string = '';

  @Input() drawerData: any;
  searchProductString: string = '';
  searchClientString: string = '';
  searchUserString: string = '';
  searchOrgString: string = '';

  queryParam: IQuery;
  currentPageNumber = 1;
  removedProductIds: number[] = [];
  regexValidationMessage = REGEX_VALIDATION_MESSAGE;

  constructor(
    private fb: FormBuilder,
    private projectService: ProjectsService,
    private router: Router,
    private groupService: GroupService,
    private permissionService: PermissionsService,
    private drawerService: ProjectDrawerService
  ) { }

  ngOnInit(): void {
    this.isEdit = this.groupService.isEditMode;
    this._maxLength = MAX_LENGTHS;
    this.isAdmin = this.permissionService.userData.isAdmin;
    this.initForm();

    if (this.isEdit) {
      this.FormData = this.groupService.getGroupData;
      this.patchForm();
      this.initQueryParams();
      this.getProducts();
      this.getClients();
      this.getOrgs();
      this.getUsers();
    }
  }

  initForm() {
    this.groupForm = this.fb.group({
      name: ['', [Validators.required,Validators.pattern(REGEX_PATTERNS.client_name_validator), Validators.maxLength(this._maxLength.Form.Name)]],
      description: ['', [Validators.required,Validators.pattern(REGEX_PATTERNS.client_name_validator), Validators.maxLength(this._maxLength.Form.Description)]],
      accessLevel: ['read']
    });
  }

  patchForm(){
    this.groupForm.patchValue({
      name: this.FormData?.name || '',
      description: this.FormData?.description || '',
      accessLevel: this.FormData?.accessLevel === 'Read' ? 'read' : this.FormData.accessLevel === 'Write' ? 'write' : 'read'
    });
    this.selectedOrgIds = this.FormData?.organizations?.map(function(e) { return e.organizationId; });
    this.selectedProductIds = this.FormData?.products?.map(function(e) { return e.productId; });
    this.selectedClientIds = this.FormData?.clients?.map(e => e.clientId);
    this.selectedUserIds = this.FormData?.users?.map(e => e.userId);
  }

  groupFormSubmit() {
    this.isSubmitted = true;
    if(this.groupForm.valid && (this.selectedOrgIds.length > 0 || this.selectedProductIds.length > 0 || this.selectedClientIds.length > 0)) {
      let productsArray = {productId: this.selectedProductIds};
      let orgsArray = {organizationId: this.selectedOrgIds};
      let clientsArray = {clientId: this.selectedClientIds};
      let usersArray = {userId: this.selectedUserIds};

      let submitData = {
        "name": this.groupForm.value.name,
        "description": this.groupForm.value.description
      }

      if(orgsArray.organizationId.length > 0)
        submitData["organizations"] = orgsArray;
      if(productsArray.productId.length > 0)
        submitData["products"] = productsArray;
      if(clientsArray.clientId.length > 0)
        submitData["clients"] = clientsArray;
      if(usersArray.userId.length > 0)
        submitData["users"] = usersArray;
      if(this.groupForm.value.accessLevel === 'read')
      {
        submitData["isReadAccess"] = true;
        submitData["isWriteAccess"] = false;
      }
      else
      {
        submitData["isReadAccess"] = false;
        submitData["isWriteAccess"] = true;
      }

      if (!this.isEdit) {
        this.createUserGroup(submitData);
      }
      else {
        submitData["id"] = this.FormData.id;
        this.updateUserGroup(submitData);
        $('.container-page').removeClass('overflow-hidden');
      }
    }
    else if(this.selectedOrgIds.length === 0 && this.selectedProductIds.length === 0 && this.selectedClientIds.length === 0) {
      this.isError = true;
      this.errorMsg = "Please select atleast one Organization/Product/Client to create a group.";
    }
  }

  toggleDrawer(){
    this.showDrawer = !this.showDrawer;
  }

  goBack() {
    $('.container-page').removeClass('overflow-hidden');
    if (this.isEdit) {
      this.router.navigate(['/admin/usergroup/view', this.FormData.id]);
    }
    else if (this.isDrawerType == true) {
      this.drawerService.close();
    }
    else {
      this.router.navigate(['/admin/usergroup/list']);
    }
  }

  updateUserGroup(submitData) {
    this.groupService.updateGroup(this.FormData.id, submitData).subscribe(
      data => {
        this.groupService.isGroupUpdated = true;
        this.router.navigate(['/admin/usergroup/view', this.FormData.id]);
      },
      error => {
        this.isError = true;
        this.errorMsg = error;
      }
    )
  };

  createUserGroup(submitData) {
    let createdGroupName = submitData.name;
    this.groupService.createGroup(submitData).subscribe(
      data => {
        this.groupService.isGroupCreated = true;
        this.groupService.newCreatedGroup = createdGroupName;
        this.router.navigate(['/admin/usergroup/list']);
      },
      error => {
        this.isError = true;
        this.errorMsg = error;
      }
    );
  }

  ngOnDestroy(): void {
    this.groupService.isEditMode = false;
    this.groupService.setGroupData = <IGroup>{};
  }

  switchDrawer(name: string) {
    switch (name) {
      case 'Organization':
        this.showOrgDrawer = true;
        this.showProductDrawer = false;
        this.showClientDrawer = false;
        this.showUserDrawer = false;
        break;
      case 'Product' :
        this.showOrgDrawer = false;
        this.showProductDrawer = true;
        this.showClientDrawer = false;
        this.showUserDrawer = false;
        break;
      case 'Client':
        this.showOrgDrawer = false;
        this.showProductDrawer = false;
        this.showClientDrawer = true;
        this.showUserDrawer = false;
        break;
      case 'User':
        this.showOrgDrawer = false;
        this.showProductDrawer = false;
        this.showClientDrawer = false;
        this.showUserDrawer = true;
        break;
    }
  }

 /**************************** Organization Drawer Code Implementation Starts *********************************** */
  orgSuggestions: any;
  selectedOrgIds: number[] = [];
  selectedOrgForDisplay = [];
  showOrgDrawer: boolean = false;

  openOrgDrawer() {
    $('#containerPage').scrollTop(0);
    $('.container-page').addClass('overflow-hidden');
    this.switchDrawer('Organization');
    if (this.isEdit)
      this.toggleDrawer();
    else
      this.getOrgs();
  }

  getOrgs(keepDrawerClose = false) {
    if(this.orgSuggestions == null) {
      let orgSubscriber = this.projectService.getSuggestions('organizations', '');
      orgSubscriber.
      subscribe(
        data => {
          const orgs = data as unknown as ISuggestion[];
          orgs.forEach(row => {
            row['selected'] = (this.isEdit) ? (this.selectedOrgIds.includes(row.id)) : false;
          });
          this.orgSuggestions = orgs;
          if (this.isEdit)
            this.createSelectedOrgListForDisplay();
          if (!(this.isEdit) && !keepDrawerClose)
            this.showDrawer = true;
        },
        error => { this.isError = true; this.errorMsg = error; } );
    }
    else {
      this.showDrawer = true;
    }
  }

  removeOrg(id){
    let removedOrg = this.orgSuggestions.find(items => items.id == id);
    removedOrg.selected = false;
    const index = this.selectedOrgIds.indexOf(removedOrg.id);
    this.selectedOrgIds.splice(index, 1);
    this.createSelectedOrgListForDisplay();
  }

  drawerOrgCancel() {
    $('.container-page').removeClass('overflow-hidden');
    this.searchOrgString = '';
    this.toggleDrawer();
    this.resetOrgSelection();
  }

  resetOrgSelection(){
    if(this.orgSuggestions){
      this.orgSuggestions.forEach(element => {
        element.selected = !(this.selectedOrgIds.indexOf(element.id) < 0);
      });
    }
  }

  drawerOrgSubmit(){
    $('.container-page').removeClass('overflow-hidden');
    const selected = this.orgSuggestions.filter(items => items.selected);
    this.selectedOrgIds = selected.map(item => item.id);
    this.createSelectedOrgListForDisplay();
    this.toggleDrawer();
    this.searchOrgString = '';
  }

  createSelectedOrgListForDisplay(){
    const selected = this.orgSuggestions.filter(items => items.selected);
    this.selectedOrgForDisplay = [];
    this.selectedOrgForDisplay = selected;
  }
  /**************************** Organization Drawer Code Implementation Ends *********************************** */


  /**************************** Product Drawer Code Implementation Starts *********************************** */
  productSuggestions: any;
  selectedProductIds: number[] = [];
  selectedProductsForDisplay = [];
  showProductDrawer: boolean = false;

  openProductsDrawer(){
    $('#containerPage').scrollTop(0);
    $('.container-page').addClass('overflow-hidden');
    this.switchDrawer('Product');
    // if (this.isEdit)
    this.toggleDrawer();
    // else
    this.initQueryParams();
    this.getProducts();
  }

  getProducts(keepDrawerClose = false) {
    //if(this.productSuggestions == null) {
      // this.showDrawer = true;
      let orgIds: string = "";
      let productSubscriber: any = this.projectService.getSuggestionsResponse("products", true, "ignoreFavorites", this.queryParam);

      if(this.selectedOrgIds.length === 1) {
        productSubscriber = this.projectService.getSuggestionsResponse("products", this.selectedOrgIds[0], "orgId", this.queryParam);
      }
      else if(this.selectedOrgIds.length > 1) {
        for(let i=0; i<this.selectedOrgIds.length; i++) {
          orgIds = orgIds + this.selectedOrgIds[i] + ",";
        }
        orgIds = orgIds.slice(0, -1);
        productSubscriber = this.projectService.getSuggestionsResponse("products", orgIds, "orgIds", this.queryParam);
      }

      productSubscriber.
      subscribe(
        data => {
          const products = data.body as unknown as ISuggestion[]
          products.forEach(row => {
            row['selected'] = this.selectedProductIds.includes(row.id);
          });
          this.productSuggestions = products;

          this.FormData?.products?.forEach(element => {
            if(!this.productSuggestions.some(e => e.id === element.productId)) {
              let productSugg = {
                  "id": element.productId,
                  "title": element.productTitle,
                  "description": element.productDescription,
                  "selected": true
              }
              this.productSuggestions.push(productSugg);
            }
          });
          if (this.isEdit)
            this.createSelectedProductListForDisplay();
          if (!(this.isEdit) && !keepDrawerClose)
            this.showDrawer = true;
        },
        error => { this.isError = true; this.errorMsg = error; } );
    // }
    // else {
    //   this.showDrawer = true;
    // }
  }

  drawerProductCancel(){
    $('.container-page').removeClass('overflow-hidden');
    this.searchProductString = '';
    this.toggleDrawer();
    this.resetProductSelection();
  }

  drawerProductSubmit(){
    $('.container-page').removeClass('overflow-hidden');
    const selected = this.productSuggestions.filter(items => items.selected);
    this.selectedProductIds = selected.map(item => item.id);
    this.createSelectedProductListForDisplay();
    this.toggleDrawer();
    this.searchProductString = '';
    this.removedProductIds = [];
  }

  createSelectedProductListForDisplay(){
    const selected = this.productSuggestions.filter(items => items.selected);
    this.selectedProductsForDisplay = [];
    this.selectedProductsForDisplay = selected;

    this.FormData?.products?.forEach(element => {
      if(!this.selectedProductsForDisplay.some(e => e.id === element.productId)
          && !this.removedProductIds.some(e => e === element.productId)
          && this.selectedProductIds.some(e => e === element.productId)) {
        let productSugg = {
            "id": element.productId,
            "title": element.productTitle,
            "description": element.productDescription,
            "selected": true
        }
        this.selectedProductsForDisplay.push(productSugg);
      }
    });
  }

  removeProduct(id){
    let removedProduct = this.selectedProductsForDisplay.find(items => items.id == id);
    this.removedProductIds.push(removedProduct.id);
    removedProduct.selected = false;
    const index = this.selectedProductIds.indexOf(removedProduct.id);
    this.selectedProductIds.splice(index, 1);
    this.createSelectedProductListForDisplay();
  }

  resetProductSelection() {
    if(this.productSuggestions) {
      this.productSuggestions.forEach(element => {
        element.selected = !(this.selectedProductIds.indexOf(element.id) < 0);
      });
    }
  }
  /**************************** Product Drawer Code Implementation Ends *********************************** */


  /**************************** Client Drawer Code Implementation Starts *********************************** */
  clientSuggestions: any;
  selectedClientIds: number[] = [];
  removedClientIds: number[] = [];
  selectedClientForDisplay = [];
  showClientDrawer: boolean = false;

  openClientDrawer() {
    $('#containerPage').scrollTop(0);
    $('.container-page').addClass('overflow-hidden');
    this.switchDrawer('Client');
    if (this.isEdit)
      this.toggleDrawer();
    else
      this.getClients();
  }

  getClients(keepDrawerClose = false) {
    if(this.clientSuggestions == null) {
      let clientSubscriber = this.projectService.getClientSuggestions("clients", null, 100);

      clientSubscriber.
      subscribe(
        data => {
          const clients = data as unknown as ISuggestion[];
          clients.forEach(row => {
            row['selected'] = (this.isEdit) ? (this.selectedClientIds.includes(row.id)) : false;
          });

          this.clientSuggestions = clients;

          this.FormData?.clients?.forEach(element => {
            if(!this.clientSuggestions.some(e => e.id === element.clientId)) {
              let clntSugg = {
                  "id": element.clientId,
                  "title": element.clientTitle,
                  "description": element.clientDescription,
                  "mdmClientMasterId": 0,
                  "selected": true
              }
              this.clientSuggestions.push(clntSugg);
            }
          });

          if (this.isEdit)
            this.createSelectedClientListForDisplay();
          if (!(this.isEdit) && !keepDrawerClose)
            this.showDrawer = true;
        },
        error => { this.isError = true; this.errorMsg = error; } );
    }
    else {
      this.showDrawer = true;
    }
  }

  getClientsByTitle(searchValue: string = '') {
    let clientSubscriber: any;

    let params = [];
    if(searchValue && searchValue.length >= 3) {
      params.push({paramName: "title", value: searchValue});
      clientSubscriber = this.projectService.getClientSuggestions("clients", params, 100);
    }
    else {
      clientSubscriber = this.projectService.getClientSuggestions("clients", null, 100);
    }

    clientSubscriber.
    subscribe(
      data => {
        const clients = data as unknown as ISuggestion[];
        clients.forEach(row => {
          row['selected'] = (this.isEdit) ? (this.selectedClientIds.includes(row.id)) : false;
        });

        this.clientSuggestions = clients;

        this.selectedClientForDisplay.forEach(element => {
          if(!this.clientSuggestions.some(e => e.id === element.id))
            this.clientSuggestions.push(element);
        });

        if (this.isEdit)
          this.createSelectedClientListForDisplay2();
      },
      error => { this.isError = true; this.errorMsg = error; });
  }

  removeClient(id){
    let removedClient = this.selectedClientForDisplay.find(items => items.id == id);
    this.removedClientIds.push(removedClient.id);
    removedClient.selected = false;
    const index = this.selectedClientIds.indexOf(removedClient.id);
    this.selectedClientIds.splice(index, 1);
    this.createSelectedClientListForDisplay();
  }

  drawerClientCancel() {
    $('.container-page').removeClass('overflow-hidden');
    this.searchClientString = '';
    this.toggleDrawer();
    this.resetClientSelection();
  }

  resetClientSelection(){
    if(this.clientSuggestions){
      this.clientSuggestions.forEach(element => {
        element.selected = !(this.selectedClientIds.indexOf(element.id) < 0);
      });
    }
  }

  drawerClientSubmit(){
    $('.container-page').removeClass('overflow-hidden');
    const selected = this.clientSuggestions.filter(items => items.selected);
    this.selectedClientIds = selected.map(item => item.id);
    this.createSelectedClientListForDisplay();
    this.toggleDrawer();
    this.searchClientString = '';
    this.removedClientIds = [];
  }

  createSelectedClientListForDisplay(){
    const selected = this.clientSuggestions.filter(items => items.selected);
    this.selectedClientForDisplay = [];
    this.selectedClientForDisplay = selected;

    this.FormData?.clients?.forEach(element => {
      if(!this.selectedClientForDisplay.some(e => e.id === element.clientId)
          && !this.removedClientIds.some(e => e === element.clientId)
          && this.selectedClientIds.some(e => e === element.clientId)) {
        let clntSugg = {
            "id": element.clientId,
            "title": element.clientTitle,
            "description": element.clientDescription,
            "mdmClientMasterId": 0,
            "selected": true
        }
        this.selectedClientForDisplay.push(clntSugg);
      }
    });
  }

  createSelectedClientListForDisplay2(){
    const selected = this.clientSuggestions.filter(items => items.selected);
    if (this.selectedClientForDisplay.length === 0){
      this.selectedClientForDisplay = selected;
    }
    else{
      this.selectedClientForDisplay = ([... new Set([...selected, ...this.selectedClientForDisplay])].filter(item => item.selected)).filter((thing, i, arr) => { return arr.indexOf(arr.find(t => t.id === thing.id)) === i; });
    }
  }

  onClientSearchChange(searchValue: string = '') {
    this.getClientsByTitle(searchValue);
  }

  // resetClientList(keepDrawerClose = false) {
  //   this.searchClientString = '';
  //   $('#refreshTask').addClass('fa-rotation');
  //   this.projectService.getClientSuggestions('clients',null, 100)
  //   .subscribe(
  //     data => {
  //       const clients = data as unknown as ISuggestion[];
  //       clients.forEach(row => {
  //         row['selected'] = (this.isEdit) ? (this.selectedClientIds.includes(row.id)) : false;
  //       });
  //       this.clientSuggestions = clients;

  //       $('#refreshTask').removeClass('fa-rotation');
  //     },
  //     error => { this.isError = true; this.errorMsg = error; } );
  // }

  /**************************** Client Drawer Code Implementation Ends *********************************** */


  /**************************** User Drawer Code Implementation Starts *********************************** */
  userSuggestions: any;
  selectedUserIds: number[] = [];
  selectedUserForDisplay = [];
  showUserDrawer: boolean = false;

  openUserDrawer() {
    $('#containerPage').scrollTop(0);
    $('.container-page').addClass('overflow-hidden');
    this.switchDrawer('User');
    if (this.isEdit)
      this.toggleDrawer();
    else
      this.getUsers();
  }

  getUsers(keepDrawerClose = false) {
    if(this.userSuggestions == null) {
      let userSubscriber = this.projectService.getSuggestions('users', true, 'onlyFiservUsers');
      userSubscriber.
      subscribe(
        data => {
          const users = data as unknown as ISuggestion[];
          users.forEach(row => {
            row['selected'] = (this.isEdit) ? (this.selectedUserIds.includes(row.id)) : false;
          });
          this.userSuggestions = users;
          if (this.isEdit)
            this.createSelectedUserListForDisplay();
          if (!(this.isEdit) && !keepDrawerClose)
            this.showDrawer = true;
        },
        error => { this.isError = true; this.errorMsg = error; } );
    }
    else {
      this.showDrawer = true;
    }
  }

  removeUser(id){
    let removedUser = this.userSuggestions.find(items => items.id == id);
    removedUser.selected = false;
    const index = this.selectedUserIds.indexOf(removedUser.id);
    this.selectedUserIds.splice(index, 1);
    this.createSelectedUserListForDisplay();
  }

  drawerUserCancel() {
    $('.container-page').removeClass('overflow-hidden');
    this.searchUserString = '';
    this.toggleDrawer();
    this.resetUserSelection();
  }

  resetUserSelection() {
    this.isRAAllowedError = false;
    if(this.userSuggestions){
      this.userSuggestions.forEach(element => {
        element.selected = !(this.selectedUserIds.indexOf(element.id) < 0);
      });
    }
  }

  drawerUserSubmit(){
    $('.container-page').removeClass('overflow-hidden');
    const selected = this.userSuggestions.filter(items => items.selected);
    this.selectedUserIds = selected.map(item => item.id);

    const selectedUserNotAllowed = selected.filter(x => x.isRestrictedAdminNotPermitted == true);
    if (selectedUserNotAllowed.length > 0) {
      this.isRAAllowedError = true;
      var selectedUserNotAllowedUsers = selectedUserNotAllowed.map(item => item.name);

      var selectedUserNotAllowedUsersNames = '';
      for (var i = 0; i < selectedUserNotAllowedUsers.length; i++) {
        selectedUserNotAllowedUsersNames = selectedUserNotAllowedUsersNames + selectedUserNotAllowedUsers[i] + ',';
      }
      this.isRAAllowedErrorMessage = selectedUserNotAllowedUsersNames.slice(0, selectedUserNotAllowedUsersNames.length - 1) + " cannot be assigned with 'Restricted Admin' Role. Please check.";
      return;
    }
    this.isRAAllowedError = false;
    this.createSelectedUserListForDisplay();
    this.toggleDrawer();
    this.searchUserString = '';
  }

  createSelectedUserListForDisplay(){
    const selected = this.userSuggestions.filter(items => items.selected);
    this.selectedUserForDisplay = [];
    this.selectedUserForDisplay = selected;
  }
  /**************************** User Drawer Code Implementation Ends *********************************** */

  getProductsByTitle(productTitle){
    if(this.isSearchProductValid(productTitle)) {
      let orgIds: string = "";
      let params = [];
      params.push({paramName: "ignoreFavorites", value: true});
      if(this.selectedOrgIds.length === 1) {
        params.push({paramName: "orgId", value: this.selectedOrgIds[0]});
      }
      else if(this.selectedOrgIds.length > 1) {
        for(let i=0; i<this.selectedOrgIds.length; i++) {
          orgIds = orgIds + this.selectedOrgIds[i] + ",";
        }
        orgIds = orgIds.slice(0, -1);
        params.push({paramName: "orgIds", value: orgIds});
      }

      let productSubscriber: any;
      if(productTitle && productTitle.length > 1) {
        params.push({paramName: "title", value: productTitle});
      }
      productSubscriber = this.projectService.getProductSuggestions("products", params, 100);

      productSubscriber.
      subscribe(
        data => {
          const products = data as unknown as ISuggestion[]
          products.forEach(row => {
            row['selected'] = (this.isEdit) ? (this.selectedProductIds.includes(row.id)) : false;
          });

          this.productSuggestions = products;

          this.selectedProductsForDisplay.forEach(element => {
            if(!this.productSuggestions.some(e => e.id === element.id))
              this.productSuggestions.push(element);
          });

          if (this.isEdit)
            this.createSelectedProductListForDisplay2();
          $('#refreshTask').removeClass('fa-rotation');
        },
        error => { this.isError = true; this.errorMsg = error; } );
    }
  }

  onSearchChange(searchValue: string): void {
      this.getProductsByTitle(searchValue);
  }

  isSearchProductValid(productName) {
    return !productName || (productName && REGEX_PATTERNS.name_title_validator.test(productName));
  }

  createSelectedProductListForDisplay2(){
    const selected = this.productSuggestions.filter(items => items.selected);
    if (this.selectedProductsForDisplay.length === 0){
      this.selectedProductsForDisplay = selected;
    }
    else{
      this.selectedProductsForDisplay = ([... new Set([...selected, ...this.selectedProductsForDisplay])].filter(item => item.selected)).filter((thing, i, arr) => { return arr.indexOf(arr.find(t => t.id === thing.id)) === i; });
    }
  }

  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      //pageSize: environment.pageSize
      pageSize: 100
    });
  }
}
