import { Component, OnInit, ViewChild, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { FormsService } from 'src/app/services/forms.service';
import { FormBuilder, Validators, FormArray, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { ProjectsDTOService } from '../../Services/projectsDTO.service';
import { IForms } from 'src/app/shared/form';
import { DataTableComponent } from 'src/app/SharedModule/Components/data-table/data-table.component';
import { REGEX_PATTERNS, MAX_LENGTHS, REGEX_VALIDATION_MESSAGE, MimeTypeConstants } from 'src/app/core/constants';
import { ProjectsService } from 'src/app/services/projects.service';
import { UserService } from 'src/app/services/user.service';
import { UtilService } from 'src/app/services/util.service';
import { ProjectDrawerService } from 'src/app/SharedModule/Components/project-drawer-container/project-drawer.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { DateFormatPipe } from 'src/app/SharedModule/Pipes/date-format.pipe';
import { ClientsService } from 'src/app/services/clients.service';
import { FileValidators } from 'src/app/self-service/components/manageFileUploads/validators/file-validators';
import { forkJoin, interval } from 'rxjs';
import { MasterService } from 'src/app/services/master.service';
import { MutipleaddComponent } from '../../../SharedModule/Components/mutipleadd/mutipleadd.component';
import { LoaderService } from '../../../loader/loader.service';
import { flatMap } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-anonymous-survey',
  templateUrl: './anonymous-survey.component.html',
  styleUrls: ['./anonymous-survey.component.css'],
  providers: [DateFormatPipe]
})
export class AnonymousSurveyComponent implements OnInit {
  formsUsersDetails: any = {};
  updatedProjectData: any = {};
  isError: boolean = false;
  anonymousSurveyForm: FormArray
  formsets: FormArray;
  survey: IForms;
  formSets: any = [];
  currentSet: number = 0;
  responseStatusData;
  assignedForms: IForms;
  errorMsg: string;
  productTitle: any;
  isSubmitted: boolean;
  dueDateMsg: string = '';
  filedJson: any;
  dummyJson: any;
  filedformApproverJson: any;
  guestUserForm: any;
  filedDetails: any;
  filedformApproverDetails: any;
  guestfieldJson: any;
  @ViewChild('selectedRows') allRowsSelection: DataTableComponent;
  columns = [{ prop: 'updatedTitle', name: 'Form Name', inlineEditing: { 'type': 'default', 'pattern': REGEX_PATTERNS.form_response_survey_name_validator }, width: 250 },
  { prop: 'description', name: 'Description', inlineEditing: { 'type': 'default', 'pattern': REGEX_PATTERNS.description_comment }, width: 250 },
  { prop: 'dueDate', name: 'Due Date (mm/dd/yyyy)', inlineEditing: { 'type': 'default' }, width: 250 }
  ]
  _maxLength: any;
  currentIndex;
  selectForms: boolean;
  bulkRequest;
  msgTitle: string;
  userRequest;
  isViewDetailsLink = [];
  selectedEmailGroup: any = {};
  emailgroupMembers: any = [];
  isCreateUserAllowed: boolean = false;
  productSelection: boolean = false;
  initiateProjId: number = -1; //blank -1, incorrect -1
  ResponderApproverList;
  prodFormsList;
  clientUsersList;
  initiateDGF: boolean = false;
  initiateDGF_clientId: number;
  //initiateDGF_clientId: number = 0; //blank 0, incorrect -1
  approverUserId: number = null;
  templateName: string;
  @ViewChildren('templateInput') templateInput: QueryList<ElementRef>;
  project_id: number;
  client_id: number;
  nonuserGroupRequest;
  alreadyAssignedForms: any = [];
  group_id: number = 0;
  respondentIndex: number = 0;
  approverIndex: number = 0;
  selectedRespondentName: any = [];
  selectedApproverName: any = [];
  selectedRespondentEmail: any = [];
  selectedApproverEmail: any = [];
  filedformApproverDetails2: any;
  regexValidationMessage: any;
  clientId: number = 0;
  validFile: boolean = false;
  validFileType: boolean = true;
  validFileName: boolean = true;

  @ViewChildren('addResponder') addResponder: QueryList<MutipleaddComponent>;
  @ViewChildren('addApprover') addApprover: QueryList<MutipleaddComponent>;
  @ViewChildren('addGuest') addGuest: QueryList<MutipleaddComponent>;

  constructor(private _formService: FormsService,
    private _router: Router,
    private _projectDto: ProjectsDTOService,
    private _projectService: ProjectsService,
    public _userService: UserService,
    private _utilService: UtilService,
    private _drawerService: ProjectDrawerService,
    private _permissionService: PermissionsService,
    private _datePipe: DateFormatPipe,
    private _clientService: ClientsService,
    private _masterService: MasterService,
    private loaderService: LoaderService,
    private fb: FormBuilder,) { }

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.filedJson = {
      'json': {
        name: ['', this.formValidators["formResponder"]],
        emails: '',
        id: ''
      }
    }
    this.dummyJson = {
      'json': {
        name: '',
      }
    }
    this.guestfieldJson = {
      'json': {
        'emailAddress': ['', this.formValidators["emailAddress"]],
        'firstName': ['', this.formValidators["firstName"]],
        'lastName': ['', this.formValidators["firstName"]],
      }
    }
    this.filedformApproverJson = {
      'json': {
        name: [''],
        emails: '',
        id: '',
        level: ['1']
      }
    }
    this.isCreateUserAllowed = this._permissionService.isCreateUserAllowed();
    /***** created project details ******/
    this.updatedProjectData = this._projectDto.getProjectFacts();

    /********* Checking for alreadyAssigned forms in a project ********/

    this.alreadyAssignedForms = this._projectDto.alreadyAssignedForms();

    if (this._router.url.indexOf('initiateDGF') > -1) this.initiateDGF = true;

    if (Object.keys(this.updatedProjectData).length !== 0 && !this.initiateDGF) {
      let allApiData = this._formService.formsUsersData();
      this.formsUsersDetails.forms = allApiData?.forms?.forms;
      this.formsUsersDetails.users = allApiData.users;
      this.formsUsersDetails.usersData = { 'data': allApiData.users };
      this.formsUsersDetails.emailGroup = allApiData.emailGroup;
      this.productTitle = this.updatedProjectData.productTitle;
      this.clientId = this.updatedProjectData.clientId;
      this.initiatingAnonymousFormGroup();
    }
    else {
      /****************** Begin Initiate DGF ****************/
      this.productSelection = true;
      this.initiatingAnonymousFormGroup();
      this.noClientProjectSelection();
      this._projectService.clientVisibility$.subscribe(clientId => {
        this.initiateDGF_clientId = clientId;
        this.formSets.length = 0;
        this.currentSet = 0
        this.initiatingAnonymousFormGroup();
        if (this.initiateDGF_clientId > 0) {
          this._clientService.getClientDataById(this.initiateDGF_clientId).subscribe(data => {
            const duns = data.dunsId === null ? '' : data.dunsId;
            this._projectDto.setInitiateDgfData(
              {
                clientId: this.initiateDGF_clientId,
                clientTitle: data.title,
                clientTitleWithDuns: data.title + ' [DUNS: ' + duns + ']'
              }
            );
          });
          this.formsUsersDetails.forms = this._formService.formsUsersData()?.orgForms?.forms;
          return this.clientRespApproverApi(this.initiateDGF_clientId, this._formService.formsUsersData())
        }
        else if (this.initiateDGF_clientId === -1) {
          this.noClientProjectSelection();
        }
        else {
          this._projectService.projVisibility$.subscribe(projectId => {
            this.initiateProjId = projectId;
            this.formSets.length = 0;
            this.currentSet = 0
            this.initiatingAnonymousFormGroup();
            if (this.initiateProjId > -1) {
              this._projectService.getProjectById(this.initiateProjId).subscribe(
                project => {
                  const projectDetails = project;
                  if (projectDetails && projectDetails.clientId) this._projectDto.setInitiateDgfData(projectDetails);
                  return this.apiToGetFormsUsers(projectDetails);
                }
              )
            }
            else {
              this.noClientProjectSelection();
            }
          });
        }
      })
      /****************** End Initiate DGF **************/
    }
    if (this._projectService.isCopyForms) {
      if (this._projectService.selectedProjectCopyForms !== 0) {
        this._projectService.getResponsesByProjectId(this._projectService.selectedProjectCopyForms).subscribe(r => {
          console.log("forms", r);
          let formsToWorkWith = r as any;
          let stringDate = new Date();
          stringDate.setDate(stringDate.getDate() + 14);
          formsToWorkWith.forms.forEach(x => {
            x.updatedTitle = x.title;
            x.dueDate = this._datePipe.transform(stringDate.toDateString()).replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$1/$2/$3");
            x.selected = true;
            x.showLaunchButton = false;
            x.id = x.formId;
          });
          this.formSets[this.currentSet].assignedFormsList = formsToWorkWith.forms;
          this.formSets[this.currentSet].showData = true;
        });
      }
    }
    //  this.reEvaluateValidators('formResponder', this.currentSet);
    this.filedDetails = [{ 'name': 'name', 'type': 'fdl', 'label': 'Form Respondent', 'isRequired': true, 'fdldata': this.formsUsersDetails.usersData, 'emailField': 'emails', "className": "col-md-12", "erroeMessage": { 'valid': 'Please select Respondent.' } },
    { 'name': 'emails', 'type': 'emailshow', 'isDisabled': 'yes', 'label': 'Email', }, { 'name': 'id', 'type': 'fdlid' }]
    this.filedformApproverDetails = [{
      'name': 'name', 'type': 'fdl', 'label': 'Approver', 'isRequired': false, 'fdldata': this.formsUsersDetails.usersData, 'emailField': 'emails', "className": "col-md-12", "erroeMessage": { 'valid': 'Please select an Approver.' },
      "tooltip": { 'class': 'material-icons btn-secondary f-18', 'icon': 'info', 'title': 'Approvers selected here will be set only for the Data Gathering Forms selected.' }
    },
    { 'name': 'emails', 'type': 'emailshow', 'isDisabled': 'yes', 'label': 'Email', }, { 'name': 'id', 'type': 'fdlid' }, { 'name': 'level', "className": "col-md-4", 'label': "Level", "erroeMessage": { 'valid': 'Please enter Approver level.' }, 'type': 'text', 'isRequired': false, }]
    this.filedformApproverDetails2 = [{
      'name': 'name', 'type': 'fdl', 'label': 'Approver', 'isRequired': false, 'fdldata': this.formsUsersDetails.usersData, 'emailField': 'emails', "className": "col-md-12", "erroeMessage": { 'valid': 'Please select an Approver.' },
      "tooltip": { 'class': 'material-icons btn-secondary f-18', 'icon': 'info', 'title': 'Approvers selected here will be set only for the Data Gathering Forms selected.' }
    },
    { 'name': 'emails', 'type': 'emailshow', 'isDisabled': 'yes', 'label': 'Email', }, { 'name': 'id', 'type': 'fdlid' },]


    this.guestUserForm = [
      { 'name': 'emailAddress', 'type': 'email', 'label': 'Respondent Email', 'isRequired': true, "className": "col-md-11", "erroeMessage": { 'valid': this.regexValidationMessage.EMAIL_VALIDATION_MESSAGE } },
      { 'name': 'firstName', 'type': 'text', 'label': 'Respondent First Name', 'isRequired': true, "className": "col-md-11", "erroeMessage": { 'valid': this.regexValidationMessage.USERNAME_VALIDATION_MESSAGE } },
      { 'name': 'lastName', 'type': 'text', 'label': 'Respondent Last Name', 'isRequired': true, "className": "col-md-11", "erroeMessage": { 'valid': this.regexValidationMessage.USERNAME_VALIDATION_MESSAGE } }]

  }

  requiredIfValidator(predicate) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    })
  }

  /****************** Begin Initiate DGF ****************/

  initiatingAnonymousFormGroup() {
    this.formSets[this.currentSet] = {
      "title": 'Form Set' + " " + (this.currentSet + 1),
      "assignedFormsList": [],
      "selectedUnauthenticatedType": 'user',
      "templateName": '',
      "respondentEmail": '',
      "approverEmail": '',
      "assignForm": this.fb.group({
        'unauthenticatedType': ['user'],
        'approverSchema': ['OR'],
        'formResponder': this.fb.array([]),
        'formRespondentEmailGroup': ['', this.formValidators["formRespondentEmailGroup"]],
        'guestResponder': this.fb.array([]),
        'formApprover': this.fb.array([]),
        'formTemplate': [null, this.formValidators["formTemplate"]]
      },
      )
    }
  }

  LoadRespondents(value: any) {
    this._formService.getRespondentsUsersByProductId(0, value).subscribe(
      data => {
        this.formsUsersDetails.usersData = { 'data': data };
        this.formSets[this.currentIndex].assignForm.get('formResponder').reset();
        this.formSets[this.currentIndex].assignForm.get('formApprover').reset();
        for (var i = 0; i < this.formSets[this.currentIndex].assignForm.controls['formApprover'].controls['formApprover'].controls.length; i++) {
          this.formSets[this.currentIndex].assignForm.controls['formApprover'].controls['formApprover'].controls[i].controls['level'].value = 1;
        }

        if (this.initiateDGF_clientId) {
          this.LoadClientRespondents(this.initiateDGF_clientId);
        }

        if (this.clientId > 0) {
          this.LoadClientRespondents(this.clientId);
        }
        
      },
      error => {
        console.log(error);
      }
    );
  }

  LoadClientRespondents(value: any) {
    this._formService.getRespondentsUsersByClientId(value).subscribe(
      data => {
        this.formsUsersDetails.clientUsersData = { 'data': data };
        this.formsUsersDetails.usersData.data = [...this.formsUsersDetails.usersData.data, ...this.formsUsersDetails.clientUsersData.data];
      },
      error => {
        console.log(error);
      }
    );
  }

  /********** BEGIN BLANK/WRONG project & Client **********/
  noClientProjectSelection() {
    this.productSelection = true;
    let initiateApiData = this._formService.formsUsersData();
    this.formsUsersDetails.forms = initiateApiData?.orgForms?.forms;
    this.formsUsersDetails.users = initiateApiData.orgUsers;
    this.formsUsersDetails.usersData = { 'data': initiateApiData.orgUsers };
    this.formsUsersDetails.emailGroup = initiateApiData.emailGroup;
  }
  /********** END BLANK/WRONG project & Client **********/

  clientRespApproverApi(clientId, b) {
    this._clientService.getClientDetailsById(clientId, "respondents").subscribe(
      usersByClient => {
        this.clientUsersList = usersByClient;
        const allUsers = (this.clientUsersList !== undefined) ? b.orgUsers.concat(this.clientUsersList) : b.orgUsers;
        this.ResponderApproverList = allUsers.sort((a, b) => a.name > b.name ? 1 : -1);
        this._formService.formsUsersDetail({ orgForms: b.orgForms, prodForms: b.prodForms, allUsers: this.ResponderApproverList, orgUsers: b.orgUsers, emailGroup: b.emailGroup });
        this.formsUsersDetails.users = this._formService.formsUsersData().allUsers;
        this.formsUsersDetails.usersData = { 'data': this._formService.formsUsersData().allUsers };
      })
  }


  apiToGetFormsUsers(projectDetails) {
    let b = this._formService.formsUsersData();
    this.formsUsersDetails.emailGroup = b?.emailGroup;
    if (projectDetails.productId && projectDetails.productId !== null) {
      this.productSelection = false;
      this._formService.getFormsByproductId(projectDetails.productId, projectDetails.clientId).subscribe(
        formsByProduct => {
          this.prodFormsList = formsByProduct;
          this._formService.formsUsersDetail({ orgForms: b.orgForms, prodForms: this.prodFormsList, orgUsers: b.orgUsers, emailGroup: b.emailGroup });
          this.formsUsersDetails.forms = this._formService.formsUsersData().prodForms?.forms;
        })
    }
    else {
      this.productSelection = true;
      this.formsUsersDetails.forms = this._formService.formsUsersData().orgForms?.forms;
    }

    if (projectDetails.clientId && projectDetails.clientId !== null) {
      this.clientRespApproverApi(projectDetails.clientId, b);
    }
    else {
      this.formsUsersDetails.users = this._formService.formsUsersData().orgUsers;
      this.formsUsersDetails.usersData = { 'data': this._formService.formsUsersData().orgUsers };
    }
    /****************** End Initiate DGF ****************/
  }


  onTemplateChange(evt, i) {
    this.resetError();
    this.loaderService.show();
    this.formSets[i].templateName = "";
    let templateCtrl = this.formSets[i].assignForm.get("formTemplate");
    if (templateCtrl.untouched) templateCtrl.markAsTouched(); //to trigger required field validation

    const target: DataTransfer = <DataTransfer>evt.target;
    let templateList: FileList = target.files;
    let maxFileSize = MAX_LENGTHS.File.Size;
    if (templateList.item(0).size <= (maxFileSize * 1024 * 1024)) {
      if (templateList.length > 0) {
        const file = templateList.item(0);
        this.formSets[i].templateName = file.name;
        this.templateName = file.name;
        templateCtrl.setValue(file);
        this.validFileName = FileValidators.ValidateFileName(file.name);
        this.validFile = this.validateFileExtension(this.formSets[i].templateName, 'xlsx');
        if (this.validFile == true) {
          this.validFileType = FileValidators.CheckAllowedContentType(evt, MimeTypeConstants.XLSX);
        }
      } else {
        this.formSets[i].templateName = "";
        templateCtrl.setValue(null);
      }
      this.resetInterval()
      // let a = interval(500).subscribe(x => {

      //   this.spinnerSubscription.unsubscribe();
      //   this.loaderService.hide();
      //   a.unsubscribe();
      // });
    }
    else {
      this.resetInterval()
      this.isError = true;
      this.errorMsg = "File size exceeds maximum size limit i.e. 50 MB";
      this.templateInput[i].nativeElement.value = "";

    }

  }

  resetInterval() {
    let a = interval(500).subscribe(x => {

      //   this.spinnerSubscription.unsubscribe();
      this.loaderService.hide();
      a.unsubscribe();
    });
  }

  validateFileExtension(name: String, fileExt: String) {
    let ext: string = name.substring(name.lastIndexOf('.') + 1);
    return (ext.toLowerCase().includes(fileExt.valueOf()));
  }

  /********** For triggering onChange on selecting same file. **********/
  templateInputClicked(i) {
    this.clearTemplateInput(i);
  }

  clearTemplateInput(i) {
    this.formSets[i].assignForm.get('formTemplate').reset();
    this.formSets[i].templateName = "";
    if (this.templateInput.find((element, index) => index === i)) {
      this.templateInput.find((element, index) => index === i).nativeElement.value = '';
    }
    //if(this.templateInput.nativeElement) this.templateInput}.nativeElement.value = '';
  }

  /********** on click of Select Data Gathering Forms button **********/
  selectFormBtn(counter) {
    setTimeout(() => {
      $('.assign-modal-table').scrollTop(0)
    }, 400)
    this.allRowsSelection.allSelected = false;
    this.currentIndex = counter;
    this.formSets[this.currentIndex].showData = false;

    let selectedTempFormId = [];
    for (let key in this.formSets[counter].assignedFormsList) {
      selectedTempFormId.push(this.formSets[counter].assignedFormsList[key].id)
    }
    if (this.updatedProjectData && this.updatedProjectData.sameForms == 'No') {
      let filterOutSelectedForms: Number[] = [];
      for (let i = 0; i < this.formSets.length; i++) {
        if (i != counter) {
          filterOutSelectedForms = filterOutSelectedForms.concat(filterOutSelectedForms,
            this.formSets[i].assignedFormsList.map(({ id }) => id));
        }
      }

      let formsFromApi = this._formService.formsUsersData()?.forms?.forms;
      this.formsUsersDetails.forms = formsFromApi.filter(({ id }) => !filterOutSelectedForms.includes(id));
    }
    /********** allow multiple forms to publish  **********/
    if (this.formsUsersDetails && this.formsUsersDetails.forms) {
      for (let form in this.formsUsersDetails.forms) {
        this.formsUsersDetails.forms[form].selected = false;
        this.formsUsersDetails.forms[form].dueDate = this._datePipe.transform(this.formsUsersDetails.forms[form].dueDate);
        for (let selectedForm in selectedTempFormId) {
          if (selectedTempFormId[selectedForm] === this.formsUsersDetails.forms[form].id) {
            this.formsUsersDetails.forms[form].selected = true;
          }
        }
      }
    }
    $('#selectForms').modal('show');
  }

  selectEmailGroup(emailGroup: any, index) {
    if (emailGroup.item && emailGroup.item.title) {

      this.isViewDetailsLink[index] = true;
      this.selectedEmailGroup = {
        'title': emailGroup.item.title,
        'id': emailGroup.item.id
      }
      this.memberDetails(emailGroup.item);
    }
  }

  memberDetails(emailGroupDetails) {
    this._formService.getMemberDetails(emailGroupDetails.id).subscribe(
      response => {
        this.emailgroupMembers = response.emailGroupMembers;
      },
      (error) => { this.isError = true; this.errorMsg = error; });
  }

  viewLink() {
    $('#emailGroupMembers').modal('show');
  }

  /********** For removing a particular formset **********/
  closeFormset(formsetToBeRemoved) {
    this.currentSet--;
    this.formSets.splice(formsetToBeRemoved, 1);
    this.formSets.map((formset, index) => {
      return formset.title = "Form Set " + (index + 1);
    });
  }

  /********** For downloading form template file **********/
  downloadTemplate() {
    let link = document.createElement("a");
    link.setAttribute('type', 'hidden');
    link.download = "Bulk Data Gathering Form Publish Template.xlsx";
    link.href = "assets/Bulk Data Gathering Form Publish Template.xlsx";
    link.click();
    link.remove();
  }

  /**********on click of another set buuton **********/
  anotherSet() {

    this.isViewDetailsLink[this.formSets.length + 1] = false;

    this.allRowsSelection.allSelected = false;
    this.isSubmitted = false;

    let newFormSet = {
      "title": 'Form Set' + " " + (this.formSets.length + 1),
      "assignedFormsList": [],
      'selectedUnauthenticatedType': 'user',
      "templateName": '',
      "respondentEmail": '',
      "approverEmail": '',
      "assignForm": this.fb.group({
        'unauthenticatedType': ['user'],
        'approverSchema': ['OR'],
        'formResponder': this.fb.array([]),
        'formRespondentEmailGroup': ['', this.formValidators["formRespondentEmailGroup"]],
        'guestResponder': this.fb.array([]),
        'formApprover': this.fb.array([]),
        'formTemplate': [null, this.formValidators["formTemplate"]]
      },
      )
    }

    /********** to scroll down on addition of another set **********/
    $(document).ready(function () {
      $("button").click(function () {
        $(document).scrollTop($(document).height());
      });
    });
    this.formSets.push(newFormSet);
    // this.reEvaluateValidators("formResponder", this.currentSet);

  }
  validation(controlName: string, values: FormArray): ValidationErrors {

    return (control: AbstractControl): { [key: string]: boolean } | null => {
      //  return { 'duplicate': true };

      let array: any = [];

      if (controlName != 'formApprover' && controlName != 'formResponder' && controlName != 'guestResponder') {


        return null;
      }
      for (let k in values[controlName]) {
        let keyName = '';
        let fieldValue = values[controlName][k];
        if (controlName == 'formApprover') {
          //keyName = fieldValue.id + '##' + fieldValue.level;
          if (fieldValue.level > 0)
            keyName = fieldValue.id + '##' + fieldValue.level;
          else
            return { 'invalid': true };
        } else if (controlName == 'formResponder') {
          keyName = fieldValue.id
        } else if (controlName == 'guestResponder') {
          keyName = fieldValue.emailAddress
          keyName = keyName.toLocaleLowerCase();
        } else {
          return null;
        }


        if (fieldValue.name != '') {
          if (array[keyName])
            array[keyName].push({ "index": k });
          else {
            array[keyName] = [];
            array[keyName].push({ "index": k });


          }


        }
      }
      for (let i in array) {
        if (array[i].length > 1) {
          return { 'duplicate': true };
        }
      }
      return null;
    }

  }
  reEvaluateValidators(fieldName, setNo, isNested = 'no') {
    if (isNested != 'no') {
      if (this.formSets[setNo].assignForm.controls[isNested]) {
        let fieldCtrlParent = this.formSets[setNo].assignForm.controls[fieldName]
        if (fieldCtrlParent && fieldCtrlParent.controls && fieldCtrlParent.controls[fieldName].length > 0) {

          fieldCtrlParent.setValidators(this.validation(fieldName, this.formSets[setNo].assignForm ? this.formSets[setNo].assignForm.get(fieldName).value : []));
        }
        let fieldCtrlArray = this.formSets[setNo].assignForm.controls[isNested].controls[isNested].controls;
        for (let m in fieldCtrlArray) {
          let fieldCtrl = fieldCtrlArray[m].controls[fieldName]
          fieldCtrl.setValidators(this.formValidatorsDynamic(fieldName, setNo));
          fieldCtrl.updateValueAndValidity();
        }

      }

    } else {
      const fieldCtrl = this.formSets[setNo].assignForm.controls[fieldName];
      fieldCtrl.setValidators(this.formValidatorsDynamic(fieldName, setNo));
      if (fieldName != 'formTemplate')
        fieldCtrl.setValidators(this.validation(fieldName, this.formSets[setNo].assignForm ? this.formSets[setNo].assignForm.get(fieldName).value : []));
      fieldCtrl.updateValueAndValidity();
    }
  }
  //validationForMatchingResponderApprover(controlName: string, controlName2: string,values: FormArray, values2: FormArray): ValidationErrors {

  //  return (control: AbstractControl): { [key: string]: boolean } | null => {
  //    //  return { 'duplicate': true };

  //    let array: any = [];
  //    if (values && values[controlName]) {
  //    for (let k in values[controlName]) {
  //      let id = '';
  //      let fieldValue = values[controlName][k];


  //      if (fieldValue.name != '') {
  //        id = fieldValue.id

  //      }
  //      for (let k in values2[controlName2]) {
  //        let fieldValue2 = values2[controlName2][k];


  //        if (fieldValue2.name != '') {
  //          if (id == fieldValue2.id) {
  //            return { 'duplicate2': true };
  //          }

  //        }
  //      }
  //    }
  //  }
  //    return null;
  //  }

  //}
  //approverAndResponderValidator(fieldName, fieldName2, setNo) {


  //  if (this.formSets[setNo].assignForm.controls[fieldName] && this.formSets[setNo].assignForm.controls[fieldName2]) {
  //    let fieldCtrlParent1 = this.formSets[setNo].assignForm.controls[fieldName]
  //    let fieldCtrlParent2 = this.formSets[setNo].assignForm.controls[fieldName2]
  //    if ((fieldCtrlParent1 && fieldCtrlParent1.controls && fieldCtrlParent1.controls[fieldName].length > 0) && (fieldCtrlParent2 && fieldCtrlParent2.controls && fieldCtrlParent2.controls[fieldName2].length > 0)) {

  //      fieldCtrlParent2.setValidators(this.validationForMatchingResponderApprover(fieldName, fieldName2, this.formSets[setNo].assignForm ? this.formSets[setNo].assignForm.get(fieldName).value : [], this.formSets[setNo].assignForm ? this.formSets[setNo].assignForm.get(fieldName2).value : []));
  //      }


  //    }


  //}
  /********** On manually adding/removing the value from email group suggestions **********/
  emailGroupValidator(control: AbstractControl): { [key: string]: boolean } {
    if (this.formSets[this.group_id]) {
      const value = control.value;
      if (!value || value !== this.selectedEmailGroup && this.selectedEmailGroup['title'] && this.selectedEmailGroup['title'].toLowerCase()) {
        this.isViewDetailsLink[this.group_id] = false;
      }
      else if (value.toLowerCase() === this.selectedEmailGroup && this.selectedEmailGroup['title'] && this.selectEmailGroup['title'].toLowerCase()) {
        this.isViewDetailsLink[this.group_id] = true;
      }
      return this.isViewDetailsLink[this.group_id]
    }
  }

  noRespondentMatched(event, i) {
    if (event === true) {
      this.formSets[i].respondentEmail = '';
    }
  }

  noApproverMatched(event, i) {
    if (event === true) {
      this.formSets[i].approverEmail = '';
    }
  }

  /********** On manually adding/removing the value from Respondent users **********/
  respondentEmailValidator(control: AbstractControl): { [key: string]: boolean } {

    return;

  }

  /********** On manually adding/removing the value from Respondent users **********/
  approverEmailValidator(control: AbstractControl): { [key: string]: boolean } {

    return;

  }


  selectRespondent(data: any, setNo) {
    if (data.item && data.item.id) {
      this.selectedRespondentName[setNo] = data.item.name;
      this.selectedRespondentEmail[setNo] = data.item.email;
      this.formSets[setNo].respondentEmail = this.selectedRespondentEmail[setNo];
      this.respondentIndex = setNo;
    }
  }

  selectApprover(data: any, setNo) {
    if (data.item && data.item.id) {
      this.selectedApproverName[setNo] = data.item.name;
      this.selectedApproverEmail[setNo] = data.item.email;
      this.formSets[setNo].approverEmail = this.selectedApproverEmail[setNo];
      this.approverIndex = setNo;
    }
  }

  formValidators = {
    formResponder: [this.requiredIfValidator(() => this.formSets[this.currentSet].assignForm.get('unauthenticatedType').value === "user"), this.respondentEmailValidator.bind(this)],
    formRespondentEmailGroup: [this.requiredIfValidator(() => this.formSets[this.currentSet].assignForm.get('unauthenticatedType').value === "group"), this.emailGroupValidator.bind(this)],
    emailAddress: [this.requiredIfValidator(() => this.formSets[this.currentSet].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.Email), Validators.maxLength(MAX_LENGTHS.User.Email)],
    firstName: [this.requiredIfValidator(() => this.formSets[this.currentSet].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],
    lastName: [this.requiredIfValidator(() => this.formSets[this.currentSet].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],
    formTemplate: [this.requiredIfValidator(() => this.formSets[this.currentSet].assignForm.get('unauthenticatedType').value === "bulk"), FileValidators.checkAllowedExtensions(['xlsx'])]
    // formTemplate: [this.requiredIfValidator(() => this.formSets[this.currentSet].assignForm.get('unauthenticatedType').value === "bulk")]
  }
  formValidatorsDynamic(fieldName, setNo) {
    let formValidators = {
      formResponder: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "user"), this.respondentEmailValidator.bind(this)],
      formRespondentEmailGroup: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "group"), this.emailGroupValidator.bind(this)],
      emailAddress: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.Email), Validators.maxLength(MAX_LENGTHS.User.Email)],
      firstName: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],
      lastName: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],
      formTemplate: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "bulk"), FileValidators.checkAllowedExtensions(['xlsx'])]
      // formTemplate: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "bulk")]
    }

    return formValidators[fieldName];

  }

  /**Update For Valida*/
  updateFormValidators(setNo) {
    this.formValidators = {
      formResponder: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "user"), this.respondentEmailValidator.bind(this)],
      formRespondentEmailGroup: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "group"), this.emailGroupValidator.bind(this)],
      emailAddress: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.Email), Validators.maxLength(MAX_LENGTHS.User.Email)],
      firstName: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],
      lastName: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],
      formTemplate: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "bulk"), FileValidators.checkAllowedExtensions(['xlsx'])]
      // formTemplate: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "bulk")]
    }
    switch (this.formSets[setNo].assignForm.get('unauthenticatedType').value) {
      case 'user':
        this.reEvaluateValidators('formResponder', setNo);
        this.reEvaluateValidators('formApprover', setNo);
        //    this.approverAndResponderValidator('formResponder', 'formApprover', setNo)
        break;
      case 'nonuser':
        this.reEvaluateValidators('guestResponder', setNo);
        this.reEvaluateValidators('formApprover', setNo);
        break;
      case 'group':
        this.reEvaluateValidators('formRespondentEmailGroup', setNo);
        break;
      case 'bulk':
        this.reEvaluateValidators('formTemplate', setNo);
        break;
    }

  }
  /**********Removing validations on change of radio button selection **********/
  removeValidators(formGrup, index) {
    for (const key in formGrup.controls) {
      formGrup.get(key).clearValidators();
      formGrup.get(key).updateValueAndValidity();
      if (key === "formTemplate") {
        this.clearTemplateInput(index);
      }
    }
  }

  /********** For revaluation of the validations applied in formFields **********/
  setUnauthenticatedType(drmsType, index) {
    this.isSubmitted = false;
    this.formSets[index].respondentEmail = '';
    this.formSets[index].approverEmail = '';
    this.formSets[index].approverSchema = 'OR';

    this.formSets[index].assignForm.reset({
      'unauthenticatedType': this.formSets[index].assignForm.get('unauthenticatedType').value,
      'formRespondentEmailGroup': "",
      'approverSchema': 'OR'
    })
    this.removeValidators(this.formSets[index].assignForm, index);
    switch (drmsType) {
      case 'user':
        this.reEvaluateValidators('formResponder', index);
        this.reEvaluateValidators('formApprover', index);
        //  this.approverAndResponderValidator('formResponder', 'formApprover', index)
        break;
      case 'nonuser':
        this.reEvaluateValidators('emailAddress', index, 'guestResponder');
        this.reEvaluateValidators('firstName', index, 'guestResponder');
        this.reEvaluateValidators('lastName', index, 'guestResponder');
        break;
      case 'group':
        this.reEvaluateValidators('formRespondentEmailGroup', index);
        break;
      case 'bulk':
        this.reEvaluateValidators('formTemplate', index);
        break;
    }
  }

  /********** on change of radio button selection **********/
  settingUnauthenticatedSurveyType(index) {



    this.group_id = index;
    this.formSets[index].selectedUnauthenticatedType = this.formSets[index].assignForm.get('unauthenticatedType').value;

    this.setUnauthenticatedType(this.formSets[index].assignForm.get('unauthenticatedType').value, index);

  }

  /********** it will navigate on success of API & and on click of Cancel **********/
  navigationForm() {
    this._projectService.isCopyForms = false;
    this._projectService.selectedProjectCopyForms = 0;
    if (Object.keys(this.updatedProjectData).length === 0 && this.initiateDGF) {
      this._router.navigate(["response/list"]);
    }
    else this._router.navigate(['/projects/view', this.updatedProjectData.id]);
  }

  /********** Due Date Validation Check **********/
  dueDateValidation(setNo, date) {
    let due_date = this._utilService.isValidDate(date);
    if (!due_date) {
      this.msgTitle = this.formSets[setNo].title;
      this.dueDateMsg = "Due date is mandatory and cannot be in the past for " + "<b>" + this.msgTitle + "</b>";
      return false;
    }
    else return true;
  }
  /*******All Sets Validation */
  allSetValidation() {
    for (let p = 0; p < this.formSets.length; p++) {
      if (this.addApprover['_results'][p]) {
        this.addApprover['_results'][p].isErrorOn();
      }
      if (this.addResponder['_results'][p]) {
        this.addResponder['_results'][p].isErrorOn();

      }
    }
  }

  resetError() {
    this.isError = false;
    this.errorMsg = "";
  }
  /********** on click of Submit **********/
  submitForm() {
    this.resetError();
    this.isSubmitted = true;
    this.userRequest = null;
    this.bulkRequest = null;
    this.nonuserGroupRequest = null;
    this.selectForms = false;
    this.dueDateMsg = '';
    let survey: any = [];
    let approverEmail: any = [];
    let approverEmailId: any = [];
    let fim;
    let groupId;
    let responses_nonuser_group: any = [];
    let responses_bulk: any = [];
    let files_bulk: any = []
    let bulk = this.formSets.some((x: any) => x.selectedUnauthenticatedType === 'bulk')
    let userOrGuest = this.formSets.some((x: any) => x.selectedUnauthenticatedType === 'user' || x.selectedUnauthenticatedType === 'nonuser')
    let approverUserId: any = [];
    let recipients: any = [];
    this._projectService.isCopyForms = false;
    this._projectService.selectedProjectCopyForms = 0;

    /********** iterate through no.of formsets created **********/
    if ((bulk && this.isSubmitted && this.validFile && this.validFileName) || userOrGuest) {
      for (let p = 0; p < this.formSets.length; p++) {
        if (this.formSets[p].assignedFormsList.length === 0) {
          this.msgTitle = this.formSets[p].title;
          this.selectForms = true;
          this.isSubmitted = false;
          return false;
        }
        if (this.formSets[p].selectedUnauthenticatedType != 'bulk') {
          this.reEvaluateValidators('formTemplate', p);
        } else {
          //  this.reEvaluateValidators('formResponder', setNo);
          this.formSets[p].assignForm.removeControl('formResponder');
        }
        if (this.formSets[p].formType == 'intake' && this.formSets[p].selectedUnauthenticatedType != 'bulk') {
          this.formSets[p].assignForm.removeControl('formApprover');
        }
        if (this.formSets[p].selectedUnauthenticatedType == 'nonuser') {
          this.formSets[p].assignForm.removeControl('formResponder');
        }
        if (this.formSets[p].selectedUnauthenticatedType == 'user') {
          this.formSets[p].assignForm.removeControl('guestResponder');
        }
        if ((this.addApprover['_results'][p] && !this.addApprover['_results'][p].isInputValid())) {
          this.allSetValidation();
        }
        if ((this.addResponder['_results'][p] && !this.addResponder['_results'][p].isInputValid())) {
          this.allSetValidation();
        }

        /********** checking validity of Reactive form for each formset **********/
        if (!this.formSets[p].assignForm.valid) {
          this.isSubmitted = true;
          this.allSetValidation();
          return false;
        }
        else {
          /********** iterate through no.of forms selected **********/
          for (let i in this.formSets[p].assignedFormsList) {

            /********** Common Check for each type | Approver **********/


            /********** Common Check for each type | ProjectId, ClientId & FIM **********/
            if (Object.keys(this.updatedProjectData).length === 0 && this._userService.getUserListData() && this.initiateDGF) {
              this.project_id = this.initiateProjId === -1 ? 0 : this.initiateProjId;
              fim = this._userService.getUserListData().email || -1;
              this.client_id = this.initiateDGF_clientId === -1 ? 0 : this.initiateDGF_clientId;
            }
            else {
              this.project_id = this.updatedProjectData.id || 0;
              fim = this.updatedProjectData.fiservImplementationManagerEmail || -1;
              this.client_id = this.updatedProjectData.clientId || 0;
            }


            /********** Creating API Requests for all types **********/

            /******* for user, API Request *******/
            if (this.formSets[p].selectedUnauthenticatedType === 'user') {
              /* Validation method for due date */
              if (!this.dueDateValidation(p, this.formSets[p].assignedFormsList[i].dueDate)) {
                return;
              }
              //  @ViewChildren('addApprover') addApprover: QueryList<MutipleaddComponent>;
              let responderUserId = this.getResponderArray(this.formSets[p].assignForm.value['formResponder']['formResponder']);

              if (this.formSets[p].formType != 'intake') {
                approverUserId = this.getApproverArray(this.formSets[p].assignForm.value['formApprover']['formApprover']);
              }

              //   this.approverUserId = this._projectService.fetchIdForRequest(this.formsUsersDetails.users, this.formSets[p].assignForm.value['formApprover']);
              //  }
              //  let responderUserId = this._projectService.fetchIdForRequest(this.formsUsersDetails.users, this.formSets[p].assignForm.value['formResponder']);
              let responseStatusData = this._masterService.getResponseStatusList().find(data => data.title.trim().toLowerCase() === "not started");

              responses_nonuser_group.push({
                "respondents": responderUserId,
                "assignedToId": 0,
                "responseStatusId": responseStatusData.id,
                "startDate": new Date().toISOString(),
                "dueDate": this._datePipe.transform(this.formSets[p].assignedFormsList[i].dueDate).replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2") + "T00:00:00Z",
                "formId": this.formSets[p].assignedFormsList[i].id,
                "title": this.formSets[p].assignedFormsList[i].updatedTitle,
                "description": this.formSets[p].assignedFormsList[i].description,
                'approvers': approverUserId,
                "approvalSchema": this.formSets[p].assignForm.value['approverSchema'],
              })
              this.userRequest = {
                'clientId': this.client_id,
                'responses': responses_nonuser_group
              }
            }

            /******* for nonuser & group, API Request *******/
            if (this.formSets[p].selectedUnauthenticatedType === 'nonuser' || this.formSets[p].selectedUnauthenticatedType === 'group') {
              if (this.formSets[p].formType != 'intake') {
                approverUserId = this.getApproverArray(this.formSets[p].assignForm.value['formApprover']['formApprover'])

                approverEmail = this.getApproverArray(this.formSets[p].assignForm.value['formApprover']['formApprover'])/* Validation method for due date */
                approverEmailId = null;
                if (approverEmail[0]) {
                  approverEmailId = approverEmail[0].emailAddress;
                }
              }
              if (!this.dueDateValidation(p, this.formSets[p].assignedFormsList[i].dueDate)) {
                return;
              }
              if (this.formSets[p].selectedUnauthenticatedType === 'group') {
                groupId = this.formSets[p].assignForm.value['formRespondentEmailGroup'] && this._projectService.fetchIdForRequest(this.formsUsersDetails.emailGroup, this.formSets[p].assignForm.value['formRespondentEmailGroup'])
              }
              if (this.formSets[p].selectedUnauthenticatedType === 'nonuser') {
                recipients[p] = this.getGuestrArray(this.formSets[p].assignForm.value['guestResponder']['guestResponder'])
              }

              survey.push({
                "name": this.formSets[p].assignedFormsList[i].updatedTitle,
                "description": this.formSets[p].assignedFormsList[i].description,
                "formId": this.formSets[p].assignedFormsList[i].id,
                "dueDate": this._datePipe.transform(this.formSets[p].assignedFormsList[i].dueDate).replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2") + "T00:00:00Z",
                "recipients": recipients[p] || undefined,
                "type": this.formSets[p].selectedUnauthenticatedType === 'group' ? "Group" : undefined,
                "approvers": approverEmail,
                "groupId": groupId || undefined,
                "approvalSchema": this.formSets[p].assignForm.value['approverSchema']
              })

              this.nonuserGroupRequest = {
                "projectId": this.project_id,
                "clientId": this.client_id,
                "organizationId": (Object.keys(this.updatedProjectData).length === 0 && this.initiateDGF) ? this._userService.getUserListData().organization : this.updatedProjectData.organizationId,
                "initiator": this._userService.getUserListData() ? this._userService.getUserListData().email : -1,
                "fiservImplementationManager": fim,
                "surveys": survey,
                "recieverOnComplete": this._userService.getUserListData() ? this._userService.getUserListData().email : -1
              }
            }

            /******* for bulk, API Request *******/
            if (this.formSets[p].selectedUnauthenticatedType === 'bulk') {

              /* Validation method for due date */

              approverUserId = this.getApproverArray(this.formSets[p].assignForm.value['formApprover']['formApprover'])
              if (approverUserId.length > 0 && approverUserId[0].approverId === null) {
                approverUserId = null;
              }
              if (!this.dueDateValidation(p, this.formSets[p].assignedFormsList[i].dueDate)) {
                return;
              }

              files_bulk.push(this.formSets[p].assignForm.get('formTemplate').value);

              responses_bulk.push({
                'Title': this.formSets[p].assignedFormsList[i].updatedTitle,
                'Description': this.formSets[p].assignedFormsList[i].description,
                'StartDate': new Date().toISOString(),
                'DueDate': this._datePipe.transform(this.formSets[p].assignedFormsList[i].dueDate).replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2") + "T00:00:00Z",
                'FormId': this.formSets[p].assignedFormsList[i].id,
                'approvers': approverUserId
              })
              this.bulkRequest = {
                'files': files_bulk,
                'responses': responses_bulk
              }
            }
          }
        }
      }
    }


    /********** calling service at one go for all type of requests **********/
    this.callApi_publishForms();
  }
  getApproverArray(approverTemp: any) {

    let approverUserId = [];
    for (let k in approverTemp) {
      if (approverTemp[k].id != '')
        approverUserId.push({
          "approverId": approverTemp[k].id,
          "level": approverTemp[k].level != 0 && approverTemp[k].level != '' ? approverTemp[k].level : 1
        })
    }
    return approverUserId;

  }
  getGuestrArray(guestTemp: any) {

    let guestTempUserId = [];
    for (let k in guestTemp) {

      guestTempUserId.push({
        "firstName": guestTemp[k].firstName,
        "lastName": guestTemp[k].lastName,
        "email": guestTemp[k].emailAddress,
      })
    }
    return guestTempUserId;

  }
  getResponderArray(ReponderTemp: any) {

    let responderUserId = [];
    for (let k in ReponderTemp) {
      responderUserId.push({ 'assignedToId': ReponderTemp[k].id })
    }
    return responderUserId;

  }
  callApi_publishForms() {

    /********** multipart form template upload *********/
    let responseString: string = "";
    const formData = new FormData();
    if (this.bulkRequest) {
      for (var i = 0; i < this.bulkRequest.responses.length; i++) {
        formData.append("files", this.bulkRequest.files[i]);
        responseString = responseString.concat(`${JSON.stringify(this.bulkRequest.responses[i])},`);
      }
      responseString = responseString.substring(0, responseString.length - 1);
    }
    formData.append("responses", `[${responseString}]`);
    let publishFormsApi: any = [];
    if (this.userRequest) publishFormsApi.push(this._formService.publishForms_user(this.userRequest, this.project_id));
    if (this.bulkRequest) publishFormsApi.push(this._formService.publishForms_bulk(formData, this.project_id, this.client_id));
    if (this.nonuserGroupRequest) publishFormsApi.push(this._formService.publishForms_nonuser_group(this.nonuserGroupRequest));
    const ids: number[] = [];
    for (var i = 0; i < this.formSets.length; i++) {
      for (var j = 0; j < this.formSets[i].assignedFormsList.length; j++) {
        ids.push(this.formSets[i].assignedFormsList[j].id);
      }
    }
    forkJoin(publishFormsApi)
      .pipe(
        flatMap(() => this._formService.updateFormPublishCount(ids))
      )
      .subscribe(() => {
        this.isError = false;
        /********** setting DTO after API Succes to show message on respective screen **********/
        this._projectDto.setPublishForms(true);
        this._projectService.projVisibility$.next(-1);
        this._projectService.clientVisibility$.next(0);
        /********** it will navigate according to its source **********/
        this.navigationForm();
      },
        (error) => { this.isError = true; this.errorMsg = error; }
      );
  }


  /********** DRAWER CHANGES BEGIN **********/
  openAddDrawer(index, ctrlName) {
    $('#containerPage').scrollTop(0);
    $('.container-page').addClass('overflow-hidden');
    this._drawerService.tempData = { index, ctrlName };
    this._drawerService.open();
  }

  setFormControlValue(index, ctrlName, entityName, entityEmail) {
    if (index != null && ctrlName != null && entityName != null && entityEmail != null) {
      if (this.formSets.length > 0) {
        if (ctrlName === "formResponder") this.formSets[index].respondentEmail = entityEmail;
        if (ctrlName === "formApprover") this.formSets[index].approverEmail = entityEmail;
        const form = this.formSets[index].assignForm;
        if (form && form.controls[ctrlName])
          form.controls[ctrlName].setValue(entityName);
      }
    }
  }
  /********** To update responder-approver list in case suggestion is updated by parent ***********/
  updateUsers() {
    let allApiData = this._formService.formsUsersData();
    this.formsUsersDetails.users = allApiData.users;
    this.formsUsersDetails.usersData = { 'data': allApiData.users };
  }
  /**********DRAWER CHANGES END **********/
  formInitialized(name: string, formSet: any, form: any) {
    this.formSets[formSet].assignForm.setControl(name, form);
    this.updateFormValidators(formSet);
    // this.settingUnauthenticatedSurveyType( formSet);



  }
}
