<nav role="navigation" class="navbar navbar-expand-md navbar-light">
  <a class="navbar-brand" href="javascript:void(0);" routerLink="/dashboard" *ngIf="!isClientExecutiveUserOnly">
    <img alt="fiserv" src="assets/images/Fiserv Logo.png">
  </a>
  <a class="navbar-brand" href="javascript:void(0);" routerLink="/commitmentdashboard/"
    *ngIf="isClientExecutiveUserOnly">
    <img alt="fiserv" src="assets/images/Fiserv Logo.png">
  </a>

  <div class="" id="inverseNavbarSupportedContent" style="margin-right: 0;">
    <ul class="navbar-nav navbar-main">
      <li class="nav-item dropdown show navigation">
        <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse"
          aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-collapse collapse" id="navbarCollapse">
          <ul class="navbar-nav mr-auto">


            <li class="nav-item" *ngIf="clientCommitmentDashboard">
              <a class="nav-link" href="javascript:void(0);" routerLink="/commitmentdashboard/"
                routerLinkActive="active">
                {{content['commitmentDashboard']}}
              </a>
            </li>


            <li class="nav-item" *ngIf="showAdminLink">
              <a class="nav-link" href="javascript:void(0);" routerLink="/admin" routerLinkActive="active"
                (click)="updateSubNavHeader()">Administration</a>
            </li>
            <li class="nav-item" *ngIf="!isClientUser">
              <a class="nav-link" href="javascript:void(0);" routerLink="/commitments-intake" routerLinkActive="active">
                {{content['commitmentsIntake']}}</a>
            </li>


          </ul>

        </div>
      </li>
      <li class="nav-item dropdown right-side-dropdown-menu">

        <div class="utilities-wrapper">

          <div class="navbar-collapse " id="navbarCollapseForms">
            <ul class="navbar-nav">
              <li style="padding-left: 10px; padding-right: 0px" class="nav-item" *ngIf="!isClientExecutiveUserOnly">
                <div class="row ">
                  <div class="form-group pr-0 col-lg-12">
                    <div class="custom-search-input">
                      <div class="input-group">
                        <!-- <input type="text" id="search" placeholder="Search" class="search-query search-menu-control"> -->
                        <span class="input-group-btn mt-13">
                          <button class="btn" title="Quick Search" type="button" data-toggle="modal"
                            data-target="#searchModal" (click)="getQuickSearchResults()">
                            <i style="font-size: 30px;" class="material-icons">search</i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li class="nav-item" style="padding-left: 10px;">
                <div data-placement="top" class="tag" title="Help Topic">
                  <button class="btn nav-link ic-live-help-image" data-toggle="modal" data-target="#helpModal">
                  </button>
                </div>
              </li>

              <li class="nav-item quick-access-icon"
                *ngIf="(clientCommitmentQuickLinks || showClientDashboardLink) && !isClientExecutiveUserOnly">
                <div data-placement="top" class="tag" title="Quick Access" id="quickLinksIcon"
                  (click)="quickLinks($event)">
                  <a class="quick-access-btn" href="javascript:void(0);" aria-label="Icon with Quick access links">
                    <i class="material-icons mat-icon-button quick-access">flash_on</i>
                  </a>
                  <div class="SiteNav quick-access-links d-none" id="quickLinkID">
                    <div class="SiteNavWrapper">
                      <ul class="siteNavList_list">
                        <li class="quick-access-menuitem" *ngIf="clientCommitmentQuickLinks && clientCommitmentId > 0">
                          <a class="quick-access_link" [routerLink]="'/commitments-intake'"
                            (click)="onCommitmentIntakeClick($event)">
                            <span class="siteNavItem_labelContainer">
                              Commitments Intake
                            </span>
                          </a>
                        </li>
                        <li class="quick-access-menuitem" *ngIf="showClientDashboardLink">
                          <a class="quick-access_link" href="javascript:void(0);" routerLinkActive="active"
                            routerLink="/commitmentdashboard">
                            <span class="siteNavItem_labelContainer">
                              Client Dashboard
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="nav-item form-responses-width user-dropdown-container">
          <a href="javascript:void(0);" class="nav-link dropdown-toggle user-dropdown" id="loggedInUser">
            <img src="assets/images/thumbnail-150x150.png" alt="" class="thumbnail img-w28 mr-1">
            <span class="uname">
              {{loggedInUser}}
              <div *ngIf="!isClientUser" class="role">{{userOrg}} </div>
            </span>

            <i class="material-icons caret-custom">expand_more</i>
          </a>
          <div (mouseenter)="showMenu=true" class="SiteNav fadeSiteNav user-info-panel" id="userInfo">
            <!-- <div class="SiteHeaderArrow" data-js-target="SiteHeader.arrow" aria-hidden="true"></div> -->
            <div class="SiteNavWrapper">
              <ul class="siteNavList_list">
                <li class="siteNavItem-isArrowHidden">
                  <a class="siteNavItem_link" href="javascript:void(0);" (click)="logout()">
                    <span class="siteNavItem_labelContainer">
                      <i class="fa fa-sign-out mr-1"></i> {{content['headerSignOut']}}
                    </span>
                  </a>
                </li>

                <li class="siteNavItem-isArrowHidden" *ngIf="hasMultipleOrgs">
                  <a class="siteNavItem_link" href="javascript:void(0);" data-toggle="modal"
                    data-target="#switchOrgModal">
                    <span class="siteNavItem_labelContainer">
                      <i class="material-icons switch-org-icon">compare_arrows</i> Switch Org
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>

<!-- Notification Modal -->
<div class="modal full-width fade position-right" id="notificationModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="height: 100%;">
      <div class="modal-header white-modal-header">
        <h4 class="modal-title notification-image" id="myModalLabel">Notifications
          <span *ngIf="notificationCounts > 0" class="badge rounded-pill bg-danger">{{ notificationCounts }}</span>
        </h4>
        <button type="button" class="close" data-dismiss="modal" tabindex="0" autofocus=""
          aria-label="Close popup"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div *ngIf="userNofitications && userNofitications.length > 0">
          <div *ngFor="let item of userNofitications | slice:0:5; let i = index;" href="javascript:void(0);"
            data-dismiss="modal" (click)="viewUserNotification(item)" style="cursor: pointer;"
            class="container description-image">
            <h5 class="d-flex justify-content-start"><span [ngClass]="getNotificationStatusCss(item)"
                style="margin-top: 0.4rem"></span>{{item.title}}</h5>
            <p style="margin-top:-10px"><small>{{item.createdOn | date: 'MM/dd/yyyy HH:mm' || 'N/A'}}</small></p>
            <p class="description text-truncate" title="{{item.messages}}" [innerHTML]="item.messagesForDisplay"></p>
          </div>
          <br>
          <div class="d-flex justify-content-between">
            <div>
              <a href="javascript:void(0);" (click)="navigateToUserNotificationPage()" data-dismiss="modal">All
                Notifications
                <span class="arrow-right-image small-image"></span>
              </a>
            </div>
            <div *ngIf="notificationCounts > 0">
              <a href="javascript:void(0);" (click)="markAllAsRead()">Mark all as read
                <span class="arrow-right-image small-image"></span>
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="!userNofitications || userNofitications.length == 0">
          <span class="text-center">No results found</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TO DO -->
<div class="modal full-width fade position-right" id="todosModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header white-modal-header">
        <h4 class="modal-title qr-code-image" id="myModalLabel">My To Dos
          <span class="badge rounded-pill bg-info text-white">2</span>
        </h4>
        <button type="button" class="close" data-dismiss="modal" tabindex="0" autofocus=""
          aria-label="Close popup"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div class="container description-image">
          <h5>New client created. Review pending.</h5>
          <p class="description">Note: Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Suspendisse vel orci vitae mi fermentum fermentum. Nunc et rutrum sem. Sed faucibus
            iaculis urna, ut facilisis ligula.</p>
          <a href="#">Create Now
            <span class="arrow-right-image small-image"></span>
          </a>
        </div>
        <div class="container description-image">
          <h5>New client created. Review pending.</h5>
          <p class="description">Note: Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Suspendisse vel orci vitae mi fermentum fermentum. Nunc et rutrum sem. Sed faucibus
            iaculis urna, ut facilisis ligula.</p>
          <a href="#">Create Now
            <span class="arrow-right-image small-image"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Help -->
<div class="modal full-width fade position-right" id="helpModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header white-modal-header">
        <h4 class="modal-title ic-live-help-image"><span>Help</span></h4>
        <button type="button" class="close" data-dismiss="modal" tabindex="0" autofocus=""
          aria-label="Close popup"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <form class="form form-border mb-3 collapse-view" [formGroup]="filterForm" autocomplete="off"
          *ngIf="!isClientUser">
          <fdl-type-ahead formControlName="name" label="Search Article" [collection]="helpArticleSuggestion"
            optionField="title" placeholder="Type to search" [scrollable]="false" [optionsLimit]="10" [minLength]="0"
            (ngModelChange)="filterList()">
          </fdl-type-ahead>
          <div class="has-error">
            <span
              *ngIf="!filterForm.get('name').valid && filterForm.get('name').errors?.pattern && filterForm.get('name').dirty"><i
                class="material-icons">warning</i>Please enter a valid Resource Article Name</span>
          </div>
        </form>
        <div class="help-recommended-topics">
          <h4>Recommended Topics</h4>
          <div class="help-body" *ngIf="!isClientUser">
            <a *ngFor="let row of helpArticleSuggestion | slice:0:4; let i = index;" href="javascript:void(0);"
              data-dismiss="modal" routerLink="/help/topic/{{row.id}}/category" routerLinkActive="active"
              class="description-image">{{row.title}}</a>
          </div>
          <a href="javascript:void(0);" routerLink="/help/category/" routerLinkActive="active" data-dismiss="modal"
            *ngIf="!isClientUser">All Topics
            <span class="arrow-right-image small-image"></span>
          </a>

          <div class="help-body" *ngIf="isClientUser">
            <a href="javascript:void(0);" data-dismiss="modal"
              routerLink="/help/topic/{{clientDashboardUserManualId}}/category" routerLinkActive="active"
              class="description-image">Client Commitment Tracker Dashboard - User Manual</a>
            <a href="javascript:void(0);" data-dismiss="modal"
              routerLink="/help/topic/{{clientDashboardFAQId}}/category" routerLinkActive="active"
              class="description-image">Client Commitment Tracker Dashboard - FAQ</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Search -->
<div class="modal full-width fade position-right" id="searchModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header white-modal-header">
        <i style="margin-left: -10px;" class="material-icons">search</i>
        <h4 style="margin-left: -30px;" class="modal-title"><span>Quick Search</span></h4>
        <button type="button" class="close" data-dismiss="modal" tabindex="0" autofocus=""
          aria-label="Close popup"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">

        <div class="form form-border mb-3 collapse-view" autocomplete="off">
          <div class="d-flex">
            <div class="fdl-select mr-1">
              <select style="width: 120px;" *ngIf="searchTypeValues" name="category" aria-invalid="false"
                class="form-control" [(ngModel)]="searchType" (change)="onQuickSearchTypeChanged()">
                <option *ngFor="let state of searchTypeValues" [ngValue]="state.value">{{ state.text }}</option>
              </select>
            </div>
            <div class="w-100">
              <fdl-type-ahead [(ngModel)]="searchText" (ngModelChange)="getQuickSearchResults()"
                [collection]="searchSuggestions" optionField="title" placeholder="Type to search" [scrollable]="true"
                [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
            </div>
          </div>
          <div *ngIf="regexError" [ngClass]="{'has-error mt-3': regexError}">
            <span>
              <i class="material-icons">warning</i>{{regexError}}
            </span>
          </div>
          <div *ngIf="permissionQuickSearchError" [ngClass]="{'has-error mt-3': permissionQuickSearchError}">
            <span>
              <i class="material-icons">warning</i>{{permissionQuickSearchError}}
            </span>
          </div>
        </div>
        <div class="help-recommended-topics" *ngIf="searchSuggestions && searchSuggestions.length !== 0">
          <h4>Recommended results</h4>
          <div class="help-body">
            <a *ngFor="let row of searchSuggestions | slice:0:5; let i = index;" href="javascript:void(0);"
              data-dismiss="modal" [routerLink]="getSearchItemUrl(row)" routerLinkActive="active"
              class="description-image">
              {{row.title}}</a>
          </div>
          <a href="javascript:void(0);" (click)="navigateToQuickSearchPage()" data-dismiss="modal">View All Result
            <span class="arrow-right-image small-image"></span>
          </a>
        </div>
        <div class="text-center"
          *ngIf="!permissionQuickSearchError && (!searchSuggestions || searchSuggestions.length === 0)">
          No results found
        </div>
      </div>
    </div>
  </div>
</div>

<!--Starts Switch Org Modal dialog-->
<div id="switchOrgModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  class="modal full-width fade position-right switch-org-modal" style="display: none;" aria-hidden="true">
  <div role="document" class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header white-modal-header">
        <h4 class="modal-title switch-org-modal-image"><span>Switch Organization</span></h4>
        <button type="button" data-dismiss="modal" tabindex="0" autofocus="" aria-label="Close popup"
          class="close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">

        <div class="org-structure">
          <h4>Select an Organization to Switch </h4>
          <div class="org-body" *ngFor="let org of organizationContext">
            <div class="description-image fdl-2" *ngIf="org.isCurrent">
              {{ org.organizationTitle }}
              <span class="badge badge-primary-dark"><span class="material-icons"> check </span> Current </span>
            </div>
            <div class="description-image fdl-2" *ngIf="!org.isCurrent">
              {{ org.organizationTitle }}
              <button class="btn btn-primary-dark" (click)="switchUserOrg(org.organizationId)"> Switch</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Ends Switch Org Modal dialog-->