<div class="heading-container fdl-2">
    <h1>Custom Clients</h1>
</div>

<div class="main-container-sub responsive-container col-settings-drawer-alignment">
    <div class="form-container">
        <div class="row">
            <section class="col-lg-12 p-0">
                <form class="form-border mb-3 box collapse-view" [formGroup]="filterForm" autocomplete="off">
                    <h2 class="h4 collapse-arrow" [ngClass]="[filterExists ? '':'collapsed']" data-toggle="collapse"
                        href="#collapseFilter">Search</h2>
                    <div class="filter-form-container filter-form-container-column2 search-filter collapse"
                        [ngClass]="[filterExists ? 'show':'']" id="collapseFilter">

                        <div class="filter-form-control pr-4 typeahead-wrapper">
                            <label>DUNS</label>
                            <fdl-type-ahead type="number" name="dunsId" placeholder="Type to search"
                                [collection]="dunsIdSuggestions" class="filter-typeahead" aria-invalid="false"
                                formControlName="dunsId"  optionField="titleWithDuns"  [optionsLimit]="100" [minLength]="0" [scrollable]="true">
                            </fdl-type-ahead>

                        </div>
                        <div class="buttom-wrapper">
                            <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search"
                                (click)="filterList()">Search</button>
                        </div>
                        <div class="ml-2 buttom-wrapper" *ngIf="filtered">
                            <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Reset"
                                (click)="clearFilterList()">Reset</button>
                        </div>
                    </div>
                </form>

                <div class="data-table-container">
                    <div class="table-responsive form-responces s-overflow-auto">
                        <div class="justify-content-between d-flex">
                            <div class="headline-container mb-1">
                                <p *ngIf="pagerObject">Total {{pagerObject.totalCount | number}} items</p>
                            </div>
                            <div class="d-flex">
                                <div class="refresh-icon-link mt-1">
                                    <a id="refreshTask" title="Refresh List" class="">
                                        <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th role="columnheader" [attr.colspan]="column.colspan" scope="col"
                                        *ngFor="let column of displayColumns; let i = index;">
                                        <span>
                                            <a (click)="sort(column.key, 'columnSettings')" href="javascript:void(0);">
                                                {{column.text}}
                                                <i class="material-icons msort14" aria-hidden="true" *ngIf="isAsc && filterColumn === column.key">arrow_upward</i>
                                                <i class="material-icons msort14" aria-hidden="true" *ngIf="!isAsc && filterColumn === column.key">arrow_downward</i>
                                            </a>
                                        </span>
                                    </th>

                                    <th style="background: white; position: sticky; right: 0; text-align: right;" scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of clients; let i = index">
                                <tr>
                                 
                                    <td>
                                        <div>{{item.id}}</div>
                                    </td>
                                    <td colspan="3">
                                        <div>{{item.title}}</div>
                                    </td>
                                    <td colspan="2">
                                        <div>{{item.dunsId}}</div>
                                    </td>
                                    <td colspan="1">
                                        <div>{{item.isSrategicCLient ? 'Yes' : 'No'}}</div>
                                    </td>

                                    <td colspan="1">
                                        <div>{{item.createdOn | dateFormat}}</div>
                                    </td>
                                    <td colspan="1">
                                        <div>{{item.modifiedOn | dateFormat}}</div>
                                    </td>
                                    <td (click)="addZIndex(i)" [attr.id]="'actions-' + i" class="actions"
                                        role="cell">
                                        <div (click)="addZIndex(i)" class="kebab-menu dropleft">
                                            <a href="javascript:void(0);" (click)="addZIndex(i)"
                                                data-toggle="dropdown" id="headerSwitcher" aria-expanded="false">
                                                <span ariaReadOnly="true" class="material-icons"> more_vert </span>
                                            </a>
                                            <div x-placement="left-start"
                                                class="dropdown-menu dropdown-menu-dark top-15 header-dropdown-switcher">
                                                <div class="dropdown-list-option">
                                                    <div>
                                                        <a  *ngIf="item.isSrategicCLient" class="dropdown-item" (click)="updateStrategy(item.id)">Deactivate Custom Client</a>
                                                        <a  *ngIf="!item.isSrategicCLient" class="dropdown-item" (click)="updateStrategy(item.id, true)">Activate Custom Client</a>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="pagerObject">
                        <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 "
                            [pagerObject]="pagerObject" (pagingChanged)="changePage($event)"></app-pagination>
                    </div> 
                </div>
            </section>
        </div>
    </div>
</div>