<!-- [ngClass]="showLeftNav ? '' : 'left-nav-collapse' -->
<div class="left-nav"   role="navigation">
    <!-- (click)="toggleSideBar()" -->
      <div class="left-nav-icon cursor-default" aria-label="menu" tabindex="0">
          <!-- <i class="navbar-icon-menu" [ngClass]=" showLeftNav ? '' : 'navbar-icon-close' "></i> -->
          <span class="toggle-heading">Admin Settings</span>
      </div>
      <ul>
        <li>
          <a routerLink="/admin/organization" href="javascript:void(0);" routerLinkActive="active" *ngIf="showOrganizationLink">
            <span>Organizations</span>
          </a>
        </li>
         
        <li>
          <a href="javascript:void(0);" routerLink="/admin/client" routerLinkActive="active" *ngIf="showClientLink">
  
            <span>Clients</span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" routerLink="/admin/custom-client/list" routerLinkActive="active" *ngIf="showCustomClientLink">
            <span>Custom Clients</span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" routerLink="/admin/user" routerLinkActive="active" *ngIf="showUserLink">
            <span>Users</span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" routerLink="/admin/product" routerLinkActive="active" *ngIf="showProductLink">            
            <span>Products</span>
          </a>
        </li>        
      </ul>
  </div>