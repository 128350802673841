<div id="reviewCommitments" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" class="modal fade bd-example-modal-lg" style="display: none;"
    aria-hidden="true">
    <div role="document" class="modal-dialog review-commitments-modal modal-lg mt-120" style="max-width: 550px;">
        <div class="modal-content">            
            <div class="modal-body pt-2">                
                <div class="row">
                    <div class="col-md-12 mb-5">
                        <label for="txtComment">Comment:</label><span class="asterisk"></span>
                        <textarea type="text" rows="4" id="comment" name="comment" maxlength="2000"
                            [(ngModel)]="comment" class="form-control">
                        </textarea>
                    </div>
                </div>
                <div class="fdl-2 w-100 text-right mt-0">
                    <button type="button" data-dismiss="modal"
                        class="btn fdl-btn btn-secondary-light mr-2">Cancel</button>
                    <button data-dismiss="modal" id="save-button-form-container" type="button"
                        [disabled]="!isReviewed || comment.trim() === ''" class="btn fdl-btn btn-primary-orange"
                        (click)="onSubmit()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>