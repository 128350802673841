import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISuggestion, ISuggestions } from '../shared/suggestion';
import { ICommitmentDelegate } from '../shared/commitment-delegate';

@Injectable({
  providedIn: 'root',
})
export class CommitmentDelegateService {
  private apiUrl = 'pagetemplateresponsedelegate';

  constructor(private http: HttpClient) {}

  callorgSpecificConfiguration(param) {
    return this.http.get('/assets/configuration/' + param + '.json');
  }

  get(delegateGuid: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/' + delegateGuid);
  }

  getList(queryParams, refresh = false) {
    let params = new HttpParams();
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: refresh ? 'true' : 'false',
      }),
    };

    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.apiUrl, {
      headers: httpOptions.headers,
      params: params,
      observe: 'response',
    });
  }

  create(data: ICommitmentDelegate[]): Observable<any> {
    return this.http.post(this.apiUrl, data);
  }

  update(delegateGuid: string, data: ICommitmentDelegate[]): Observable<any> {
    return this.http.put(`${this.apiUrl}/${delegateGuid}`, data);
  }

  delete(delegateGuid): Observable<any> {
    return this.http.delete(this.apiUrl + '/' + delegateGuid);
  }

  getSuggestions(params: any) {
    return this.http.get<ISuggestions>(`${this.apiUrl}/suggestions`);
  }
}