<div class="admin-container main-container-sub responsive-container col-settings-drawer-alignment">

    <div class="row m-0 height-100">
        <aside class="side-navbar" >
            <div class="main">
                <app-sidebarnav *ngIf="!isCCT"></app-sidebarnav>
                <app-sidebarnav-cct *ngIf="isCCT"></app-sidebarnav-cct>
            </div>
        </aside>
        <section class="right-section " >        
            <div>
                <router-outlet></router-outlet>
            </div>
        </section>
    </div>

</div>
<!-- <app-footer></app-footer> -->