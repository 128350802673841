import { UserFilterService } from './../../../services/user.filter.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IForms } from 'src/app/shared/form';
import { ProjectsService } from 'src/app/services/projects.service';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IQuery } from 'src/app/shared/query';
import { IPager } from 'src/app/shared/pagination';
import { ICreateForm } from '../models/createForm';
import { ISuggestions } from 'src/app/shared/suggestion';
import { ManageFormsService } from '../services/manageForms.service';
import { SubHeaderService } from 'src/app/SharedModule/services/subHeader.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { MasterService } from 'src/app/services/master.service';
import { UserService } from 'src/app/services/user.service';
import { PreferenceService } from 'src/app/services/preference.service';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { FdlFilterUIHepler } from 'src/app/shared/fdl-filter-ui-helper';
declare var $: any;

@Component({
  templateUrl: './form-list.component.html'
})
export class FormListComponent implements OnInit, OnDestroy {
  isError: boolean;
  filterForm: FormGroup;

  formList: IForms = { forms: [] };
  queryParam: IQuery;
  pagerObject: IPager = {
    totalCount: 0,
    pageSize: 0,
    currentPage: 0,
    totalPages: 0
  };

  displayColumns = ['ID','Permission', 'Form Name', 'Form Description', 'Form Type', 'Product Name', 'Last Updated User', 'Last Updated Date'];
  keys = ['id', 'title','isLocked', 'description', 'formType', 'productTitle', 'modifiedByName', 'modifiedOn'];
  currentPageNumber: number = 1;
  showComment: boolean = true;
  productSuggestion: ISuggestions;
  formSuggestion: ISuggestions;
  sortingObject;
  errorMsg: string = "";
  successMsg: string = "";
  warningMsg: string = "";
  isCreateFormAllowed: boolean = false;
  isEditFormAllowed: boolean = false;
  isDeleteFormAllowed: boolean = false;
  showProductFilter: boolean = false;
  showOrganizationFilter: boolean = false;
  organizationSuggestion: ISuggestions;
  isViewHistoryAllowed: boolean = false;
  isViewTimelineAllowed: boolean = false;
  filterExists: boolean = false;
  selectedOrgId: number;
  listname: string = PreferenceService.formTemplateList;
  filtered: boolean = false;
  productText: string;
  selectedForm = { id: '', title: '' };
  isSubmitted = false;
  regexValidationMessage = REGEX_VALIDATION_MESSAGE;
  constructor(private _router: Router, private projectService: ProjectsService,
    private _manageForm: ManageFormsService, private permissionService: PermissionsService,
    private _subHeaderService: SubHeaderService, private masterService: MasterService,
    private userService: UserService, private userFilterService: UserFilterService,
    private route: ActivatedRoute,
    private fdlFilterHelper: FdlFilterUIHepler) { }

  ngOnInit(): void {
    this.selectedOrgId = 0;
    this.isCreateFormAllowed = this.permissionService.isCreateFormAllowed();
    this.isEditFormAllowed = this.permissionService.isEditFormAllowed();
    this.isDeleteFormAllowed = this.permissionService.isDeleteFormAllowed();
    this.showProductFilter = this.permissionService.showProductFilter();
    this.showOrganizationFilter = this.permissionService.userData.isAdmin;
    this.initFilterForm();
    this.initQueryParams();
    this.filterExists = this.userFilterService.loadAndApplyFilters(this.route, this.filterForm, this.queryParam);
    this.callProjectListService();
    this.getSuggestions();
    this.showFormSuccessMsg();
    this.isViewHistoryAllowed = !this.userService.isClient && !this.userService.isRespondent;

    this.filtered = this.filterExists

  }

  refresh() {
    this.callRefreshFormListService();
  }

  getSuggestions2() {
    if (this.showProductFilter) {
      this.projectService.getSuggestions("products", this.selectedOrgId, "orgId").subscribe(
        suggestions => this.productSuggestion = suggestions,
        error => { this.isError = true; this.errorMsg = error; }
      );
    }

    if (this.showOrganizationFilter) {
      this.projectService.getSuggestions("organizations", "")
        .subscribe(suggestions => {
          this.organizationSuggestion = suggestions;
        },
          error => { this.isError = true; this.errorMsg = error; });
    }

    this.getFormSuggestions();
  }

  getSuggestions() {
    if (this.showProductFilter) {
      let suggestionSubscriber: any;
      let params = [];
      let productTitle = this.filterForm.value.ProductTitle
      if (productTitle && productTitle.length > 1) {
        params.push({ paramName: "title", value: productTitle });
        params.push({ paramName: "orgId", value: this.selectedOrgId });
        suggestionSubscriber = this.projectService.getClientSuggestions("products", params, 100);
      }
      else {
        params.push({ paramName: "orgId", value: this.selectedOrgId });
        suggestionSubscriber = this.projectService.getClientSuggestions("products", params, 100);
      }

      suggestionSubscriber.subscribe(
        data => {
          this.productSuggestion = data;
        },
        error => { this.isError = true; this.errorMsg = error; }
      );
    }

    if (this.showOrganizationFilter) {
      this.projectService.getSuggestions("organizations", "")
        .subscribe(suggestions => {
          this.organizationSuggestion = suggestions;
        },
          error => { this.isError = true; this.errorMsg = error; });
    }

    this.getFormSuggestions();
  }

  typeaheadLoadingProducts() {
    this.getSuggestions();
  }

  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      title: new FormControl('', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),
      ProductTitle: new FormControl(''),
      OrganizationTitle: new FormControl('', [Validators.pattern(REGEX_PATTERNS.organization_name_validator)]),
      formType: new FormControl(''),
      id: new FormControl('', [Validators.pattern(REGEX_PATTERNS.Numeric)])
    });
  }

  downLoadType(event: any) {
    if (event.type == 'xlsx') {
      this.exportExcel();
    }
  }

  exportExcel() {
    this.projectService.exportFormList(this.queryParam).subscribe(res => {
      let newBlob = new Blob([res.body], { type: "application/vnd.ms-excel" });

      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob);
      } else {
        const data = window.URL.createObjectURL(newBlob);
        const filename = 'FormTemplates.xlsx';
        const link = document.createElement('a');
        link.href = data;
        link.download = filename;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
        }, 100);
      }
    },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  showFormSuccessMsg() {
    this.successMsg = "";
    this.warningMsg = "";
    this.isError = false;
    this.errorMsg = "";
    if (this._manageForm.formVal?.isSuccess) {
      const title = this._manageForm.formVal.isExist ? this._manageForm.formVal.previousTitle : this._manageForm.formVal.formName;
      this.successMsg = `The form template '${title}${this._manageForm.formVal.isExist ? "' has been updated." : "' has been created."}`
    }

    if (this._manageForm.warningMessage) {
      this.warningMsg = this._manageForm.warningMessage;
    }
    /* not required as redirection is happening on the form list only in error cases
    else  if(this._manageForm.formVal && this._manageForm.formVal.isError)
    {
      this.isError=true;
      this.errorMsg=this._manageForm.formVal.errorMessage;
    }*/
    // clear the form values
    this._manageForm.formVal = null;
  }

  callProjectListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.projectService.getFormList(this.queryParam)
      .pipe(take(1))
      .subscribe(responses => {
        // below is handled in the showFormSuccessMsg method
        //this.isError = false;
        this.formList = responses.body as IForms;

        if (responses.headers.get('X-pagination')) {
          this.pagerObject = Object.assign({}, JSON.parse(responses.headers.get('X-pagination')) as IPager);
        }
        this.masterService.resetFiltersCancelledFlag();
        $('#refreshTask').removeClass('fa-rotation');
      },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  callRefreshFormListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.projectService.getRefreshFormList(this.queryParam)
      .pipe(take(1))
      .subscribe(responses => {
        // below is handled in the showFormSuccessMsg method
        //this.isError = false;
        this.formList = responses.body as IForms;
        if (responses.headers.get('X-pagination')) {
          this.pagerObject = Object.assign({}, JSON.parse(responses.headers.get('X-pagination')) as IPager);
        }
        this.masterService.resetFiltersCancelledFlag();
        $('#refreshTask').removeClass('fa-rotation');
      },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  filterList() {
    this.isSubmitted = true;
    if (this.filterForm.valid) {
      this.userFilterService.saveFilter(this.route, this.filterForm);
      this.initQueryParams();
      let filterValues = this.filterForm.value;
      if (filterValues['id'])
        this.queryParam.id = filterValues['id'];
      if (filterValues['title'])
        this.queryParam.title = filterValues['title']
      if (filterValues['ProductTitle'])
        this.queryParam.productTitle = filterValues['ProductTitle']
      if (filterValues['OrganizationTitle'])
        this.queryParam.organizationTitle = filterValues['OrganizationTitle'];
      if (filterValues['formType'])
        this.queryParam.formType = filterValues['formType'];
      this.setOrderBy(this.sortingObject);

      this.callProjectListService();

      if (filterValues['OrganizationTitle'] === "") {
        this.selectedOrgId = 0;
        this.getSuggestions();
      }
      this.filtered = true
    }
  }

  clearFilterList() {
    this.filtered = false
    this.initQueryParams()
    this.initFilterForm();
    this.userFilterService.clearFilter()
    this.callRefreshFormListService();
    this.productText = '';
    this.getSuggestions();
    this.fdlFilterHelper.showSearchIconWhenResetFilter();
  }

  navigate(event) {
    if (event.action === "extractionTemplate") {
      this._router.navigate(['/self-service/formExtracts/formExtractionTemplate/', event.data]);
    }
    else if (event.action === "view") {
      this._subHeaderService.updateSideBarNavigation(false);
      this._router.navigate(['/admin/form/view', event.data]);
    }
    else if (event.action === "downloadFormFields") {
      this._manageForm.getFormFieldsByformId(event.data).subscribe(data => {
        let newBlob = new Blob([data.body], { type: "application/vnd.ms-excel" });

        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveOrOpenBlob(newBlob);
        } else {
          const data = window.URL.createObjectURL(newBlob);
          const filename = 'FormFields.xlsx';
          const link = document.createElement('a');
          link.href = data;
          link.download = filename;
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      },
        error => {
          this.isError = true;
          this.errorMsg = error;
        });
    }
    else if (event.action === "edit") {
      this._manageForm.getFormByformId(event.data).subscribe(data => {
        let dataForm: ICreateForm = {
          "id": event.data,
          formDes: data.description,
          orgName: data.organizationTitle,
          productName: data.productTitle,
          productId: data.productId,
          templateOption: '',
          formTemplate: '',
          formName: data.title,
          json: data.formJSON,
          initialDataJSON: data.initialDataJSON,
          isActive: data.isActive,
          isExist: true,
          orgId: data.organizationId,
          "connectedForms": data.connectedForms,
          "IsPublishable": data.IsPublishable,
          "formType": data.formType,
          "approvalSchema": data.approvalSchema,
          "formSubType": data.formSubTypeId,
          "isSensitiveData": data.isSensitiveData,
          enableEmailNotifications: data.enableEmailNotifications,
          formFieldListJson: data.formFieldListJson,
          searchByFormFieldJson: data.searchByFormFieldJson
        };
        if (data["isIntakeForm"]) {
          dataForm["isIntakeForm"] = data.isIntakeForm;
        }

        if (data["recieverOnComplete"]) {
          dataForm["recieverOnComplete"] = data.recieverOnComplete;
        }

        if (data["approvers"] && data["approvers"].length > 0) {
          dataForm["approvers"] = data.approvers;
        }

        if (data["parentIds"] && data["parentIds"].length > 0) {
          dataForm["parentIds"] = data.parentIds;
        }
        this._manageForm.saveFormValues(dataForm);
        this._router.navigate(['/admin/form/template']);
      });
    }
    else if (event.action === "delete") {
      this.successMsg = '';
      this.isError = false;
      this._manageForm.deleteForm(event.data).subscribe(data => {
        this.callProjectListService();
        this.getFormSuggestions();
        this.successMsg = `The form template '${event.title}' has been deleted.`
      },
        error => {
          this.isError = true;
          this.errorMsg = error;
        });
    }
    else if (event.action === "viewHistory") {
      this._manageForm.getFormByformId(event.data).subscribe(data => {
        this._manageForm.historyName = data.title
        this._router.navigate(['/admin/form/history/list', event.data]);
      });
    }
    else if (event.action === "viewTimeline") {
      this._manageForm.getFormByformId(event.data).subscribe(data => {
        this._manageForm.historyName = data.title
        this._router.navigate(['/admin/form/timeline/list', event.data]);
      });
    }
    else if (event.action === "updateInflightForms") {
      this.selectedForm.id = event.data.id;
      this.selectedForm.title = event.data.title;
      $('#updateInflightForms').modal('show');
    }
  }

  submitForm() {
    this._manageForm.editForm().subscribe(data => {
      this._router.navigate(['admin/form/list']);
    },
      error => { },
      () => { }
    );
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.callProjectListService();
  }

  createForm() {
    this._router.navigate(['admin/form/create']);
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.callProjectListService();
  }

  setOrderBy(sortingData) {
    if (sortingData) {
      this.queryParam.orderBy = (sortingData.isAsc) ? sortingData.key : sortingData.key + " desc";
    }
  }

  cancel() {
    this._router.navigate(['admin/form/list']);
  }

  getFormSuggestions() {
    this.projectService.getSuggestions("forms", this.selectedOrgId, "orgId").subscribe(
      suggestions => this.formSuggestion = suggestions,
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  onOrgSelect(event: any) {
    if (event.item.id > 0) {
      this.selectedOrgId = event.item.id;
      this.filterForm.get('title').setValue("");
      this.filterForm.get('ProductTitle').setValue("");
      this.getSuggestions();
    }
  }

  columDisplayChecker(param) {
    if (param.name === "publishedCount" || param.display === "Publish count") {
      return this.permissionService.userData.isAdmin && this.permissionService.isPublishCountViewAllowed();
    }
    return true;
  }

  ngOnDestroy(): void {
    this._manageForm.warningMessage = null;
  }
}
