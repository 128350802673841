import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { ManualCommitmentBUOptions } from 'src/app/core/constants';
declare var $: any;
@Component({
  selector: 'app-manual-commitment-commitment-details',
  templateUrl: './manual-commitment-commitment-details.component.html',
  styleUrls: ['./manual-commitment-commitment-details.component.css']
})
export class ManualCommitmentCommitmentDetailsComponent implements OnInit {
  @Output() buOrProductClicked = new EventEmitter();
  @Input() formGroupName: string;
  commitmentForm: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.commitmentForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  getCountSelectedLabel(controlName: string) {
    const count = this.commitmentForm.get(controlName).value.length;
    return `${count} item${count > 1 ? 's' : ''}`;
  }

  onClickProductOrBU(): void {
   this.buOrProductClicked.emit();
  }
}
