<div id="reviewTrail" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" class="modal fade bd-example-modal-lg" style="display: none;"
    aria-hidden="true">
    <div role="document" class="modal-dialog review-commitments-modal modal-lg mt-10">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="myLargeModalLabel" class="modal-title">
                    Project Review Trail
                </h4>
                <button type="button" data-dismiss="modal" aria-label="Close popup" class="close"
                    (click)="resetData()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body pt-2">
                <div class="row">
                    <div class="col-12">
                        <div class="apland-timeline-area">
                            <div class="single-timeline-area blue header-timeline">
                                <div class="row timeline-header">
                                    <div class="col-md-2 col-lg-3">
                                        <div class="single-timeline-content d-flex">
                                            <div class="timeline-text">
                                                <p>Modified Date</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2">
                                        <div class="single-timeline-content d-flex">
                                            <div class="timeline-text">
                                                <p>Project Id</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-4">
                                        <div class="single-timeline-content d-flex">
                                            <div class="timeline-text">
                                                <p>Event Name and Value</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2">
                                        <div class="single-timeline-content d-flex">
                                            <div class="timeline-text">
                                                <p>Modified By</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngFor="let timeline of data"
                                [ngClass]="getTimelineStatusCSSClass(timeline.action, timeline.value)">
                                <div class="row">
                                    <div class="timeline-date"></div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="single-timeline-content d-flex">
                                            <div class="timeline-text">
                                                <p class="h6">{{timeline.modifiedOn | dateFormat}}</p>
                                                <p>{{timeline.modifiedOn | formatDateTime}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-2">
                                        <div class="single-timeline-content d-flex">
                                            <div class="timeline-text">
                                                <p class="h6">{{timeline.projectNumber}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-4">
                                        <div class="single-timeline-content d-flex">
                                            <div class="timeline-text">
                                                <p class="h6">{{translate(timeline.action, timeline.value)}}</p>
                                                <div
                                                    *ngIf="timeline.action === action.Title || timeline.action === action.Description || timeline.action === action.Note">
                                                    <pre>{{timeline.value}}</pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="single-timeline-content d-flex">
                                            <div class="timeline-text">
                                                <p class="h6">{{timeline.modifiedByName}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>