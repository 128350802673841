<div class="clearfix"></div>
<div class="alert alert-success alert-dismissible" *ngIf="msgSuccess">
  <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="onError()">
    ×
  </button>
  <span class="iconLabel" aria-label="sucess"></span>
  <i class="material-icons" aria-hidden="true">check_circle</i>
  <p>
    {{msgSuccess}}
  </p>
</div>
<div class="alert alert-dismissible alert-danger" *ngIf="selectForms">
  <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="selectForms=false">×</button>
  <i class="material-icons">warning</i>
  <p>Please assign forms by using "Select Forms" of {{msgTitle}}</p>
</div>
<div class="alert alert-dismissible alert-danger" *ngIf="dueDateMsg">
  <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="dueDateMsg=''">×</button>
  <i class="material-icons">warning</i>
  <p [innerHTML]="dueDateMsg"></p>
</div>
<div class="alert alert-dismissible alert-danger" *ngIf="isError">
  <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
  <i class="material-icons">warning</i>
  <p [innerHtml]="errorMsg"></p>
</div>
<div class="clearfix"></div>

<div class="col-md-12 box pt-1" id="formSet" *ngFor="let formSet of formSets; let i=index; let first = first">
  <a *ngIf="!first" href="javascript:void(0);" class="close pull-right mt-3" aria-label="close"
     (click)="closeFormset(i);">&times;</a>

  <div *ngIf="formSets[i].assignedFormsList.length > 0" style="overflow-y: scroll; max-height: 300px; overflow-x: hidden;">
    <div class="col-md-12 p-0 mt-3">
      <p class="mb-0" *ngIf="formSets[i].assignedFormsList.length > 0"><b>Note:</b> To edit the Form Name or Description, double-click the respective record.</p>
      <fdl-data-grid [rows]="formSets[i].assignedFormsList" [columns]="columns" emptyMessage="No Data Available"
                     columnMode="standard">
      </fdl-data-grid>
    </div>
  </div>

  <p class="mt-5"><b>Select Respondent Type:</b></p>
  <form [formGroup]="formSet.assignForm" autocomplete="off">
    <div class="radio-group pt-0 d-flex">
      <div class="radio mr-4">
        <input type="radio" id="userSelection_{{i}}" value="user" name="unauthenticatedType"
               formControlName="unauthenticatedType" (change)="settingUnauthenticatedSurveyType(i);">
        <label for="userSelection_{{i}}"><span>Registered User</span></label>
      </div>
      <div class="radio mr-4">
        <input type="radio" id="nonuserSelection_{{i}}" value="nonuser" name="unauthenticatedType"
               formControlName="unauthenticatedType" (change)="settingUnauthenticatedSurveyType(i);">
        <label for="nonuserSelection_{{i}}"><span>Guest</span></label>
      </div>
    </div>

    <div *ngIf="formSet.selectedUnauthenticatedType === 'nonuser'">

      <div [ngClass]="{'has-error': ( formSet.assignForm.get('guestResponder').errors?.duplicate) }">
        <app-mutipleadd #addResponder [isAddButtonEnable]="true" (formReady)="formInitialized('guestResponder',i, $event)" [filedJson]=" guestfieldJson" elementName="guestResponder" [filedDetails]="guestUserForm" [formControlData]="formSet.assignForm"></app-mutipleadd>
        <span *ngIf="formSet.assignForm.get('guestResponder').errors?.duplicate">
          <i class="material-icons">warning</i>Duplicate respondent not allowed.
        </span>

      </div>
      <div class="row">

        <div class="form-typeahead-wrapper" [ngClass]="{'has-error': ( formSet.assignForm.get('formApprover').errors?.duplicate) }">
          <app-mutipleadd #addApprover buttonText="Add Approver" [isAddButtonEnable]="true" (formReady)="formInitialized('formApprover',i ,$event)" [isSubmitted]="isSubmitted" [isFdl]="true" [fdlData]="formsUsersDetails.usersData" [filedJson]="filedformApproverJson" elementName="formApprover" [filedDetails]="filedformApproverDetails" [formControlData]="formSet.assignForm"></app-mutipleadd>

          <!--<div class="pr-2 action-link-single guest" [ngClass]="{'pt-3': !(formSet.approverEmail !=='')}" *ngIf="isCreateUserAllowed">
            <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Add New Approver" (click)="openAddDrawer(i, 'formApprover')"><i class="material-icons f-30">add_circle_outline</i></a>
          </div>-->
          <span *ngIf="formSet.assignForm.get('formApprover').errors?.duplicate">
            <i class="material-icons">warning</i>The Approver can not be duplicated at the same level.
          </span>
          <span *ngIf="formSet.assignForm.get('formApprover').errors?.invalid">
            <i class="material-icons">warning</i>The Level should be an integer number greater than or equal to 1.
          </span>
        </div>
        <div class="form-typeahead-wrapper p-0" *ngIf="formSet.formType!='intake'" style="left: 75px;top: -11px;">
          <label class="mt-2">Select Approver Criteria:<span _ngcontent-edj-c316="" data-toggle="tooltip" title="Select Approval Criteria if Multiple Approvers at same level." class="material-icons btn-secondary f-18"> info</span></label>


          <div>

            <div class="fdl-select">
              <select class="form-control" formControlName="approverSchema"
                      name="approverSchema">
                <option value="OR">ANY</option>
                <option value="AND">ALL</option>

              </select>
            </div>
          </div>


        </div>
      </div>
    </div>

    <div class="row mb-3" *ngIf="formSet.selectedUnauthenticatedType === 'user'">
      <div class="form-typeahead-wrapper"
           [ngClass]="{'has-error': ( formSet.assignForm.get('formResponder').errors?.duplicate) }">
        <app-mutipleadd #addResponder [isAddButtonEnable]="true"
                        (formReady)="formInitialized('formResponder',i ,$event)" [isSubmitted]="isSubmitted"
                        [isFdl]="true" [fdlData]="formsUsersDetails.usersData" [filedJson]="filedJson"
                        elementName="formResponder" [filedDetails]="filedDetails"
                        [formControlData]="formSet.assignForm"></app-mutipleadd>

        <span *ngIf="formSet.assignForm.get('formResponder').errors?.duplicate">
          <i class="material-icons">warning</i>Duplicate respondent not allowed.
        </span>
      </div>


      <div class="form-typeahead-wrapper p-0"
           [ngClass]="{'has-error': ( formSet.assignForm.get('formApprover').errors?.duplicate || formSet.assignForm.get('formApprover').errors?.duplicate2) }">
        <app-mutipleadd #addApprover buttonText="Add Approver"
                        [isAddButtonEnable]="true" (formReady)="formInitialized('formApprover',i ,$event)"
                        [isSubmitted]="isSubmitted" [isFdl]="true" [fdlData]="formsUsersDetails.usersData"
                        [filedJson]="filedformApproverJson" elementName="formApprover"
                        [filedDetails]="filedformApproverDetails"
                        [formControlData]="formSet.assignForm"></app-mutipleadd>
        <span *ngIf="formSet.assignForm.get('formApprover').errors?.duplicate">
          <i class="material-icons">warning</i>The Approver can not be duplicated at the same level.
        </span>
        <span *ngIf="formSet.assignForm.get('formApprover').errors?.duplicate2">
          <i class="material-icons">warning</i>Duplicate Approver
        </span>
        <span *ngIf="formSet.assignForm.get('formApprover').errors?.invalid">
          <i class="material-icons">warning</i>The Level should be an integer number greater than or equal
          to 1.
        </span>
      </div>

      <div class="form-typeahead-wrapper p-0 ml-3"
           style="left: 105px;top: -11px;">
        <label class="mt-2">
          Select Approver Criteria:<span _ngcontent-edj-c316="" data-toggle="tooltip"
                                         title="Select Approval Criteria if Multiple Approvers at same level."
                                         class="material-icons btn-secondary f-18"> info</span>
        </label>
        <div>
          <div class="fdl-select">
            <select class="form-control" formControlName="approverSchema" name="approverSchema">
              <option value="OR">ANY</option>
              <option value="AND">ALL</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6" [ngClass]="{'has-error': !formSet.assignForm.get('dueDate').valid && (formSet.assignForm.get('dueDate').touched)}">
        <label for="dueDate">Due Date:</label>
        <input type="date" class="form-control" [min]="todaysdate" formControlName="dueDate" id="dueDate">
      </div>
    </div>
  </form>
</div>
