<button *ngIf="!isExporting" type="button" class="close" (click)="closeModal()">
    <span aria-hidden="true">×</span>
</button>
<div *ngIf="contacts && contacts.length > 0" class="contact-body">
    <div *ngFor="let contact of contacts" class="contact-dropdown-item dropdown-item pb-3">
        <h5 clas="mb-1">{{contact.name ?? "Not Available"}}</h5>
        <p clas="mb-2" *ngIf="!isExporting">{{contact.role}}</p>
        <p *ngIf="isExporting">{{contact.role}}</p>
        
        <label class="form-label">Email:</label>
        <div *ngIf="contact && contact.email" class="email-title">
            <a href="mailto:{{contact.email}}" title="{{contact.email}}">
                {{contact.email}}
            </a>
        </div>
        <div *ngIf="!(contact && contact.email)" class="email-title">Not Available</div>
    </div>
</div>
<div class="contact-dropdown-item dropdown-item pb-4 pt-4" *ngIf="!contacts || contacts.length === 0">Not Available</div>