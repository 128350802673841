import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageTemplateResponseReviewTrailDTO } from './models/page-template-response-review-trail-d-t-o';
import moment from 'moment';
import { PageTemplateResponseReviewTrailService } from 'src/app/services/page-template-response-review-trail-.service';
import { PageTemplateResponseReviewTrailAction } from './enums/page-template-response-review-trail-action.enum';

@Component({
  selector: 'app-client-review-trail',
  templateUrl: './client-review-trail.component.html',
  styleUrls: ['./client-review-trail.component.css']
})
export class ClientReviewTrailComponent implements OnInit {

  public action = PageTemplateResponseReviewTrailAction;

  public data: Array<PageTemplateResponseReviewTrailDTO> = new Array<PageTemplateResponseReviewTrailDTO>();

  @Input()
  public set id(value: number) {
    if (value !== 0) {
      this.loadData(value);
    }
  }

  @Output()
  private modalClosed = new EventEmitter<void>();

  constructor(private pageTemplateResponseReviewTrailService: PageTemplateResponseReviewTrailService) { }

  ngOnInit(): void {
  }

  private loadData(pageTemplateResponseId: number): void {
    this.pageTemplateResponseReviewTrailService.getPageTemplateResponseReviewTrailDetail(pageTemplateResponseId)
      .subscribe(result => {
        this.data = result.body as Array<PageTemplateResponseReviewTrailDTO>;
        this.data.forEach(item => {
          item.modifiedOn = moment.utc(item.modifiedOn).local();
        });
      });
  }

  public resetData(): void {
    this.data = [];
    this.modalClosed.emit();
  }

  public getTimelineStatusCSSClass(status: PageTemplateResponseReviewTrailAction, value: string): string {
    let color = 'single-timeline-area grey';
    if (status === PageTemplateResponseReviewTrailAction.IsRisk && (value === 'true' || value === 'True')) {
      color = 'single-timeline-area red';
    }
    return color;
  }

  public translate(status: PageTemplateResponseReviewTrailAction, value: string): string {
    let result = '';
    switch (status) {
      case PageTemplateResponseReviewTrailAction.Description:
        result = 'Description';
        break;
      case PageTemplateResponseReviewTrailAction.IsRisk:
        if (value === 'true' || value === 'True') {
          result = 'Risk Added';
        } else {
          result = 'Risk Removed';
        }
        break;
      case PageTemplateResponseReviewTrailAction.IsTracked:
        if (value === 'true' || value === 'True') {
          result = 'Tracked';
        } else {
          result = 'Untracked';
        }
        break;
      case PageTemplateResponseReviewTrailAction.ManualCommitmentIsCreated:
        result = 'Manual Commitment created';
        break;
      case PageTemplateResponseReviewTrailAction.Title:
        result = 'Title';
        break;
      case PageTemplateResponseReviewTrailAction.Note:
        result = 'Note';
        break;
      case PageTemplateResponseReviewTrailAction.Deleted:
        result = 'Deleted';
        break;
      default:
        result = '';
        break;
    }
    return result;
  }
}
