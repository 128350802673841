import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { IForms, IForm, IFormSubTypes } from '../shared/form';
import { IClients } from '../shared/client';
import { IHistorySummary } from '../shared/history-summary';
import { InterceptorSkipHeader } from 'src/app/core/constants';
import { FileUploadHeader } from '../core/constants';
import { DateFormatPipe } from '../SharedModule/Pipes/date-format.pipe';
import { ProjectsService } from './projects.service';
import { MasterService } from './master.service';
import { CustomHttpParamEncoder } from "../shared/custom-encoder";
import { IResponseTimeline } from "../shared/timeline";
import { IQuery } from "../shared/query";

type HistoryData = {
  historyName: string;
  organizationId: number;
  respondentName: string;
  approverName: string;
  currentAssignee: string;
  responseStatusTitle: string;
  dueDate: string;
};

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  responses: any = [];
  survey: any = [];
  groupId: number;
  public triggerSave$ = new BehaviorSubject<string>('-1');
  private formAPIURL = 'forms'
  private formPublishCountURL = 'formpublish'
  private productAPIURL = 'products'
  private responseAPIURL = 'responses'
  private copyResponseAPIURL = 'copyResponses'
  private delegateAPIURL = 'delegate'
  private restPdfApi = 'export/sendextractionmessage'
  private templateAPIURL = 'template'
  private organizationAPIURL = 'organizations'
  private clientsAPIURL = 'clients'
  private respondentAPIURL = 'respondents'
  private projectAPIURL = 'projects'
  private usersAPIURL = 'users/respondents'
  private usersAllAPIURL = 'users/all'
  private updateResponderAPIURL = 'updateresponder'
  private updateApproverAPIURL = 'updateapprover';
  private sendbackToApprover = 'sendbacktoapprover';
  private changeApproverAPIURL = 'changeapprovers';
  private changeClientAPIURL = 'updateclient';
  private exportAPIURL = 'export';
  private surveyAPIURL = 'surveys';
  private emailGroupAPIURL = 'emailgroups/members';
  private resendAPIURL = 'resend';
  private updateVersionAPIURL = 'updateversion';
  private intakeRequests = 'responses/intake';
  private intakeListRequests = 'responses/intakeList';
  private unlinkResponseURL = 'unlink';
  private timelineResponseURL = 'Response/ResponseTimelineView';
  private getFormSubTypesURL = 'forms/formSubTypeSuggestions';

  formsUsersDetails: any;


  private _versionModifiedOn: string = "";
  private _version: string = "";
  private _historyData: HistoryData;
  constructor(private http: HttpClient, private _datePipe: DateFormatPipe,
    private _projectService: ProjectsService, private _masterService: MasterService) {

  }
  gettriggerSave(): Observable<string> {
    return this.triggerSave$.asObservable();
  }
  settriggerSavef(pdf: string) {
    this.triggerSave$.next(pdf);
  }
  getForms(): Observable<IForms> {
    return this.http.get<IForms>(this.formAPIURL);
  }

  getFormSubTypes(): Observable<IFormSubTypes> {
    return this.http.get<IFormSubTypes>(this.getFormSubTypesURL);
  }

  getFormsByproductId(productId: number, clientId: number): Observable<IForms> {
    clientId = clientId === null ? 0 : clientId;
    return this.http.get<IForms>(this.productAPIURL + '/' + productId + '/' + this.formAPIURL + '?' + 'clientId=' + clientId + '&' + 'IncludeDueDate=' + true);
  }

  getFormsByOrganizationId(orgId: number, clientId: number): Observable<IForms> {
    clientId = clientId === null ? 0 : clientId;
    return this.http.get<IForms>(`${this.organizationAPIURL}/${orgId}/${this.formAPIURL}?clientId=${clientId}&IncludeDueDate=${true}`);
  }
  getIntakeForm(projectId: number, orgId: number): Observable<IForms> {
    return this.http.get<IForms>(this.intakeRequests + '?projectId=' + projectId + '&orgID=' + orgId);
  }
  getIntakeList(queryParams) {
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });

   for (let key of Object.keys(queryParams)) {
    let value = queryParams[key];
    params = params.append(key, value);
    }

    let options = {
      'refresh': 'true'
    }

    return this.http.get(this.intakeListRequests, { headers: options, params: params, observe: 'response' });
  }
  getInTakeFormsByClientId(queryParams: IQuery, clientId: number = 0): Observable<HttpResponse<IForms>> {
    let params = new HttpParams();
    if(queryParams){
      for (const key of Object.keys(queryParams)) {
          let value = queryParams[key];
          params = params.append(key, value);
      }
    }

    let options = { 'refresh': 'true' }
    return this.http.get<IForms>(`${this.formAPIURL}?clientId=${clientId}&OnlyIntakeForms=${true}&IncludeDueDate=${true}`, { headers: options, params: params, observe: 'response' });
  }

  getUsersByOrganizationId(orgId: number): Observable<IClients> {
    return this.http.get<IClients>(this.organizationAPIURL + '/' + orgId + '/' + this.respondentAPIURL);
    //users/suggestions
  }

  getRespondentsUsersByProductId(orgId: number, productId: number): Observable<IClients> {
    var ignoreOrg = orgId == 0 ? true : false;
    return this.http.get<IClients>(this.organizationAPIURL + '/' + orgId + '/' + productId + '/' + this.respondentAPIURL + '?IgnoreOrg=' + ignoreOrg);
      
    //users/suggestions
  }

  getRespondentsUsersByClientId(clientId: number): Observable<IClients> {
    return this.http.get<IClients>(this.clientsAPIURL + '/' + clientId + '/respondents');
  }

  getUsersByOrganizationIds(orgIds: string): Observable<IClients> {
    return this.http.get<IClients>(this.organizationAPIURL + '/' + 0 + '/' + this.respondentAPIURL + '?' + 'orgIds=' + orgIds);
  }

  getFiservUserForSelectedOrg(orgId: number): Observable<IClients> {
    let params = new HttpParams();
    params = params.append("ignoreOrg", "false");

    if (orgId > 0)
      return this.http.get<IClients>(this.organizationAPIURL + '/' + orgId + '/' + this.respondentAPIURL, {params: params});
    else
      return this.http.get<IClients>(this.organizationAPIURL + '/' + orgId + '/' + this.respondentAPIURL);
  }

  getUsersForSelectedOrg(orgId: number): Observable<IClients> {
    let params = new HttpParams();
    params = params.append("ignoreOrg", "false");

    if (orgId > 0)
      return this.http.get<IClients>(this.organizationAPIURL + '/' + orgId + '/pageapprovers', {params: params});
    else
      return this.http.get<IClients>(this.organizationAPIURL + '/' + orgId + '/pageapprovers');
  }

  getUsersAll(queryParams = {}): Observable<IClients> {
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }

    return this.http.get<IClients>(this.usersAPIURL, {
      params: params
    });
    //users/suggestions
  }

  getAllFiservUsers() {
    return this.http.get<any>(this.usersAllAPIURL);
  }

  getCall(url) {

    return this.http.get<any>(url+ '&ignore='+Math.random() );
  }
  getPackagesAll(canIncludeNoClient: Boolean = false) {
   if(canIncludeNoClient){
    return this.http.get<any>(`${this.responseAPIURL}/packages?canIncludeNoClient=${canIncludeNoClient}`);
   }
    return this.http.get<any>(this.responseAPIURL+'/packages');
  }

  getFormJson(formId: number): Observable<IForm> {
    return this.http.get<IForm>(`${this.formAPIURL}\\${formId}`);
  }
  getResponseJson(formId: number): Observable<IForm> {
    return this.http.get<IForm>(`${this.responseAPIURL}\\${formId}`);
  }

  getMyAssigned(queryParams) {
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
   // return this.http.get<IForm>(`${this.responseAPIURL}\\my`);
   for (let key of Object.keys(queryParams)) {
    let value = queryParams[key];
    params = params.append(key, value);
    }
    return this.http.get(this.responseAPIURL + '\\my', { params: params, observe: 'response' });
  }

  refreshMyAssigned(queryParams) {
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
   // return this.http.get<IForm>(`${this.responseAPIURL}\\my`);
   for (let key of Object.keys(queryParams)) {
    let value = queryParams[key];
    params = params.append(key, value);
    }

    let options = {
      'refresh': 'true'
    }

    return this.http.get(this.responseAPIURL + '\\my', { headers: options, params: params, observe: 'response' });
  }

  connectedFormJson(type: string, formId: number, connectedformId: number, isSkip: boolean = false): Observable<IForm> {

    let options = {
      'Content-Type': 'application/json'
    }
    if (isSkip)
      options[InterceptorSkipHeader] = '';


    let httpOptions = {
      headers: new HttpHeaders(options)
    }


    if (type != 'Admin')
      return this.http.get<IForm>(`${this.responseAPIURL}\\${formId}\\${connectedformId}`, httpOptions);
    else
      return this.http.get<IForm>(`${this.formAPIURL}\\${connectedformId}`, httpOptions);
  }

  publishForms_user(formsParams, projectId) {
    return this.http.post<IForms>(`${this.projectAPIURL}\\${projectId}\\${this.responseAPIURL}`, formsParams);
  }

  publishCopyForms_user(formsParams, projectId) {
    return this.http.post<IForms>(`${this.projectAPIURL}\\${projectId}\\${this.copyResponseAPIURL}`, formsParams);
  }

  publishForms_nonuser_group(formParams) {
    return this.http.post<any>(`${this.surveyAPIURL}`, formParams);
  }

  publishForms_bulk(formData, projectId, clientId) {
    let httpOptions = {
      headers: new HttpHeaders({
        [FileUploadHeader]: ""
      })
    };

    return this.http.post<any>(`${this.projectAPIURL}\\${projectId}\\${clientId}\\${this.responseAPIURL}\\${this.templateAPIURL}`, formData, httpOptions);
  }

  updateFormPublishCount(ids: number[]) {
    return this.http.post(`${this.formPublishCountURL}/formpublish/instertpdatepublishcount/`, { ids });
  }

  deleteForm(formId: number): Observable<IForm> {
    return this.http.delete<IForm>(`${this.responseAPIURL}\\${formId}`);
  }

  submitAnswer(formsParams) {
    return this.http.put<any>(this.responseAPIURL + "/" + formsParams.id, formsParams)
  }
  submitConnectedFormAnswer(formsParams) {
    return this.http.put<any>(this.responseAPIURL + "/" + formsParams.responseId + "/" + formsParams.formId, formsParams)
  }
  updateResponder(projectId: number, formsData) {
    return this.http.put<any>(this.projectAPIURL + "/" + projectId + "/" + this.updateResponderAPIURL, formsData)
  }
  updateDelegate(projectId: number, formsData) {
    return this.http.put<any>(this.projectAPIURL + "/" + projectId + "/" + this.updateResponderAPIURL, formsData)
  }

  updateApprover(projectId: number, formsData) {
    return this.http.put<any>(this.projectAPIURL + "/" + projectId + "/" + this.updateApproverAPIURL, formsData)
  }
  updateResponseApprover(projectId: number, formsData) {
    return this.http.put<any>(this.responseAPIURL + "/" + projectId + "/" + this.updateApproverAPIURL, formsData)
  }
  sendBackToApprover(responseId: number, formsData) {
    return this.http.put<any>(this.responseAPIURL + "/" + responseId + "/" + this.sendbackToApprover, formsData)
  }
  changeResponseApprover(responseId: number, formsData) {
    return this.http.post<any>(this.responseAPIURL + "/" + responseId + "/" + this.changeApproverAPIURL, formsData)
  }
  changeclient(projectId: number, formsData) {
    return this.http.put<any>(this.projectAPIURL + "/" + projectId + "/" + this.changeClientAPIURL, formsData)
  }
  updateResponsedelegate(responseId: number, formsData) {
    return this.http.put<any>(this.responseAPIURL + "/" + responseId + "/" + this.delegateAPIURL, formsData)
  }
  updateSurveyResponsedelegate(surveyId: number, formsData) {
    return this.http.put<any>(this.surveyAPIURL + "/" + surveyId + "/" + this.delegateAPIURL, formsData)
  }
  approveResponse(params) {
    return this.http.put<any>(`${this.responseAPIURL}/${params.responseId}/approval`, params);
  }
  rejectedResponse(params, id) {
    return this.http.put<any>(`${this.responseAPIURL}/${id}/reject`, params);
  }

  updateResponse(params, id) {
    return this.http.put<any>(`${this.responseAPIURL}/${id}`, params);
  }

  updateResponseDetails(params, id) {
    return this.http.put<any>(`${this.responseAPIURL}/${id}/formdetails`, params);
  }

  holdResponse(params, id) {
    return this.http.put<any>(`${this.responseAPIURL}/${id}/${params.status}`, params);
  }
  exportToPdf(params) {
    return this.http.put<any>(`${this.exportAPIURL}/exportToPdf`, params,
      {
        observe: 'response', responseType: 'blob' as 'json'
      });
  }
  exportPdfWithoutSpinner(params) {
    let options = {
      'Content-Type': 'application/json'
    }
    options[InterceptorSkipHeader] = '';


    let httpOptions = {
      headers: new HttpHeaders(options)
    }
    return this.http.put<any>(`${this.restPdfApi}`, params, httpOptions);
  }
  formsUsersDetail(formsUsersDetails): any {
    this.formsUsersDetails = formsUsersDetails;
  }

  formsUsersData(): any {
    return this.formsUsersDetails;
  }

  getMemberDetails(emailGroupId: number): Observable<IForm> {
    return this.http.get<IForm>(`${this.emailGroupAPIURL}\\${emailGroupId}`);
  }

  updateUsersList(userList) {
    if (this.formsUsersDetails) {
      this.formsUsersDetails['users'] = userList;
    }
  }

  resendResponses(responseId: number, requestBody: any): Observable<any> {
    return this.http.put<IForm>(`${this.responseAPIURL}/${responseId}/${this.resendAPIURL}`, requestBody);
  }
  resendSurvey(surveyId: string, requestBody: any): Observable<any> {
    return this.http.put<IForm>(`${this.surveyAPIURL}/${surveyId}/${this.resendAPIURL}`, requestBody);
  }


  getResponsesHistory(responseId: number, archived: boolean = false): Observable<IHistorySummary[]> {
    return this.http.get<IHistorySummary[]>(this.responseAPIURL + "/id/versionsummary?id=" + responseId + "&archived=" + archived);
  }

  getResponsesVersionHistory(responseId: number, version: string): Observable<any> {
    return this.http.get<any>(this.responseAPIURL + "/id/versionhistory?id=" + responseId + "&version=" + version);
  }

  getResponsesTimeline(responseId: number, sorting: string): Observable<IResponseTimeline[]> {
    let options = { 'refresh': 'true' }
    return this.http.get<IResponseTimeline[]>(this.timelineResponseURL + "?id=" + responseId + "&sorting=" + sorting, { headers: options});
    // return this.http.get<IResponseTimeline[]>(this.timelineResponseURL + "?id=" + responseId + "&sorting=" + sorting+ "&ignore=" + Math.random());
  }

  updateResponsesHistoryVersion(responseId: number, version: string): Observable<any> {
    return this.http.put<any>(`${this.responseAPIURL}/${this.updateVersionAPIURL}/${responseId}`, null, { params: { version: version } });
  }

  getFormsHistory(formId: number): Observable<IHistorySummary[]> {
    return this.http.get<IHistorySummary[]>(this.formAPIURL + "/id/versionsummary?id=" + formId);
  }

  getFormsVersionHistory(formId: number, version: string, modifiedOn: string): Observable<any> {
    return this.http.get<any>(this.formAPIURL + "/id/versionhistory?id=" + formId + "&version=" + version + "&modifiedOn=" + modifiedOn);
  }

  updateFormsHistoryVersion(formId: number, version: string, modifiedOn: string): Observable<any> {
    return this.http.put<any>(`${this.formAPIURL}/${this.updateVersionAPIURL}/${formId}`, null, { params: { version: version, modifiedOn: modifiedOn } });
  }

  get versionModifiedOn() {
    return this._versionModifiedOn;
  }

  set versionModifiedOn(modifiedOn: string) {
    this._versionModifiedOn = modifiedOn;
  }

  get version() {
    return this._version;
  }

  set version(version: string) {
    this._version = version;
  }

  get historyData() {
    return this._historyData;
  }

  set historyData(data: HistoryData) {
    this._historyData = data;
  }

  postResponseComment(formsParams, formId: number) {
    let httpOptions = {
      headers: new HttpHeaders({
        [FileUploadHeader]: ""
      })
    };
    const fd = new FormData();
    for (let key of Object.keys(formsParams)) {
        let value = formsParams[key];
        fd.append(key, value);
    }

   return this.http.post<any>(`${this.responseAPIURL}\\${formId}\\comments`, fd, httpOptions);
  }

  deleteResponseComment(Id: number, formId: number) {
    return this.http.delete(`${this.responseAPIURL}/${formId}/comments/${Id}`);
  }

  updateResponseComment(formsParams, Id: number, formId: number) {
    let httpOptions = {
      headers: new HttpHeaders({
        [FileUploadHeader]: ""
      })
    };
    const fd = new FormData();
    for (let key of Object.keys(formsParams)) {
        let value = formsParams[key];
        fd.append(key, value);
    }

    return this.http.put<any>(`${this.responseAPIURL}/${formId}/comments/${Id}`, fd, httpOptions);
  }

  unlinkResponse(responseId: number) {
    return this.http.put<any>(`${this.responseAPIURL}\\${responseId}\\${this.unlinkResponseURL}`, null);
  }

  getAttachedFiles(formId: number){
    return this.http.get(`${this.responseAPIURL}/attachments/${formId}`, {observe: 'response', responseType: 'blob'});
  }
}
