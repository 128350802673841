import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';
import { PERMISSIONS, ADMIN_MODULES, allowedPermissions, PERMISSION_NEED_TO, ROLES } from '../core/constants';
import { PermissionsService } from '../services/permissions.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SelfServiceAccessGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private permissionService: PermissionsService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const module = next.data.module;
    let user = this.userService.getUserListData();
    if (!user) {
      return this.userService.callUserListData().pipe(map((data) => {
        user = data;
        this.userService.setUserListData(data);
        this.permissionService.setUserData(data);
        let usersPermission = [];
        data.permissions.forEach((ele) => {
          let permission: any = allowedPermissions[ele];
          for (let perm in permission) {
            if (permission[perm])
              usersPermission[perm] = permission[perm]
          }
        });

        this.userService.setPermissions(usersPermission);
        let allow = false;
        switch (module) {
          case ADMIN_MODULES.SelfServiceHome:
            return true;
          case ADMIN_MODULES.BulkUpload:
            return true;
          case ADMIN_MODULES.Forms:
            allow = user.isAdmin || user.permissions.includes(PERMISSIONS.Forms_ReadWrite_All);
            break;
          case ADMIN_MODULES.Help:
            allow = user.isAdmin
              || user.permissions.includes(PERMISSIONS.HelpTopics_Create_Only)
              || user.permissions.includes(PERMISSIONS.HelpTopics_ReadWrite_All);
            break;
          case ADMIN_MODULES.Report:
            const requiredPermisison = next.data.requiredPermisison;
            const isFiservDivAdmin = user.roles.some(x => x.roleTitle === ROLES.Fiserv_Division_Admin);
            const isFiservProtfolioManager = user.roles.some(x => x.roleTitle === ROLES.Fiserv_Portfolio_Manager);
            const isFiservProtfolioManagerReadOnly = user.roles.some(x => x.roleTitle === ROLES.Fiserv_Portfolio_Manager_Read_Only);
            if (requiredPermisison !== PERMISSION_NEED_TO.Create)
            {
              allow = user.isAdmin
                || isFiservDivAdmin
                || isFiservProtfolioManager
                || isFiservProtfolioManagerReadOnly
                || user.permissions.includes(PERMISSIONS.Forms_ReadWrite_All)
                || user.permissions.includes(PERMISSIONS.Forms_Read_All);
              break;
            }
            
            allow = user.isAdmin 
                || isFiservDivAdmin
                || user.permissions.includes(PERMISSIONS.Forms_ReadWrite_All);
            break;    
          case ADMIN_MODULES.Workflow:
            allow = user.isAdmin
            break;
          case ADMIN_MODULES.Extracts:
            allow = user.isAdmin
              || this.permissionService.showRequestExtractsLink()
            break;
          case ADMIN_MODULES.Feature:
            return true;
          case ADMIN_MODULES.CommitmentDelegate:
            const isCCTAdmin = user.roles.some(x => x.roleTitle === ROLES.CCT_Admin);
            allow = isCCTAdmin;
            break;
          default:
            allow = false;
        }
        if (allow) {
          return allow;
        }
        else {
          this.router.navigate(['']);
          return false;
        }
      }));
    }
    else {
      let allow = false;
      switch (module) {
        case ADMIN_MODULES.SelfServiceHome:
          return true;
        case ADMIN_MODULES.BulkUpload:
          return true;
        case ADMIN_MODULES.Forms:
          allow = user.isAdmin || user.permissions.includes(PERMISSIONS.Forms_ReadWrite_All);
          break;
        case ADMIN_MODULES.Help:
          allow = user.isAdmin || this.permissionService.showHelpCategoryLink() || this.permissionService.showHelpArticleLink()
          || user.permissions.includes(PERMISSIONS.HelpTopics_ReadWrite_All);
          break;
        case ADMIN_MODULES.Report:
          const requiredPermisison = next.data.requiredPermisison;
            const isFiservDivAdmin = user.roles.some(x => x.roleTitle === ROLES.Fiserv_Division_Admin);
            const isFiservProtfolioManager = user.roles.some(x => x.roleTitle === ROLES.Fiserv_Portfolio_Manager);
            const isFiservProtfolioManagerReadOnly = user.roles.some(x => x.roleTitle === ROLES.Fiserv_Portfolio_Manager_Read_Only);
            if (requiredPermisison !== PERMISSION_NEED_TO.Create)
            {
              allow = user.isAdmin
                || isFiservDivAdmin
                || isFiservProtfolioManager
                || isFiservProtfolioManagerReadOnly
                || user.permissions.includes(PERMISSIONS.Forms_ReadWrite_All)
                || user.permissions.includes(PERMISSIONS.Forms_Read_All);
              break;
            }
            
            allow = user.isAdmin 
                || isFiservDivAdmin
                || user.permissions.includes(PERMISSIONS.Forms_ReadWrite_All);
            break;
        case ADMIN_MODULES.Workflow:
          allow = user.isAdmin
          break;
        case ADMIN_MODULES.Extracts:
          allow = user.isAdmin
            || this.permissionService.showRequestExtractsLink()
          break;
        case ADMIN_MODULES.Feature:
          return true;

        case ADMIN_MODULES.CommitmentDelegate:
          const isCCTAdmin = user.roles.some(x => x.roleTitle === ROLES.CCT_Admin);
          allow = isCCTAdmin;
          break;
          
        default:
          allow = false;
      }
      if (allow) {
        return allow;
      }
      else {
        this.router.navigate(['']);
        return false;
      }
    }
  }
}
