import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IQuery } from 'src/app/shared/query';
import { IClients } from 'src/app/shared/client';
import { ClientsService } from 'src/app/services/clients.service';
import { ISuggestions } from 'src/app/shared/suggestion';
import * as $ from 'jquery';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';

@Component({
  selector: 'app-commitments-update-modal',
  templateUrl: './commitments-update-modal.component.html',
  styleUrls: ['./commitments-update-modal.component.css'],
})
export class CommitmentsUpdateModalComponent implements OnInit {
  queryParam: IQuery;
  clientList: IClients;
  errorMsg: any;  
  @Input() updatedCommitments: any[] = [];
  @Input() untrackedCommitments: any[] = [];
  @Output() private closeModal = new EventEmitter();
  @Output() updateCommitment = new EventEmitter<{ updatedCommitments: any[], untrackedCommitments: any[] }>();
  constructor(private clientService: ClientsService, private clientCommitmentsService: ClientCommitmentsService,) {}

  ngOnInit() {
    $('body').addClass('modal-open');
  }

  closeDrawer() {
    this.closeModal.emit();
    $('.container-page').removeClass('overflow-hidden');
    $('body').removeClass('modal-open');
  }

  submit() {

    if (this.updatedCommitments.length > 0) {
      for (var i = 0; i < this.updatedCommitments.length; i++) {
        if (this.updatedCommitments[i].isCustomClientCommitment) {
          this.createStrategicClientUsers(this.updatedCommitments[i]);
        }
      }
    }

    this.updateCommitment.emit({updatedCommitments: this.updatedCommitments, untrackedCommitments: this.untrackedCommitments});   

    $('.container-page').removeClass('overflow-hidden');
    $('body').removeClass('modal-open');
  }

  getStatusCSSClass(row: any) {
    if (row.statusA == 'update') {
      return 'alert-message success'
    };

    if (row.statusA == 'untrack') {
      return 'alert-message'
    };
  }

  createStrategicClientUsers(updatedCommitment: any) {
    var clientId = updatedCommitment.clientId;
    var CommitmentId = updatedCommitment.id;
    var ProjectId = updatedCommitment.customeFormFieldListJsonAnswer.clarityProjectNumberIntake;
    var request = [];
    if (updatedCommitment.clientCommitmentUsersList != undefined && updatedCommitment.clientCommitmentUsersList.length > 0 ) { 
      for (var i = 0; i < updatedCommitment.clientCommitmentUsersList.length; i++) {
      var tempreq = {
        ClientId: clientId,
        CommitmentId: CommitmentId,
        ProjectNumber: ProjectId,
        Userid: updatedCommitment.clientCommitmentUsersList[i].id,
      };
      request.push(tempreq);
      }
    }
    this.clientCommitmentsService.createStrategicCLientUsers(CommitmentId,request).subscribe(
        (response) => {
        },
        (error) => {
        }
      );

    console.log(request);
  }
}
