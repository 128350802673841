import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SelfServiceComponent } from './self-service.component';
import { SelfServiceAccessGuard } from './self-service-access.guard';
import { SelfServiceHomeComponent } from './components/home/home.component';
import { CreateWorkflowComponent } from './components/Workflow/create-workflow/create-workflow.component';
import { CreateWorkflowContextComponent } from './components/Workflow/create-workflow-context/create-workflow-context.component';
import { ViewWorkflowContextComponent } from './components/Workflow/view-workflow-context/view-workflow-context.component';
import { WorkflowComponent } from './components/Workflow/workflow/workflow.component';
import { WorkflowExecutionComponent } from './components/Workflow/workflow-execution/workflow-execution.component';
import { WorkFlowIntegrationsComponent } from './components/Workflow/workflow-execution-integrations/workflow-integrations.component';
import { FormExtractsComponent } from './components/form-extraction-template/form-extracts/form-extracts.component';
import { FormExtractionTemplateComponent } from './components/form-extraction-template/form-template/form-template.component';
import { FormTemplateViewComponent } from './components/form-extraction-template/form-view/form-template-view.component';
import { RequestExtractsComponent } from './components/request-extracts/request-extracts/request-extracts.component';
import { ExtractionRequestComponent } from './components/request-extracts/extraction-request/extraction-request.component';
import { MasterResponseStatusResolver } from '../services/master-response-status-reslover.service';
import { ReportViewComponent } from './components/manageReports/report-view/report-view.component';
import { ReportListComponent } from './components/manageReports/report-list/report-list.component';
import { ReportEditComponent } from './components/manageReports/report-edit/report-edit.component';
import { ReportCreateComponent } from './components/manageReports/report-create/report-create.component';
import { ADMIN_MODULES, PERMISSION_NEED_TO } from '../core/constants';
import { ReportResolver } from '../services/report-resolver.service';
import { EmailTemplateListComponent } from './components/manageEmailTemplates/email-template-list/email-template-list.component';
import { EmailTemplateCreateComponent } from './components/manageEmailTemplates/email-template-create/email-template-create.component';
import { EmailTemplateViewComponent } from './components/manageEmailTemplates/email-template-view/email-template-view.component';
import { HelpCategoryListComponent } from './components/manageHelpCategory/components/helpCategory-list/helpCategory-list.component';
import { HelpCategoryFormComponent } from './components/manageHelpCategory/components/helpCategory-form/helpCategory-form.component';
import { ViewHelpCategoryComponent } from './components/manageHelpCategory/components/view-helpCategory/view-helpCategory.component';
import { EditHelpCategoryComponent } from './components/manageHelpCategory/components/edit-helpCategory/edit-helpCategory.component';
import { BulkUploadComponent } from './components/bulk-upload/bulk-upload.component';
import { UserFilterResolver } from '../services/user.filter.resolver.service';
import { HelpArticleListComponent } from './components/manageHelpArticle/components/helpArticle-list/helpArticle-list.component';
import { ViewResponsesComponent } from './components/request-extracts/view-responses/view-responses.component';
import { CreateTopicComponent } from './components/manageHelpArticle/components/create-Article/create-topic.component';
import { ViewHelpArticleComponent } from './components/manageHelpArticle/components/view-helpArticle/view-helpArticle.component';
import { EditHelpArticleComponent } from './components/manageHelpArticle/components/edit-helpArticle/edit-helpArticle.component';
import { PageTemplateResponseComponent } from './components/page-template-response/page-template-response/page-template-response.component';
import { CreateTopicTemplateComponent } from './components/manageHelpArticle/components/create-topic-template/create-topic-template.component';
import { ViewPageTemplateComponent } from './components/page-template-response/view-page/view-page.component';
import { PageTemplateListComponent } from './components/page-template-list/page-template-list.component';
import { RefereshComponent } from './components/referesh/referesh.component';
import { MsalGuard } from '@azure/msal-angular';
import { CommitmentDashboardComponent } from './components/commitment-dashboard/commitment-dashboard.component';
import { CommitmentsIntakeComponent } from './components/commitments-intake/commitments-intake.component';
import { CommitmentDelegateListComponent } from './components/commitment-delegate-list/commitment-delegate-list.component';
import { ClientReviewTrailOverallComponent } from './components/client-review-trail-overall/client-review-trail-overall.component';
import { ManualCommitmentComponent } from './components/manual-commitment/manual-commitment.component';
import { ManualCommitmentListComponent } from './components/manual-commitment/manual-commitment-list/manual-commitment-list.component';
import { ManualCommitmentAccessGuard } from './manual-commitment-access.guard';

const routes: Routes = [
  {
    path: 'self-service',
    component: SelfServiceComponent,
    canActivate: [MsalGuard],

    children: [
      {
        path: '', redirectTo: 'home', pathMatch: 'full'
      },
      {
        path: 'home',
        component: SelfServiceHomeComponent,
        data: { title: 'Home', module: ADMIN_MODULES.SelfServiceHome },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'workflowContext/:id/view',
        component: ViewWorkflowContextComponent,
        data: { title: 'View Workflow Context', module: ADMIN_MODULES.Workflow },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'workflow/create',
        component: CreateWorkflowComponent,
        data: { title: 'Create Workflow', module: ADMIN_MODULES.Workflow },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'workflow',
        component: WorkflowComponent,
        data: { title: 'Workflow', module: ADMIN_MODULES.Workflow },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'workflow/:id/createWorkflowContext',
        component: CreateWorkflowContextComponent,
        data: { title: 'Create Workflow Context', module: ADMIN_MODULES.Workflow },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'workflowExecution/:id/view',
        component: WorkFlowIntegrationsComponent,
        data: { title: 'Workflow Execution Integrations', module: ADMIN_MODULES.Workflow },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'workflowExecution',
        component: WorkflowExecutionComponent,
        data: { title: 'Workflow Execution', module: ADMIN_MODULES.Workflow },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'formExtracts/formExtractionTemplate/:id',
        component: FormExtractionTemplateComponent,
        data: { title: 'Form Extraction Template', module: ADMIN_MODULES.Extracts },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'formExtracts/formExtractionTemplate',
        component: FormExtractionTemplateComponent,
        data: { title: 'Form Extraction Template', module: ADMIN_MODULES.Extracts },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'formExtracts/view/:id',
        component: FormTemplateViewComponent,
        data: { title: 'Form Template View', module: ADMIN_MODULES.Extracts },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'formExtracts',
        component: FormExtractsComponent,
        data: { title: 'Form Extracts', module: ADMIN_MODULES.Extracts },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'requestExtracts/viewResponses/:id',
        component: ViewResponsesComponent,
        data: { title: 'View Responses', module: ADMIN_MODULES.Extracts },
        resolve: { resolveData: MasterResponseStatusResolver },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'requestExtracts/extractionRequest/:id',
        component: ExtractionRequestComponent,
        data: { title: 'Extraction Request', module: ADMIN_MODULES.Extracts },
        resolve: { resolveData: MasterResponseStatusResolver },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'requestExtracts/extractionRequest',
        component: ExtractionRequestComponent,
        data: { title: 'Extraction Request', module: ADMIN_MODULES.Extracts },
        resolve: { resolveData: MasterResponseStatusResolver },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'requestExtracts',
        component: RequestExtractsComponent,
        data: { title: 'Request Extracts', module: ADMIN_MODULES.Extracts },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'report', redirectTo: 'report/list', pathMatch: 'full'
      },
      {
        path: 'report/list',
        component: ReportListComponent,
        data: { title: 'Report List', module: ADMIN_MODULES.Report },
        resolve: {
          resolveData: MasterResponseStatusResolver,
          userFilter: UserFilterResolver
        },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'report/create',
        component: ReportCreateComponent,
        data: { title: 'Add Report', module: ADMIN_MODULES.Report, requiredPermisison: PERMISSION_NEED_TO.Create },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'report/view/:id',
        component: ReportViewComponent,
        resolve: { resolvedData: ReportResolver },
        data: { title: 'View Report Configuration' },
      },
      {
        path: 'report/edit/:id',
        component: ReportEditComponent,
        resolve: { resolvedData: ReportResolver },
        data: { title: 'Edit Report Configuration' },
      },

      {
        path: 'emailTemplate', redirectTo: 'emailTemplate/list', pathMatch: 'full'
      },
      {
        path: 'emailTemplate/list',
        component: EmailTemplateListComponent,
        data: { title: 'Email Template List', module: ADMIN_MODULES.Forms },
        resolve: { userFilter: UserFilterResolver },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'emailTemplate/create',
        component: EmailTemplateCreateComponent,
        data: { title: 'Create Email Template', module: ADMIN_MODULES.Forms },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'emailTemplate/view/:id',
        component: EmailTemplateViewComponent,
        data: { title: 'View Email Template', module: ADMIN_MODULES.Forms },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'emailTemplate/edit',
        component: EmailTemplateCreateComponent,
        data: { title: 'Edit Email Template', module: ADMIN_MODULES.Forms },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'helpCategory', component: HelpCategoryListComponent,
        data: { title: 'Help Category List', module: ADMIN_MODULES.Help },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'helpCategory/create', component: HelpCategoryFormComponent,
        data: { title: 'Create Help Category', module: ADMIN_MODULES.Help },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'helpCategory/view/:id',
        component: ViewHelpCategoryComponent,
        data: { title: 'View Help Category', module: ADMIN_MODULES.Help },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'helpCategory/edit/:id',
        component: EditHelpCategoryComponent,
        data: { title: 'Edit Help Category', module: ADMIN_MODULES.Help },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'helpCategory/edit/:id',
        component: EditHelpCategoryComponent,
        data: { title: 'Edit Help Category' },
      },
      {
        path: 'fileUploads',
        loadChildren: () => import('./components/manageFileUploads/file-upload.module').then(m => m.FileUploadModule),
      },
      {
        path: 'bulkUpload/:type',
        component: BulkUploadComponent,
        data: { title: 'Bulk Upload', module: ADMIN_MODULES.BulkUpload },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'helpTopic', component: HelpArticleListComponent,
        data: { title: 'Resource Article List', module: ADMIN_MODULES.Help },
        resolve: {
          userFilter: UserFilterResolver
        },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: "helpTopic/create",
        component: CreateTopicComponent,
        data: { title: "Create Resource Article", module: ADMIN_MODULES.Help },
        canActivate: [SelfServiceAccessGuard]

      },
      {
        path: 'helpTopic/view/:id',
        component: ViewHelpArticleComponent,
        data: { title: 'View Resource Article', module: ADMIN_MODULES.Help },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: "helpTopic/edit/:id",
        component: EditHelpArticleComponent,
        data: { title: "Resource Article Edit", module: ADMIN_MODULES.Help },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: "helpTopic/template",
        component: CreateTopicTemplateComponent,
        data: { title: "Resource Article Edit", module: ADMIN_MODULES.Help },
        canActivate: [SelfServiceAccessGuard]

      },
      {
        path: "helpTopic/templateedit/:id",
        component: CreateTopicTemplateComponent,
        data: { title: "Resource Article Edit", module: ADMIN_MODULES.Help },
        canActivate: [SelfServiceAccessGuard]

      },
      {
        path: "helpTopic/edit/:id/:flowname",
        component: CreateTopicComponent,
        data: { title: "Resource Article Edit", module: ADMIN_MODULES.Help },
        canActivate: [SelfServiceAccessGuard]
      },

      // Page Template Response Routing
      {
        path: 'pageTemplate/:id/response/:id',
        component: ViewPageTemplateComponent,
        data: { title: 'View Page Response', module: ADMIN_MODULES.SelfServiceHome },
        resolve: {
          resolveData: MasterResponseStatusResolver
        },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'pageTemplate/:id',
        component: ViewPageTemplateComponent,
        data: { title: 'View Page Response', module: ADMIN_MODULES.SelfServiceHome },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'pageTemplate/:id/responses',
        component: PageTemplateResponseComponent,
        data: { title: 'Page Template Responses', module: ADMIN_MODULES.SelfServiceHome },
        resolve: {
          userFilter: UserFilterResolver
        },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: ':pageTemplate/:id/referesh',
        component: RefereshComponent,
        data: { title: 'Page Template Responses', module: ADMIN_MODULES.SelfServiceHome },
        resolve: {
          userFilter: UserFilterResolver
        },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'pageTemplates',
        component: PageTemplateListComponent,
        data: { title: 'Page Templates', module: ADMIN_MODULES.SelfServiceHome },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'commitments-intake',
        component: CommitmentsIntakeComponent,
        data: { title: 'Commitments Intake', module: ADMIN_MODULES.SelfServiceHome },
        canActivate: [SelfServiceAccessGuard]
      },
      {
        path: 'commitments-delegate',
        component: CommitmentDelegateListComponent,
        data: { title: 'Commitments Delegate', module: ADMIN_MODULES.CommitmentDelegate },
        canActivate: [SelfServiceAccessGuard]
      }
    ]
  },
  {
    path: 'commitmentdashboard',
    component: CommitmentDashboardComponent,
    data: { title: 'Client Dashboard' },
    canActivate: [MsalGuard],
  },
  {
    path: 'ClientReviewTrailOverall',
    component: ClientReviewTrailOverallComponent,
    data: { title: 'Client Review Trail Overall' },
    canActivate: [MsalGuard],
  },
  {
    path: 'ClientReviewTrailOverall/:clientId/:clientName',
    component: ClientReviewTrailOverallComponent,
    data: { title: 'Client Review Trail Overall' },
    canActivate: [MsalGuard],
  },
  {
    path: 'manual-commitment',
    component: ManualCommitmentComponent,
    data: { title: 'Manual Commitment' },
    canActivate: [MsalGuard, ManualCommitmentAccessGuard],
  },
  {
    path: 'manual-commitment/edit/:id',
    component: ManualCommitmentComponent,
    data: { title: 'Manual Commitment' },
    canActivate: [MsalGuard, ManualCommitmentAccessGuard],
  },
  {
    path: 'manual-commitment/view/:id',
    component: ManualCommitmentComponent,
    data: { title: 'Manual Commitment' },
    canActivate: [MsalGuard],
  },
  {
    path: 'manual-commitment/list',
    component: ManualCommitmentListComponent,
    data: { title: 'Manual Commitment List' },
    canActivate: [MsalGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SelfServiceRoutingModule {
}
