import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'; 
import { CommitmentNoteInput } from '../self-service/components/commitment-dashboard/models/commitment-note-input';
@Injectable({
  providedIn: 'root'
})
export class CommitmentNoteService {
  private _commitmentNoteBaseUrl: string = "commitmentnote";

  constructor(private http: HttpClient) { }

  public createOrUpdateCommitmentNote(commitmentNoteInput: CommitmentNoteInput) {
    const options = { 'refresh': 'true' };

    return this.http.post(`${this._commitmentNoteBaseUrl}`, commitmentNoteInput, { headers: options, observe: 'response' });
  }

  public getCommitmentNote(pageTemplateResponseId: number) {
    const options = { 'refresh': 'true' };
    return this.http.get(`${this._commitmentNoteBaseUrl}/${pageTemplateResponseId}`, { headers: options, observe: 'response' });
  }
}