import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { take } from 'rxjs/internal/operators/take';
import { IPager } from 'src/app/shared/pagination';
import { IQuery } from 'src/app/shared/query';
import { environment } from 'src/environments/environment';
import { MasterService } from 'src/app/services/master.service';
import { IPageTemplate, IPageTemplateResponse, IPageTemplateResponseList } from 'src/app/shared/page-template';
import { PageTemplateService } from 'src/app/services/page-template.service';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ProjectsService } from 'src/app/services/projects.service';
import { ISuggestions } from 'src/app/shared/suggestion';
import { REGEX_PATTERNS, MAX_LENGTHS } from 'src/app/core/constants';
import { UserService } from 'src/app/services/user.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { PreferenceService } from 'src/app/services/preference.service';
import { forkJoin } from 'rxjs';
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';
import { FormsService } from 'src/app/services/forms.service';
import { ManageFormsService } from 'src/app/admin/manageForms/services/manageForms.service';
import { ICreateForm } from 'src/app/admin/manageForms/models/createForm';
import { UserFilterService } from 'src/app/services/user.filter.service';
import { ClientsService } from 'src/app/services/clients.service';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
declare var $: any;

@Component({
  selector: 'page-template-response',
  templateUrl: './page-template-response.component.html'
})
export class PageTemplateResponseComponent implements OnInit, OnDestroy {
  pageTemplateName: IPageTemplate;
  pageTemplateNameTitle: string;
  pageTemplateNameDescription: string;
  initiatorDrawer: boolean = false;
  successMsg: any;
  pageTemplateId: number;
  formSets: any = [];
  queryParam: IQuery;
  currentPageNumber = 1;
  pagerObject: IPager;
  listname: any = '';
  errorMsg: string = "";
  clientText: string;
  sortingObject;
  displayColumns: any; // = ['ID', 'Page Name', 'Description', 'Due Date', 'Status', 'Submitted By','Submitted On'];
  keys: any; // = ['id', 'title', 'description', 'dueDate', 'pageTemplateResponseStatusTitle', 'submittedByName','createdOn'];
  pageResponseList: IPageTemplateResponseList;
  pageResponse: IPageTemplateResponse;
  filterForm: FormGroup;
  submittedBySuggestion: ISuggestions;
  formFieldSuggestion: any;
  returnPage: string;
  selectedFormFieldApiKey: string;
  selectedFormFieldLabel: string;
  searchByFormFieldJson: any;
  listFormFieldJson: any;
  pageResponses: any;
  userData: any;
  canRequest: boolean = false;
  pageTemplateAdmin: boolean = false;
  isClientExecutive = false;
  pageTemplateReadAdmin: boolean = false;
  canChangeApprover: boolean = false;
  isDelete: boolean = false;
  isAdmin: boolean = false;
  private isCCTAdmin = false;
  private isRm = false;
  private isRestrictedAdmin = false;
  pageTemplateResponseId: number = 0;
  /** start of column settings changes**/
  columnPreferences: any;
  prefColumnOptions: any;
  listName: any;
  preferenceId: any;
  userId: any;
  refreshGrid: boolean = false;
  maxColumnCount: any;
  showAddColumnOptions: boolean = false;
  isError: boolean = false;
  showColumnSettingsDrawer: boolean = false;
  /** end of column settings changes*/

  /** Assign Approver Code Changes**/
  approverFormGroup: FormGroup;
  isDynamicApprover: boolean = false;
  allowClientUser: boolean = false;
  isEntityLink: boolean = false;
  initiateApproverDrawer: boolean = false;
  usersData: any;
  filedJson: any;
  filedDetails: any;
  clientSuggestion: any = {};
  organizationSuggestion: any = {};
  productSuggestion: any = {};
  selectedClientId: number = 0;
  selectedClientName: string;
  selectedOrganizationId: number = 0;
  selectedOrganizationName: string;
  selectedProductId: number = 0;
  selectedProductName: string;
  selectedRMName: string;
  selectedRMEmail: string;

  missingOrganizationName: boolean = false;
  invalidOrganizationName: boolean = false;
  invalidProductName: boolean = false;
  invalidClientName: boolean = false;
  organizationDetails: any;
  userGroupOrgIds: string = "";
  /** Assign Approver Code Changes**/

  /** Delegate Respondent Changes**/
  delegateForm: FormGroup;
  isDelegateError: boolean = false;
  delegateErrorMsg: string;
  _maxLengths: any;
  regexValidationMessage: any;
  respondentSuggestion: any;
  isDelegateSubmitted: boolean = false;
  invalidRespondent: boolean = true;
  selectedRespondentId: number = 0;
  selectedRespondentName: string;
  delegateResponseTitle: string;
  respondentEmail: string;
  /** Delegate Respondent Changes**/
  ignoreSortingColumns = ['description', 'formsCount', 'fileSize', 'count', 'formType', 'level', 'entityName', 'status', 'entityLevel', 'currentApproverName', 'editAccessWith', 'pageTemplateResponseStatusTitle', 'submittedByName'];
  filterExists: boolean = false;
  respondentUserList: any;

  /** Update Approver Changes **/
  responseData: any = {};
  updateApproverMsg = '';
  clntSuggestion: any = {};
  orgSuggestion: any = {};
  prodSuggestion: any = {};
  lkpProdSuggestion: any = {};
  searchOrgId: number = 0;
  /** Update Approver Changes **/
  isMultipleSubmitAllowed: boolean = false;
  isLinkingAllowed: boolean = false;
  submissionFromUI: boolean = false;
  isSearchAllowed: boolean = false;
  isUpdateUIListPage: boolean = false;
  isSubmitUIListPage: boolean = false;
  isBulkUploadAllowed: boolean = false;

  //ADD OR REMOVE To ARRAY
  baseSelectedForms: any = [];
  addedPageTemplateTolink: any = []
  removedPageTemplateTolink: any = []
  exitedPageTemplateTolink: any = []


  isDeleteAllowed: boolean = false;
  public isSelectMultipleResponseAllowed: boolean = false;
  filtered: boolean = false;
  columnSettingErrorMsg: string = '';
  columns = [{ prop: 'title', name: 'Form Name', inlineEditing: { 'type': 'default', 'pattern': REGEX_PATTERNS.form_response_survey_name_validator }, width: 250 },
  { prop: 'id', name: 'ID', inlineEditing: { 'type': 'default', 'pattern': REGEX_PATTERNS.name_title_validator }, width: 250 },
  ]
  removedArray: any = [];
  customFormFieldListJson: any;
  linkedToggle: boolean = false;

  selectedClientValue: string = '';
  selectedClientDunsId: string = '';
  selectedClientGudId: string = '';
  isMultipleResponseSubmit: boolean = false;
  legendListJson: any;
  isSubmitted = false;
  invalidClientDuns: boolean = false;
  private _needRefetch: boolean = false;
  // clarityFeedDictUrl: string = 'https://fiservcorp.sharepoint.com/:b:/s/fgsbsg/EVFlHknajpdKjxGkBogNFukB-F6tlSeZL-SUyim04DS6hA?e=Z8nWsW';
  // commitmentIntakeDictUrl: string = 'https://fiservcorp.sharepoint.com/:b:/r/sites/fgsbsg/Shared%20Documents/Client%20Commitment/CCT%20Documentation/Release%20and%20Training%20Documentation/Commitment%20Intake%20-%20Data%20Dictionary.pdf?csf=1&web=1&e=4CP8y1';

  clarityFeedDictUrl: string = 'https://fiservcorp.sharepoint.com/:b:/s/fgsbsg/EbkgMEmxEuFGiuRyAdeWQrIBRL8Ugsy5I6FdQOqWPEkDmQ?e=wEsMvN';
  commitmentIntakeDictUrl: string = 'https://fiservcorp.sharepoint.com/:b:/s/fgsbsg/EfnqfNE5vb1JlKOQTS2ka1sB5C-pzH_tkIYKybAw5SXeUA?e=qQZEjV';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private pageTemplateService: PageTemplateService,
    private clientCommitmentService: ClientCommitmentsService,
    private userService: UserService,
    private masterService: MasterService,
    private projectService: ProjectsService,
    private permission: PermissionsService,
    private preferenceService: PreferenceService,
    private excelService: ExportToExcelService,
    private manageForm: ManageFormsService,
    private userFilterService: UserFilterService,
    /** Assign Approver Code Changes**/
    private fb: FormBuilder,
    private formService: FormsService,
    private clientsService: ClientsService
    /** Assign Approver Code Changes**/
  ) {
    this.route.params
      .subscribe((params) => {
        if (this._needRefetch) {
          this.ngOnInit();
        }
      });
  }

  ngOnDestroy(): void {
    this.initiateApproverDrawer = false;
    this.selectedClientValue = '';
    this.setSuccessMsg();
  }

  ngOnInit(): void {
    this._needRefetch = true;
    /** start of column settings changes**/
    this.maxColumnCount = PreferenceService.maxColumnCount;
    /** end of column settings changes**/
    this.userData = this.userService.getUserListData();
    this.isAdmin = this.userData.isAdmin;
    this.userData.roles.forEach(item => {
      const role = item.roleTitle.toLowerCase();
      switch (role) {
        case 'cct admin':
          this.isCCTAdmin = true;
          break;
        case 'relationship manager':
          this.isRm = true;
          break;
        case 'restricted admin':
          this.isRestrictedAdmin = true;
          break;
        default:
          break;
      }
    });
    this.organizationDetails = Object.assign({}, this.userService.getUserListData());
    this.selectedFormFieldApiKey = "";
    this.searchByFormFieldJson = "";
    this.listFormFieldJson = "";
    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.pageTemplateId = params['id'];
      });
    // this.pageTemplateId = this.route.snapshot.params["id"];
    this.successMsg = this.pageTemplateService.isResponseCreated;
    this.listname = PreferenceService.pageTemplateResponseList;
    if (this.pageTemplateService.isPrePopulateRequest === true)
      this.successMsg = "Pre-populate request(s) has been uploaded successfully. Request(s) would be available once processed by System.";
    this.getPageTemplateData();
    this.initQueryParams();
    this.initFilterForm();
    this.getGroupOrgIds();
    this.getEntitySuggestions();
    this.initiateDelegateForm();
    this.formSets[0] = {
      'assignedFormsList': []
    }
    if (this.pageTemplateService.sideNav)
      this.returnPage = "Request Template(s) List";
    else
      this.returnPage = "Self Service";
  }

  getGroupOrgIds() {
    if (this.userData.isRestrictedAdmin) {
      this.userGroupOrgIds = "";
      let groupOrgIds: number[] = [];
      let grpIdObj: any;
      groupOrgIds = this.userData.groups.map(item => item.organizations.map(item => item.organizationId));
      if (groupOrgIds.length > 0) {
        for (let i = 0; i < groupOrgIds.length; i++) {
          grpIdObj = groupOrgIds[i];
          if (grpIdObj.length > 0) {
            for (let j = 0; j < grpIdObj.length; j++) {
              if (!(this.userGroupOrgIds.includes(grpIdObj[j] + ",")))
                this.userGroupOrgIds = this.userGroupOrgIds + grpIdObj[j] + ",";
            }
          }
        }
        if (this.userGroupOrgIds !== "")
          this.userGroupOrgIds = this.userGroupOrgIds.slice(0, -1);
      }
    }
  }

  setSuccessMsg() {
    this.pageTemplateService.isResponseCreated = "";
    if (this.pageTemplateService.isPrePopulateRequest === true)
      this.pageTemplateService.isPrePopulateRequest = false;
  }

  refresh() {
    this.callRefreshResponseListService();
  }

  getPageTemplateData() {
    this.clientCommitmentService.getPageTemplateById(this.pageTemplateId)
      .pipe(take(1))
      .subscribe(
        page => {
          this.displayColumns = [];
          this.keys = [];
          this.pageTemplateName = page.body as IPageTemplate;
          this.pageTemplateName.parentPageTemplateList = this.pageTemplateName.parentPageTemplateList ?? [];
          this.isDynamicApprover = this.pageTemplateName.isDynamicApprover;
          this.allowClientUser = this.pageTemplateName.allowClientUser;
          this.isEntityLink = this.pageTemplateName.isEntityLink;
          this.isMultipleSubmitAllowed = this.pageTemplateName.isUpdateUIListPage || this.pageTemplateName.isSubmitUIListPage;
          this.isLinkingAllowed = this.pageTemplateName.isLinkingAllowed;
          this.submissionFromUI = this.pageTemplateName.submissionFromUI && (this.userData.type == "Fiserv" && !this.userData.roles.some(r => r.roleId > 8));
          this.isDeleteAllowed = this.pageTemplateName.isDeleteAllowed &&
            (this.isAdmin || this.isCCTAdmin || this.isRm || this.isRestrictedAdmin);
          this.isSelectMultipleResponseAllowed = this.pageTemplateName.isSelectMultipleResponseAllowed;
          this.isSearchAllowed = this.pageTemplateName.isSearchAllowed;
          this.isUpdateUIListPage = this.pageTemplateName.isUpdateUIListPage;
          this.isSubmitUIListPage = this.pageTemplateName.isSubmitUIListPage;
          this.isBulkUploadAllowed = this.pageTemplateName.isBulkUploadAllowed;
          if (this.pageTemplateName.legendDataJson !== undefined && this.pageTemplateName.legendDataJson !== null && this.pageTemplateName.legendDataJson !== "") {
            this.legendListJson = JSON.parse(this.pageTemplateName.legendDataJson);
          }
          else {
            this.legendListJson = undefined;
          }

          if (this.pageTemplateName.formFieldListJson !== undefined && this.pageTemplateName.formFieldListJson !== null && this.pageTemplateName.formFieldListJson !== "") {
            this.customFormFieldListJson = JSON.parse(this.pageTemplateName.formFieldListJson);
          }
          else {
            this.customFormFieldListJson = undefined;
          }
          
          this.canRequest = this.pageTemplateName.pageTemplateAdmins?.some(a => a.userId === this.userData.id) || this.permission.userData.isAdmin || (this.userData.isRestrictedAdmin && this.userData.roles.some(r => r.roleId === 10));
          this.pageTemplateAdmin = this.pageTemplateName.pageTemplateAdmins?.some(a => a.userId === this.userData.id);
          this.isClientExecutive = this.userData.roles.some((r) => r.roleTitle.toLowerCase() === 'client executive');
          this.pageTemplateReadAdmin = this.pageTemplateName.pageTemplateReadAdmins?.some(a => a.userId === this.userData.id);
          this.canChangeApprover = this.pageTemplateName.pageTemplateAdmins?.some(a => a.userId === this.userData.id) || this.permission.userData.isAdmin;
          this.isDelete = this.pageTemplateName.pageTemplateAdmins?.some(a => a.userId === this.userData.id) || this.permission.userData.isAdmin || (this.userData.isRestrictedAdmin && this.userData.roles.some(r => r.roleId === 10));
          this.pageTemplateService.isEntity = this.pageTemplateName.isEntityLink;
          if (!this.canRequest) {
            this.selectedOrganizationId = this.organizationDetails?.organization;
          }

          if (this.isSearchAllowed) this.getUserSuggestions();
          this.callPreferenceGetService();
        },
        (error) => { this.errorMsg = error; });
  }

  initDataTableColumns(preferences: any) {
    this.pageTemplateNameTitle = this.pageTemplateName.title;
    this.pageTemplateNameDescription = this.pageTemplateName.description;
    this.displayColumns = [];
    this.keys = [];

    for (let i = 0; i < preferences.keys.length; i++) {
      if (preferences.keys[i] === 'id') {
        this.keys.push('id');
        this.displayColumns.push(preferences.displayColumns[i]);
      }

      if (preferences.keys[i] === 'title') {
        this.keys.push('title');
        this.displayColumns.push(preferences.displayColumns[i]);
      }
    }

    if (this.pageTemplateName.searchByFormFieldJson === undefined || this.pageTemplateName.searchByFormFieldJson === null || this.pageTemplateName.searchByFormFieldJson === "")
      this.searchByFormFieldJson = JSON.parse('[]');
    else
      this.searchByFormFieldJson = JSON.parse(this.pageTemplateName.searchByFormFieldJson);

    if (this.pageTemplateName.formFieldListJson !== undefined && this.pageTemplateName.formFieldListJson !== null && this.pageTemplateName.formFieldListJson !== "") {
      this.listFormFieldJson = JSON.parse(this.pageTemplateName.formFieldListJson);
      if (this.listFormFieldJson.length > 0) {
        for (let item of this.listFormFieldJson) {
          // this.displayColumns.push(item['formfieldlabel']);
          // this.keys.push(item['formfieldapikey']);
          this.ignoreSortingColumns.push(item['formfieldapikey']);
        }
      }
    }
    else {
      this.listFormFieldJson = JSON.parse('[]');
    }

    for (let i = 0; i < preferences.keys.length; i++) {
      if (preferences.keys[i] !== 'id' && preferences.keys[i] !== 'title') {
        this.keys.push(preferences.keys[i]);
        this.displayColumns.push(preferences.displayColumns[i]);
      }
    }
    this.formFieldSuggestions();
    /* PBI-233834 - Commented below code for changes
      if(this.filterExists && this.searchByFormFieldJson.length > 0 ) {
        this.selectedFormFieldApiKey = this.filterForm.get('formFieldLabel').value;
        for (let item of this.searchByFormFieldJson) {
          if (item['formfieldapikey'] === this.selectedFormFieldApiKey)
            this.selectedFormFieldLabel = item['formfieldlabel'];
        }
      }
    */
  }

  formFieldSuggestions() {
    // PBI-233834 - Form Field Label multi-select Code Starts
    if (this.searchByFormFieldJson.length > 0) {
      for (let item of this.searchByFormFieldJson) {
        this.filterForm.addControl(item['formfieldapikey'], new FormControl(''));
      }

      this.pageTemplateService.getFormFieldSuggestionByUser(this.pageTemplateId).subscribe(data => {
        let sugg: any = data;
        for (let s = 0; s < sugg.length; s++) {
          for (let f = 0; f < this.searchByFormFieldJson.length; f++) {
            if (sugg[s]['apiKey'] === this.searchByFormFieldJson[f]['formfieldapikey'])
              this.searchByFormFieldJson[f]['formFieldSuggestion'] = sugg[s].apiKeyValues;
          }
        }
      }
        , error => { this.isError = true; this.errorMsg = "Form Field suggestion list can't be loaded. " + error; });
    }
    // PBI-233834 - Form Field Label multi-select Code Ends

    this.filterExists = this.userFilterService.loadAndApplyFilters(this.route, this.filterForm, this.queryParam);
    this.linkedToggle = this.filterForm.controls['isLinked'].value;
  }

  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      id: new FormControl(''),
      submittedBy: new FormControl('', [Validators.pattern(REGEX_PATTERNS.user_name_validator)]),
      formFieldLabel: new FormControl(''),
      formFieldValue: new FormControl(''),
      orgName: new FormControl('', [Validators.pattern(REGEX_PATTERNS.organization_name_validator)]),
      prodName: new FormControl(''),
      clntName: new FormControl('', [Validators.pattern(REGEX_PATTERNS.client_name_validator)])
      , isLinked: new FormControl(this.linkedToggle)
    });
  }

  getUserSuggestions() {
    this.projectService.getSuggestions("users", "").subscribe(
      data => this.submittedBySuggestion = data,
      (error) => { this.errorMsg = error; }
    );
  }

  filterList() {
    this.isSubmitted = true
    if (this.filterForm.valid) {
      this.userFilterService.saveFilter(this.route, this.filterForm);
      this.initQueryParams();

      this.clearSelectedResponseList();
      this.pageResponses = [];

      let filterValues = this.filterForm.value;

      if (filterValues['submittedBy'])
        this.queryParam.submittedBy = filterValues['submittedBy'];
      if (filterValues['id'])
        this.queryParam.id = filterValues['id'];
      if (filterValues['formFieldLabel'])
        this.queryParam.formFieldApiKey = filterValues['formFieldLabel'];
      if (filterValues['formFieldValue'])
        this.queryParam.formFieldValue = filterValues['formFieldValue'];
      if (filterValues['orgName'])
        this.queryParam.organizationTitle = filterValues['orgName'];
      if (filterValues['prodName'])
        this.queryParam.productTitle = filterValues['prodName'];
      if (filterValues['clntName'])
        this.queryParam.clientTitle = filterValues['clntName'];
      this.queryParam.isLinked = this.linkedToggle;

      // PBI-233834 - Form Field Label multi-select Code starts
      if (this.searchByFormFieldJson.length > 0) {
        let formFieldJson: any = [];
        for (let f = 0; f < this.searchByFormFieldJson.length; f++) {
          if (filterValues[this.searchByFormFieldJson[f]['formfieldapikey']]) {
            let formField: any = {
              ApiKey: this.searchByFormFieldJson[f]['formfieldapikey'],
              ApiKeyValue: filterValues[this.searchByFormFieldJson[f]['formfieldapikey']]
            }
            formFieldJson.push(formField);
          }
        }

        if (formFieldJson.length > 0) { this.queryParam.formFields = JSON.stringify(formFieldJson); this.filtered = true; }
      }
      // PBI-233834 - Form Field Label multi-select Code ends

      this.setOrderBy(this.sortingObject);
      this.callResponseListService();

      if (filterValues['orgName'] === "") {
        this.searchOrgId = 0;
        this.getProductSuggestions();
      }

      if (filterValues['submittedBy'] || filterValues['id'] || filterValues['formFieldLabel'] || filterValues['formFieldValue'] || filterValues['orgName'] || filterValues['prodName'] || filterValues['clntName'])
        this.filtered = true;
    }
  }

  clearFilterList() {
    this.filtered = false;
    this.filterForm.reset();
    this.initQueryParams();
    this.queryParam.isLinked = this.linkedToggle;
    // this.initFilterForm();
    this.userFilterService.clearFilter();
    this.callResponseListService();
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.callResponseListService();
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.callResponseListService();
  }

  setOrderBy(sortingData) {
    if (sortingData) {
      this.queryParam.orderBy = (sortingData.isAsc) ? sortingData.key : sortingData.key + " desc";
    }
  }

  navigate(event) {
    this.pageTemplateResponseId = event.data;
    let response: IPageTemplateResponse = this.pageResponseList.responses.filter(
      item => item.id == this.pageTemplateResponseId
    )[0];
    switch (event.action) {
      case "view":
        this.pageResponse = response;
        let responseDetails: any = {
          clientId: this.pageResponse.clientId,
          clientTitle: this.pageResponse.clientTitle,
          organizationId: this.pageResponse.organizationId,
          organizationTitle: this.pageResponse.organizationTitle,
          productId: this.pageResponse.productId,
          productTitle: this.pageResponse.productTitle
        }
        this.pageTemplateService.formVal = responseDetails;
        this.showApproverDrawer();
        break;
      case "delegate":
        this.delegateResponseTitle = response.title;
        this.getRespondents();
        break;
      case "changeApprover":
        this.clientCommitmentService.getPageTemplateResponseById(event.data).subscribe(
          response => {
            let data = response as IPageTemplateResponse;
            this.updateApproverMsg = '';
            this.responseData = {
              id: data.id,
              title: data.title,
              organizationId: this.organizationDetails.organization,
              approvers: data.pageTemplateApprovers,
              approvalSchema: data.approvalSchema,
              clientId: data.clientId
            }
            $('#changeApprover').modal('show');
          });
        break;
      case "edit":
        this.clientCommitmentService.getPageTemplateResponseById(event.data).subscribe(
          response => {
            let data = response as IPageTemplateResponse;
            let filedSets = JSON.parse(data.formJSON)
            let fieldSets2 = filedSets.components.filter((data) => {
              //
              if (data.type == 'panel' || data.type == 'fieldset') {
                data.type = "panel"
                data.legend = data.title
                return true;
              }
            });
            filedSets.components = fieldSets2;
            let dataForm: ICreateForm = {
              "id": event.data,
              formDes: data.description,
              templateOption: '',
              formTemplate: '',
              formName: data.title,
              initialDataJSON: data.formJSON,
              json: JSON.stringify(filedSets),
              isExist: true,
              isResponse: true,
              rowVersion: data.rowVersion,
              answerJSON: data.answerJSON,
              assignedToName: data.currentAssignedTo,
              currentApproverName: data.currentApproverName,
              dueDate: data.dueDate,
              responseStatusId: data.pageTemplateResponseStatusId,
              type: "page",
              pageTemplateId: data.pageTemplateId
            }
            this.manageForm.saveFormValues(dataForm);
            this.router.navigate(['/admin/form/template']);
          });
        break;
      case "delete":
        this.deleteTemplate(this.pageTemplateResponseId, response.title);
        break;
      default:
        break;
    }
  }

  createResponse() {
    this.router.navigate(['/self-service/pageTemplate', this.pageTemplateId]);
  }

  navigateToResponse(pageResponseId: number) {
    let url: string = '/self-service/pageTemplate/' + this.pageTemplateId + "/response"
    this.router.navigate([url, pageResponseId]);
  }

  callResponseListService() {
    $('#refreshTask').addClass('fa-rotation');
    if (this.pageResponses) {
      for (let i = 0; i < this.pageResponses.length; i++) {
        this.removedArray[this.pageResponses[i].id] = !this.pageResponses[i].selected;
      }
    }
    this.pageTemplateService.getPageTemplateResponseList(this.pageTemplateId, this.queryParam)
      .pipe(take(1))
      .subscribe(
        responses => {
          this.pageResponseList = responses.body as IPageTemplateResponseList;
          this.pageResponseList.responses.forEach(responseList => {
            responseList.selected = responseList.isLinked ? true : false;
            if (responseList.selected) {
              if (this.removedArray[responseList.id] === undefined) {
                this.baseSelectedForms.push(responseList);
              }
            }
            // responseList.accessLevel = 'Write'; // Commented due to PBI-260094
            responseList.pageTemplateAdmin = this.pageTemplateAdmin || this.isAdmin;
            responseList.customeFormFieldListJsonAnswer = JSON.parse(responseList.answerJSON);

            if (this.listFormFieldJson.length > 0) {
              for (let item of this.listFormFieldJson) {
                const formFieldKey = `${item['formfieldapikey']}OriginalValue`;
                responseList.customeFormFieldListJsonAnswer[formFieldKey] = responseList.customeFormFieldListJsonAnswer[item['formfieldapikey']];
              }
            }

            if (this.pageTemplateReadAdmin || this.userData.roles.some(r => r.roleTitle === 'Division Leader' || r.roleTitle === 'BU Leader' || r.roleTitle === 'Delivery Owner' || r.roleTitle === 'Delivery Leader')) {
              responseList.isReadOnlyRole = true;
            }

            if (responseList.pageTemplateResponseStatusId === 3 &&
              responseList.pageTemplateApprovers.some(a => a.approverId === this.userData.id && a.level === responseList.currentLevel && a.isReviewComplete === false)
            )
              responseList.updateApproverLink = true;
            else
              responseList.updateApproverLink = false;
          });

          /* -- Commented due to PBI-260094
           for (let i in this.pageResponseList.responses) {
             if(this.pageTemplateReadAdmin && !this.pageTemplateAdmin && !this.isAdmin && !(this.pageResponseList.responses[i].submittedById === this.userData.id && this.pageResponseList.responses[i].pageTemplateResponseStatusId < 3)) {
               this.pageResponseList.responses[i].accessLevel = 'Read';
             }
   
             if(this.pageResponseList.responses[i].pageTemplateApprovers.some(a => a.approverId === this.userData.id)) {
               if(this.pageResponseList.responses[i].pageTemplateResponseStatusId === 3
                 && this.pageResponseList.responses[i].pageTemplateApprovers.some(a => a.approverId === this.userData.id
                                                                                   && a.level === this.pageResponseList.responses[i].currentLevel
                                                                                   && a.isReviewComplete === false
                                                                                 )
                 ) {
                 this.pageResponseList.responses[i].updateApproverLink = true;
                 this.pageResponseList.responses[i].accessLevel = 'Write';
               }
               else if (!this.canRequest && this.pageResponseList.responses[i].submittedById !== this.userData.id){
                 this.pageResponseList.responses[i].accessLevel = 'Read';
               }
             }
   
             if(this.userData.isRestrictedAdmin === true){
               let response = this.pageResponseList.responses[i];
               let isExists: boolean = false;
               let isApprover: boolean = false;
               let sortOrder = ['Read','Write'];
               let groups = this.userData.groups.sort((a,b)=>{return sortOrder.indexOf(a.accessLevel) - sortOrder.indexOf(b.accessLevel);});
   
               isApprover = response.pageTemplateApprovers.some(a => a.approverId === this.userData.id && a.level === response.currentLevel) && response.pageTemplateResponseStatusId === 3;
   
               if(!isApprover && response.submittedById !== this.userData.id)
               {
                 for (let g in groups) {
                   isExists = false;
                   if(response.organizationId > 0) {
                     if(groups[g].organizations.some(org => org.organizationId === response.organizationId)) {
                       isExists = true;
                     }
                   }
   
                   if(response.productId > 0) {
                     if(groups[g].products.some(prd => prd.productId === response.productId)) {
                       isExists = true;
                     }
                   }
   
                   if(response.clientId > 0) {
                     if(groups[g].clients.some(clnt => clnt.clientId === response.clientId)) {
                       isExists = true;
                     }
                   }
   
                   if(isExists) {
                     this.pageResponseList.responses[i].accessLevel = groups[g].accessLevel;
                   }
                 }
               }
             }
           }
           -- Commented due to PBI-260094 */
          this.getFormFieldsData();

          if (responses.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(responses.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
        (error) => { this.errorMsg = error; });
  }

  callRefreshResponseListService() {
    $('#refreshTask').addClass('fa-rotation');
    if (this.pageResponses) {
      for (let i = 0; i < this.pageResponses.length; i++) {
        this.removedArray[this.pageResponses[i].id] = !this.pageResponses[i].selected;
      }
    }
    this.pageTemplateService.getPageTemplateRefreshResponseList(this.pageTemplateId, this.queryParam)
      .pipe(take(1))
      .subscribe(
        responses => {
          this.pageResponseList = responses.body as IPageTemplateResponseList;
          this.pageResponseList.responses.forEach(responseList => {
            responseList.selected = responseList.isLinked ? true : false;
            if (responseList.selected) {
              if (this.removedArray[responseList.id] === undefined) {
                this.baseSelectedForms.push(responseList);
              }
            }
            // responseList.accessLevel = 'Write'; // Commented due to PBI-260094
            responseList.pageTemplateAdmin = this.pageTemplateAdmin || this.isAdmin;
            responseList.customeFormFieldListJsonAnswer = JSON.parse(responseList.answerJSON);

            if (this.listFormFieldJson.length > 0) {
              for (let item of this.listFormFieldJson) {
                const formFieldKey = `${item['formfieldapikey']}OriginalValue`;
                responseList.customeFormFieldListJsonAnswer[formFieldKey] = responseList.customeFormFieldListJsonAnswer[item['formfieldapikey']];
              }
            }

            if (this.pageTemplateReadAdmin || this.userData.roles.some(r => r.roleTitle === 'Division Leader' || r.roleTitle === 'BU Leader' || r.roleTitle === 'Delivery Owner' || r.roleTitle === 'Delivery Leader')) {
              responseList.isReadOnlyRole = true;
            }

            if (responseList.pageTemplateResponseStatusId === 3 &&
              responseList.pageTemplateApprovers.some(a => a.approverId === this.userData.id && a.level === responseList.currentLevel && a.isReviewComplete === false)
            )
              responseList.updateApproverLink = true;
            else
              responseList.updateApproverLink = false;
          });

          /* -- Commented due to PBI-260094
          for (let i in this.pageResponseList.responses) {
            if(this.pageTemplateReadAdmin && !this.pageTemplateAdmin && !this.isAdmin && !(this.pageResponseList.responses[i].submittedById === this.userData.id && this.pageResponseList.responses[i].pageTemplateResponseStatusId < 3)) {
              this.pageResponseList.responses[i].accessLevel = 'Read';
            }
  
            if(this.pageResponseList.responses[i].pageTemplateApprovers.some(a => a.approverId === this.userData.id)) {
              if(this.pageResponseList.responses[i].pageTemplateResponseStatusId === 3
                && this.pageResponseList.responses[i].pageTemplateApprovers.some(a => a.approverId === this.userData.id
                                                                                  && a.level === this.pageResponseList.responses[i].currentLevel
                                                                                  && a.isReviewComplete === false
                                                                                )
                ) {
                this.pageResponseList.responses[i].updateApproverLink = true;
                this.pageResponseList.responses[i].accessLevel = 'Write';
              }
              else if (!this.canRequest && this.pageResponseList.responses[i].submittedById !== this.userData.id){
                this.pageResponseList.responses[i].accessLevel = 'Read';
              }
            }
  
            if(this.userData.isRestrictedAdmin === true){
              let response = this.pageResponseList.responses[i];
              let isExists: boolean = false;
              let isApprover: boolean = false;
              let sortOrder = ['Read','Write'];
              let groups = this.userData.groups.sort((a,b)=>{return sortOrder.indexOf(a.accessLevel) - sortOrder.indexOf(b.accessLevel);});
  
              isApprover = response.pageTemplateApprovers.some(a => a.approverId === this.userData.id && a.level === response.currentLevel) && response.pageTemplateResponseStatusId === 3;
  
              if(!isApprover && response.submittedById !== this.userData.id)
              {
                for (let g in groups) {
                  isExists = false;
                  if(response.organizationId > 0) {
                    if(groups[g].organizations.some(org => org.organizationId === response.organizationId)) {
                      isExists = true;
                    }
                  }
  
                  if(response.productId > 0) {
                    if(groups[g].products.some(prd => prd.productId === response.productId)) {
                      isExists = true;
                    }
                  }
  
                  if(response.clientId > 0) {
                    if(groups[g].clients.some(clnt => clnt.clientId === response.clientId)) {
                      isExists = true;
                    }
                  }
  
                  if(isExists) {
                    this.pageResponseList.responses[i].accessLevel = groups[g].accessLevel;
                  }
                }
              }
            }
          }
          -- Commented due to PBI-260094 */
          this.getFormFieldsData();

          if (responses.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(responses.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
        (error) => { this.errorMsg = error; });
  }

  getFormFieldsData() {
    this.pageResponses = [];

    for (let i = 0; i < this.pageResponseList.responses.length; i++) {
      this.pageResponses.push(this.pageResponseList.responses[i]);

      let ansJson = JSON.parse(this.pageResponseList.responses[i].answerJSON);
      if (ansJson) {
        if (this.listFormFieldJson.length > 0) {
          for (let item of this.listFormFieldJson) {
            this.pageResponses[i][item['formfieldapikey']] = ansJson[item['formfieldapikey']];
          }
        }
      }
      for (let i = 0; i < this.pageResponses.length; i++) {
        let idInResponse = false;
        this.formSets[0].assignedFormsList.forEach(row => {
          if (row.id == this.pageResponses[i].id) {
            idInResponse = true;
            this.pageResponses[i].selected = true;
            this.removedArray[this.pageResponses[i].id] = false;
          } else if (this.removedArray[this.pageResponses[i].id]) {
            this.pageResponses[i].selected = false;
          }
        })

        if (!idInResponse && this.pageResponseList.responses[i].selected && (!this.removedArray || !this.removedArray[this.pageResponses[i].id] || (this.removedArray && this.removedArray[this.pageResponses[i].id] && this.removedArray[this.pageResponses[i].id] != true)))
          this.formSets[0].assignedFormsList.push(this.pageResponses[i]);
      }
    }
  }

  deleteTemplate(id: number, title: string) {
    this.successMsg = "";
    this.pageTemplateService.deletePageResponse(id)
      .subscribe(
        () => {
          this.pageTemplateResponseId = 0;
          this.callRefreshResponseListService();
          this.successMsg = `Request - '${title}' has been deleted successfully.`;
        },
        error => {
          this.errorMsg = error;
        }
      );
  }

  goBack() {
    this.pageTemplateService.sideNav = false;
    if (this.returnPage === "Self Service")
      this.router.navigate(['/self-service/home']);
    else
      this.router.navigate(['self-service/pageTemplates']);
  }

  onFormFieldSelect(event) {
    this.filterForm.controls['formFieldValue'].setValue("");

    if (this.selectedFormFieldApiKey === "") {
      this.selectedFormFieldLabel = "";
      return;
    }

    for (let item of this.searchByFormFieldJson) {
      if (item['formfieldapikey'] === this.selectedFormFieldApiKey)
        this.selectedFormFieldLabel = item['formfieldlabel'];
    }

    this.pageTemplateService.getFormFieldSuggestion(this.pageTemplateId, this.selectedFormFieldApiKey).subscribe(
      data => this.formFieldSuggestion = data,
      (error) => { this.errorMsg = error; }
    );
  }

  prePopulateRequests() {
    this.pageTemplateService.pageTemplateId = this.pageTemplateId;
    let url = '/self-service/bulkUpload/' + this.pageTemplateNameTitle;
    this.router.navigate([url]);
  }

  /** start of column settings changes**/
  showColumnSettings() {
    $('.container-page').addClass('overflow-hidden');
    this.showColumnSettingsDrawer = true;
    this.columnSettingErrorMsg = '';
  }

  callPreferenceGetService() {
    const userId = this.userData.id;
    const listName = PreferenceService.pageTemplateResponseList;
    const fetchPreferenceByUserId = this.preferenceService.getPreferenceByUserId(userId, listName, this.pageTemplateId);
    const fetchAllPreferenceByListName = this.preferenceService.getAllPreferenceByListName(listName, this.pageTemplateId);
    this.showAddColumnOptions = true;
    forkJoin([fetchPreferenceByUserId, fetchAllPreferenceByListName])
      .subscribe((response: any) => {
        const columnPrefs = response[0];
        const columnList = response[1];
        this.refreshPreference(columnPrefs, columnList);
      }, error => {
        this.isError = true; this.errorMsg = error;
      }
      );
  }

  takeAction(isSubmit: boolean = false) {
    this.errorMsg = "";
    this.initiatorDrawer = true;
    this.isMultipleResponseSubmit = isSubmit;
    $('#containerPage').scrollTop(0);
    $('.container-page').addClass('overflow-hidden');
    this.addedPageTemplateTolink = [];
    this.removedPageTemplateTolink = [];
    this.exitedPageTemplateTolink = [];

    for (let i = 0; i < this.baseSelectedForms.length; i++) {
      let idInResponse = false;
      this.formSets[0].assignedFormsList.forEach(row => {
        if (row.id == this.baseSelectedForms[i].id) {
          idInResponse = true;
        }
      })
      if (!idInResponse) {
        this.removedPageTemplateTolink.push(this.baseSelectedForms[i]);
      }
    }

    this.formSets[0].assignedFormsList.forEach(row => {
      let idInResponse = false;
      for (let i = 0; i < this.baseSelectedForms.length; i++) {
        if (row.id == this.baseSelectedForms[i].id) {
          idInResponse = true;
          let rows = row;
          rows.statusA = 'exits';
        }
      }
      if (!idInResponse) {
        let rows = row;
        rows.statusA = 'new';
        this.addedPageTemplateTolink.push(rows);
      }
    })

    this.addedPageTemplateTolink.forEach(element => {
      let fldLength = this.customFormFieldListJson.length;
      if (fldLength > 0) {
        for (let c = 0; c < fldLength; c++) {
          if (this.customFormFieldListJson[c]['dependentChildField'] !== undefined && this.customFormFieldListJson[c]['dependentChildField'] !== '') {
            let index = this.customFormFieldListJson.findIndex(e => e.formfieldapikey === this.customFormFieldListJson[c]['dependentChildField'])
            if (element.customeFormFieldListJsonAnswer[this.customFormFieldListJson[c]['formfieldapikey']] === true
              && (element.customeFormFieldListJsonAnswer[this.customFormFieldListJson[c]['dependentChildField']] === undefined
                || element.customeFormFieldListJsonAnswer[this.customFormFieldListJson[c]['dependentChildField']] === ''
                || (element.customeFormFieldListJsonAnswer[this.customFormFieldListJson[c]['dependentChildField']] === 'other'
                  && this.customFormFieldListJson[index]['showOtherOptionField'] === true
                  && (element.customeFormFieldListJsonAnswer[this.customFormFieldListJson[index]['otherOptionFieldApiKey']] === undefined
                    || element.customeFormFieldListJsonAnswer[this.customFormFieldListJson[index]['otherOptionFieldApiKey']] === '')
                )
              )
              && this.customFormFieldListJson[index]['isMandatory'] === true
            ) {
              this.errorMsg = 'Please provide all required highlighted fields.';
              this.initiatorDrawer = false;
              $('.container-page').removeClass('overflow-hidden');
              return;
            }
          }
        }
      } ``
    });
  }

  cancelDrawer() {
    this.initiatorDrawer = false;
    this.isMultipleResponseSubmit = false;
    $('.container-page').removeClass('overflow-hidden');
  }

  submitRequest() {
    this.successMsg = '';
    this.formSets[0].assignedFormsList.forEach(responseList => {
      responseList.answerJSON = JSON.stringify(responseList.customeFormFieldListJsonAnswer);
      responseList.requestType = this.isMultipleResponseSubmit ? 'submit' : 'update';
      delete responseList.customeFormFieldListJsonAnswer;
    })

    if (this.removedPageTemplateTolink.length > 0 && this.linkedToggle) {
      this.formSets[0].assignedFormsList = this.removedPageTemplateTolink;
      this.formSets[0].assignedFormsList.forEach(element => { element.statusA = 'remove'; element.requestType = this.isMultipleResponseSubmit ? 'submit' : 'update'; });
    }

    this.clientCommitmentService.updateMultiplePageResponses(this.formSets[0].assignedFormsList)
      .subscribe(
        (data) => {
          this.formSets[0].assignedFormsList.forEach(element => {
            const index: number = this.baseSelectedForms.findIndex(e => e.id === element.id);
            if (index !== -1) {
              this.baseSelectedForms.splice(index, 1);
            }
          });

          this.formSets[0] = { 'assignedFormsList': [] };
          this.removedArray = [];
          this.successMsg = this.linkedToggle
            ? `Selected '${this.pageTemplateName.title}' request(s) de-linked with '${this.pageTemplateName.dependentPageTemplateTitle}' successfully.`
            : this.isLinkingAllowed
              ? this.isMultipleResponseSubmit
                ? `Selected '${this.pageTemplateName.title}' request(s) linked with '${this.pageTemplateName.dependentPageTemplateTitle}' successfully.`
                : `Selected '${this.pageTemplateName.title}' request(s) updated successfully.`
              : this.isMultipleResponseSubmit
                ? `Selected '${this.pageTemplateName.title}' request(s) completed successfully.`
                : `Selected '${this.pageTemplateName.title}' request(s) updated successfully.`;

          this.cancelDrawer();
          this.queryParam.pageNumber = 1;
          this.refresh();
        },
        error => {
          this.errorMsg = error;
          this.cancelDrawer();
        }
      );

    $('.container-page').removeClass('overflow-hidden');
  }

  savePreference() {
    const hasEmpty = this.preferenceService.hasEmptyColumns(this.columnPreferences);
    if (hasEmpty) {
      this.isError = true;
      this.errorMsg = "Empty column selected.";
      return;
    }

    const preferences = this.preferenceService.getPreferencesToBeSaved(this.columnPreferences, this.prefColumnOptions)
    const isDuplicate = this.preferenceService.checkForDuplicates(preferences);
    if (isDuplicate) {
      this.columnSettingErrorMsg = "Duplicate column selected";
      return;
    }
    const params: any = {
      userId: this.userData.id,
      listName: this.listName,
      columnOptions: JSON.stringify(preferences),
      pageTemplateId: this.pageTemplateId
    };
    if (this.userId != 0) {
      params.id = this.preferenceId;
      this.updatePreference(params);
    } else {
      this.createPreference(params);
    }

    $('.container-page').removeClass('overflow-hidden');
    this.showColumnSettingsDrawer = false;
  }

  createPreference(params: any) {
    this.preferenceService.savePreference(params).pipe(take(1))
      .subscribe(
        (preference: any) => {
          this.refreshPreference(preference);
        }, error => {
          this.isError = true; this.errorMsg = error;
        }
      );
  }

  updatePreference(params: any) {
    this.preferenceService.updatePreference(params).pipe(take(1))
      .subscribe(
        (preference: any) => {
          this.refreshPreference(preference);
        }, error => {
          this.isError = true; this.errorMsg = error;
        }
      );
  }

  refreshPreference(columnPrefs: any, columnList?: any) {
    this.isError = false;
    const columnOptions = JSON.parse(columnPrefs.columnOptions);
    this.listName = columnPrefs.listName;
    this.userId = columnPrefs.userId;
    this.preferenceId = columnPrefs.id;
    this.columnPreferences = this.preferenceService.convertToDropDownItems(columnOptions);
    if (this.columnPreferences && this.columnPreferences.length < this.maxColumnCount) {
      this.showAddColumnOptions = true;
    } else {
      this.showAddColumnOptions = false;
    }
    if (columnList) {
      const listOfoptions = JSON.parse(columnList.allColumnOptions);
      this.prefColumnOptions = this.preferenceService.convertToDropDownItems(listOfoptions);
    }
    const columnPreferences = this.preferenceService.refreshGrid(columnOptions);
    this.initDataTableColumns(columnPreferences);
    this.filterList();
    if (!this.filterExists) this.filtered = false;
    this.refreshGrid = true;
  }

  cancelPreference() {
    $('.container-page').removeClass('overflow-hidden');
    this.showColumnSettingsDrawer = false;
    this.callPreferenceGetService();
  }

  removeColumn(counter: any) {
    const columnPrefsCount = this.columnPreferences.length;
    this.showAddColumnOptions = true;
    if (columnPrefsCount > 0) {
      this.columnPreferences.splice(counter, 1);
    }
  }

  addColumn() {
    const columnObject = Object.assign({}, this.columnPreferences[0]);
    let columnPrefsCount = this.columnPreferences.length;
    this.showAddColumnOptions = true;
    if (columnPrefsCount < this.maxColumnCount) {
      this.columnPreferences.push(columnObject);
    }
    columnPrefsCount = this.columnPreferences.length;
    if (columnPrefsCount === this.maxColumnCount) {
      this.showAddColumnOptions = false;
    }
  }
  /** end of column settings changes**/

  exportExcel() {
    let exportResponseList: IPageTemplateResponseList;
    let exportResponses = [];

    this.pageTemplateService.exportPageTemplateResponseList(this.pageTemplateId, this.queryParam)
      .pipe(take(1))
      .subscribe(
        responses => {
          exportResponseList = responses.body as IPageTemplateResponseList;
          let len = Object.keys(exportResponseList).length;
          for (let i = 0; i < len; i++) {
            exportResponses[i] = [];
            exportResponses[i]['Id'] = exportResponseList[i]['id'];
            exportResponses[i]['Page Template Id'] = exportResponseList[i]['pageTemplateId'];
            exportResponses[i]['Title'] = exportResponseList[i]['title'];
            exportResponses[i]['Description'] = exportResponseList[i]['description'];

            let ansJson = JSON.parse(exportResponseList[i].answerJSON);
            if (ansJson) {
              if (this.listFormFieldJson.length > 0) {
                for (let item of this.listFormFieldJson) {
                  if (item['formfieldtype'] === 'select' && item['showOtherOptionField'] === true && ansJson[item['formfieldapikey']] === 'other')
                    exportResponses[i][item['formfieldlabel']] = ansJson[item['formfieldapikey']] + " - " + ansJson[item['otherOptionFieldApiKey']];
                  else
                    exportResponses[i][item['formfieldlabel']] = typeof (ansJson[item['formfieldapikey']]) === 'object' ? ansJson[item['formfieldapikey']].join(",") : ansJson[item['formfieldapikey']]
                }
              }
            }
            exportResponses[i]['Current Assignee'] = exportResponseList[i]['editAccessWith'];
            exportResponses[i]['Current Approver Name'] = exportResponseList[i]['currentApproverName'];
            exportResponses[i]['Approval Schema'] = exportResponseList[i]['approvalSchema'];
            // exportResponses[i]['Status'] = exportResponseList[i]['pageTemplateResponseStatusTitle'];
            exportResponses[i]['Client Title'] = exportResponseList[i]['clientTitle'];
            exportResponses[i]['Product Title'] = exportResponseList[i]['productTitle'];
            exportResponses[i]['Organization Title'] = exportResponseList[i]['organizationTitle'];
            exportResponses[i]['Start Date'] = exportResponseList[i]['startDate'];
            exportResponses[i]['Due Date'] = exportResponseList[i]['dueDate'];
            exportResponses[i]['Submitted By'] = exportResponseList[i]['submittedByName'];
            exportResponses[i]['Submitted On'] = exportResponseList[i]['createdOn'];
            exportResponses[i]['Modified By'] = exportResponseList[i]['modifiedByName'];
            exportResponses[i]['Modified On'] = exportResponseList[i]['modifiedOn'];
          }

          this.excelService.exportAsExcelFile(exportResponses, this.pageTemplateNameTitle + '_responses');
        },
        (error) => { this.errorMsg = error; });
  }

  /**************************** Assign Approver Code Changes ******************************** */
  showApproverDrawer() {
    if ((this.isDynamicApprover || this.isEntityLink)
      && (!this.pageResponse // || (this.pageResponse?.accessLevel === 'Write' && (this.pageResponse.pageTemplateResponseStatusId === 1 || this.pageResponse.pageTemplateResponseStatusId === 2))
      )) {
      $('#containerPage').scrollTop(0);
      $('.container-page').addClass('overflow-hidden');
      this.apiFormData();
    }
    else {
      this.resetApproverData();
      if (this.pageResponse)
        this.navigateToResponse(this.pageResponse.id);
      else
        this.createResponse();
    }
  }

  apiFormData() {
    this.resetApproverChecks();
    let apiCall = [];
    apiCall.push(this.formService.getUsersForSelectedOrg(0));
    apiCall.push(this.projectService.getClientSuggestionsWithMDM("clients", null, 100));

    if (this.canRequest) {
      if (this.userGroupOrgIds !== "" && this.userData.isRestrictedAdmin)
        apiCall.push(this.projectService.getSuggestions("organizations", this.userGroupOrgIds, "orgIds"));
      else
        apiCall.push(this.projectService.getSuggestions("organizations", ""));
      apiCall.push(this.projectService.getSuggestions("products", ""));
    }
    else {
      apiCall.push(this.projectService.getSuggestions("organizations", this.selectedOrganizationId, "orgId"));
      apiCall.push(this.projectService.getSuggestions("products", this.selectedOrganizationId, "orgId"));
    }

    if (this.pageTemplateResponseId > 0)
      apiCall.push(this.clientCommitmentService.getPageTemplateResponseById(this.pageTemplateResponseId));
    forkJoin(apiCall).subscribe(data => {
      this.usersData = { 'data': data[0] };
      this.clientSuggestion = data[1];
      this.organizationSuggestion = data[2];
      this.productSuggestion = data[3];
      if (this.pageTemplateResponseId > 0) {
        this.pageResponse = data[4] as IPageTemplateResponse;

        if (this.pageResponse && this.pageResponse.organizationId > 0) {
          this.getProductSuggestionbyOrgId(this.pageResponse.organizationId);
          this.selectedOrganizationId = this.pageResponse.organizationId;
          this.selectedOrganizationName = this.pageResponse.organizationTitle;
        }
      }

      let approver = [];
      if (this.pageResponse && this.pageResponse.pageTemplateApprovers.length > 0) {
        for (let k in this.pageResponse.pageTemplateApprovers) {
          let userObj = this.pageResponse.pageTemplateApprovers[k];
          approver.push({ 'name': userObj.user.name, 'emails': userObj.user.email, id: this.pageResponse.pageTemplateApprovers[k].approverId, level: this.pageResponse.pageTemplateApprovers[k].level })
        }
      }

      this.filedJson = {
        "dataToEdit": approver,
        'json': {
          name: [''],
          emails: '',
          id: '',
          level: ['1']
        }
      }

      this.filedDetails = [
        { 'name': 'emails', 'type': 'emailshow' },
        { 'name': 'id', 'type': 'fdlid' },
        { 'name': 'name', 'type': 'fdl', 'label': 'Name', "className": "col-md-12", "erroeMessage": { 'valid': 'Please select an Approver.' }, 'isRequired': false, 'fdldata': this.userData, 'emailField': 'emails' },
        { 'name': 'level', "className": "col-md-3", 'label': "Level", "erroeMessage": { 'valid': 'Please enter Approver level.' }, 'type': 'text', 'isRequired': false }
      ];

      this.initiateApproverForm();
      this.initiateApproverDrawer = true;
    },
      (error) => { this.isError = true; this.errorMsg = error; });
  }

  checkValidDuns() {
    if (this.selectedClientDunsId === null || this.selectedClientDunsId === undefined || isNaN(+this.selectedClientDunsId)) {
      this.invalidClientDuns = true;
      return;
    }
    else {
      this.invalidClientDuns = false;
    }
  }

  resetClientData() {
    this.selectedClientId = 0;
    this.selectedClientName = "";
    this.selectedClientValue = "";
    this.selectedClientDunsId = "";
    this.selectedClientGudId = "";
    this.selectedRMName = "";
    this.selectedRMEmail = "";
  }

  assignApprover() {
    if (this.invalidClientDuns) return;
    this.resetApproverChecks();

    let approver: any = this.approverFormGroup.get('approver').value;
    let approverIdArray = []
    for (let k in approver['approver']) {
      if (approver['approver'][k].name != '') {
        approverIdArray.push({
          "approverId": approver['approver'][k].id,
          "level": approver['approver'][k].level
        })
      }
    }
    /********** Commented due to PBI-266617 Changes
    this.selectedOrganizationName = this.approverFormGroup.get('organizationName').value;
    this.selectedProductName = this.approverFormGroup.get('productName').value;
    this.selectedClientName=  this.approverFormGroup.get('clientName').value;
    ******** Commented due to PBI-266617 Changes */

    if (this.isEntityLink) {
      // PBI-266617 Changes Starts
      const selectedOrgName = this.approverFormGroup.get('organizationName').value;
      const selectedProdName = this.approverFormGroup.get('productName').value;
      const selectedClntName = this.approverFormGroup.get('clientName').value;

      if (selectedOrgName && this.selectedOrganizationId < 1) {
        this.invalidOrganizationName = true;
        return;
      }

      if (selectedProdName && this.selectedProductId < 1) {
        this.invalidProductName = true;
        return;
      }

      if (selectedOrgName === "" && selectedProdName && this.selectedProductId > 0) {
        this.missingOrganizationName = true;
        return;
      }

      if (selectedClntName === "") {
        this.invalidClientName = true;
        this.resetClientData();
        return;
      }

      if (selectedProdName === "") {
        this.selectedProductId = 0;
        this.selectedProductName = "";
      }

      if (selectedOrgName === "") {
        this.selectedProductId = 0;
        this.selectedProductName = "";
        this.selectedOrganizationId = 0;
        this.selectedOrganizationName = "";
      }

      if (selectedClntName && this.selectedClientId < 1) {
        this.invalidClientName = true;
        return;
      }
      // PBI-266617 Changes Ends

      /********** Commented due to PBI-266617 Changes
      if (this.selectedOrganizationName) {
        let orgId = this.emailTemplateService.fetchIdForRequestByTitle(this.orgSuggestion, this.selectedOrganizationName);
        if (orgId > 0) { this.selectedOrganizationId = orgId; }
        else {this.selectedOrganizationId = 0;this.invalidOrganizationName = true; return;}
      }
      else {this.selectedOrganizationId = 0; }
  
      if (this.selectedProductName) {
        let productId = this.projectService.fetchIdForRequest(this.lkpProdSuggestion, this.selectedProductName);
        if (productId > 0) { this.selectedProductId = productId; }
        else {this.selectedProductId = 0; this.invalidProductName = true; return;}
  
        if(this.selectedOrganizationName === "") {this.missingOrganizationName = true; return;}
      } else {this.selectedProductId = 0; }
   
      if (this.selectedClientName) {
        let clientId = this.projectService.fetchIdForRequest(this.clientSuggestion, this.selectedClientName);
        if (clientId > 0) { this.selectedClientId = clientId; }
        else {this.selectedClientId = 0; this.invalidClientName = true; return;}
      } else {this.selectedClientId = 0; this.invalidClientName = true; return;}
      ******** Commented due to PBI-266617 Changes */
    }

    if (!this.approverFormGroup.valid) return;

    let responseDetails: any = {
      clientId: this.selectedClientId,
      clientTitle: this.selectedClientName,
      organizationId: this.selectedOrganizationId,
      organizationTitle: this.selectedOrganizationName,
      productId: this.selectedProductId,
      productTitle: this.selectedProductName,
      clientDunsId: this.selectedClientDunsId,
      clientGudId: this.selectedClientGudId,
      relationshipManagerName: this.selectedRMName,
      relationshipManagerEmail: this.selectedRMEmail
    }

    if (this.selectedClientId) {
      let client: any = this.clientSuggestion.filter(
        item => item.id == this.selectedClientId
      )[0];

      if (!client) {
        this.invalidClientName = true;
        this.resetClientData();
        return;
      }
      responseDetails['mdmClientMasterId'] = client.mdmClientMasterId > 0 ? client.mdmClientMasterId : 0;
    }

    this.pageTemplateService.formVal = responseDetails;
    if (this.isDynamicApprover) {
      responseDetails['approvalSchema'] = this.approverFormGroup.get('approvalSchema').value,
        this.pageTemplateService.formVal.approvers = approverIdArray;
    }

    $('.container-page').removeClass('overflow-hidden');
    this.resetApproverData();
    this.approverFormGroup.reset();

    if (this.pageResponse)
      this.navigateToResponse(this.pageResponse.id);
    else
      this.createResponse();
  }

  initiateApproverForm() {
    if (this.pageResponse?.organizationId > 0)
      this.selectedOrganizationId = this.pageResponse?.organizationId;

    this.approverFormGroup = this.fb.group({
      'approvalSchema': new FormControl(this.pageResponse ? this.pageResponse?.approvalSchema : "OR"),
      'approver': [this.fb.array([])],
      'organizationName': new FormControl(this.pageResponse ? this.pageResponse?.organizationTitle : ""),
      'productName': new FormControl(this.pageResponse ? this.pageResponse?.productTitle : ""),
      'clientName': new FormControl(this.pageResponse ? this.pageResponse?.clientTitle : "") // PBI-266617 Changes
    });
  }

  formInitialized(name: string, form: any) {
    this.approverFormGroup.setControl(name, form);
    this.formValidationDynamic();
  }

  formValidationDynamic() {
    this.reEvaluateValidators('approver', 'approver');
  }

  reEvaluateValidators(fieldName, isNested: any = 'no') {
    let fieldCtrl: any;
    fieldCtrl = this.approverFormGroup.controls[fieldName]
    if (fieldCtrl && fieldCtrl.controls && fieldCtrl.controls[fieldName].length > 0) {
      fieldCtrl.setValidators(this.validation(fieldName, this.approverFormGroup ? this.approverFormGroup.get(fieldName).value : []));
      fieldCtrl.updateValueAndValidity();
    }
  }

  validation(controlName: string, values: FormArray): ValidationErrors {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let array: any = [];
      for (let k in values[controlName]) {
        let keyName = '';
        let fieldValue = values[controlName][k];
        if (controlName == 'approver') {
          if (fieldValue.level > 0)
            keyName = fieldValue.id + '##' + fieldValue.level;
          else
            return { 'invalid': true };
        }
        else {
          keyName = fieldValue.id
        }

        // if(!REGEX_PATTERNS.name_title_validator.test(fieldValue.name)) {
        //   return { 'notpermitted': true };
        // }

        if (fieldValue.name != '') {
          if (array[keyName])
            array[keyName].push({ "index": k });
          else {
            array[keyName] = [];
            array[keyName].push({ "index": k });
          }
        }
      }
      for (let i in array) {
        if (array[i].length > 1) {
          return { 'duplicate': true };
        }
      }
      return null;
    }
  }

  cancelApproverForm() {
    this.resetApproverChecks();
    this.resetApproverData();
    this.pageResponse = undefined;
    this.pageTemplateResponseId = 0;
    this.pageTemplateService.formVal = undefined;
    $('.container-page').removeClass('overflow-hidden');
    this.approverFormGroup.reset();
    this.typeaheadLoadingClients("", true);
  }

  resetApproverData() {
    this.selectedClientId = 0;
    this.selectedClientName = ""
    this.selectedOrganizationName = "";
    this.selectedClientValue = "";
    this.selectedProductId = 0;
    this.selectedProductName = "";
    this.selectedRMName = "";
    this.selectedRMEmail = "";
    this.initiateApproverDrawer = false;

    if (!this.canRequest)
      this.selectedOrganizationId = this.organizationDetails.organization;
    else
      this.selectedOrganizationId = 0;
  }

  onClientSelect = (event: any) => {
    if (this.approverFormGroup.valid) {
      this.selectedClientId = event.item.id;
      this.selectedClientName = event.item.title;
      this.selectedRMName = event.item.relationshipManagerName;
      this.selectedRMEmail = event.item.relationshipManagerEmail;
      this.invalidClientName = false;
      this.selectedClientValue = event.item.titleWithDuns;
      this.selectedClientDunsId = event.item.dunsId;
      this.selectedClientGudId = event.item.gud;
      this.checkValidDuns();
    }
  }

  onOrganizationSelect = (event: any) => {
    if (this.approverFormGroup.valid) {
      this.selectedOrganizationId = event.item.id;
      this.selectedOrganizationName = event.item.title;
      this.invalidOrganizationName = false;
      this.missingOrganizationName = false;
      this.invalidProductName = false;
      this.approverFormGroup.get('productName').setValue("");

      this.getProductSuggestionbyOrgId(this.selectedOrganizationId);
    }
  }

  getProductSuggestionbyOrgId(orgId: number) {
    this.projectService.getSuggestions("products", orgId, "orgId")
      .subscribe(
        suggestions => {
          this.productSuggestion = suggestions;
        },
        (error) => { this.isError = true; this.errorMsg = error; }
      );
  }

  onProductSelect = (event: any) => {
    if (this.approverFormGroup.valid) {
      this.selectedProductId = event.item.id;
      this.selectedProductName = event.item.title;
      this.invalidProductName = false;
    }
  }

  resetApproverChecks() {
    this.missingOrganizationName = false;
    this.invalidOrganizationName = false;
    this.invalidProductName = false;
    this.invalidClientName = false;
    this.invalidClientDuns = false;
  }
  /**************************** Assign Approver Code Changes ******************************** */

  /**************************** Delegate Respondent Code Changes ******************************** */
  initiateDelegateForm() {
    this._maxLengths = MAX_LENGTHS;
    let validatorArray = [Validators.maxLength(this._maxLengths.Approval.Comments)];
    validatorArray.push(Validators.required);

    this.delegateForm = new FormGroup({
      'respondentName': new FormControl(''),
      'comments': new FormControl('', validatorArray)
    })
  }

  getRespondents() {
    let usersByOrganization;
    let usersByClient
    let respondentApiArr = [];

    this.clientCommitmentService.getPageTemplateResponseById(this.pageTemplateResponseId)
      .pipe(take(1))
      .subscribe(
        response => {
          let pageResponse = response as IPageTemplateResponse;

          if (this.canRequest) {
            if (this.userGroupOrgIds !== "" && this.userData.isRestrictedAdmin) {
              respondentApiArr.push(this.formService.getUsersByOrganizationIds(this.userGroupOrgIds));
            }
            else {
              respondentApiArr.push(this.formService.getUsersForSelectedOrg(0));
            }
          }
          else {
            if (this.userData.type === 'Client') {
              respondentApiArr.push(this.projectService.getSuggestions("users", ""));
            }
            else {
              respondentApiArr.push(this.formService.getFiservUserForSelectedOrg(this.organizationDetails.organization));
            }
          }

          if (!(this.pageTemplateAdmin || this.userData.isAdmin || (this.userGroupOrgIds === "" && this.userData.isRestrictedAdmin)) && pageResponse.clientId > 0 && this.allowClientUser && this.userData.type === 'Fiserv') {
            respondentApiArr.push(this.clientsService.getClientDetailsById(pageResponse.clientId, "respondents"));
          }

          forkJoin(respondentApiArr).subscribe(userData => {
            usersByOrganization = userData[0];
            usersByClient = userData[1];
            const allUsers = (usersByClient !== undefined) ? usersByOrganization.concat(usersByClient) : usersByOrganization;
            this.respondentUserList = allUsers.sort((a, b) => a.name > b.name ? 1 : -1);
          },
            (error) => { this.isError = true; this.errorMsg = error; });

          $('#delegateResponse').modal('show');
        },
        (error) => { this.errorMsg = error; });
  }

  onRespondentSelect(event) {
    this.respondentEmail = "";
    this.selectedRespondentId = event.item.id;
    this.selectedRespondentName = event.item.name;
    this.invalidRespondent = false;

    let respondent = this.projectService.fetchDetailsForUserByUserId(this.respondentUserList, this.selectedRespondentId);
    this.respondentEmail = respondent.email;
  }

  submitDelegate() {
    this.isDelegateSubmitted = true;

    this.selectedRespondentName = this.delegateForm.get('respondentName').value;
    if (this.selectedRespondentName) {
      let respId = this.projectService.fetchIdForRequest(this.respondentUserList, this.selectedRespondentName);
      if (respId > 0) { this.selectedRespondentId = respId; this.invalidRespondent = false; }
      else { this.invalidRespondent = true; return; }
    }

    if (!this.delegateForm.valid) return;

    let data: any = {
      assignedToId: this.selectedRespondentId,
      comments: this.delegateForm.get('comments').value
    }

    this.pageTemplateService.delegateResponse(this.pageTemplateResponseId, data)
      .subscribe(
        (data) => {
          this.cancelDelegate();
          this.callResponseListService();
          this.successMsg = `Request - '${this.delegateResponseTitle}' has been delegated successfully.`;
        },
        error => {
          this.isDelegateError = true;
          this.delegateErrorMsg = error;
        }
      );
  }

  cancelDelegate() {
    this.isDelegateSubmitted = false;
    this.invalidRespondent = true;
    this.isDelegateError = false;
    this.delegateErrorMsg = "";
    this.respondentEmail = "";
    this.pageTemplateResponseId = 0;
    this.delegateForm.reset();
    $('#delegateResponse').modal('hide');
  }
  /**************************** Delegate Respondent Code Changes ******************************** */

  successUpdateApproverClick(evt) {
    this.callResponseListService();
    this.updateApproverMsg = evt.updateApproverSuccessMsg;
  }

  typeaheadLoadingClients(searchValue: string, isDrawerField: boolean = false) {
    if (this.approverFormGroup.valid) {
      let suggestionSubscriber: any;
      if (searchValue && searchValue.length >= 3) {
        const params = [{ paramName: "title", value: searchValue }];
        suggestionSubscriber = this.projectService.getClientSuggestionsWithMDM("clients", params, 100);
      }
      else {
        suggestionSubscriber = this.projectService.getClientSuggestionsWithMDM("clients", null, 100);
      }

      suggestionSubscriber.subscribe(data => {
        if (isDrawerField) {
          this.clientSuggestion = data;
        }
        else {
          this.clntSuggestion = data;
        }
      },
        error => { this.isError = true; this.errorMsg = error; }
      );
    }
  }

  getEntitySuggestions() {
    let suggestionApiCall = [];
    let params = [];
    suggestionApiCall.push(this.projectService.getSuggestions("organizations", ""));
    suggestionApiCall.push(this.projectService.getSuggestions("products", true, "ignoreAll"));

    if (this.clientText && this.clientText.length >= 3) {
      params.push({ paramName: "title", value: this.clientText });
      suggestionApiCall.push(this.projectService.getClientSuggestionsWithMDM("clients", params, 100));
    }
    else {
      suggestionApiCall.push(this.projectService.getClientSuggestionsWithMDM("clients", null, 100));
    }

    forkJoin(suggestionApiCall).subscribe(data => {
      this.orgSuggestion = data[0];
      this.prodSuggestion = data[1];
      this.lkpProdSuggestion = data[1];
      this.clntSuggestion = data[2];
    },
      (error) => { this.isError = true; this.errorMsg = error; });
  }

  onOrgSelect(event: any) {
    if (event.item.id > 0) {
      this.filterForm.get('prodName').setValue("");
      this.searchOrgId = event.item.id;
      this.getProductSuggestions();
    }
  }

  getProductSuggestions() {
    this.projectService.getSuggestions("products", this.searchOrgId, "orgId").subscribe(
      data => this.prodSuggestion = data,
      (error) => { this.isError = true; this.errorMsg = error; }
    );
  }

  navigateToPageResponse(id: number) {
    let url2: string = '/self-service/pageTemplateR/' + id + '/referesh';
    this.router.navigate([url2]);
  }

  isDisplay(row: any) {
    if (row.statusA == 'new')
      return true
    return false;
  }

  getStatusCSSClass(row: any) {
    if (row.statusA == 'new')
      return "alert-message success"
  }

  toggleLinked() {
    this.linkedToggle = !this.linkedToggle;
    this.filterForm.controls['isLinked'].setValue(this.linkedToggle);
    this.clearSelectedResponseList();
    this.filterList();
  }

  clearSelectedResponseList() {
    this.baseSelectedForms = [];
    this.removedArray = [];
    this.formSets[0] = {
      'assignedFormsList': []
    };
  }

  navigateToDataDict(url: string) {
    window.open(url, "_blank");
  }

  canDelete = (data: any): boolean => {
    if (!this.isClientExecutive &&
      (((this.isCCTAdmin && this.pageTemplateAdmin) || (this.isCCTAdmin && !this.pageTemplateAdmin && data.cretedById === this.userData.id))
        || this.userData.isAdmin || this.userData.isRestrictedAdmin || this.isRm)) {
      return true;
    } else {
      return false;
    }
  }
}
