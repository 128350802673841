import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { ProjectsService } from 'src/app/services/projects.service';
import { UserService } from 'src/app/services/user.service';
import { ISuggestion, ISuggestions } from 'src/app/shared/suggestion';
import { FormGroup, Validators, FormControl, FormBuilder, FormArray, ValidationErrors, AbstractControl } from '@angular/forms';
import { IProject, IProjects } from 'src/app/shared/project';
import { ProjectsDTOService } from 'src/app/Projects/Services/projectsDTO.service';
import { REGEX_PATTERNS, MAX_LENGTHS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { PermissionsService } from 'src/app/services/permissions.service';
import { NoWhitespaceValidator } from 'src/app/shared/no-whitespace.validator';
import { ProjectDrawerService } from 'src/app/SharedModule/Components/project-drawer-container/project-drawer.service';

import { forkJoin, Subject } from 'rxjs';
import { flatMap, takeUntil, take } from 'rxjs/operators';
import * as $ from 'jquery';
import moment from 'node_modules/moment';
import { MutipleaddComponent } from 'src/app/SharedModule/Components/mutipleadd/mutipleadd.component';
import { IInputStakeholder, IStakeHolder } from 'src/app/shared/stakeholder';
import { IQuery } from 'src/app/shared/query';
import { environment } from 'src/environments/environment';
import { LoggingService } from 'src/app/services/logging.service';
import { uniqueProjectTitleValidator } from 'src/app/shared/uniqueProjectValidator';
import { userInfo } from 'os';

@Component({
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.css'],
})
export class ProjectInfoComponent implements OnInit, AfterViewInit {
  edit_projectId: number;
  projectJson: any;
  currentDate: any;
  clientJson: any;
  createdProject: any;
  projectInfo: any;
  projectSuggestion: ISuggestion[];
  isInputTouched: any;
  projectForm: FormGroup;
  isSubmitted: boolean = false;
  projectManager: string;
  productList: any = [];
  productSuggestion: ISuggestions;
  projParam: any = {};
  userSuggestion: ISuggestions;
  message: string = '';
  userData: any = {};
  projectList: IProjects = { projects: [] };
  canCopyProject: boolean = false;
  selectCopyProject: any = {};
  selectCopyProjectId: number = 0;
  showExistingProjects: Boolean = false;
  queryParam: IQuery;
  prodName_defaultValue: string;
  projManager_defaultValue: string;
  editableProdId: number;
  editableFiservImplementationManagerId: number;
  isError: boolean;
  rowVersion;
  editableClientId;
  editableclientManagerId;
  editableStatusId;
  errorMsg: string = '';
  projectValues: any;
  clientAddress: any = {};
  _maxLength: any;
  isFIMCreate: boolean;
  PMEmail: string = '';
  hasPM: boolean = false;
  isImplementationManager: boolean = false;
  isPMDiffOrg: boolean = false;
  isPMDiffProduct: boolean = false;
  isPMNotAllowRole: boolean = false;
  isPMNotFiserv: boolean = false;
  isPMDisabled: boolean = false;
  isProductDisabled: boolean = false;
  isProductUnavailable: boolean = false;
  selectedPMName: string = '';
  createUserAllowed: boolean = false;
  loadDrawer: boolean = false;
  showSuccessMsg: boolean = false;
  userAuth: boolean = false;
  successMsg: string;
  todaysdate: string;
  dueDate: string;
  private destroySubject = new Subject();
  currentPageNumber: number = 1;
  userRoleList: any = [];
  isProjectManagerError: Boolean;
  productText: string;

  // START | PBI-168948
  userSuggestionCopy: ISuggestions;
  loadDrawerStakeholder: boolean = false;
  private destroyDrawerStakeholderSubject = new Subject();
  formsStakeholderDetails: any = {};
  filedformStakeholderDetails: any;
  filedStakeholderJson: any;
  stakeholderControlData: FormGroup;
  stakeholderPossibleList: any = [];
  duplicatedStakeholder = false;
  stakeholderSameAsPm = false;
  @ViewChild("addProjectStakeholder") addProjectStakeholder: MutipleaddComponent;
  stakeholders: IStakeHolder[];
  newStakeholderPosition: number = -1;
  pendingNavigationRoute = "";
  // END | PBI-168948
  projectId: number = -1;
  projectData: IProject;
  projectStakeholders: IStakeHolder[];
  parentURL: string;
  regexValidationMessage: any;
  invalidStakeholder: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    public DTOService: ProjectsDTOService,
    private userService: UserService,
    private permissionService: PermissionsService,
    private drawerService: ProjectDrawerService,
    private loggingService: LoggingService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.todaysdate = this.projectsService.getTodaysDate();
    this.currentDate = moment();
    this._maxLength = MAX_LENGTHS;
    this.userData = this.userService.getUserListData();
    this.isImplementationManager = this.userData.roles.findIndex(r => r.roleTitle == "Fiserv Implementation Manager") != -1;
    this.userData.roles.forEach((role) => {
      this.userRoleList.push(role.roleTitle);
    });
    if (
      this.userRoleList.includes('Admin') ||
      this.userRoleList.includes('Fiserv Division Admin') ||
      this.userRoleList.includes('Fiserv Portfolio Manager') ||
      this.userRoleList.includes('Fiserv Portfolio Manager - Read Only') ||
      this.userRoleList.includes('Fiserv Implementation Manager')
    ) {
      this.userAuth = true;
    }

    this.isFIMCreate = this.permissionService.isCreateFIMAllowed();

    /********************EDIT BEGIN*************************/
    this.projectValues = this.DTOService.getProjectFacts();
    this.stakeholders = this.DTOService.getProjectStakeHolders();
    //this.clientAddress = this.projectValues.clientAddress;
    /********************EDIT END*************************/

    this.route.parent.url.subscribe((url: UrlSegment[]) => this.parentURL = url[0].path)
    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.projectId = params['id'];

        if (this.projectId > -1) {
          /*if (Object.keys(this.projectValues).length === 0) {*/
            this.getProjectData();
          /*}*/
        }
      });

    if (this.parentURL === 'edit') {
      // if (Object.keys(this.projectValues).length !== 0) {
      this.setFormDetails();
      // }
    }

    if (this.parentURL === 'create') {
      this.setFormDetails();
    }

  }

  getCopyProjectData(id) {
    $('.refresh-icon-link').addClass('fa-rotation');
    forkJoin([this.projectsService.getProjectById(id), this.projectsService.getStakeholders(id)])
      .pipe(take(1))
      .subscribe(
        result => {
          const project = result[0];
          this.stakeholders = result[1];
          this.projectValues = project;
          this.DTOService.setParentType("edit");
          this.DTOService.setProjectFacts(this.projectValues);
          this.projectValues.sameForms = project.sameForms ? "Yes" : "No";

          this.setFormDetails();

          $('.refresh-icon-link').removeClass('fa-rotation');
        }
      );
  }

  getProjectData() {
    $('.refresh-icon-link').addClass('fa-rotation');
    forkJoin([this.projectsService.getProjectById(this.projectId), this.projectsService.getStakeholders(this.projectId)])
      .pipe(take(1))
      .subscribe(
        result => {
          const orgId = this.userData.organization;  
          const project = result[0];
          this.getManagerSuggestionsByProductId(orgId, project.productId);
          this.stakeholders = result[1];
          this.projectValues = project;
          this.DTOService.setParentType("edit");
          this.DTOService.setProjectFacts(this.projectValues);
          this.projectValues.sameForms = project.sameForms ? "Yes" : "No";                  
          var hadPM = project.fiservImplementationManagerId ? true : false;          
          this.getStakeHolders(orgId, project.productId, hadPM);
          this.setFormDetails();
          $('.refresh-icon-link').removeClass('fa-rotation');
        }
      );
  }

  setFormDetails() {
    if (this.projectValues.startDate) {
      this.currentDate = this.projectValues?.startDate;
    }

    if (
      this.projectValues.dueDate &&
      this.projectValues.dueDate != '0001-01-01T00:00:00Z' &&
      this.projectValues.dueDate != this.projectValues.startDate
    ) {
      this.dueDate = this.projectValues?.dueDate.split('T')[0];
    } else {
      this.dueDate = '';
    }

    this.initQueryParams();

    this.projectForm = new FormGroup({
      productName: new FormControl(
        this.projectValues?.productTitle || '', [
        Validators.required
      ]),
      copyProjectName: new FormControl(
        this.projectValues?.copyProjectName || '', [
        Validators.required
      ]),
      projectName: new FormControl(this.projectValues?.title || '', [
        Validators.required,
        Validators.pattern(REGEX_PATTERNS.project_name_validator),
        Validators.maxLength(this._maxLength.Project.Name),
        NoWhitespaceValidator.validateWhiteSpaces,
      ]),
      description: new FormControl(this.projectValues?.description || '', [
        Validators.required,
        Validators.pattern(REGEX_PATTERNS.project_name_validator),
        Validators.maxLength(this._maxLength.Project.Description),
        NoWhitespaceValidator.validateWhiteSpaces,
      ]),
      projectManager: new FormControl(
        this.projectValues?.fiservImplementationManagerName || '', [
        Validators.required,
        // TODO: verify to use the validation for the email
        Validators.pattern(REGEX_PATTERNS.user_name_validator),
      ]),
      sameForms: new FormControl('0'),
      //copyProjectName: new FormControl(''),
      copyProject: new FormControl('0'),
      projectClosureDate: new FormControl(this.dueDate),
    });

    this.getProjectSuggestions();

    //if (this.DTOService.getParentType() !== 'edit' && this.parentURL !== 'edit') {
    //  this.projectForm.controls["projectName"].addAsyncValidators([uniqueProjectTitleValidator(this.projectsService)]);
    //}

    if (this.projectValues != null && this.projectValues.sameForms == 'Yes') {
      this.projectForm.patchValue({ sameForms: '1' });
    }




    this.projectForm.get('projectManager').valueChanges.subscribe((value) => {
      if (
        value &&
        value.toLowerCase() === this.selectedPMName &&
        this.selectedPMName.toLowerCase()
      ) {
        this.PMEmail = this.projectsService.fetchEmail(
          this.userSuggestion,
          value
        );
      } else this.PMEmail = '';
    });

    if (!this.isFIMCreate) {
      this.projectForm.patchValue({
        projectManager: this.userService.getUserListData().name,
      });
      this.PMEmail = this.userService.getUserListData().email;
    }
    const x = this.projectForm.controls['productName'].value;
    this.getProductSuggestions(x);
    // this.projectsService.getSuggestions('products', x).subscribe(
    //   (products) => {
    //     this.isError = false;
    //     this.productSuggestion = products;
    //   },
    //   (error) => {
    //     (this.isError = true), (this.errorMsg = error);
    //   }
    // );

    if (this.isFIMCreate) {
      this.getManagerSuggestions();
    }

    // START | PBI-168948
    const out = [];
    if (this.stakeholders && this.stakeholders.length > 0) {
      this.stakeholders.forEach((value) => {
        out.push({
          id: value.userId,
          name: value.userName,
          emails: value.email
        });
      });
    }
    this.stakeholderControlData = this.fb.group({
      projectStakeholder: this.fb.array(out)
    });
    this.formsStakeholderDetails.usersData = {
      data: []
    };
    if (this.parentURL === 'create')
      this.getClients(this.userData.organization);

    this.filedStakeholderJson = {
      'dataToEdit': out,
      'json': {
        name: [''],
        emails: '',
        id: ''
      }
    };

    this.filedformStakeholderDetails = [
      {
        'name': 'name',
        'type': 'fdl',
        'label': 'Project Stakeholder',
        'isRequired': false,
        'fdldata': this.formsStakeholderDetails.usersData,
        'emailField': 'emails',
        "className": "col-md-12",
        "erroeMessage": {
          'valid': 'Please select a Stakeholder.'
        },
        "tooltip": {
          'class': 'material-icons btn-secondary f-18',
          'icon': 'info',
          'title': 'Project stakeholders will be informed about actions in the project'
        }
      },
      {
        'name': 'emails',
        'type': 'emailshow',
        'isDisabled': 'yes',
        'label': 'Email',
      },
      {
        'name': 'id',
        'type': 'fdlid'
      }
    ];


    // END | PBI-168948

    this.clientAddress = this.projectValues.clientAddress;
  }

  // START | PBI-168948
  getClients(orgId: number) {
    this.projectsService.getUsersForOrgId(orgId).subscribe(
      users => {
        this.stakeholderPossibleList = users;
        this.formsStakeholderDetails.usersData = { data: users }
      }
    );
  }
  inputStakeholder() {
    this.invalidStakeholder = false;
  }

  getStakeHolders(orgId: number, productId: number, hasPM: boolean) {
    this.projectsService.getProjectsStakeHolders(orgId, productId).subscribe(
      users => {
        this.stakeholderPossibleList = users;
        this.formsStakeholderDetails.usersData = { data: users }
        if (hasPM && this.PMEmail) {
          this.formsStakeholderDetails.usersData = {
            data: this.stakeholderPossibleList.filter(x => x.email != this.PMEmail)
          }
        }
        for (var i = 0; i < this.stakeholders.length; i++) {
          var filterData = this.stakeholderPossibleList.filter(x => x.email == this.stakeholders[i].email)
          if (filterData.length == 0 && this.invalidStakeholder == false) {
            this.invalidStakeholder = true;
          }
        }
      }
    );
  }

  onExtraButtonClick(action: any) {
    if (action.action === "add-stakeholder") {
      this.newStakeholderPosition = action.index;
      this.openAddStakeholderPanel()
    }
  }
  // END | PBI-168948

  getManagerSuggestions(isExistPM = false) {
    if (!this.userData) {
      return;
    }

    let orgId =
      this.DTOService.getParentType() === 'edit'
        ? this.projectValues.organizationId
        : this.userData.organization;

    let productId = this.DTOService.getParentType() === 'edit'
      ? this.projectValues.productId
      : 0;

    this.isError = false;
    this.isProjectManagerError = false;
    if (productId == 0) {
      this.projectsService.getProjectsByOrgId(orgId).subscribe(
        (response) => {
          this.userSuggestion = response;
          if (typeof response["filter"] === "function") {
            this.userSuggestionCopy = response["filter"](_ => true);
          }
          if (isExistPM) {
            this.projectForm.controls['projectManager'].setValue(
              this.selectedPMName
            );
          }

          const projectManagerName = this.projectForm.get('projectManager').value;
          let fiservImplementationManagerId =
            this.projectsService.fetchIdForRequest(
              this.userSuggestion,
              projectManagerName
            );

          if (fiservImplementationManagerId == -1 && projectManagerName) {
            this.isError = true;
            this.isProjectManagerError = true;
            this.errorMsg = 'User does not exist. Please add a valid Fiserv Implementation Manager';
            return;
          }

          if (!projectManagerName) {
            this.PMEmail = '';
            return;
          }

          this.PMEmail = this.projectsService.fetchEmail(
            this.userSuggestion,
            projectManagerName
          );
        },
        (error) => {
          (this.isError = true), (this.errorMsg = error);
        }
      );
    }
      
    else {
      this.getManagerSuggestionsByProductId(orgId, productId, isExistPM);
    }
  }

  getManagerSuggestionsByProductId(orgId: number, productId: number, isExistPM = false) {

    this.projectsService.getProjectsByProductId(orgId, productId).subscribe(
      (response) => {
        this.userSuggestion = response;
        if (typeof response["filter"] === "function") {
          this.userSuggestionCopy = response["filter"](_ => true);
        }
        if (isExistPM) {
          this.projectForm.controls['projectManager'].setValue(
            this.selectedPMName
          );
        }

        const projectManagerName = this.projectForm.get('projectManager').value;
        let fiservImplementationManagerId =
          this.projectsService.fetchIdForRequest(
            this.userSuggestion,
            projectManagerName
          );

        if (fiservImplementationManagerId == -1 && projectManagerName) {
          this.isError = true;
          this.isProjectManagerError = true;
          this.errorMsg = 'User does not exist. Please add a valid Fiserv Implementation Manager';
          return;
        }

        if (!projectManagerName) {
          this.PMEmail = '';
          return;
        }

        this.PMEmail = this.projectsService.fetchEmail(
          this.userSuggestion,
          projectManagerName
        );
      },
      (error) => {
        (this.isError = true), (this.errorMsg = error);
      }
    );
  }

  typeaheadLoadingProducts(searchValue: string) {
    console.log(this.projectForm)
    if (this.projectForm.controls.productName.valid) {
      this.getProductSuggestions(searchValue);
    }
  }

  getProductSuggestions(searchValue: string = '') {
    let params = [];
    if (searchValue && searchValue.length >= 3) {
      params.push({ paramName: "title", value: searchValue });
      this.projectsService.getProductSuggestions("products", params, 100).subscribe(
        data => {
          this.isError = false;
          this.productSuggestion = data;
        },
        error => { this.isError = true, this.errorMsg = error }
      );
    }
    else {
      this.projectsService.getProductSuggestions("products", null, 100).subscribe(
        data => {
          this.isError = false;
          this.productSuggestion = data;
        },
        error => { this.isError = true, this.errorMsg = error }
      );
    }
  }

  getProjectSuggestions() {
    this.projectsService.getSuggestions("projects", this.userData.clientOrgId, "orgId")
      .subscribe(suggestions => {
        this.projectSuggestion = suggestions as any;
        this.canCopyProject = this.projectSuggestion && this.projectSuggestion.length > 0;
      },
        error => { this.isError = true; this.errorMsg = error; });
  }

  initQueryParams() {
    this.queryParam = Object.assign(
      {},
      {
        pageNumber: this.currentPageNumber,
        pageSize: environment.pageSize,
      }
    );
  }

  validateDates(startDate, endDate) {
    let validDate = true
    let compareStartDate
    if (startDate != undefined) {
      compareStartDate = new Date(startDate)
    } else {
      compareStartDate = new Date(new Date().toLocaleDateString().replace(/[/]/g, '-'))
    }
    let compareEndDate = new Date(endDate)
    if (compareEndDate < compareStartDate) {
      validDate = false
    }
    return validDate
  }

  saveAndContinueProjectForm() {
    let validDate = this.projectForm.controls['projectClosureDate'];
    this.isSubmitted = true;

    let ProductName = this.projectForm.controls['productName'].value;
    if (ProductName == '' || ProductName == null) {
      ProductName.setErrors({ invalid: true });
      return false;
    }

    let validate = this.validateDates(this.projectValues?.startDate, validDate.value)
    if (!validate) {
      validDate.setErrors({ invalid: true });
      return false;
    }
    if (this.addProjectStakeholder.hasDuplicates || this.invalidStakeholder) {
      return false;
    }

    let productId =
      this.projectForm.value.productName &&
      this.projectsService.fetchIdForRequest(
        this.productSuggestion,
        this.projectForm.value.productName
      );

    let fiservImplementationManagerId = !this.isFIMCreate
      ? this.userService.getUserListData().id
      : this.projectsService.fetchIdForRequest(
        this.userSuggestion,
        this.projectForm.value.projectManager
      );

    //if (this.projectForm.value.copyProject === '1') {
    //  fiservImplementationManagerId = this.selectCopyProject.fiservImplementationManagerId;
    //}

    let projValues = this.projectForm.value;
    if (this.DTOService.getParentType() === 'edit' || this.parentURL === 'edit') {
      this.projParam = Object.assign({
        id: this.projectValues?.id || '',
        title: projValues['projectName'],
        description: projValues['description'],
        organizationId:
          this.projectValues?.organizationId || this.userData.organization,
        productId: productId || '',
        startDate: this.projectValues?.startDate,
        fiservImplementationManagerId: fiservImplementationManagerId || '',
        rowVersion: this.projectValues?.rowVersion || '',
        clientId: this.projectValues?.clientId || '',
        clientImplementationManagerId:
          this.projectValues?.clientImplementationManagerId || '',
        statusId: this.projectValues?.statusId || '',
        sameForms: this.projectForm.value.sameForms == '1' ? true : false,
      });
    } else {
      this.projParam = Object.assign({
        id: this.projectValues?.id || '',
        title: projValues['projectName'],
        description: projValues['description'],
        organizationId:
          this.projectValues?.organizationId || this.userData.organization,
        productId: productId || '',
        startDate: new Date().toISOString(),
        fiservImplementationManagerId: fiservImplementationManagerId || '',
        rowVersion: this.projectValues?.rowVersion || '',
        clientId: this.projectValues?.clientId || '',
        clientImplementationManagerId:
          this.projectValues?.clientImplementationManagerId || '',
        statusId: this.projectValues?.statusId || '',
        sameForms: this.projectForm.value.sameForms == '1' ? true : false,
      });
    }

    if (this.projectForm.value.projectClosureDate) {
      this.projParam.dueDate = this.projectForm.value.projectClosureDate;
    }

    /********************EDIT BEGIN*************************/
    if (this.DTOService.getParentType() === 'edit' || this.parentURL === 'edit') {
      this.projectsService.updateProject(this.projectValues.id, this.projParam)
        .pipe(
          flatMap(projects => {
            this.isError = false;
            this.createdProject = projects;
            const stakeholders: IInputStakeholder[] = [];
            const stakeholderList = this.stakeholderControlData.value.projectStakeholder.projectStakeholder;
            if (stakeholderList) {
              stakeholderList.forEach(value => {
                if (value.id >= 1 && value.emails !== "" && value.name !== "") {
                  stakeholders.push({
                    projectId: this.createdProject?.id,
                    userId: value.id,
                    role: "stakeholder"
                  });
                }
              });
            }

            //when FIM changes
            if (+this.userData.id === +this.projectValues.fiservImplementationManagerId && (this.projectValues.fiservImplementationManagerName !== this.projectForm.value.projectManager)) {
              if (!this.createdProject || !this.createdProject.id) {
                this.loggingService.logException(new Error("242002 - CreatedProject is null or empty when FIM changes, " + JSON.stringify(this.createdProject)));
              }
              this.DTOService.setProjectFacts(this.createdProject);
              this.DTOService.setChangedProjectManager(`Project details has been updated.`);
              //this.router.navigate(['/projects/list']);
              this.pendingNavigationRoute = '/projects/list';
              return this.projectsService.setStakeholders(this.projectValues.id, stakeholders, productId);
            }

            else if (this.clientAddress !== null) {
              let updatedData = Object.assign(this.clientAddress, this.createdProject);
              if (!updatedData || !updatedData.id) {
                this.loggingService.logException(new Error("242002 - UpdatedData is null or empty when client address not null, " + JSON.stringify(updatedData)));
              }
              this.DTOService.setProjectFacts(updatedData);
            }
            else {
              if (!this.createdProject || !this.createdProject.id) {
                this.loggingService.logException(new Error("242002 - CreatedProject is null or empty, " + + JSON.stringify(this.createdProject)));
              }
              this.DTOService.setProjectFacts(this.createdProject);
            }
            return this.projectsService.setStakeholders(this.projectValues.id, stakeholders, productId);
          })
        ).subscribe(() => {
          this.DTOService.setEditProjectStatus(true);
          this.DTOService.setOldProjectFacts(this.projectValues);
          if (this.pendingNavigationRoute === "") {
            this.router.navigate(['/projects/edit/clientinfo']);
          } else {
            this.router.navigate([this.pendingNavigationRoute]);
          }
        },
          error => { this.isError = true, this.errorMsg = error });
    }
    /********************EDIT END*************************/

    else {
      if (this.stakeholderControlData.value.projectStakeholder.projectStakeholder[0].id !== '' && this.stakeholderControlData.value.projectStakeholder.projectStakeholder[0].id !== null) {

        this.projectsService.createProject(this.projParam)
          .pipe(
            flatMap(project => {
              this.isError = false;
              this.createdProject = project;
              if (!this.createdProject || !this.createdProject.id) {
                this.loggingService.logException(new Error("242002 - CreatedProject is null or empty when parent type is not edit, " + + JSON.stringify(this.createdProject)));
              }
              this.DTOService.setProjectFacts(this.createdProject);
              this.DTOService.setProjectResponse(this.createdProject.title);
              const stakeholders: IInputStakeholder[] = [];
              const stakeholderList = this.stakeholderControlData.value.projectStakeholder.projectStakeholder;
              stakeholderList.forEach(value => {
                if (value.id >= 1 && value.emails !== "" && value.name !== "") {
                  stakeholders.push({
                    projectId: this.createdProject?.id,
                    userId: value.id,
                    role: "stakeholder"
                  });
                }
              });

              return this.projectsService.addStakeholders(stakeholders, productId);
            })
          ).subscribe(() => {
            this.router.navigate(['/projects/create/clientinfo']);
          }, error => {
            this.isError = true;
            this.errorMsg = error;
          }
          );
      }
      else {
        this.projectsService.createProject(this.projParam)
          .subscribe(project => {
            this.isError = false;
            this.createdProject = project;
            if (!this.createdProject || !this.createdProject.id) {
              this.loggingService.logException(new Error("242002 - CreatedProject is null or empty when parent type is not edit, " + + JSON.stringify(this.createdProject)));
            }
            this.DTOService.setProjectFacts(this.createdProject);
            this.DTOService.setProjectResponse(this.createdProject.title);
            this.router.navigate(['/projects/create/clientinfo']);
          }, error => {
            this.isError = true;
            this.errorMsg = error;
          }
          );
      }
    }
  }

  selectPM(event) {
    this.isPMDiffOrg = false;
    this.isPMDiffProduct = false;
    this.isPMNotAllowRole = false;
    this.isPMNotFiserv = false;
    this.isPMDisabled = false;
    this.isProductDisabled = false;
    this.isProductUnavailable = false;
    if (event.item) {
      this.selectedPMName = event.item.name;
      this.formsStakeholderDetails.usersData = {
        data: this.stakeholderPossibleList.filter(x => x.email != event.item.email)
      }
      this.PMEmail = event.item.email;
      this.isError = false;
      this.isProjectManagerError = false; //this.checkPmIsStakeholder();
    }
  }

  cancelForm() {
    if (this.DTOService.getParentType() === 'edit') {
      this.router.navigate(['/projects/view', this.projectValues.id]);
    } else this.router.navigate(['/projects/list']);
  }

  /********************DRAWER CHANGES BEGIN*************************/
  openAddUserDrawer() {
    this.loadDrawer = true;
    $('#containerPage').scrollTop(0);
    $('.container-page').addClass('overflow-hidden');
  }
  ngAfterViewInit() {
    this.createUserAllowed = this.permissionService.isCreateUserAllowed();
    if (this.createUserAllowed) {
      this.subscribeDrawerService();
      this.subscribeDrawerServiceStakeHolder();
    }
  }
  private async subscribeDrawerService() {
    this.drawerService.closeDrawer$
      .pipe(
        takeUntil(this.destroySubject)
      )
      .subscribe(postData => {
        if (postData && this.loadDrawer) {
          this.loadDrawer = false;
          if (postData['submitPressed']) {
            this.successMsg = `Project Manager has been added successfully.`;
            this.showSuccessMsg = true;
            this.getManagerSuggestions(true);
            if (postData['entityEmail']) this.PMEmail = postData['entityEmail'];

            if (postData['entityName']) {
              this.selectedPMName = postData['entityName'];
            }
          }
        }
      });
  }
  ngOnDestroy() {
    this.isError = false;
    this.isProjectManagerError = false;
    this.drawerService.clear();
    this.destroySubject.next();
    this.destroySubject.complete();
    this.destroyDrawerStakeholderSubject.next();
    this.destroyDrawerStakeholderSubject.complete();
  }
  /********************DRAWER CHANGES END*************************/

  // START | PBI-168948

  handleDuplicates(event) {
    console.log(event)
    this.duplicatedStakeholder = event.hasDuplicates;
  }

  formInitialized(name: string, form: any) {
    this.stakeholderControlData.setControl(name, form);
    const fieldCtrl = form.controls[name];
    fieldCtrl.setValidators(this.stakeholdersDuplicateValidator());
    fieldCtrl.updateValueAndValidity();
  }

  handleRespondentSelected(event: any) {
    if (typeof this.userSuggestionCopy["filter"] === "function") {
      const emailList = [];
      const stakeholderList = this.stakeholderControlData.value.projectStakeholder.projectStakeholder;
      for (let i = 0; i < stakeholderList.length; i++) {
        emailList.push(stakeholderList[i].emails);
      }
      this.userSuggestion = this.userSuggestionCopy["filter"](x => !emailList.includes(x.email));
    }
    //this.checkStakeholderIsPM();
    this.invalidStakeholder = false;
  }

  stakeholdersDuplicateValidator(): ValidationErrors {
    const self = this;
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      self.stakeholderSameAsPm = false;
      const values = control as FormArray;
      for (const user of values.value) {
        if (user.emails === "") continue;
        self.stakeholderSameAsPm = (user.emails !== "" && self.PMEmail !== "" && user.emails === self.PMEmail);
        if (self.stakeholderSameAsPm) {
          break;
        }
      }
      return { "pm": self.stakeholderSameAsPm };
    }
  }

  checkStakeholderIsPM() {
    let toRemove = -1;
    const stakeholderList = this.stakeholderControlData.value.projectStakeholder.projectStakeholder;
    for (let i = 0; i < stakeholderList.length; i++) {
      const entry = stakeholderList[i];
      if (entry.emails === this.PMEmail) {
        toRemove = i;
        break;
      }
    }
    if (toRemove >= 0) {
      this.addProjectStakeholder.removeSkill(toRemove);
    }
    return toRemove >= 0;
  }

  checkPmIsStakeholder() {
    const stakeholderList = this.stakeholderControlData.value.projectStakeholder.projectStakeholder;
    for (let i = 0; i < stakeholderList.length; i++) {
      const entry = stakeholderList[i];
      if (entry.emails === this.PMEmail) {
        this.projectForm.get('projectManager').reset();
        return true;
      }
    }
    return false;
  }

  openAddStakeholderPanel() {
    this.loadDrawerStakeholder = true;
    $('#containerPage').scrollTop(0);
    $('.container-page').addClass('overflow-hidden');
  }

  private async subscribeDrawerServiceStakeHolder() {
    this.drawerService.closeDrawer$
      .pipe(
        takeUntil(this.destroyDrawerStakeholderSubject)
      )
      .subscribe(postData => {
        if (postData && this.loadDrawerStakeholder) {
          this.loadDrawerStakeholder = false;
          if (postData['submitPressed']) {
            this.successMsg = `Project Stakeholder has been added successfully.`;
            this.showSuccessMsg = true;
            this.getClients(this.userData.organization);
            if (postData['entityEmail'] && postData['entityName']) {
              this.addProjectStakeholder.addElements({
                name: postData['entityName'],
                emails: postData['entityEmail'],
                id: postData['entityId']
              });
            }
          }
        }
      });
  }

  // END | PBI-168948

  /*****************COPY PROJECT CHANGES BEGIN********************/
  showProjects() {

    this.isPMDiffOrg = false;
    this.isPMDiffProduct = false;
    this.isPMNotAllowRole = false;
    this.isPMNotFiserv = false;
    this.isPMDisabled = false;
    this.isProductDisabled = false;
    this.isProductUnavailable = false;
    if (this.projectForm.value.copyProject === '1') {
      if (this.projectList.projects.length === 0) {
        this.PMEmail = '';
        this.callProjectListService();
      }
      this.showExistingProjects = true;
    } else if (this.projectForm.value.copyProject === '0') {
      this.showExistingProjects = false;
    }
    this.resetFields();
    if (!this.isFIMCreate) {
      this.projectForm.patchValue({
        projectManager: this.userService.getUserListData().name,
      });
      this.PMEmail = this.userService.getUserListData().email;
    }
  }
  onProductClick(event: any) {
    debugger;
    let productId =
      this.projectForm.value.productName &&
      this.projectsService.fetchIdForRequest(
        this.productSuggestion,
        this.projectForm.value.productName
      );
    var currentUser = this.userService.getUserListData();
    this.getStakeHolders(currentUser.organization, productId,false);
    this.getManagerSuggestionsByProductId(currentUser.organization, productId);
    this.isProductUnavailable = false;
    if (!this.isImplementationManager) {
      this.projectForm.controls['projectManager'].setValue(
        ""
      );
    }
    
    this.stakeholderControlData.controls['projectStakeholder'].reset();
  }

  onCopyProjectClick(event: any) {
    this.isPMDiffOrg = false;
    this.isPMDiffProduct = false;
    this.isPMNotAllowRole = false;
    this.isPMNotFiserv = false;
    this.isProductDisabled = false;
    this.isProductUnavailable = false;
    this.addProjectStakeholder.resetForm();
    this.selectCopyProjectId = event.item.id;
    var currentUser = this.userService.getUserListData();

    forkJoin([this.projectsService.getProjectById(this.selectCopyProjectId), this.projectsService.getStakeholders(this.selectCopyProjectId)])
      .pipe(take(1))
      .subscribe(
        (result) => {
          const project = result[0];
          var selectProjectStakeholders = result[1];
          console.log("project", project);
          this.isError = false;
          this.selectCopyProject = project;

          this.projectsService.setProjectDetails(this.selectCopyProject);
          //this.projectForm.controls['projectName'].setValue(this.selectCopyProject.title);
          this.projectForm.controls['description'].setValue(
            this.selectCopyProject.description
          );
          this.userService.getUserById(this.selectCopyProject.fiservImplementationManagerId).subscribe(manager => {
            let isImplementationManager = false;
            let isAdmin = false;
            let isSameOrg = false;
            if (manager.organizationTitle === this.selectCopyProject.organizationTitle) {
              isSameOrg = true;
            } else {
              this.isPMDiffOrg = true;
            }
            if (manager.type !== "Fiserv") {
              this.isPMNotFiserv = true;
            }
            manager.roles.forEach(role => {
              if (role.roleTitle === "Fiserv Implementation Manager") {
                isImplementationManager = true;
              }
              if (role.roleTitle === "Admin") {
                isAdmin = true;
              }
            });
            if (!isImplementationManager && !isAdmin) {
              this.isPMNotAllowRole = true;
            }
            if ((manager.type === "Fiserv" && isAdmin) || (manager.type === "Fiserv" && !this.isImplementationManager && isImplementationManager  && isSameOrg)) {
              this.projectForm.controls['projectManager'].setValue(
                this.selectCopyProject.fiservImplementationManagerName
              );
              this.hasPM = true;

              this.PMEmail = this.PMEmail = this.selectCopyProject.fiservImplementationManagerEmail;
              //this.projectForm.controls['projectClosureDate'].setValue(this.selectCopyProject.dueDate);
              this.formsStakeholderDetails.usersData = {
                data: this.stakeholderPossibleList.filter(x => x.email != this.selectCopyProject.fiservImplementationManagerEmail)
              }
            }
            else if (selectProjectStakeholders != null && selectProjectStakeholders.length > 0) {
              var isAtStake = selectProjectStakeholders.findIndex(s => s.userId.toString() == currentUser.id) > -1;
              if (isAtStake && currentUser.type == 'Fiserv' && currentUser.roles.findIndex(r => r.roleTitle == "Fiserv Implementation Manager") > -1) {
                this.projectForm.controls['projectManager'].setValue(currentUser.name);
                this.PMEmail = this.PMEmail = currentUser.email;
                this.hasPM = true;
                this.formsStakeholderDetails.usersData = {
                  data: this.stakeholderPossibleList.filter(x => x.email != this.selectCopyProject.fiservImplementationManagerEmail)
                }
              }              
            }
            else {
              if (!this.isImplementationManager) {
                this.projectForm.controls['projectManager'].setValue(
                  ""
                );
              }
              this.stakeholderControlData.controls['projectStakeholder'].reset();
            }
          });

          this.userService.getUserByName(this.selectCopyProject.fiservImplementationManagerName).subscribe(managers => {
            if (managers.users.length > 0) {
              if (managers.users[0].isActive === false) {
                this.isPMDisabled = true;
              }
            }
          });


          if (this.selectCopyProject.productId == -1 || this.selectCopyProject.productId == null) {
            this.isProductDisabled = true;
            this.projectForm.controls['productName'].setValue(
              ""
            );
            if (!this.isImplementationManager) {
              this.projectForm.controls['projectManager'].setValue(
                ""
              );
            }
          }
          else if (!currentUser.isAdmin && currentUser.products.filter(x => x == this.selectCopyProject.productId).length == 0 ) {
            this.isProductUnavailable = true;
            this.isPMDiffProduct = true;
            this.projectForm.controls['productName'].setValue(
              ""
            );
            if (!this.isImplementationManager) {
              this.projectForm.controls['projectManager'].setValue(
                ""
              );
            }
          }
          else {
            debugger;
            this.isProductUnavailable = false;
            this.isPMDiffProduct = false;
            this.projectForm.controls['productName'].setValue(
              this.selectCopyProject.productTitle
            );
            this.getManagerSuggestionsByProductId(currentUser.organization, this.selectCopyProject.productId);
            this.getStakeHolders(currentUser.organization, this.selectCopyProject.productId, this.hasPM);            
          }

          //this.PMEmail = this.selectCopyProject.fiservImplementationManagerEmail;
          this.projectForm.controls['sameForms'].setValue(
            this.selectCopyProject.sameForms ? '1' : '0'
          );
        },
        (error) => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
  }

  resetFields() {
    this.projectForm.controls['copyProjectName'].setValue('');
    this.projectForm.controls['productName'].setValue('');
    this.projectForm.controls['projectName'].setValue('');
    this.projectForm.controls['description'].setValue('');
    this.projectForm.controls['projectManager'].setValue('');
    this.projectForm.controls['projectClosureDate'].setValue('');
    this.stakeholderControlData.reset();
    this.PMEmail = '';
    this.projectForm.controls['sameForms'].setValue('0');
    this.addProjectStakeholder.resetForm();
  }

  callProjectListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.projectsService
      .getProjectsByUserToCopyProject()
      .pipe(take(1))
      .subscribe(
        (projects) => {
          this.isError = false;
          this.projectList = projects.body as IProjects;
          this.projectList.projects = this.projectList.projects.filter(
            (project) => project.organizationId === this.userData.organization
          );
          $('#refreshTask').removeClass('fa-rotation');
        },
        (error) => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
  }

  projectTitleChange() {
    console.log(this.projectForm);
  }
  /*****************COPY PROJECT CHANGES END********************/
}
