<div class="heading-container fdl-2">
    <h1>User Delegate</h1>
    <button type="button" *ngIf="isCreateAllowed" class="btn fdl-btn btn-primary-orange" (click)="openDelegatePopup(true);">
        <span class="material-icons">add</span>
        Select users from/to Delegate
    </button>
</div>


<div class="main-container-sub responsive-container col-settings-drawer-alignment">
    <div class="form-container">
        <div class="row">
            <section class="col-lg-12 p-0">
                <div class="alert alert-dismissible alert-danger" *ngIf="isError">
                    <button type="button" class="close" data-dismiss="alert" aria-label="close"
                        (click)="isError=false">×</button>
                    <i class="material-icons">warning</i>
                    <p [innerHtml]="errorMsg"></p>
                </div>


                <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
                    <button type="button" class="close" data-dismiss="alert" aria-label="close"
                        (click)="successMsg=''">×</button>
                    <span class="iconLabel" aria-label="sucess"></span>
                    <i class="material-icons" aria-hidden="true">check_circle</i>
                    <p>
                        {{successMsg}}

                    </p>
                </div>

                <div class="alert alert-success alert-dismissible" *ngIf="deletedMsg">
                    <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
                    <span class="iconLabel" aria-label="sucess">
                    </span>
                    <i class="material-icons" aria-hidden="true">check_circle</i>
                    <p>
                        {{deletedMsg}}

                    </p>
                </div>

                <form class="form-border mb-3 box collapse-view" [formGroup]="filterForm" autocomplete="off">
                    <h2 class="h4 collapse-arrow" [ngClass]="[filterExists ? '':'collapsed']" data-toggle="collapse"
                        href="#collapseFilter">Search</h2>
                    <div class="filter-form-container filter-form-container-column2 search-filter collapse"
                        [ngClass]="[filterExists ? 'show':'']" id="collapseFilter">


                        <!-- <div class="filter-form-control pr-4 typeahead-wrapper"
                            [ngClass]="{'has-error': !filterForm.get('userId').valid && (filterForm.get('userId').touched || filterForm.get('userId').dirty  || isSubmitted)}">
                            <div class="has-error">
                                <span
                                    *ngIf="!filterForm.get('userId').valid && filterForm.get('userId').errors?.pattern && (filterForm.get('userId').touched || filterForm.get('userId').dirty  || isSubmitted)">
                                    <i class="material-icons">warning</i> Please enter a valid ID </span>
                            </div>
                            <fdl-type-ahead class="filter-typeahead" label="User ID:" formControlName="userId"
                                [collection]="userIdSuggestion" optionField="id" placeholder="Type to search"
                                [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                            </fdl-type-ahead>
                        </div> -->

                        <div class="filter-form-control pr-4 typeahead-wrapper"
                            [ngClass]="{'has-error': !filterForm.get('name').valid && (filterForm.get('name').touched || filterForm.get('name').dirty || isSubmitted)}">
                            <div class="has-error">
                                <span
                                    *ngIf="!filterForm.get('name').valid && filterForm.get('name').errors?.pattern && (filterForm.get('name').touched || filterForm.get('name').dirty || isSubmitted)">
                                    <i class="material-icons">warning</i> Please enter a valid Name</span>
                            </div>
                            <fdl-type-ahead class="filter-typeahead" label="Name:" formControlName="name"
                                [collection]="userSuggestion" optionField="name" placeholder="Type to search"
                                [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                            </fdl-type-ahead>
                        </div>

                        <div class="filter-form-control pr-4 typeahead-wrapper"
                            [ngClass]="{'has-error': !filterForm.get('email').valid && (filterForm.get('email').touched || filterForm.get('email').dirty  || isSubmitted)}">
                            <div class="has-error">
                                <span
                                    *ngIf="!filterForm.get('email').valid && filterForm.get('email').errors?.pattern && (filterForm.get('email').touched || filterForm.get('email').dirty  || isSubmitted)">
                                    <i class="material-icons">warning</i> Please enter a valid Email </span>
                            </div>
                            <fdl-type-ahead class="filter-typeahead" label="Email:" formControlName="email"
                                [collection]="emailSuggestion" optionField="email" placeholder="Type to search"
                                [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                            </fdl-type-ahead>
                        </div>


                        <div class="buttom-wrapper">
                            <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search"
                                (click)="filterList()">Search</button>
                        </div>
                        <div class="ml-2 buttom-wrapper" *ngIf="filtered">
                            <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Reset"
                                (click)="clearFilterList()">Reset</button>
                        </div>
                    </div>
                </form>

                <div class="data-table-container">
                    <div class="justify-content-between d-flex">
                        <div class="headline-container">
                            <h2 class="h4">From Delegate List</h2>
                            <p *ngIf="pagerObject">Total {{pagerObject.totalCount}} Items</p>
                        </div>

                        <div class="d-flex">
                            <div class="refresh-icon-link mt-1">
                                <!-- add class of 'fa-rotation' on click of the refresh icon -->
                                <a title="Refresh Project List" id="refreshTask" (click)="refresh()">
                                    <i aria-hidden="true" class="fa fa-refresh"></i>
                                </a>
                            </div>
                        </div>

                    </div>

                    <div class="table-responsive form-responces s-overflow-auto">
                        <div>
                            <app-data-table *ngIf="delegateList" [rows]="delegateList" [keys]="keys"
                                [displayColumns]="displayColumns" (buttonClicked)="navigate($event)"
                                (deleteBtnClicked)="navigate($event)" (sortingClicked)="applySorting($event)"
                                [showCheckbox]="false" type="CommitmentDelegate"
                                [listname]="listName">
                            </app-data-table>

                            
                        </div>

                        <div *ngIf="pagerObject">
                            <app-pagination
                                *ngIf="pagerObject && pagerObject.totalPages > 1 && delegateList && delegateList.length > 0"
                                [pagerObject]="pagerObject" (pagingChanged)="changePage($event)"></app-pagination>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

<app-commitment-delegate-select-popup #commitmentSelectPopup (successDelegate)="successDelegateClick($event)" [delegateFormData]="userDelegate" [userSuggestions]="userSuggestion" [mode]="'delegate'"></app-commitment-delegate-select-popup>

  <!-- Delete Modal -->
  <div class="modal fade bd-example-modal-lg" id="deleteDelegateConfirmModal" tabindex="-1" role="dialog" aria-hidden="true" #content>
    <div class="modal-dialog modal-lg mt-120" role="document" style="max-width: 700px;">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Delete delegate
          </h4>
        </div>
        <div class="modal-body">
          
            Are you sure you want to delete this record?
        </div>
        <div class="modal-footer fdl-2">
          <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal">
            Close
          </button>
          <button *ngIf="isDeleteAllowed" type="button" class=" btn fdl-btn btn-primary-orange mr-2" (click)="doDeleteDelegate()">
            Delete
          </button>
        </div>
      </div>
    </div>
  