<div [formGroup]="commitmentForm" class="col">
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Product Name</th>
                    <th scope="col">eCRM Opportunity ID </th>
                    <th scope="col">eCRM Opportunity TCV</th>
                    <th scope="col">eCRM Opportunity ACV</th>
                    <th scope="col">BU(s)</th>
                    <th scope="col">Platform</th>
                    <th scope="col">Commitment Type</th>
                    <th scope="col">FAP</th>
                    <th scope="col">Obligation Type</th>
                    <th scope="col">Client Requested Commitment Date</th>
                    <th scope="col">Executive Leader Name</th>
                    <th scope="col">Executive Leader Email</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <!-- <td>{{commitmentForm.value.productName}}</td>
                    <td>{{commitmentForm.value.ecrmOppId}}</td>
                    <td>{{commitmentForm.value.ecrmOppTCV}}</td>
                    <td>{{commitmentForm.value.ecrmOppACV}}</td>
                    <td>{{commitmentForm.value.buName}}</td>
                    <td>{{commitmentForm.value.platform}}</td>
                    <td>{{commitmentForm.value.fiservAccountProcessor}}</td>
                    <td>{{commitmentForm.value.commitmentType}}</td>
                    <td>{{commitmentForm.value.obligationType}}</td>
                    <td>{{commitmentForm.value.clientRequestedCommitmentDate}}</td>
                    <td>@{{commitmentForm.value.execLeaderName}}</td>
                    <td>{{commitmentForm.value.execLeaderEmail}}</td> -->
                </tr>
            </tbody>
        </table>
    </div>
</div>

<br>