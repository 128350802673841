<div class="modal fade bd-example-modal-md" id="delegateForms" data-backdrop="static" data-keyboard="false"
  tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-md" role="document" style="max-width: 1210px;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">
          <span *ngIf='isDelegate'>User Delegate</span>
        </h4>
        <button type="button" class="close" data-dismiss="modal" (click)="cancelDelegateForm()"
          aria-label="Close popup"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body" style="max-height: 612px; overflow: auto;">
        <div class="alert alert-dismissible alert-danger" *ngIf="isError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
          <i class="material-icons">warning</i>
          <p>{{errMsg}}</p>
        </div>
        <form [formGroup]="delegateForm" autocomplete="off">

          <div class="row">
            <div *ngIf='isReady' class="col-md-6 typeahead-wrapper"
              [ngClass]="{'has-error': !delegateForm.get('respondentName').valid && isClicked}">

              <div [ngClass]="{'has-error': ( isDuplicate ) }">
                <app-commitment-delegate-details cssWidth="413px" elementName="respondentName" #commitmentDelegateDetailsComponent buttonText="Add To RM/AM/SE/AE"
                  (formReady)="formInitializedDelegate('respondentName',0 ,$event)"
                  [isFdl]="true" [fdlData]="respondentUsers2" [fieldJson]="fieldformApproverJson"
                  [fieldDetails]="filedformApproverDetails" [formControlData]="delegateForm" [isCreate]="isCreate"  [isReadOnlyMode]="isReadOnly"></app-commitment-delegate-details>

                <span *ngIf="isDuplicate">
                  <i class="material-icons">warning</i>Duplicate respondent not allowed.
                </span>
              </div>

              <!-- <div [ngClass]="{'has-error': ( isDuplicate ) }" *ngIf='isSurvey'>
                <app-mutipleadd #addSurvey buttonText="Add Respondent" [isAddButtonEnable]="true"
                  (formReady)="formInitializedDelegate('guest',0 ,$event)" [isFdl]="false" [fdlData]="respondentUsers2"
                  [filedJson]="fieldformSurveyJson" elementName="guest" [filedDetails]="filedformSurveyDetails"
                  [formControlData]="delegateForm"></app-mutipleadd>
                <span *ngIf="isDuplicate">
                  <i class="material-icons">warning</i>Duplicate respondent not allowed.
                </span>
              </div> -->
            </div>

          </div>
          <!-- <div class="row mt-2">
            <div class="col-md-6"
              [ngClass]="{'has-error': !delegateForm.get('dueDate').valid && (delegateForm.get('dueDate').touched || isClicked)}">
              <label class="asterisk" for="dueDate">Expire Date:</label>
              <input type="date" class="form-control" [min]="todaysdate" formControlName="dueDate" id="dueDate">
              <span
                *ngIf="!(checkDate(delegateForm.get('dueDate')) && delegateForm.get('dueDate').valid) || isClicked ">
                <i class="material-icons">warning</i> Please enter a valid future date.
              </span>
            </div>
          </div> -->
          <div class="row mt-4">
            <div class="col-md-12"
              [ngClass]="{'has-error': !delegateForm.get('comments').valid && (delegateForm.get('comments').touched || delegateForm.get('comments').dirty || isClicked)}">
              <label for="comments" class="asterisk">Comments:</label>
              <textarea [ngStyle]="{'pointer-events': isReadOnly ?  'none':'initial'}" class="form-control" id="comments" formControlName="comments"></textarea>
              <span
                *ngIf="!delegateForm.get('comments').valid && (delegateForm.get('comments').errors?.required || delegateForm.get('comments').errors?.validateWhiteSpaces) && isClicked">
                <i class="material-icons">warning</i> Please enter comments.</span>
              <span
                *ngIf="!delegateForm.get('comments').valid && delegateForm.get('comments').errors?.maxlength && (delegateForm.get('comments').touched || isClicked)">
                <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.Delegate.Comments}},
                please correct it.</span>
              <span
                *ngIf="!delegateForm.get('comments').valid && delegateForm.get('comments').errors?.pattern && (delegateForm.get('comments').touched || delegateForm.get('comments').dirty)">
                <i class="material-icons">warning</i> {{regexValidationMessage.DESCRIPTION_COMMENT_VALIDATION_MESSAGE}}
              </span>
            </div>
          </div>
        </form>

      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal"
          (click)="cancelDelegateForm()">
          Cancel</button>
        <button *ngIf='!isReadOnly' type="button" class=" btn fdl-btn btn-primary-orange ml-2"
          [disabled]="isSubmitted || !isReady && !(isCreate || isEdit) "
          (click)="doDelegate()">
          <span *ngIf='isCreate'>Delegate</span>
          <span *ngIf='isEdit'>Update</span>
        </button>


      </div>
    </div>
  </div>
</div>
