import { Component, OnInit } from '@angular/core';
import { PageTemplateResponseReviewTrailOverallDTO } from './models/page-template-response-review-trail-overall-d-t-o';
import { ActivatedRoute } from '@angular/router';
import { NoteReviewTrailDTO } from './models/note-review-trail-d-t-o';
import { ClientNoteService } from 'src/app/services/client-note.service';
import moment from 'moment';
import { PageTemplateResponseReviewTrailService } from 'src/app/services/page-template-response-review-trail-.service';

@Component({
  selector: 'app-client-review-trail-overall',
  templateUrl: './client-review-trail-overall.component.html',
  styleUrls: ['./client-review-trail-overall.component.css']
})
export class ClientReviewTrailOverallComponent implements OnInit {

  public id = 0;
  public data: Array<PageTemplateResponseReviewTrailOverallDTO> = new Array<PageTemplateResponseReviewTrailOverallDTO>();
  public noteData: Array<NoteReviewTrailDTO> = new Array<NoteReviewTrailDTO>();
  public clientId = 0;
  public clientName = '';
  public noData = false;
  public noteNoData = false;

  constructor(private pageTemplateResponseReviewTrailService: PageTemplateResponseReviewTrailService,
    private clientNoteService: ClientNoteService,
    private activatedRoute: ActivatedRoute) {
    this.clientId = +this.activatedRoute.snapshot.paramMap.get('clientId');
    this.clientName = this.activatedRoute.snapshot.paramMap.get('clientName');
    if (this.clientName) {
      this.clientName = decodeURIComponent(this.clientName);
    }
    if (this.clientId && this.clientId !== 0 && this.clientId !== -1) {
      this.loadData();
      this.loadNoteData();
    }
  }

  ngOnInit(): void {
  }

  private loadData(): void {
    this.pageTemplateResponseReviewTrailService.getPageTemplateResponseReviewTrail(this.clientId)
      .subscribe(result => {
        this.data = result.body as Array<PageTemplateResponseReviewTrailOverallDTO>;
        if (!this.data || this.data.length === 0) {
          this.noData = true;
        } else {
          this.data.forEach(item => {
            item.modifiedOn = moment.utc(item.modifiedOn).local();
          });
        }
      });
  }

  private loadNoteData(): void {
    this.clientNoteService.getClientNoteReviewTrail(this.clientId)
      .subscribe(result => {
        this.noteData = result.body as Array<NoteReviewTrailDTO>;
        if (!this.noteData || this.noteData.length === 0) {
          this.noteNoData = true;
        } else {
          this.noteData.forEach(item => {
            item.createdOn = moment.utc(item.createdOn).local();
          });
        }
      });
  }

  public selectProjectNumberId(id: number): void {
    this.id = id;
  }

  public modalClosed(): void {
    this.id = 0;
  }
}
