import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PageTemplateResponseReviewTrailService {
  private _pageTemplateResponseReviewTrailBaseUrl: string = "PageTemplateResponseReviewTrail";

  constructor(private http: HttpClient) { }

  public getPageTemplateResponseReviewTrail(clientId: number) {
    const options = { 'refresh': 'true' };
    return this.http.get(`${this._pageTemplateResponseReviewTrailBaseUrl}/${clientId}`, { headers: options, observe: 'response' });
  }

  public getPageTemplateResponseReviewTrailDetail(pageTemplateResponseId: number) {
    const options = { 'refresh': 'true' };
    return this.http.get(`${this._pageTemplateResponseReviewTrailBaseUrl}/Detail/${pageTemplateResponseId}`, { headers: options, observe: 'response' });
  }
}