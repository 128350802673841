import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, retry, take } from 'rxjs/operators';
import { MAX_LENGTHS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE, ROLES } from 'src/app/core/constants';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { UserService } from 'src/app/services/user.service';
import { ISuggestion, ISuggestions } from 'src/app/shared/suggestion';
import { IUser } from 'src/app/shared/user';
import { UsersDTOService } from 'src/app/shared/usersDTO.service';
import { ProjectDrawerService } from 'src/app/SharedModule/Components/project-drawer-container/project-drawer.service';
import * as $ from 'jquery';
import { IPager } from 'src/app/shared/pagination';
import { IQuery } from 'src/app/shared/query';
import { Location } from '@angular/common';
import { NoWhitespaceValidator } from 'src/app/shared/no-whitespace.validator';
import { CrossOrganization } from './models/cross-organization';
import { BasicDropdownType } from './models/basic-dropdown-type';
import { PageTemplateAccessType } from './models/page-template-access-type.enum';
import { PageTemplateAccess } from './models/page-template-access';
import { ICreatePageTemplates } from '../../managePages/models/createPageTemplate';
import { PageTemplateService } from 'src/app/services/page-template.service';
import { environment } from 'src/environments/environment';
import { PageTemplateAdminDTO } from './models/page-template-admin-d-t-o';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: [
    './user-form.component.css'
  ]
})
export class UserFormComponent implements OnInit, OnDestroy {

  hasClient = false;
  userForm: FormGroup
  roleForm: FormGroup
  roles: ISuggestion[];
  showDrawer: boolean = false;
  isSubmitted: boolean = false; //For userForm
  isRoleFormSubmitted: boolean = false;  //For roleForm
  selectedRoleIds: number[] = [];
  public selectedBaseRoles: BasicDropdownType[] = [];
  public selectedBaseProducts: Array<any> = [];
  public selectedBaseGroups: Array<any> = [];
  public crossOrganizations: CrossOrganization[] = [];
  public crossOrganizationsForDisplay: CrossOrganization[] = [];
  public showHierarchyDateReadOnly: boolean = false;
  public showHierarchyDateReadOnlyForDisplay: boolean = false;
  public showHierarchyDateReadWrite: boolean = false;
  public showHierarchyDateReadWriteForDisplay: boolean = false;
  public crossOrganizationHasError = false;
  public viewCheckbox: boolean = false;
  public readAllCheckbox: boolean = false;
  public adminCheckbox: boolean = false;
  public groupHasError = false
  public baseProductHasError = false;
  public showPageTemplateDrawer: boolean = false;
  public pageTemplateAccessType = PageTemplateAccessType;
  private pageTemplateTempData: PageTemplateAccess[] = [];
  public pageTemplateDataForDisplay: PageTemplateAccess[] = [];
  public baseOrganizationIsToched = false;
  public isDivisionLeaderSelected = false;
  public isBuLeaderSelected = false;
  public isDeliveryOwnerSelected = false;
  public isDeliveryLeaderSelected = false;
  public isRelationshipManagerSelected = false;
  public isSalesExecutiveSelected = false;
  public isAccountTeamSelected = false;
  public doesBaseOrgHaveRestrictedAdminRole = false;
  public cctAdminRoleHasError = false;
  public basicDropdownTypeRoleList: BasicDropdownType[] = [];
  public pageTemplateList: ICreatePageTemplates = {
    pageTemplates: []
  };

  public currentPageNumberForLoadPageTemplate = 1;
  public pagerObjectForPageTemplate: IPager = {
    totalCount: 0,
    pageSize: 0,
    currentPage: 0,
    totalPages: 0
  };

  public pageTemplateQueryParam: IQuery = Object.assign({}, {
    title: '',
    pageNumber: this.currentPageNumberForLoadPageTemplate,
    pageSize: environment.pageSize
  });

  selectedRoleListForDisplay: ISuggestion[] = []; //to show on UI
  clientSuggestion: ISuggestions;
  FormData: IUser;
  isError: boolean = false;
  errorMsg: string = "";
  isEdit: boolean = false;
  isClone: boolean = false;
  selectedUserType: string = '';
  _maxLength: any;
  organizationDetails: any;
  userData: any;
  isAdmin: boolean = false;
  isFiservUser = true;
  clientText: string;
  selectedClientValue: string = '';

  private isFirstTime = false;

  @Input() drawerData: any;
  isDrawerType: boolean = false;
  hideUserControl: boolean = false;
  readOnlyClientControl: boolean = false;
  organizationSuggestion: ISuggestion[];
  public organizationSuggestionEdited: ISuggestion[] = [];
  isRespondentDeselected: boolean = false;

  showChannelPartnerSelectionOptions = false;
  showGroupSelectionOptions: boolean = false;
  isAdminRoleSelected: boolean = false;
  isRestrictedAdminRoleSelected: boolean = false;
  channelPartnerSuggestions: any;
  selectedChannelPartnerIds: number[] = []; // array of selected channel Partner client ids
  removedChannelPartnerIds: number[] = []; // array of selected channel Partner client ids
  removedProductIds: number[] = [];
  removedGroupIds: number[] = [];
  selectedChannelPartnerForDisplay = []; // array of channel Partner with details to show on UI
  showChannelPartnerDrawer = false;
  isChannelPartnerFormInvalid = false;  // For channel partner
  searchChannelPartnerString: string = '';

  CHANNEL_PARTNER_EMPTY = 'Please assign at least one client.';
  currentPageNumber = 1;
  pagerObject: IPager;
  queryParam: IQuery;

  isCreateNewUserFromDelegateScreen = false;
  delegateParams: any;
  // PBI-239433 Changes
  userADDetails: any;
  userEmail: string = '';
  ADError: boolean = false;
  // PBI-239433 Changes
  invalidOrganizationName: boolean = false;
  invalidClientName: boolean = false;

  regexValidationMessage = REGEX_VALIDATION_MESSAGE;
  selectedClientId: number = 0;
  selectedClientName: string = '';
  nonAdminSelectedRoleListForDisplay: any = []; //to show on UI
  editRoleCounter: number = 0;

  nonAdminSelectedProductListForDisplay: any = []; //to show on UI
  editProductCounter: number = 0;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userDto: UsersDTOService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectsService,
    private drawerService: ProjectDrawerService,
    private permissionService: PermissionsService,
    private pageTemplateService: PageTemplateService,
    private readonly location: Location) { }

  get roleFormArrayCntrls() {
    return this.roleForm.controls.roles;
  }

  ngOnInit(): void {
    this.isEdit = this.userDto._isEditMode;
    this.isClone = this.userDto._isCloneMode;
    this._maxLength = MAX_LENGTHS;
    this.isAdmin = this.permissionService.userData.isAdmin;
    this.organizationDetails = Object.assign({}, this.userService.getUserListData());
    this.userData = Object.assign({}, this.userService.getUserListData());
    this.initQueryParams();
    this.initForm();
    this.getOrganizationSuggestions();
  }

  initUserData() {
    if (this.isEdit || this.isClone) {
      this.FormData = this.userDto.getUserData();

      this.organizationDetails.organizationTitle = this.FormData?.organizationTitle;
      this.isFiservUser = this.FormData?.type === 'Fiserv';
      if (!this.isFiservUser) {
        const organizationForm = this.userForm.get("organization");
        organizationForm.clearValidators();
        organizationForm.updateValueAndValidity();
      }

      if (this.isClone) {
        this.isFirstTime = true;
        this.getSuggestions();
        this.patchCloneForm();
      }
      else {
        this.patchForm();
      }

      this.getRoles();
      this.getChannelPartnerSuggestions();
      this.getGroups();
    }

    this.getProducts(true);

    if (this.drawerData && this.isDrawerType == true) {
      this.initialiseDrawer();
    }
    //if(this.isAdmin)
    // this.showSelectProductOption = this.showFavoriteProduct();

    // Create user from response delegate screen
    this.getDelegateParamsFromUrl();

    if (this.FormData !== null &&
      this.FormData !== undefined &&
      this.FormData.crossOrganizations !== null &&
      this.FormData.crossOrganizations !== undefined &&
      this.FormData.crossOrganizations.length > 0) {
      this.loadCrossOrganizations();
      this.setBaseOrgHasRestrictedAdminRole();
    }

    this.pageTemplateDataForDisplay = [];
    if (this.FormData !== null &&
      this.FormData !== undefined &&
      this.FormData.pageTemplateAdminList !== null &&
      this.FormData.pageTemplateAdminList !== undefined &&
      this.FormData.pageTemplateAdminList.length > 0) {
      this.loadPageTemplateAdminList(this.FormData.pageTemplateAdminList, PageTemplateAccessType.Admin);
    }

    if (this.FormData !== null &&
      this.FormData !== undefined &&
      this.FormData.pageTemplateReadAllList !== null &&
      this.FormData.pageTemplateReadAllList !== undefined &&
      this.FormData.pageTemplateReadAllList.length > 0) {
      this.loadPageTemplateAdminList(this.FormData.pageTemplateReadAllList, PageTemplateAccessType.ReadAll);
    }
    if (this.isEdit || this.isClone) {
      this.setCrossOrganization();
      this.crossOrganizations.forEach(data => {
        this.onRolesChangeForCrossOrganization(data);
      });
    }
    this.onOrganizationSuggestionChanged();
  }

  private loadPageTemplateAdminList(pageTemplateAdminList: PageTemplateAdminDTO[], pageTemplateAccessType: PageTemplateAccessType): void {
    pageTemplateAdminList.forEach(item => {
      const data: PageTemplateAccess = {
        id: item.pageTemplateId,
        accessType: pageTemplateAccessType,
        title: item.pageTemplateName
      };
      this.pageTemplateDataForDisplay.push(data);
    });
  }

  private loadCrossOrganizations(): void {
    this.crossOrganizationsForDisplay = [];
    this.FormData.crossOrganizations.forEach(item => {
      const data: CrossOrganization = {
        id: item.organizationId,
        name: item.organizationName,
        roles: item.roles.map(r => {
          const temp: BasicDropdownType = {
            id: r.roleId,
            name: r.roleTitle
          };
          return temp;
        }),
        products: item.products.map(r => {
          const temp: BasicDropdownType = {
            id: r.productId,
            name: r.productTitle
          };
          return temp;
        }),
        productsToSelect: [],
        groups: item.groups.map(r => {
          const temp: BasicDropdownType = {
            id: r.groupId,
            name: r.groupTitle
          };
          return temp;
        }),
        groupsToSelect: [],
        crossOrganizationHasError: false,
        crossOrganizationRoleHasError: false,
        crossOrganizationProductHasError: false,
        crossOrganizationGroupHasError: false,
        isRespondentDeselected: false,
        isAdminRoleSelected: false,
        isRestrictedAdminRoleSelected: false,
        isDivisionLeaderSelected: false,
        isBuLeaderSelected: false,
        isDeliveryOwnerSelected: false,
        isDeliveryLeaderSelected: false,
        isRelationshipManagerSelected: false,
        isSalesExecutiveSelected: false,
        isAccountTeamSelected: false,
        cctAdminRoleHasError: false
      };
      this.crossOrganizationsForDisplay.push(data);
    });

    this.crossOrganizationsForDisplay.forEach(data => {
      this.onProductChanges(data, '');

      const role = data.roles.map(r => r.name.toLowerCase());
      if (this.doesRoleNeedGroups(data.roles)) {
        if (data.groupsToSelect.length === 0) {
          this.onGroupChanges(data, '');
        }
      }
    });
  }

  private setCrossOrganization(): void {
    this.crossOrganizations = [];
    this.crossOrganizationsForDisplay.forEach(item => {
      const data: CrossOrganization = {
        id: item.id,
        name: item.name,
        roles: [],
        products: [],
        productsToSelect: [],
        groups: [],
        groupsToSelect: [],
        crossOrganizationHasError: false,
        crossOrganizationRoleHasError: false,
        crossOrganizationProductHasError: false,
        crossOrganizationGroupHasError: false,
        isRespondentDeselected: false,
        isAdminRoleSelected: false,
        isRestrictedAdminRoleSelected: false,
        isDivisionLeaderSelected: false,
        isBuLeaderSelected: false,
        isDeliveryOwnerSelected: false,
        isDeliveryLeaderSelected: false,
        isRelationshipManagerSelected: false,
        isSalesExecutiveSelected: false,
        isAccountTeamSelected: false,
        cctAdminRoleHasError: false
      };
      item.roles.forEach(roleItem => {
        const temp: BasicDropdownType = {
          id: roleItem.id,
          name: roleItem.name
        };
        data.roles.push(temp);
      });

      item.products.forEach(productItem => {
        const temp: BasicDropdownType = {
          id: productItem.id,
          name: productItem.name
        };
        data.products.push(temp);
      });

      item.groups.forEach(productItem => {
        const temp: BasicDropdownType = {
          id: productItem.id,
          name: productItem.name
        };
        data.groups.push(temp);
      });
      this.crossOrganizations.push(data);
    });
    this.showHierarchyDateReadOnly = this.showHierarchyDateReadOnlyForDisplay;
    this.showHierarchyDateReadWrite = this.showHierarchyDateReadWriteForDisplay;
  }

  private getDelegateParamsFromUrl(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params.isDelegate === 'true') {
        this.isCreateNewUserFromDelegateScreen = true;
        this.delegateParams = params;
        this.isFiservUser = true;
        this.selectedUserType = 'fiserv';
        this.getRoles();
        this.location.replaceState('/admin/user/create');
      }
    });
  }

  getSuggestions() {
    let suggestionSubscriber: any;

    if (this.isDrawerType) {
      this.clientText = this.userService.tempData['clientTitleWithDuns'];
    }

    if (this.isClone && this.isFirstTime && !this.isFiservUser) {
      this.clientText = this.FormData['clientTitleWithDuns'];
    }

    let params = [];
    if (this.clientText && this.clientText.length >= 3) {
      params.push({ paramName: "titleWithDuns", value: this.clientText });
      suggestionSubscriber = this.projectService.getClientSuggestions("clients", params, 100);
    }
    else {
      suggestionSubscriber = this.projectService.getClientSuggestions("clients", null, 100);
    }

    suggestionSubscriber.subscribe(
      data => {
        this.clientSuggestion = data;
        if (this.isClone && this.isFirstTime) {
          this.isFirstTime = false;
          this.userForm.controls['clientId'].setValue(this.FormData?.clientTitleWithDuns || '');
        }
        if (this.isDrawerType)
          this.setClientCtrlValue();
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  typeaheadLoadingClients() {
    this.getSuggestions();
  }

  onClientSelect = (event: any) => {
    this.selectedClientValue = event.item.titleWithDuns;
    if (event.item.id > 0) {
      this.invalidClientName = false;
      this.selectedClientId = event.item.id;
      this.selectedClientName = event.item.title;
    }
  }

  initForm() {
    this.userForm = this.fb.group({
      userType: ['Fiserv', [Validators.required]],
      name: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.user_name_validator), Validators.maxLength(this._maxLength.User.Name), NoWhitespaceValidator.validateWhiteSpaces]],
      email: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.Email), Validators.maxLength(this._maxLength.User.Email)]],
      phone: ['', [Validators.pattern(REGEX_PATTERNS.Mobile)]],
      clientId: new FormControl('', [requiredIfValidator(() => this.userForm.get('userType').value.toLowerCase() === "client")]),
      organization: [(this.organizationDetails.organizationTitle || ''), [Validators.required, Validators.pattern(REGEX_PATTERNS.organization_name_validator), NoWhitespaceValidator.validateWhiteSpaces]],
      isDistributionUser: false,
      restrictedUserGroup: ['']
    });

    this.roleForm = this.fb.group({
      roles: this.fb.array([], [minSelectedCheckboxes(1)])
    });

    if (this.isAdmin && !(this.isEdit || this.isClone)) {
      this.userForm.controls['organization'].valueChanges.pipe(distinctUntilChanged()).subscribe(
        value => {
          const organizationId = this.projectService.fetchIdForRequest(this.organizationSuggestion, (value || ''));
          this.onOrganizationChanged(null);
          if ((value && organizationId > -1) || value == "") {
            if (this.selectedUserType.toLowerCase() == 'client')
              this.getSuggestions();
            else if (this.selectedUserType.toLowerCase() != 'client') {
              this.removeFavoriteProducts();
              this.productSuggestions = null;
              this.getProducts(true);
            }
          }
        }
      );
    }

    this.userForm.controls['clientId'].valueChanges.subscribe(value => {
      // Refetch the client suggestions when the client selected is removed
      if (value === "" && this.isClone && !this.isFiservUser) {
        this.clientText = null;
        this.getSuggestions();
      }
    });
  }

  patchForm() {
    this.userForm.patchValue({
      name: this.FormData?.name || '',
      email: this.FormData?.email || '',
      phone: this.FormData?.phone || '',
      userType: this.FormData?.type || '',
      clientId: this.FormData?.clientTitleWithDuns || '',
      organization: this.organizationDetails.organizationTitle || '',
      isDistributionUser: this.FormData?.isDistributionUser || false,
      restrictedUserGroup: this.FormData?.restrictedUserGroup || ''
    });
    this.selectedRoleIds = this.FormData?.roles?.map(function (e) { return e.roleId; });
    this.selectedProductIds = this.FormData?.products?.map(function (e) { return e.productId; });
    this.selectedChannelPartnerIds = this.FormData?.clients?.map(e => e.clientId);
    this.selectedGroupIds = this.FormData?.groups?.map(e => e.groupId);
  }

  patchCloneForm() {
    this.userForm.patchValue({
      name: '',
      email: '',
      phone: '',
      userType: this.FormData?.type || '',
      clientId: this.FormData?.clientTitleWithDuns || '',
      organization: this.organizationDetails.organizationTitle || '',
      isDistributionUser: this.FormData?.isDistributionUser || false,
      restrictedUserGroup: this.FormData?.restrictedUserGroup || ''
    });
    this.selectedRoleIds = this.FormData?.roles?.map(function (e) { return e.roleId; });
    this.selectedProductIds = this.FormData?.products?.map(function (e) { return e.productId; });
    this.selectedChannelPartnerIds = this.FormData?.clients?.map(e => e.clientId);
    this.selectedGroupIds = this.FormData?.groups?.map(e => e.groupId);
  }

  getRoles() {
    let userType = (this.isEdit || this.isClone) ? this.FormData?.type : this.selectedUserType;
    this.userService.getRolesSuggestions(userType.toLowerCase()).subscribe(
      data => {
        this.roles = data;
        this.basicDropdownTypeRoleList = [];
        this.roles.forEach(item => {
          const baseDropdownType: BasicDropdownType = {
            id: item.id,
            name: item.title
          };
          this.basicDropdownTypeRoleList.push(baseDropdownType);
        });

        if (this.isEdit || this.isClone || this.isCreateNewUserFromDelegateScreen) {
          if (this.isCreateNewUserFromDelegateScreen) {
            const formRespondentRole = this.roles.find(role => role.title === ROLES.Form_Respondent);
            if (formRespondentRole) {
              this.selectedRoleIds.push(formRespondentRole.id);
            }
          }

          this.loadBaseRole();

          this.addCheckboxes();
          this.createSelectedRoleListForDisplay();
          this.showSelectProductOption = this.showFavoriteProduct();
          this.toggleChannelPartner();
          this.toggleGroup();
        }
        else {
          this.isRoleFormSubmitted = false;
          this.addCheckboxes();
          this.showDrawer = true;
        }

        if (this.isEdit || this.isClone) {
          this.onRolesChange();
        }
      },
      error => { this.isError = true; this.errorMsg = error; }
    )
  }

  private loadBaseRole(): void {
    if (!this.isDrawerType && this.roles !== null && this.roles !== undefined) {
      this.selectedBaseRoles = [];
      this.roles.filter(r => this.selectedRoleIds.includes(r.id)).forEach(role => {
        const data: BasicDropdownType = {
          id: role.id,
          name: role.title
        };
        this.selectedBaseRoles.push(data);
      });
    }
  }

  private addCheckboxes() {
    this.roles.forEach((i, o) => {
      const control = this.fb.control(((this.isEdit || this.isClone || this.isCreateNewUserFromDelegateScreen) && this.selectedRoleIds.includes(i.id)));
      (this.roleForm.controls.roles as FormArray).push(control);
    });
  }

  private addCheckboxesForDrawerMode() {
    this.roles.forEach(item => {
      let disabled = false;
      if (this.drawerData['addNewFiservManager'] === true && item.title.toLowerCase() === 'fiserv implementation manager') {
        disabled = true;
      }
      const control = this.fb.control({ value: this.selectedRoleIds.includes(item.id), disabled: disabled });
      (this.roleForm.controls.roles as FormArray).push(control);
    });
  }

  public onBaseRoleChanged(): void {
    this.roleForm.controls.roles['controls'].forEach(element => {
      element.patchValue(false);
    });
    this.selectedBaseRoles.forEach(r => {
      const formArrayIndex = this.roles.map(r => r.id).indexOf(r.id);
      const temp = this.roleForm.controls.roles['controls'][formArrayIndex];
      temp.patchValue(true);
    });
    this.onRolesChange();
  }

  public onBaseRoleDeleted(id: number): void {
    this.selectedBaseRoles = this.selectedBaseRoles.filter(r => r.id !== id);
    this.onBaseRoleChanged();
  }

  public onBaseProductChanged(product: Array<any>): void {
    this.productSuggestions.forEach(element => {
      if (element === product) {
        element.selected = true;
      } else {
        element.selected = false;
      }
    });
  }

  public onBaseProductDeleted(id: number): void {
    this.productSuggestions.find(r => r.id === id).selected = false;
    this.selectedBaseProducts = this.selectedBaseProducts.filter(r => r.id !== id);
  }

  public onBaseGroupChanged(group: Array<any>): void {
    this.groupSuggestions.forEach(element => {
      if (element === group) {
        element.selected = true;
      } else {
        element.selected = false;
      }
    });
  }

  public onBaseGroupDeleted(id: number): void {
    this.groupSuggestions.find(r => r.id === id).selected = false;
    this.selectedBaseGroups = this.selectedBaseGroups.filter(r => r.id !== id);
  }

  userFormSubmit() {
    this.errorMsg = '';
    this.isError = false;
    this.isSubmitted = true;
    let orgName = this.userForm.controls['organization'].value;
    let foundOrgObj: number;

    if (this.crossOrganizationValidationHasError(this.crossOrganizationsForDisplay)) {
      return;
    }

    if (this.isRespondentDeselected === true ||
      this.isDivisionLeaderSelected === true ||
      this.isBuLeaderSelected === true ||
      this.isDeliveryOwnerSelected === true ||
      this.isDeliveryLeaderSelected === true ||
      this.isRelationshipManagerSelected === true ||
      this.isSalesExecutiveSelected === true ||
      this.isAccountTeamSelected === true) {
      return;
    }

    if (!this.isAdmin && this.selectedRoleIds.indexOf(1) > -1) {
      this.errorMsg = "You don't have permission to update this user.";
      this.isError = true;
      return;
    }

    this.groupHasError = false;
    if (this.doesBaseRoleNeedGroups() && this.selectedGroupsForDisplay.length === 0) {
      this.groupHasError = true;
      return;
    }

    if (
      this.selectedProductIds.length === 0 &&
      this.selectedRoleIds.length > 0 &&
      (
        this.selectedRoleIds.indexOf(2) > -1 ||
        this.selectedRoleIds.indexOf(3) > -1 ||
        this.selectedRoleIds.indexOf(4) > -1 ||
        this.selectedRoleIds.indexOf(6) > -1 ||
        this.selectedRoleIds.indexOf(7) > -1 ||
        this.selectedRoleIds.indexOf(8) > -1
      )
      && this.selectedRoleIds.indexOf(1) === -1
    ) {
      return;
    }

    if (this.userForm.value.userType.toLowerCase() === 'fiserv') {
      if (orgName && orgName.trim().length > 0) {
        foundOrgObj = this.projectService.fetchIdForRequest(this.organizationSuggestion, orgName);
        if (foundOrgObj < 1) {
          this.invalidOrganizationName = true;
          return;
        }
      }
      else {
        this.invalidOrganizationName = false;
      }

      this.checkBaseRoleCCTAdminRoleHasError();
      const crossOrganizationsForDisplayHasError = this.checkCrossOrganizationCCTAdminRoleHasError();

      if (crossOrganizationsForDisplayHasError || this.cctAdminRoleHasError) {
        return;
      }
    }

    if (this.userForm.value.userType.toLowerCase() === 'client') {
      this.userForm.controls['organization'].setValue(this.userData.organizationTitle);
    }

    if (this.userForm.valid && this.selectedRoleIds.length > 0 && !this.isRespondentDeselected && this.validChannelPartner() && this.ValidGroup()) {
      let rolesArray = [];
      this.selectedRoleIds.forEach((data) => {
        let userRole = {
          'roleId': data
        }
        rolesArray.push(userRole);
      });

      if (this.nonAdminSelectedRoleListForDisplay.length > 0 && !this.isAdmin && this.isEdit) {
        this.nonAdminSelectedRoleListForDisplay.forEach((item) => {
          if (rolesArray.findIndex(x => x.roleId === item.roleId) === -1) {
            let userRole = {
              'roleId': item.roleId
            }
            rolesArray.push(userRole);
          }
        });
      }

      // let productsArray = { productId: this.selectedProductIds };
      let productIdsArray = [];

      if (this.nonAdminSelectedProductListForDisplay.length > 0 && !this.isAdmin && this.isEdit) {
        this.nonAdminSelectedProductListForDisplay.forEach((item) => {
          if (this.selectedProductIds.findIndex(x => x === item.productId) === -1) {
            productIdsArray.push(item.productId);
          }
        });
      }

      this.selectedProductIds.forEach((data) => {
        productIdsArray.push(data);

      });
      let productsArray = { productId: productIdsArray };

      let submitData = {};

      let channelPartnerIds = [];
      if (this.showChannelPartnerSelectionOptions) {
        channelPartnerIds = this.selectedChannelPartnerIds;
      }
      const channelPartnerArray = { 'clientId': channelPartnerIds };

      let groupIds = [];
      if (this.showGroupSelectionOptions) {
        groupIds = this.selectedGroupIds;
      }
      let groupsArray = { 'groupId': groupIds };

      let crossOrganizationsData = [];
      let pageTemplateIdsForAdmin = [];
      let pageTemplateIdsForReadAll = [];
      let pageTemplateIdsForView = [];

      if (this.isAdmin && this.userForm.get('userType').value === 'Fiserv') {
        crossOrganizationsData = this.crossOrganizationsForDisplay.map(item => {
          return {
            organizationId: item.id,
            roles: item.roles.map(r => r.id),
            products: item.products.map(r => r.id),
            groups: item.groups.map(r => r.id)
          };
        });

        if (!this.doesBaseOrgHaveRestrictedAdminRole) {
          pageTemplateIdsForAdmin = this.pageTemplateDataForDisplay.filter(r => r.accessType === PageTemplateAccessType.Admin).map(r => r.id);
          pageTemplateIdsForReadAll = this.pageTemplateDataForDisplay.filter(r => r.accessType === PageTemplateAccessType.ReadAll).map(r => r.id);
          pageTemplateIdsForView = this.pageTemplateDataForDisplay.filter(r => r.accessType === PageTemplateAccessType.View).map(r => r.id);
        }
      }

      if (this.isEdit) {
        submitData = {
          'id': this.FormData.id,
          'name': this.userForm.value.name,
          'email': this.userForm.value.email,
          'isDistributionUser': this.userForm.value.isDistributionUser,
          'restrictedUserGroup': this.userForm.value.restrictedUserGroup,
          'phone': this.userForm.value.phone ? this.userForm.value.phone.toString() : '',
          'roles': rolesArray,
          'productManager': productsArray,
          'clients': channelPartnerArray,
          'groups': groupsArray,
          'pageTemplateIdsForAdmin': pageTemplateIdsForAdmin,
          'pageTemplateIdsForReadAll': pageTemplateIdsForReadAll,
          'pageTemplateIdsForView': pageTemplateIdsForView,
          'crossOrganizations': crossOrganizationsData,
          'showHierarchyDateReadOnly': this.showHierarchyDateReadOnlyForDisplay,
          'showHierarchyDateReadWrite': this.showHierarchyDateReadWriteForDisplay
        }
        if (this.userForm.value.userType.toLowerCase() == 'client') {
          if (!this.showSelectProductOption) {
            submitData['productManager'] = { 'productId': [] };
          }
        }
        else {
          submitData['organizationId'] = this.projectService.fetchIdForRequest(this.organizationSuggestion, this.userForm.controls['organization'].value);
        }
        this.updateUser(submitData);
      }
      else {
        submitData = {
          'name': this.userForm.value.name,
          'email': this.userForm.value.email,
          'isDistributionUser': this.userForm.value.isDistributionUser,
          'restrictedUserGroup': this.userForm.value.restrictedUserGroup,
          'phone': this.userForm.value.phone ? this.userForm.value.phone.toString() : '',
          'type': this.userForm.value.userType,
          'userRoles': rolesArray,
          'productManager': productsArray,
          'clients': channelPartnerArray,
          'groups': groupsArray,
          'pageTemplateIdsForAdmin': pageTemplateIdsForAdmin,
          'pageTemplateIdsForReadAll': pageTemplateIdsForReadAll,
          'pageTemplateIdsForView': pageTemplateIdsForView,
          'crossOrganizations': crossOrganizationsData,
          'showHierarchyDateReadOnly': this.showHierarchyDateReadOnlyForDisplay,
          'showHierarchyDateReadWrite': this.showHierarchyDateReadWriteForDisplay
        }
        if (this.userForm.value.userType.toLowerCase() == 'client') {
          if (this.selectedClientId > 0)
            submitData['clientId'] = this.selectedClientId;
          else {
            submitData['clientId'] = this.projectService.fetchClientIdByTitleWithDuns(this.clientSuggestion, this.userForm.value.clientId);
          }

          if (!this.showSelectProductOption && !this.isDrawerType) {
            submitData['productManager'] = { 'productId': [] };
          }

          let clientName = this.userForm.controls['clientId'].value;
          if (clientName && clientName.trim().length > 0) {
            let foundClientId: number;
            foundClientId = this.projectService.fetchClientIdByTitleWithDuns(this.clientSuggestion, clientName);
            if (foundClientId < 1) {
              this.invalidClientName = true;
              return;
            }
          }
          else {
            this.invalidClientName = false;
          }
        }
        if (this.userForm.value.userType.toLowerCase() == 'fiserv') {
          if (this.isAdmin)
            submitData['organizationId'] = this.projectService.fetchIdForRequest(this.organizationSuggestion, this.userForm.controls['organization'].value);
          else
            submitData['organizationId'] = this.userService.getUserListData() ? this.userService.getUserListData().organization : -1;
        }
        this.createUser(submitData);
        this.userDto._isCloneMode = false;
        $('.container-page').removeClass('overflow-hidden');
      }
    }
  }

  private crossOrganizationValidationHasError(crossOrganizations: CrossOrganization[]): boolean {
    let hasError = false;

    this.crossOrganizationHasError = false;

    if (this.userForm.get('userType').value === 'Client') {
      return false;
    }

    crossOrganizations.forEach(item => {
      item.crossOrganizationHasError = false;
      item.crossOrganizationRoleHasError = false;
      item.crossOrganizationProductHasError = false;
      item.crossOrganizationGroupHasError = false;
      item.cctAdminRoleHasError = false;

      if (item.id === 0 && item.name === '') {
        hasError = true;
        item.crossOrganizationHasError = true;
      }
      if (item.roles.length === 0) {
        hasError = true;
        item.crossOrganizationRoleHasError = true;
      }
      if (item.products.length === 0 &&
        item.roles.length > 0 &&
        item.roles.find(r => r.id === 2 ||
          r.id === 3 ||
          r.id === 4 ||
          r.id === 6 ||
          r.id === 7 ||
          r.id === 8
        ) &&
        !item.roles.find(r => r.id === 1)) {
        hasError = true;
        item.crossOrganizationProductHasError = true;
      }

      if (this.doesRoleNeedGroups(item.roles)) {
        if (item.groups.length === 0) {
          hasError = true;
          item.crossOrganizationGroupHasError = true;
        }
      }
      this.onRolesChangeForCrossOrganization(item);
      if (this.crossOrganizationHasError) {
        hasError = true;
      }
    });
    this.crossOrganizationHasError = hasError;
    return hasError;
  }

  public doesRoleNeedGroups(roles: BasicDropdownType[]): boolean {
    const role = roles.map(r => r.name.toLowerCase());
    if (role.indexOf('restricted admin') !== -1 ||
      role.indexOf('restricted admin - read only') !== -1) {
      return true;
    } else {
      return false;
    }
  }

  public doesBaseRoleNeedGroups(): boolean {
    const role = this.selectedRoleListForDisplay.map(r => r.title.toLowerCase());
    if (role.indexOf('restricted admin') !== -1 ||
      role.indexOf('restricted admin - read only') !== -1) {
      return true;
    } else {
      return false;
    }
  }

  drawerSubmit() {
    this.isRoleFormSubmitted = true;
    if (this.isAdminRoleSelected === true) return;
    if (this.isRestrictedAdminRoleSelected === true) return;

    if (this.crossOrganizationValidationHasError(this.crossOrganizations)) {
      return;
    }

    if (this.isRespondentDeselected === true ||
      this.isDivisionLeaderSelected === true ||
      this.isBuLeaderSelected === true ||
      this.isDeliveryOwnerSelected === true ||
      this.isDeliveryLeaderSelected === true ||
      this.isRelationshipManagerSelected === true ||
      this.isSalesExecutiveSelected === true ||
      this.isAccountTeamSelected === true) {
      return;
    }

    this.baseProductHasError = false;
    const tempSelectedRoleIds = this.roleForm.value.roles
      .map((v, i) => v ? this.roles[i].id : null)
      .filter(v => v !== null);
    if (
      this.selectedBaseProducts.length === 0 &&
      tempSelectedRoleIds.length > 0 &&
      (
        tempSelectedRoleIds.indexOf(2) > -1 ||
        tempSelectedRoleIds.indexOf(3) > -1 ||
        tempSelectedRoleIds.indexOf(4) > -1 ||
        tempSelectedRoleIds.indexOf(6) > -1 ||
        tempSelectedRoleIds.indexOf(7) > -1 ||
        tempSelectedRoleIds.indexOf(8) > -1
      )
      && tempSelectedRoleIds.indexOf(1) === -1
    ) {
      this.baseProductHasError = true;
      return;
    }

    this.groupHasError = false;
    if (this.doesRoleNeedGroups(this.selectedBaseRoles) && this.selectedBaseGroups.length === 0) {
      this.groupHasError = true;
      return;
    }

    if (this.roleForm.valid && !this.isRespondentDeselected) {

      this.selectedRoleIds = this.roleForm.value.roles
        .map((v, i) => v ? this.roles[i].id : null)
        .filter(v => v !== null);

      this.crossOrganizationsForDisplay = [];
      this.crossOrganizations.forEach(item => {
        const data: CrossOrganization = {
          id: item.id,
          name: item.name,
          roles: [],
          products: [],
          productsToSelect: [],
          groups: [],
          groupsToSelect: [],
          crossOrganizationHasError: false,
          crossOrganizationRoleHasError: false,
          crossOrganizationProductHasError: false,
          crossOrganizationGroupHasError: false,
          isRespondentDeselected: false,
          isAdminRoleSelected: false,
          isRestrictedAdminRoleSelected: false,
          isDivisionLeaderSelected: false,
          isBuLeaderSelected: false,
          isDeliveryOwnerSelected: false,
          isDeliveryLeaderSelected: false,
          isRelationshipManagerSelected: false,
          isSalesExecutiveSelected: false,
          isAccountTeamSelected: false,
          cctAdminRoleHasError: false
        };
        item.roles.forEach(roleItem => {
          const temp: BasicDropdownType = {
            id: roleItem.id,
            name: roleItem.name
          };
          data.roles.push(temp);
        });

        item.products.forEach(productItem => {
          const temp: BasicDropdownType = {
            id: productItem.id,
            name: productItem.name
          };
          data.products.push(temp);
        });

        item.groups.forEach(productItem => {
          const temp: BasicDropdownType = {
            id: productItem.id,
            name: productItem.name
          };
          data.groups.push(temp);
        });
        this.crossOrganizationsForDisplay.push(data);
      });
      this.showHierarchyDateReadOnlyForDisplay = this.showHierarchyDateReadOnly;
      this.showHierarchyDateReadWriteForDisplay = this.showHierarchyDateReadWrite;

      this.createSelectedRoleListForDisplay();
      // if (this.isRespondentRoleOnly()) this.removeFavoriteProducts();
      this.showSelectProductOption = this.showFavoriteProduct();
      this.toggleChannelPartner();
      this.toggleGroup();
      this.toggleDrawer();
      this.isAdminRoleSelected = false;
      this.isRestrictedAdminRoleSelected = false;
      $('.container-page').removeClass('overflow-hidden');

      this.selectedBaseProducts.forEach(item => {
        const temp = this.productSuggestions.find(r => r.id === item.id);
        if (temp) {
          temp.selected = true;
        } else {
          this.productSuggestions.push(item);
        }
      });
      this.drawerProductSubmit();
      this.selectedBaseProducts = [];

      this.selectedBaseGroups.forEach(item => {
        const temp = this.groupSuggestions.find(r => r.id === item.id);
        if (temp) {
          temp.selected = true;
        } else {
          this.groupSuggestions.push(item);
        }
      });

      if (this.doesRoleNeedGroups(this.selectedBaseRoles) &&
        this.userForm.get('userType').value === 'Fiserv') {
        this.drawerGroupSubmit();
      } else {
        this.toggleDrawer();
      }
      this.selectedBaseGroups = [];
    }

    this.setBaseOrgHasRestrictedAdminRole();
  }

  private setBaseOrgHasRestrictedAdminRole(): void {
    this.doesBaseOrgHaveRestrictedAdminRole = false;

    const hasRestrictedAdminRole = this.crossOrganizationsForDisplay.find(r => r.roles.find(r => r.name === 'Restricted Admin'));

    if (this.selectedRoleListForDisplay.find(r => r.title === 'Restricted Admin') || hasRestrictedAdminRole) {
      this.doesBaseOrgHaveRestrictedAdminRole = true;
    }
  }

  drawerCancel() {
    $('.container-page').removeClass('overflow-hidden');
    this.baseProductHasError = false;
    this.groupHasError = false;
    this.crossOrganizationHasError = false;
    this.toggleDrawer();
    if (this.selectedRoleIds.length === 0) {
      this.isRoleFormSubmitted = false;
      this.roleForm.reset();
    }
    else {
      this.resetCheckboxes();
    }

    this.productSuggestions.forEach(item => {
      const temp = this.selectedProductsForDisplay.find(r => r.id === item.id);
      if (!temp) {
        item.selected = false;
      }
    });
    this.onRolesChange();
    this.crossOrganizations = [];
    this.onOrganizationSuggestionChanged();
  }

  toggleDrawer() {
    this.showDrawer = !this.showDrawer;
    this.setBaseOrgHasRestrictedAdminRole();
  }

  resetCheckboxes() {
    this.roleForm.reset();
    this.selectedRoleIds.forEach(element => {
      const formArrayIndex = this.roles.map(function (e) { return e.id; }).indexOf(element);
      if (formArrayIndex != -1) {
        this.roleForm.controls.roles['controls'][formArrayIndex].patchValue(true);
      }
    });
  }
  goBack() {
    $('.container-page').removeClass('overflow-hidden');
    if (this.isEdit) {
      this.router.navigate(['/admin/user/view', this.FormData.id]);
    }
    else if (this.isDrawerType == true) {
      this.drawerService.close();
    }
    else if (this.isClone) {
      this.router.navigate(['/admin/user/list']);
      this.userDto._isCloneMode = false;
    }
    else {
      this.router.navigate(['/admin/user/list']);
    }
    this.selectedClientValue = '';
  }

  removeRole(id) {
    const roleIndex = this.selectedRoleIds.indexOf(id);
    const isRespondentRemoved = this.roles.filter((element, idx) => element.id === +id).some(elem => elem.title === ROLES.Form_Respondent);
    if (isRespondentRemoved) {
      const isIntakeSubmitterSelected = this.selectedRoleListForDisplay.some(elem => elem.title === ROLES.Intake_Submitter);
      if (isIntakeSubmitterSelected || this.isCreateNewUserFromDelegateScreen) {
        this.isRespondentDeselected = true;
      }
    }

    this.selectedRoleIds.splice(roleIndex, 1);

    const formArrayIndex = this.roles.map(function (e) { return e.id; }).indexOf(id);
    this.roleForm.controls.roles['controls'][formArrayIndex].patchValue(false);
    this.createSelectedRoleListForDisplay();
    // if (this.isRespondentRoleOnly()) this.removeFavoriteProducts();
    this.showSelectProductOption = this.showFavoriteProduct();
    const isChannelPartnerRole = this.roles.filter((element, idx) => element.id === +id).some(elem => elem.title === ROLES.Channel_Partner);
    const isRestrictedAdminRoleRemoved = this.roles.filter((element, idx) => element.id === +id).some(elem => elem.title === ROLES.Restricted_Admin || elem.title === ROLES.Restricted_Admin_Read_Only);
    if (isChannelPartnerRole) {
      this.resetPartnerClientSelection();
    }
    if (isRestrictedAdminRoleRemoved) {
      this.resetGroupComponent();
    }
    this.onRolesChange();
    this.setBaseOrgHasRestrictedAdminRole();

    this.checkBaseRoleCCTAdminRoleHasError();
  }

  private checkBaseRoleCCTAdminRoleHasError(): void {
    this.cctAdminRoleHasError = false;
    if (this.selectedRoleListForDisplay.some(r => r.title === 'CCT Admin') &&
      !this.pageTemplateDataForDisplay.some(r => r.title === 'Commitments Intake')
    ) {
      this.cctAdminRoleHasError = true;
    }
  }

  private checkCrossOrganizationCCTAdminRoleHasError(): boolean {
    let crossOrganizationsForDisplayHasError = false;
    this.crossOrganizationsForDisplay.forEach(item => {
      item.cctAdminRoleHasError = false;
      if (item.roles.some(s => s.name === 'CCT Admin') &&
        !this.pageTemplateDataForDisplay.some(r => r.title === 'Commitments Intake')) {
        crossOrganizationsForDisplayHasError = true;
        item.cctAdminRoleHasError = true;
      }
    });
    return crossOrganizationsForDisplayHasError;
  }

  private checkCrossOrganizationCCTAdminRoleHasErrorForAnItem(item: CrossOrganization): void {
    item.cctAdminRoleHasError = false;
    if (item.roles.some(s => s.name === 'CCT Admin') &&
      !this.pageTemplateDataForDisplay.some(r => r.title === 'Commitments Intake')) {
      item.cctAdminRoleHasError = true;
    }
  }

  createSelectedRoleListForDisplay() {
    this.selectedRoleListForDisplay = [];
    this.selectedRoleListForDisplay = this.roles.filter(
      data => this.selectedRoleIds.includes(data.id)
    );

    let userRoleIds = this.selectedRoleListForDisplay.map(item => item.id);

    if (!this.isAdmin && this.isEdit && this.editRoleCounter === 0) {
      this.editRoleCounter = 1;
      this.nonAdminSelectedRoleListForDisplay = this.FormData?.roles
        .filter(function (item) {
          return userRoleIds.indexOf(item.roleId) === -1;
        });
    }
    this.setBaseOrgHasRestrictedAdminRole();
  }

  updateUser(data) {
    this.userService.updateUser(this.FormData.id, data).subscribe(
      data => {
        this.userDto._isUserUpdated = true;
        this.router.navigate(['/admin/user/view', this.FormData.id]);
      },
      error => {
        this.isError = true;
        this.errorMsg = error;
      }
    )
  }

  createUser(data) {
    let createdUserName = data.name;
    let createdUserEmail = data?.email;
    this.userService.createUser(data).subscribe(
      data => {
        if (this.isDrawerType == true) {
          const tempData = this.drawerService.tempData;
          this.drawerService.save(createdUserName, (tempData || null), createdUserEmail, parseInt(data["id"]));
          if (tempData) {
            this.drawerService.tempData = null;
          }
          return;
        }
        this.userDto._isUserCreated = true;
        this.userDto._newCreatedUser = createdUserName;
        if (this.isCreateNewUserFromDelegateScreen) {
          const params = {
            delegateFormId: this.delegateParams.formId,
            delegateRespondentId: data.id
          };

          if (this.delegateParams.projectId && +this.delegateParams.projectId !== 0) {
            this.router.navigate([`/projects/view/${this.delegateParams.projectId}`, params]);
          } else {
            this.router.navigate([`/response/list`, params]);
          }
        } else {
          this.router.navigate(['/admin/user/list']);
        }

      },
      error => {
        this.isError = true;
        this.errorMsg = error;
      }
    );
  }

  ngOnDestroy(): void {
    this.userDto._isEditMode = false;
    this.userDto.setUserData(<IUser>{});
    this.selectedClientValue = '';
    this.productSuggestions = null;
    $('body').removeClass('overflow-hidden');
    $('.container-page').removeClass('overflow-hidden');
    $('#createNewUserDw').removeClass('overflow-hidden');
  }

  //#region Role Drawer section
  openRolesDrawer() {
    this.onOrganizationSuggestionChanged();
    $('#containerPage').scrollTop(0);
    $('.container-page').addClass('overflow-hidden');
    this.switchDrawer('Role');
    this.isRespondentDeselected = false;
    if (this.isEdit || this.isClone) {
      this.toggleDrawer();
    }
    else {
      let userType = this.userForm.get('userType').value;
      if (userType === '') userType = 'fiserv';
      if (this.selectedUserType !== userType.toLowerCase()) {
        this.selectedUserType = userType.toLowerCase();
        this.getRoles();
      }
      else {
        this.toggleDrawer();
      }
    }

    this.loadBaseRole();
    this.selectedBaseProducts = [];
    this.selectedBaseProducts.push(...this.selectedProductsForDisplay);

    this.selectedBaseGroups = [];
    this.selectedBaseGroups.push(...this.selectedGroupsForDisplay);

    this.setCrossOrganization();
    this.crossOrganizations.forEach(data => {
      this.onProductChanges(data, '');

      if (this.doesRoleNeedGroups(data.roles)) {
        if (data.groupsToSelect.length === 0) {
          this.onGroupChanges(data, '');
        }
      }
      this.onRolesChangeForCrossOrganization(data);
    });
    this.onRolesChange();
  }

  public addCrossOrganization(): void {
    const data: CrossOrganization = {
      id: 0,
      name: '',
      roles: [],
      products: [],
      productsToSelect: [],
      groups: [],
      groupsToSelect: [],
      crossOrganizationHasError: false,
      crossOrganizationRoleHasError: false,
      crossOrganizationProductHasError: false,
      crossOrganizationGroupHasError: false,
      isRespondentDeselected: false,
      isAdminRoleSelected: false,
      isRestrictedAdminRoleSelected: false,
      isDivisionLeaderSelected: false,
      isBuLeaderSelected: false,
      isDeliveryOwnerSelected: false,
      isDeliveryLeaderSelected: false,
      isRelationshipManagerSelected: false,
      isSalesExecutiveSelected: false,
      isAccountTeamSelected: false,
      cctAdminRoleHasError: false
    };
    this.crossOrganizations.push(data);
  }

  public deleteCrossOrganization(item: CrossOrganization): void {
    this.crossOrganizations = this.crossOrganizations.filter(r => r !== item);
    this.onOrganizationSuggestionChanged();
    this.crossOrganizationValidationHasError(this.crossOrganizations);
  }

  public onOrganizationChanged(item: CrossOrganization | null): void {
    if (item === null && !this.baseOrganizationIsToched) {
      this.baseOrganizationIsToched = true;
      this.onOrganizationSuggestionChanged();
    }
    else if (item !== null && item.id !== 0) {
      item.id = 0;
      item.products = [];
      item.productsToSelect = [];
      this.onOrganizationSuggestionChanged();
    }
  }

  public onOrganizationSelect($event: any, item: CrossOrganization): void {
    item.id = $event.item.id;
    item.name = $event.item.title;
    item.products = [];
    item.productsToSelect = [];
    this.onOrganizationSuggestionChanged();
    this.onProductChanges(item, '');
  }

  public onOrganizationsRoleSelect(item: CrossOrganization): void {
    if (this.doesRoleNeedGroups(item.roles)) {
      if (item.groupsToSelect.length === 0) {
        this.onGroupChanges(item, '');
      }
    } else {
      item.groupsToSelect = [];
      item.groups = [];
    }
    this.onRolesChangeForCrossOrganization(item);
  }

  public deleteOrganizationRole(id: number, item: CrossOrganization): void {
    item.roles = item.roles.filter(r => r.id !== id);

    if (!this.doesRoleNeedGroups(item.roles)) {
      item.groupsToSelect = [];
      item.groups = [];
    }
    this.onRolesChangeForCrossOrganization(item);
    this.setBaseOrgHasRestrictedAdminRole();
    this.checkCrossOrganizationCCTAdminRoleHasErrorForAnItem(item);
  }

  public deleteOrganizationProduct(id: number, item: CrossOrganization): void {
    item.products = item.products.filter(r => r.id !== id);
  }

  public deleteOrganizationGroup(id: number, item: CrossOrganization): void {
    item.groups = item.groups.filter(r => r.id !== id);
  }

  public setShowHierarchyDateReadOnlyToFalse(): void {
    this.showHierarchyDateReadOnlyForDisplay = false;
  }

  public setShowHierarchyDateReadWriteToFalse(): void {
    this.showHierarchyDateReadWriteForDisplay = false;
  }

  public onProductChanges(crossOrganization: CrossOrganization, searchValue: string): void {
    const queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: 100
    });

    this.projectService.getSuggestionsResponseWithOrgId('products', searchValue, 'title', queryParam, false, crossOrganization.id).
      subscribe(
        data => {
          const temp = data.body as unknown as ISuggestion[];
          if (crossOrganization.productsToSelect === undefined || crossOrganization.productsToSelect.length === 0) {
            crossOrganization.productsToSelect = [];
          }
          temp.forEach(r => {
            if (!crossOrganization.productsToSelect.find(z => z.id === r.id)) {
              const basicDropdownType: BasicDropdownType = {
                id: r.id,
                name: r.title
              };
              crossOrganization.productsToSelect.push(basicDropdownType);
            }
          });
        },
        error => { this.isError = true; this.errorMsg = error; });
  }

  public onGroupChanges(crossOrganization: CrossOrganization, searchValue: string): void {
    if (this.isSearchGroupValid(searchValue)) {
      const params = [];
      params.push({ paramName: 'title', value: searchValue });
      this.projectService.getPaginatedSuggestions('groups', params, 100).subscribe(data => {
        crossOrganization.groupsToSelect = [];
        const temp = data as unknown as ISuggestion[];
        temp.forEach(r => {
          const basicDropdownType: BasicDropdownType = {
            id: r.id,
            name: r.name
          };
          crossOrganization.groupsToSelect.push(basicDropdownType);
        });
      },
        error => { this.isError = true; this.errorMsg = error; });
    }
  }

  //#endregion

  handleChange($event) {
    if (!this.isDrawerType) {
      this.selectedRoleListForDisplay = [];
      this.selectedRoleIds = [];
    }
    this.roleForm.reset();
    this.isRoleFormSubmitted = false;
    this.selectedUserType = this.userForm.get('userType').value;
    if (this.selectedUserType.toLowerCase() === 'client') {
      this.crossOrganizationHasError = false;
    }
    if (!this.clientSuggestion || (this.selectedUserType.toLowerCase() == 'client' && this.isAdmin)) {
      this.getSuggestions();
    }
    (this.roleForm.controls.roles as FormArray).clear();
    if (this.selectedProductIds.length > 0) this.removeFavoriteProducts(); //on switching user type clear products
    this.showSelectProductOption = this.showFavoriteProduct();
    if (!this.isDrawerType) this.userForm.get('clientId').reset();

    if (this.selectedUserType.toLowerCase() !== 'client') {
      this.resetPartnerClientSelection();
    }

    if (this.isDrawerType) {
      this.initialiseDrawer();
    }
    this.setBaseOrgHasRestrictedAdminRole();
  }

  /*********DRAWER CHANGES*********/
  initialiseDrawer() {
    this.selectedRoleIds = [];
    this.hideUserControl = (this.drawerData['hideUserControl'] || false);
    const selectedUserType = (this.userForm.get('userType').value || '');
    let roles = (this.drawerData['roleKeywords'] || []);
    if (selectedUserType === 'Client' && roles.includes('fiserv implementation manager')) {
      roles = roles.filter(item => item !== 'fiserv implementation manager');
      roles.unshift('client implementation manager');
    }
    const tempData = this.userService.tempData;
    if (tempData) {
      if (tempData['clientId'] !== null && tempData['clientId'] !== undefined) {
        this.hasClient = true;
      } else {
        this.hasClient = false;
      }
      if (tempData['isFiservOnly'] == true) {
        this.hideUserControl = true;
      }
      if (tempData['clientId'] != null && tempData['isFiservOnly'] == false) {
        this.readOnlyClientControl = true;
      }
    }
    this.userService.getRoles(selectedUserType.toLowerCase()).subscribe(
      data => {
        //this.roles = data;
        this.roles = [];
        roles.forEach(element => {
          const selectedRole = data.find(item => (item.title.toLowerCase().indexOf(element.toLowerCase()) > -1));
          if (selectedRole) {
            if (this.drawerData['addNewFiservManager'] === true && element === 'fiserv implementation manager') {
              this.selectedRoleIds.push(selectedRole.id);
            }
            this.roles.push(selectedRole);
          }
        });
        this.basicDropdownTypeRoleList = [];
        this.roles.forEach(item => {
          const baseDropdownType: BasicDropdownType = {
            id: item.id,
            name: item.title
          };
          this.basicDropdownTypeRoleList.push(baseDropdownType);
        });
        this.addCheckboxesForDrawerMode();
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  setClientCtrlValue() {
    const tempData = this.userService.tempData;
    if (tempData) {
      const clientId = (tempData['clientId'] || '');
      const client = (this.clientSuggestion as unknown as ISuggestion[]).find(item => item.id == clientId);
      if (client) {
        this.userForm.controls['clientId'].setValue(client.titleWithDuns);
        this.selectedClientId = clientId;
      }
    }
  }

  getOrganizationSuggestions() {
    this.projectService.getSuggestions('organizations', '')
      .subscribe(suggestions => {
        this.organizationSuggestion = suggestions as unknown as ISuggestion[];
        this.organizationSuggestionEdited = suggestions as unknown as ISuggestion[];
        this.initUserData();
      },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  private onOrganizationSuggestionChanged(): void {
    this.organizationSuggestionEdited = [];
    this.organizationSuggestionEdited = [...this.organizationSuggestion];
    const temp = this.crossOrganizations.map(r => r.id);
    const baseOrgId = this.projectService.fetchIdForRequest(this.organizationSuggestion, this.userForm.controls['organization'].value);
    temp.push(baseOrgId);
    this.organizationSuggestionEdited = this.organizationSuggestionEdited.filter(r => !temp.includes(r.id));
  }

  onRolesChangeForDrawer(id: number) {
    if (this.selectedRoleIds.includes(id)) {
      this.selectedRoleIds = this.selectedRoleIds.filter(r => r !== id);
    } else {
      this.selectedRoleIds.push(id);
    }
  }

  private onRolesChange(): void {
    this.isRespondentDeselected = false;
    this.isAdminRoleSelected = false;
    this.isRestrictedAdminRoleSelected = false;
    if (this.roles) {
      // selectedRoles.forEach((element, idx) => {
      const intakeSubmitterId = this.roles.findIndex((element) => element.title === ROLES.Intake_Submitter);
      const formRespondentId = this.roles.findIndex((element) => element.title === ROLES.Form_Respondent);
      const adminRoleId = this.roles.findIndex((element) => element.title === ROLES.Admin);
      const restrictedAdminRoleId = this.roles.findIndex((element) => element.title === ROLES.Restricted_Admin);
      const restrictedAdminReadOnlyRoleId = this.roles.findIndex((element) => element.title === ROLES.Restricted_Admin_Read_Only);

      const divisionLeaderId = this.roles.findIndex(element => element.title === 'Division Leader');
      const buLeaderId = this.roles.findIndex(element => element.title === 'BU Leader');
      const deliveryOwnerId = this.roles.findIndex(element => element.title === 'Delivery Owner');
      const deliveryLeaderId = this.roles.findIndex(element => element.title === 'Delivery Leader');
      const relationshipManagerId = this.roles.findIndex(element => element.title === 'Relationship Manager');
      const salesExecutiveId = this.roles.findIndex(element => element.title === 'Sales Executive');
      const accountTeamId = this.roles.findIndex(element => element.title === 'Account Team');

      const isIntakeSubmitterSelected = this.roleForm.value.roles[intakeSubmitterId];
      const isRespondentDeSelected = !this.roleForm.value.roles[formRespondentId];
      const isAdminRole = this.roleForm.value.roles[adminRoleId];
      const isRestrictedAdminRole = this.roleForm.value.roles[restrictedAdminRoleId];
      const isRestrictedAdminReadOnlyRole = this.roleForm.value.roles[restrictedAdminReadOnlyRoleId];

      const isDivisionLeaderSelected = this.roleForm.value.roles[divisionLeaderId];
      const isBuLeaderSelected = this.roleForm.value.roles[buLeaderId];
      const isDeliveryOwnerSelected = this.roleForm.value.roles[deliveryOwnerId];
      const isDeliveryLeaderSelected = this.roleForm.value.roles[deliveryLeaderId];
      const isRelationshipManagerSelected = this.roleForm.value.roles[relationshipManagerId];
      const isSalesExecutiveSelected = this.roleForm.value.roles[salesExecutiveId];
      const isAccountTeamSelected = this.roleForm.value.roles[accountTeamId];

      if (this.roleForm.controls.roles['controls'][intakeSubmitterId].value) {
        this.roleForm.controls.roles['controls'][formRespondentId].patchValue(true);

        if (this.selectedBaseRoles.find(s => s.name === ROLES.Form_Respondent) === undefined) {
          const role = this.roles.find(r => r.title === ROLES.Form_Respondent);
          const data: BasicDropdownType = {
            id: role.id,
            name: role.title
          };
          const temp2 = [...this.selectedBaseRoles];
          temp2.push(data);
          this.selectedBaseRoles = [];
          this.selectedBaseRoles = [...temp2];
        }

      } else if (isRespondentDeSelected && (isIntakeSubmitterSelected || this.isCreateNewUserFromDelegateScreen)) {
        this.isRespondentDeselected = !(this.roleForm.value.roles[formRespondentId]);
      }

      if (isAdminRole && (isRestrictedAdminRole || isRestrictedAdminReadOnlyRole)) {
        this.isAdminRoleSelected = true;
      }
      else if (isRestrictedAdminRole && isRestrictedAdminReadOnlyRole) {
        this.isRestrictedAdminRoleSelected = true;
      }
      else {
        this.isAdminRoleSelected = false;
        this.isRestrictedAdminRoleSelected = false;
      }
      this.isDivisionLeaderSelected = (isDivisionLeaderSelected && isRestrictedAdminRole) ? true : false;
      this.isBuLeaderSelected = (isBuLeaderSelected && isRestrictedAdminRole) ? true : false;
      this.isDeliveryOwnerSelected = (isDeliveryOwnerSelected && isRestrictedAdminRole) ? true : false;
      this.isDeliveryLeaderSelected = (isDeliveryLeaderSelected && isRestrictedAdminRole) ? true : false;
      this.isRelationshipManagerSelected = (isRelationshipManagerSelected && isRestrictedAdminRole) ? true : false;
      this.isSalesExecutiveSelected = (isSalesExecutiveSelected && isRestrictedAdminRole) ? true : false;
      this.isAccountTeamSelected = (isAccountTeamSelected && isRestrictedAdminRole) ? true : false;

      // });
    }
  }

  private onRolesChangeForCrossOrganization(item: CrossOrganization) {
    this.crossOrganizationHasError = false;
    item.isRespondentDeselected = false;
    item.isAdminRoleSelected = false;
    item.isRestrictedAdminRoleSelected = false;
    item.isDivisionLeaderSelected = false;
    item.isBuLeaderSelected = false;
    item.isDeliveryOwnerSelected = false;
    item.isDeliveryLeaderSelected = false;
    item.isRelationshipManagerSelected = false;
    item.isSalesExecutiveSelected = false;
    item.isAccountTeamSelected = false;

    const intakeSubmitterId = item.roles.find(element => element.name === ROLES.Intake_Submitter);
    const formRespondentId = item.roles.find(element => element.name === ROLES.Form_Respondent);
    const adminRoleId = item.roles.find(element => element.name === ROLES.Admin);
    const restrictedAdminRoleId = item.roles.find(element => element.name === ROLES.Restricted_Admin);
    const restrictedAdminReadOnlyRoleId = item.roles.find(element => element.name === ROLES.Restricted_Admin_Read_Only);

    const divisionLeaderId = item.roles.find(element => element.name === 'Division Leader');
    const buLeaderId = item.roles.find(element => element.name === 'BU Leader');
    const deliveryOwnerId = item.roles.find(element => element.name === 'Delivery Owner');
    const deliveryLeaderId = item.roles.find(element => element.name === 'Delivery Leader');
    const relationshipManagerId = item.roles.find(element => element.name === 'Relationship Manager');
    const salesExecutiveId = item.roles.find(element => element.name === 'Sales Executive');
    const accountTeamId = item.roles.find(element => element.name === 'Account Team');

    const isIntakeSubmitterSelected = intakeSubmitterId === undefined ? false : true;
    const isRespondentDeSelected = formRespondentId === undefined ? true : false;
    const isAdminRole = adminRoleId === undefined ? false : true;
    const isRestrictedAdminRole = restrictedAdminRoleId === undefined ? false : true;
    const isRestrictedAdminReadOnlyRole = restrictedAdminReadOnlyRoleId === undefined ? false : true;

    const isDivisionLeaderSelected = divisionLeaderId === undefined ? false : true;
    const isBuLeaderSelected = buLeaderId === undefined ? false : true;
    const isDeliveryOwnerSelected = deliveryOwnerId === undefined ? false : true;
    const isDeliveryLeaderSelected = deliveryLeaderId === undefined ? false : true;
    const isRelationshipManagerSelected = relationshipManagerId === undefined ? false : true;
    const isSalesExecutiveSelected = salesExecutiveId === undefined ? false : true;
    const isAccountTeamSelected = accountTeamId === undefined ? false : true;

    if (intakeSubmitterId) {
      if (item.roles.find(s => s.name === ROLES.Form_Respondent) === undefined) {
        const role = this.roles.find(r => r.title === ROLES.Form_Respondent);
        const data: BasicDropdownType = {
          id: role.id,
          name: role.title
        };
        const temp2 = [...item.roles];
        temp2.push(data);
        item.roles = [];
        item.roles = [...temp2];
      }
    } else if (isRespondentDeSelected &&
      (isIntakeSubmitterSelected || this.isCreateNewUserFromDelegateScreen)) {
      item.isRespondentDeselected = formRespondentId === undefined ? true : false;
    }

    if (isAdminRole && (isRestrictedAdminRole || isRestrictedAdminReadOnlyRole)) {
      item.isAdminRoleSelected = true;
    }
    else if (isRestrictedAdminRole && isRestrictedAdminReadOnlyRole) {
      item.isRestrictedAdminRoleSelected = true;
    } else {
      item.isAdminRoleSelected = false;
      item.isRestrictedAdminRoleSelected = false;
    }

    item.isDivisionLeaderSelected = (isDivisionLeaderSelected && isRestrictedAdminRole) ? true : false;
    item.isBuLeaderSelected = (isBuLeaderSelected && isRestrictedAdminRole) ? true : false;
    item.isDeliveryOwnerSelected = (isDeliveryOwnerSelected && isRestrictedAdminRole) ? true : false;
    item.isDeliveryLeaderSelected = (isDeliveryLeaderSelected && isRestrictedAdminRole) ? true : false;
    item.isRelationshipManagerSelected = (isRelationshipManagerSelected && isRestrictedAdminRole) ? true : false;
    item.isSalesExecutiveSelected = (isSalesExecutiveSelected && isRestrictedAdminRole) ? true : false;
    item.isAccountTeamSelected = (isAccountTeamSelected && isRestrictedAdminRole) ? true : false;

    if (item.isRespondentDeselected === true ||
      item.isAdminRoleSelected === true ||
      item.isRestrictedAdminRoleSelected === true ||
      item.isDivisionLeaderSelected === true ||
      item.isBuLeaderSelected === true ||
      item.isDeliveryOwnerSelected === true ||
      item.isDeliveryLeaderSelected === true ||
      item.isRelationshipManagerSelected === true ||
      item.isSalesExecutiveSelected === true ||
      item.isAccountTeamSelected === true) {
      this.crossOrganizationHasError = true;
    }
  }

  /**************************** Group Drawer Code Implementation Starts *********************************** */
  groupSuggestions: any;
  selectedGroupIds: number[] = [];
  selectedGroupsForDisplay = [];
  showGroupDrawer: boolean = false;
  searchGroupString: string = '';

  openGroupDrawer() {
    $('#containerPage').scrollTop(0);
    $('.container-page').addClass('overflow-hidden');
    this.switchDrawer('Group');
    if (this.isEdit || this.isClone)
      this.toggleDrawer();
    else {
      this.initQueryParams();
      this.getGroups();
    }
  }

  getGroups(keepDrawerClose = false) {
    if (this.groupSuggestions == null) {
      $('#refreshTask').addClass('fa-rotation');
      let groupSubscriber = this.projectService.getPaginatedSuggestions("groups", null, 100);

      groupSubscriber.
        subscribe(
          data => {
            const groups = data as unknown as ISuggestion[];
            groups.forEach(row => {
              row['selected'] = (this.isEdit || this.isClone) ? (this.selectedGroupIds.includes(row.id)) : false;
            });
            this.groupSuggestions = groups;

            this.FormData?.groups?.forEach(element => {
              if (!this.groupSuggestions.some(e => e.id === element.groupId)) {
                let groupSugg = {
                  "id": element.groupId,
                  "name": element.groupTitle,
                  "description": element.groupDescription,
                  "selected": true
                }

                if (!this.groupSuggestions.some(g => g.id === groupSugg.id))
                  this.groupSuggestions.push(groupSugg);
              }
            });

            if (this.isEdit || this.isClone)
              this.createSelectedGroupListForDisplay();
            if (!(this.isEdit || this.isClone) && !keepDrawerClose)
              this.showDrawer = true;

            $('#refreshTask').removeClass('fa-rotation');
          },
          error => { this.isError = true; this.errorMsg = error; });
    }
    else {
      this.showDrawer = true;
    }
  }

  removeGroup(id) {
    let removedGroup = this.selectedGroupsForDisplay.find(items => items.id == id);
    this.removedGroupIds.push(removedGroup.id);
    removedGroup.selected = false;
    const index = this.selectedGroupIds.indexOf(removedGroup.id);
    this.selectedGroupIds.splice(index, 1);
    this.createSelectedGroupListForDisplay();
  }

  drawerGroupCancel() {
    $('.container-page').removeClass('overflow-hidden');
    this.searchGroupString = '';
    this.toggleDrawer();
    this.resetGroupSelection();
  }

  resetGroupSelection() {
    if (this.groupSuggestions) {
      this.groupSuggestions.forEach(element => {
        element.selected = !(this.selectedGroupIds.indexOf(element.id) < 0);
      });
    }
  }

  drawerGroupSubmit() {
    $('.container-page').removeClass('overflow-hidden');
    const selected = this.groupSuggestions.filter(items => items.selected);
    this.selectedGroupIds = selected.map(item => item.id);
    this.createSelectedGroupListForDisplay();
    this.toggleDrawer();
    this.searchGroupString = '';
    this.removedGroupIds = [];
  }

  createSelectedGroupListForDisplay() {
    const selected = this.groupSuggestions.filter(items => items.selected);
    this.selectedGroupsForDisplay = [];
    this.selectedGroupsForDisplay = selected;

    this.FormData?.groups?.forEach(element => {
      if (!this.groupSuggestions.some(e => e.id === element.groupId)) {
        let groupSugg = {
          "id": element.groupId,
          "name": element.groupTitle,
          "description": element.groupDescription,
          "selected": true
        }
        this.groupSuggestions.push(groupSugg);
      }
    });
  }

  toggleGroup() {
    if (this.isRestrictedAdminRole()) {
      this.showGroupSelectionOptions = true;
    }
    else {
      this.resetGroupComponent();
      this.showGroupSelectionOptions = false;
    }
  }

  isRestrictedAdminRole() {
    for (let p in this.selectedRoleListForDisplay) {
      const role = this.selectedRoleListForDisplay[p].title.toLowerCase();
      if (role.indexOf('restricted admin') != -1 || role.indexOf('restricted admin - read only') != -1) {
        return true;
      }
    }
    return false;
  }

  public isRestrictedAdminRoleForBaseGroup(): boolean {
    let showBaseGroup = false;
    this.selectedBaseRoles.forEach(item => {
      const role = item.name.toLowerCase();
      if (role === 'restricted admin' || role === 'restricted admin - read only') {
        showBaseGroup = true;
      }
    });

    if (showBaseGroup && !this.groupSuggestions) {
      this.groupSuggestions = [];
      this.getGroupsByTitle('');
    }

    return showBaseGroup;
  }

  isAdminRole() {
    for (let p in this.selectedRoleListForDisplay) {
      const role = this.selectedRoleListForDisplay[p].title.toLowerCase();
      if (role.indexOf("admin") != -1) {
        return true;
      }
    }
    return false;
  }

  resetGroupComponent() {
    this.showGroupSelectionOptions = false;
    this.selectedGroupIds = [];
    this.selectedGroupsForDisplay = [];
    this.resetGroupSelection();
    this.ValidGroup();
  }

  isSearchGroupValid(groupName) {
    return !groupName || (groupName && REGEX_PATTERNS.name_title_validator.test(groupName));
  }

  getGroupsByTitle(searchValue) {
    if (this.isSearchGroupValid(searchValue)) {
      const selected = this.groupSuggestions.filter(items => items.selected);
      this.selectedGroupIds = selected?.map(item => item.id);

      let params = [];
      params.push({ paramName: "title", value: searchValue });
      let groupSubscriber = this.projectService.getPaginatedSuggestions("groups", params, 100);

      groupSubscriber.
        subscribe(
          data => {
            const groups = data as unknown as ISuggestion[]
            groups.forEach(row => {
              row['selected'] = (this.isEdit) ? (this.selectedGroupIds.includes(row.id)) : false;
            });

            this.groupSuggestions = groups;

            selected?.forEach(element => {
              let groupSugg = {
                "id": element.id,
                "name": element.name,
                "description": element.description,
                "selected": true
              }

              if (!this.groupSuggestions.some(g => g.id === groupSugg.id))
                this.groupSuggestions.push(groupSugg);
            });

            this.selectedGroupsForDisplay.forEach(element => {
              if (!this.groupSuggestions.some(e => e.id === element.id))
                this.groupSuggestions.push(element);
            });

            if (this.isEdit)
              this.createSelectedGroupListForDisplay2();
            $('#refreshTask').removeClass('fa-rotation');
          },
          error => { this.isError = true; this.errorMsg = error; });
    }
  }

  onGroupSearchChange(searchValue: string): void {
    this.getGroupsByTitle(searchValue);
  }

  isSearchUserGroupValid(groupName) {
    return REGEX_PATTERNS.name_title_validator.test(groupName);
  }

  createSelectedGroupListForDisplay2() {
    const selected = this.groupSuggestions.filter(items => items.selected);
    if (this.selectedGroupsForDisplay.length === 0) {
      this.selectedGroupsForDisplay = selected;
    }
    else {
      this.selectedGroupsForDisplay = ([... new Set([...selected, ...this.selectedGroupsForDisplay])].filter(item => item.selected)).filter((thing, i, arr) => { return arr.indexOf(arr.find(t => t.id === thing.id)) === i; });
    }
  }
  /**************************** Group Drawer Code Implementation Ends *********************************** */

  /********************** FAVORITE PRODUCT CHANGES ******************************/
  showRoleDrawer: boolean = false; //Flag to identity if Role drawer needs to be opened.
  showProductDrawer = false;
  productSuggestions;
  selectedProductIds: number[] = []; //array of selected product ids
  selectedProductsForDisplay = []; //array of products with details to show on UI
  searchProductString: string = '';
  showSelectProductOption: boolean = true;
  //Open Product Drawer
  openProductsDrawer() {
    $('#containerPage').scrollTop(0);
    $('.container-page').addClass('overflow-hidden');
    $('body').addClass('overflow-hidden');
    if (this.isDrawerType) {
      $('#createNewUserDw').scrollTop(0);
      $('#createNewUserDw').addClass('overflow-hidden');
    }

    this.switchDrawer('Product');
    if (this.isEdit || this.isClone) {
      this.toggleDrawer();
    }
    else {
      this.initQueryParams();
      this.getProducts();
    }
  }
  //Get Product list
  getProducts(keepDrawerClose = false) {
    if (this.productSuggestions == null) {
      $('#refreshTask').addClass('fa-rotation');

      let orgId = this.projectService.fetchIdForRequest(this.organizationSuggestion, this.userForm.controls['organization'].value);
      let productSubscriber = this.projectService.getSuggestionsWithoutFavoritesResponse("products", "orgId", orgId, "");

      /* this.projectService.getSuggestionsResponse("products", "", "title", this.queryParam)
      if (this.isAdmin && !(this.isEdit || this.isClone)) {
        let orgId = this.projectService.fetchIdForRequest(this.organizationSuggestion, this.userForm.controls['organization'].value);
        if (orgId > -1) productSubscriber = this.projectService.getSuggestionsWithoutFavoritesResponse("products", "orgId", orgId, "");
       }
      */

      productSubscriber.
        subscribe(
          data => {
            const products = data.body as unknown as ISuggestion[]
            products.forEach(row => {
              row['selected'] = (this.isEdit || this.isClone) ? (this.selectedProductIds.includes(row.id)) : false;
            });

            this.productSuggestions = products;

            if (this.userForm.controls['organization'].value === this.FormData?.organizationTitle) {
              this.FormData?.products?.forEach(element => {
                if (!this.productSuggestions.some(e => e.id === element.productId)) {
                  let productSugg = {
                    "id": element.productId,
                    "title": element.productTitle,
                    "description": element.productDescription,
                    "selected": true
                  }
                  this.productSuggestions.push(productSugg);
                }
              });
            }

            if (this.isEdit || this.isClone)
              this.createSelectedProductListForDisplay();
            if (!(this.isEdit || this.isClone) && !keepDrawerClose)
              this.showDrawer = true;

            $('#refreshTask').removeClass('fa-rotation');
          },
          error => { this.isError = true; this.errorMsg = error; });
    }
    else {
      this.showDrawer = true;
    }
  }

  getProductsByTitle(searchValue, keepDrawerClose = false) {
    if (this.isSearchProductValid(searchValue)) {
      const orgId = this.projectService.fetchIdForRequest(this.organizationSuggestion, this.userForm.controls['organization'].value);
      // let productSubscriber = this.projectService.getSuggestionsResponse("products", searchValue, "title", this.queryParam, false)
      let productSubscriber = this.projectService.getSuggestionsResponseWithOrgId("products", searchValue, "title", this.queryParam, false, orgId);
      if (this.isAdmin && !(this.isEdit || this.isClone)) {
        // let orgId = this.projectService.fetchIdForRequest(this.organizationSuggestion, this.userForm.controls['organization'].value);
        if (orgId > -1) productSubscriber = this.projectService.getSuggestionsWithoutFavoritesResponse("products", "orgId", orgId, searchValue);
      }

      productSubscriber.
        subscribe(
          data => {
            const products = data.body as unknown as ISuggestion[]
            products.forEach(row => {
              row['selected'] = (this.isEdit || this.isClone) ? (this.selectedProductIds.includes(row.id)) : false;
            });

            this.productSuggestions = products;

            this.selectedProductsForDisplay.forEach(element => {
              if (!this.productSuggestions.some(e => e.id === element.id))
                this.productSuggestions.push(element);
            });

            if (this.isEdit)
              this.createSelectedProductListForDisplay2();
            $('#refreshTask').removeClass('fa-rotation');
          },
          error => { this.isError = true; this.errorMsg = error; });
    }
  }

  onSearchChange(searchValue: string): void {
    this.getProductsByTitle(searchValue);

  }

  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: 100
    });
  }

  //Drawer close event
  drawerProductCancel() {
    $('.container-page').removeClass('overflow-hidden');
    $('body').removeClass('overflow-hidden');

    if ($('#createNewUserDw').hasClass('overflow-hidden')) {
      $('.container-page').addClass('overflow-hidden');
      $('#createNewUserDw').removeClass('overflow-hidden')
    }
    this.searchProductString = '';
    this.toggleDrawer();
    this.resetSelection();

  }
  //Drawer Submit event
  drawerProductSubmit() {
    const selected = this.productSuggestions.filter(items => items.selected);
    this.selectedProductIds = selected.map(item => item.id);
    this.createSelectedProductListForDisplay();
    this.toggleDrawer();
    this.searchProductString = '';
    $('.container-page').removeClass('overflow-hidden');
    $('body').removeClass('overflow-hidden');

    if ($('#createNewUserDw').hasClass('overflow-hidden')) {
      $('.container-page').addClass('overflow-hidden');
      $('#createNewUserDw').removeClass('overflow-hidden');
    }

    this.removedProductIds = [];
  }
  //Create product list for UI
  createSelectedProductListForDisplay() {
    this.selectedProductsForDisplay = [];
    this.selectedProductsForDisplay = this.productSuggestions.filter(
      data => this.selectedProductIds.includes(data.id)
    );

    let userProductIds = this.selectedProductsForDisplay.map(item => item.id);

    if (!this.isAdmin && this.isEdit && this.editProductCounter === 0) {
      this.editProductCounter = 1;
      this.nonAdminSelectedProductListForDisplay = this.FormData?.products
        .filter(function (item) {
          return userProductIds.indexOf(item.productId) === -1;
        });;
    }

    /*
    const selected = this.productSuggestions.filter(items => items.selected);
    this.selectedProductsForDisplay = [];
    this.selectedProductsForDisplay = selected;
 
    this.FormData?.products?.forEach(element => {
      if (!this.selectedProductsForDisplay.some(e => e.id === element.productId)
        && !this.removedProductIds.some(e => e === element.productId)
        && this.selectedProductIds.some(e => e === element.productId)) {
        let productSugg = {
          "id": element.productId,
          "title": element.productTitle,
          "description": element.productDescription,
          "selected": true
        }
        this.selectedProductsForDisplay.push(productSugg);
      }
    });
    */
  }

  //Create product list for UI
  createSelectedProductListForDisplay2() {
    const selected = this.productSuggestions.filter(items => items.selected);
    if (this.selectedProductsForDisplay.length === 0) {
      this.selectedProductsForDisplay = selected;
    }
    else {
      this.selectedProductsForDisplay = ([... new Set([...selected, ...this.selectedProductsForDisplay])].filter(item => item.selected)).filter((thing, i, arr) => { return arr.indexOf(arr.find(t => t.id === thing.id)) === i; });
    }
  }
  //Remove product button click event
  removeProduct(id) {
    let removedProduct = this.selectedProductsForDisplay.find(items => items.id == id);
    this.removedProductIds.push(removedProduct.id);
    removedProduct.selected = false;
    const index = this.selectedProductIds.indexOf(removedProduct.id);
    this.selectedProductIds.splice(index, 1);
    this.createSelectedProductListForDisplay();
  }
  //Resetting checkbox selection on drawer close
  resetSelection() {
    if (this.productSuggestions) {
      this.productSuggestions.forEach(element => {
        element.selected = !(this.selectedProductIds.indexOf(element.id) < 0);
      });
    }
  }
  //To hide/show select favorite button
  showFavoriteProduct() {
    let show = true;
    // let userType = this.isEdit ? this.FormData.type : this.selectedUserType;
    // if ((this.isDrawerType || (this.userData.id === this.FormData?.id && this.isEdit) // || this.isRespondentRoleOnly()) && !this.isAdmin)
    if (this.isDrawerType)
      show = false;
    // if (this.isIntakeRequestRole()) show = true;
    return show;
  }

  //to check if only respondent role is selected
  isRespondentRoleOnly() {
    let result = false;
    if (this.selectedRoleListForDisplay.length == 1) {
      const role = this.selectedRoleListForDisplay[0].title.toLowerCase();
      if (role.indexOf("respondent") > -1)
        result = true;
    }
    return result;
  }

  isIntakeRequestRole() {
    let result = false;
    for (let p in this.selectedRoleListForDisplay) {
      const role = this.selectedRoleListForDisplay[p].title.toLowerCase();
      if (role.indexOf("intake form") != -1)
        result = true;
    }
    return result;
  }

  //to remove all favorite products
  removeFavoriteProducts() {
    this.selectedProductIds = [];
    this.selectedProductsForDisplay = [];
    this.resetSelection();
  }

  /******** Channel Partner Changes ********************/
  onClientSearchChange(searchValue: string = '') {
    this.getClientsByTitle(searchValue);
  }

  getClientsByTitle(searchValue: string = '') {
    let clientSubscriber: any;
    let params = [];
    if (searchValue && searchValue.length >= 3) {
      params.push({ paramName: "titleWithDuns", value: searchValue });
      clientSubscriber = this.projectService.getClientSuggestions("clients", params, 100);
    }
    else {
      clientSubscriber = this.projectService.getClientSuggestions("clients", null, 100);
    }

    clientSubscriber.
      subscribe(
        data => {
          const clients = data as unknown as ISuggestion[];
          clients.forEach(row => {
            row['selected'] = (this.isEdit) ? (this.selectedChannelPartnerIds.includes(row.id)) : false;
          });

          this.channelPartnerSuggestions = clients;
          this.selectedChannelPartnerForDisplay.forEach(element => {
            if (!this.channelPartnerSuggestions.some(e => e.id === element.id))
              this.channelPartnerSuggestions.push(element);
          });

          if (this.isEdit)
            this.createSelectedChannelPartnerListForDisplay2();
        },
        error => { this.isError = true; this.errorMsg = error; });
  }

  toggleChannelPartner() {
    const userType = (this.isEdit || this.isClone) ? this.FormData?.type : this.selectedUserType;
    if (userType && userType.toLowerCase() === 'client') {
      if (this.isChannelPartnerRole()) {
        this.showChannelPartnerSelectionOptions = true;
      } else {
        this.resetPartnerClientSelection();
      }
    } else {
      this.showChannelPartnerSelectionOptions = false;
    }
  }

  openChannelPartnerDrawer() {
    this.isChannelPartnerFormInvalid = false;
    $('#containerPage').scrollTop(0);
    $('.container-page').addClass('overflow-hidden');

    this.switchDrawer('Partner');
    if (this.isEdit || this.isClone) {
      this.toggleDrawer();
    }
    else {
      this.getChannelPartnerSuggestions();
    }
  }

  removeChannelPartner(id: number) {
    const removedChannelPartner = this.selectedChannelPartnerForDisplay.find(items => items.id === id);
    this.removedChannelPartnerIds.push(removedChannelPartner.id);
    removedChannelPartner.selected = false;
    const index = this.selectedChannelPartnerIds.indexOf(removedChannelPartner.id);
    this.selectedChannelPartnerIds.splice(index, 1);
    this.createSelectedChannelPartnerListForDisplay();
  }

  resetPartnerClientSelection() {
    this.showChannelPartnerSelectionOptions = false;
    this.selectedChannelPartnerIds = [];
    this.selectedChannelPartnerForDisplay = [];
    this.resetPartnerClientSuggestions();
    this.validChannelPartner();
  }

  resetPartnerClientSuggestions() {
    if (this.channelPartnerSuggestions) {
      this.channelPartnerSuggestions.forEach(element => {
        element.selected = !(this.selectedChannelPartnerIds.indexOf(element.id) < 0);
      });
    }
  }

  getChannelPartnerSuggestions(keepDrawerClose = false) {
    if (this.channelPartnerSuggestions == null) {
      this.projectService.getClientSuggestions('clients', null, 100)
        .subscribe(
          data => {
            const clients = data as unknown as ISuggestion[];
            clients.forEach(row => {
              row['selected'] = (this.isEdit || this.isClone) ? (this.selectedChannelPartnerIds.includes(row.id)) : false;
            });

            this.channelPartnerSuggestions = clients;

            this.FormData?.clients?.forEach(element => {
              if (!this.channelPartnerSuggestions.some(e => e.id === element.clientId)) {
                let clntSugg = {
                  "id": element.clientId,
                  "title": element.clientTitle,
                  "description": element.clientDescription,
                  "mdmClientMasterId": 0,
                  "selected": true
                }
                this.channelPartnerSuggestions.push(clntSugg);
              }
            });

            if (this.isEdit || this.isClone) {
              this.createSelectedChannelPartnerListForDisplay();
            }
            if (!(this.isEdit || this.isClone) && !keepDrawerClose) {
              this.showDrawer = true;
            }
          },
          error => { this.isError = true; this.errorMsg = error; });
    }
    else {
      this.showDrawer = true;
    }
  }

  // resetClientList(keepDrawerClose = false) {
  //   this.searchString = '';
  //   $('#refreshTask').addClass('fa-rotation');
  //   this.projectService.getClientSuggestions('clients',null, 100)
  //   .subscribe(
  //     data => {
  //       const clients = data as unknown as ISuggestion[];
  //       clients.forEach(row => {
  //         row['selected'] = (this.isEdit || this.isClone) ? (this.selectedChannelPartnerIds.includes(row.id)) : false;
  //       });
  //       this.channelPartnerSuggestions = clients;

  //       $('#refreshTask').removeClass('fa-rotation');
  //     },
  //     error => { this.isError = true; this.errorMsg = error; } );
  // }

  // Create partner client list for UI
  createSelectedChannelPartnerListForDisplay() {
    const selected = this.channelPartnerSuggestions.filter(items => items.selected);
    this.selectedChannelPartnerForDisplay = [];
    this.selectedChannelPartnerForDisplay = selected;

    this.FormData?.clients?.forEach(element => {
      if (!this.selectedChannelPartnerForDisplay.some(e => e.id === element.clientId)
        && !this.removedChannelPartnerIds.some(e => e === element.clientId)
        && this.selectedChannelPartnerIds.some(e => e === element.clientId)) {
        let clntSugg = {
          "id": element.clientId,
          "title": element.clientTitle,
          "description": element.clientDescription,
          "mdmClientMasterId": 0,
          "selected": true
        }
        this.selectedChannelPartnerForDisplay.push(clntSugg);
      }
    });
  }

  createSelectedChannelPartnerListForDisplay2() {
    const selected = this.channelPartnerSuggestions.filter(items => items.selected);
    if (this.selectedChannelPartnerForDisplay.length === 0) {
      this.selectedChannelPartnerForDisplay = selected;
    }
    else {
      this.selectedChannelPartnerForDisplay = ([... new Set([...selected, ...this.selectedChannelPartnerForDisplay])].filter(item => item.selected)).filter((thing, i, arr) => { return arr.indexOf(arr.find(t => t.id === thing.id)) === i; });
    }
  }

  isChannelPartnerRole() {
    for (let p in this.selectedRoleListForDisplay) {
      const role = this.selectedRoleListForDisplay[p].title.toLowerCase();
      if (role.indexOf("channel partner") != -1) {
        return true;
      }
    }
    return false;
  }

  //#region Page Template section
  public translatePageTemplateAccessType(pageTemplateAccessType: PageTemplateAccessType): string {
    if (pageTemplateAccessType === PageTemplateAccessType.Admin) {
      return 'Admin';
    } else if (pageTemplateAccessType === PageTemplateAccessType.ReadAll) {
      return 'Read all';
    } else if (pageTemplateAccessType === PageTemplateAccessType.View) {
      return 'View';
    } else {
      return '';
    }
  }

  public drawerPageTemplateCancel(): void {
    $('.container-page').removeClass('overflow-hidden');
    this.searchGroupString = '';
    this.toggleDrawer();
    this.pageTemplateTempData = [];
  }

  public drawerPageTemplateSubmit(): void {
    $('.container-page').removeClass('overflow-hidden');

    this.pageTemplateDataForDisplay = [];
    this.pageTemplateTempData.forEach(item => {
      this.pageTemplateDataForDisplay.push(item);
    });

    this.toggleDrawer();
  }

  public openPageTemplatesDrawer(): void {
    if (this.isAdmin) {
      this.pageTemplateQueryParam = Object.assign({}, {
        title: '',
        pageNumber: this.currentPageNumberForLoadPageTemplate,
        pageSize: environment.pageSize
      });
      $('#containerPage').scrollTop(0);
      $('.container-page').addClass('overflow-hidden');
      this.switchDrawer('PageTemplate');
      this.isRespondentDeselected = false;
      this.toggleDrawer();

      this.pageTemplateTempData = [];
      this.pageTemplateDataForDisplay.forEach(item => {
        this.pageTemplateTempData.push(item);
      });

      this.loadPageTemplates();
    }
  }

  public removePageTemplate(id: number, type: PageTemplateAccessType): void {
    const temp = this.pageTemplateDataForDisplay.filter(r => r.id === id && r.accessType === type)[0];
    this.pageTemplateDataForDisplay = this.pageTemplateDataForDisplay.filter(r => r !== temp);
  }

  public loadPageTemplates(): void {
    this.viewCheckbox = false;
    this.readAllCheckbox = false;
    this.adminCheckbox = false;
    this.pageTemplateService.getPageTemplateList(this.pageTemplateQueryParam)
      .pipe(take(1))
      .subscribe(
        pages => {
          this.isError = false;
          this.pageTemplateList = pages.body as ICreatePageTemplates;
          if (pages.headers.get('X-pagination')) {
            this.pagerObjectForPageTemplate = Object.assign({}, JSON.parse(pages.headers.get('X-pagination')) as IPager);
          }
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  public pageTemplateChangePage(page: number): void {
    this.pageTemplateQueryParam.pageNumber = page;
    this.loadPageTemplates();
  }

  public onPageTemplateCheckboxChanged(id: number, title: string, type: PageTemplateAccessType): void {
    if (this.pageTemplateTempData.find(r => r.id === id && r.accessType === type) !== undefined) {
      const temp = this.pageTemplateTempData.filter(r => r.id === id && r.accessType === type)[0];
      this.pageTemplateTempData = this.pageTemplateTempData.filter(r => r !== temp);
    } else {
      const data: PageTemplateAccess = {
        id: id,
        title: title,
        accessType: type
      };
      this.pageTemplateTempData.push(data);
    }
  }

  public pageTemplateReadDisable(id: number): boolean {
    if (this.pageTemplateTempData.find(r => r.id === id && r.accessType === PageTemplateAccessType.Admin) !== undefined) {
      if (this.pageTemplateTempData.find(r => r.id === id && r.accessType === PageTemplateAccessType.ReadAll) !== undefined) {
        const temp = this.pageTemplateTempData.filter(r => r.id === id && r.accessType === PageTemplateAccessType.ReadAll)[0];
        this.pageTemplateTempData = this.pageTemplateTempData.filter(r => r !== temp);
      }
      return true;
    } else {
      return false;
    }
  }

  public pageTemplateCheckboxCheckedStatus(id: number, type: PageTemplateAccessType): string {
    let result = '';

    switch (type) {
      case PageTemplateAccessType.View:
        result = this.viewCheckbox === true ? 'Checked' : this.pageTemplateTempData.find(r => r.id === id && r.accessType === PageTemplateAccessType.View) ? 'Checked' : '';
        break;
      case PageTemplateAccessType.ReadAll:
        if (this.readAllCheckbox === true) {
          if (this.pageTemplateTempData.find(r => r.id === id && r.accessType === PageTemplateAccessType.Admin) === undefined) {
            result = 'Checked';
          }
        } else {
          if (this.pageTemplateTempData.find(r => r.id === id && r.accessType === PageTemplateAccessType.ReadAll)) {
            result = 'Checked';

          }
        }
        break;
      case PageTemplateAccessType.Admin:
        result = this.adminCheckbox === true ? 'Checked' : this.pageTemplateTempData.find(r => r.id === id && r.accessType === PageTemplateAccessType.Admin) ? 'Checked' : '';
        break;
      default:
        result = '';
        break;
    }
    return result;
  }

  public onCheckboxStateChanged(checkboxState: boolean, pageTemplateAccessType: PageTemplateAccessType): void {
    if (checkboxState === true) {
      this.pageTemplateList.pageTemplates.forEach(item => {
        const data: PageTemplateAccess = {
          id: item.id,
          title: item.title,
          accessType: pageTemplateAccessType
        };
        if (!this.pageTemplateTempData.find(r => r.id === data.id && r.accessType === data.accessType)) {
          if (pageTemplateAccessType === PageTemplateAccessType.Admin ||
            this.pageTemplateTempData.find(r => r.id === data.id && r.accessType === PageTemplateAccessType.Admin) === undefined) {
            this.pageTemplateTempData.push(data);
          }
        }
        if (pageTemplateAccessType === PageTemplateAccessType.Admin) {
          this.pageTemplateReadDisable(item.id);
        }
      });
    } else {
      this.pageTemplateTempData = this.pageTemplateTempData.filter(r => r.accessType !== pageTemplateAccessType);
    }
  }
  //#endregion

  switchDrawer(name: string) {
    switch (name) {
      case 'Role':
        this.showRoleDrawer = true;
        this.showProductDrawer = false;
        this.showChannelPartnerDrawer = false;
        this.showGroupDrawer = false;
        this.showPageTemplateDrawer = false;
        break;
      case 'Product':
        this.showRoleDrawer = false;
        this.showProductDrawer = true;
        this.showChannelPartnerDrawer = false;
        this.showGroupDrawer = false;
        this.showPageTemplateDrawer = false;
        break;
      case 'Partner':
        this.showRoleDrawer = false;
        this.showProductDrawer = false;
        this.showChannelPartnerDrawer = true;
        this.showGroupDrawer = false;
        this.showPageTemplateDrawer = false;
        break;
      case 'Group':
        this.showRoleDrawer = false;
        this.showProductDrawer = false;
        this.showChannelPartnerDrawer = false;
        this.showGroupDrawer = true;
        this.showPageTemplateDrawer = false;
        break;
      case 'PageTemplate':
        this.showRoleDrawer = false;
        this.showProductDrawer = false;
        this.showChannelPartnerDrawer = false;
        this.showGroupDrawer = false;
        this.showPageTemplateDrawer = true;
        break;
    }
  }

  drawerChannelPartnerSubmit() {
    const selected = this.channelPartnerSuggestions.filter(items => items.selected);

    this.selectedChannelPartnerIds = selected.map(item => item.id);
    if (this.selectedChannelPartnerIds.length === 0) {
      this.isChannelPartnerFormInvalid = true;
    } else {
      this.createSelectedChannelPartnerListForDisplay();
      this.isChannelPartnerFormInvalid = false;
      this.toggleDrawer();
      $('.container-page').removeClass('overflow-hidden');
    }
    this.removedChannelPartnerIds = [];
    this.searchChannelPartnerString = '';
  }

  drawerChannelPartnerCancel() {
    this.isChannelPartnerFormInvalid = false;
    $('.container-page').removeClass('overflow-hidden');
    this.searchChannelPartnerString = '';
    this.toggleDrawer();
    this.resetPartnerClientSuggestions();
    $('.container-page').removeClass('overflow-hidden');
  }

  validChannelPartner(defaultValue = true) {
    return this.showChannelPartnerSelectionOptions ? this.selectedChannelPartnerIds.length > 0 : defaultValue;
  }

  public channelPartnerIsExists(): boolean {
    return this.selectedChannelPartnerIds.length === 0 && this.selectedChannelPartnerForDisplay.length > 0;
  }

  ValidGroup(defaultValue = true) {
    return this.showGroupSelectionOptions ? this.selectedGroupIds.length > 0 : defaultValue;
  }

  fetchDetailsFromAD() {
    this.userADDetails = undefined;
    this.ADError = false;
    this.userEmail = this.userForm.value.email;
    this.userService.getUserDetails(this.userEmail)
      .subscribe(
        data => {
          this.userADDetails = Object.assign({}, data);
          this.ADError = false;
        },
        (error) => { this.ADError = true; }
      );
  }

  closeMessage() {
    this.ADError = false;
  }

  isSearchProductValid(productName) {
    return !productName || (productName && REGEX_PATTERNS.name_title_validator.test(productName));
  }

  /****************************************************** */

  onOrgSelect(event: any) {
    if (event.item.id > 0) {
      this.baseOrganizationIsToched = false;
      this.invalidOrganizationName = false;
      this.productSuggestions = null;
      this.selectedProductsForDisplay = [];
      this.selectedProductIds = [];
      this.getProducts(true);
      this.onOrganizationSuggestionChanged();
    }
  }
}

//Atleast 1 checkbox selected validator
function minSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: FormArray) => {
    const totalSelected = formArray.controls
      .map(control => control.value)
      .reduce((prev, next) => next ? prev + next : prev, 0);
    return totalSelected >= min ? null : { required: true };
  };

  return validator;
}
//Conditional Validator for ClientId
function requiredIfValidator(predicate) {
  return (formControl => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return Validators.required(formControl);
    }
    return null;
  })
}


