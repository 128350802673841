import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISuggestions } from '../shared/suggestion';

@Injectable({
  providedIn: 'root',
})
export class ManualCommitmentService {
  private apiUrl = 'manualcommitment';
  private buApiUrl = 'manualcommitmentbusinessunit';
  private pageTemplateResponseApiUrl = 'pagetemplateresponsedelegate';

  constructor(private http: HttpClient) {}

  callorgSpecificConfiguration(param) {
    return this.http.get('/assets/configuration/' + param + '.json');
  }

  get(id: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/' + id);
  }

  getList(queryParams, refresh = false) {
    return this.getApiWithParams(refresh, this.apiUrl, queryParams);
  }

  getCommitmentSuggestions(name, value, refresh = false) {
    let params = new HttpParams();
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: refresh ? 'true' : 'false',
      }),
    }; 

    return this.http.get(this.apiUrl + `/suggestions?name=${name}&value=${value}`, {
      headers: httpOptions.headers,
      params: params,
      observe: 'response',
    });
  }
  
  create(data: any): Observable<any> {
    return this.http.post(this.apiUrl, data);
  }

  update(id: string, data: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/${id}`, data);
  }

  bulkUpdate(data: Array<any>): Observable<any> {
    return this.http.put(`${this.apiUrl}/bulkUpdate`, data);
  }

  delete(id): Observable<any> {
    return this.http.delete(this.apiUrl + '/' + id);
  }

  getSearchSuggestions(queryParams, refresh = false) {
    const url = this.apiUrl + '/searchSuggestions';
    return this.getApiWithParams(refresh, url, queryParams);
  }

  getReferenceData(refresh = false) {
    const url = this.apiUrl + '/referenceData';
    return this.getApiWithParams(refresh, url, null);
  }

  getECRMProducts(refresh = false) {
    const url = this.apiUrl + '/ecrmProducts';
    return this.getApiWithParams(refresh, url, null);
  }

  getECRMClientsByUser(userId: number, queryParams, refresh = false) {
    const url = this.apiUrl + `/ecrmClientsByUser/${userId}`;
    return this.getApiWithParams(refresh, url, queryParams);
  }

  private getApiWithParams(refresh = false, url: string, queryParams: any): any {
    let params = new HttpParams();
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: refresh ? 'true' : 'false',
      }),
    }; 

    if (queryParams) {
      for (let key of Object.keys(queryParams)) {
        let value = queryParams[key];
        params = params.append(key, value);
      }
    }

    return this.http.get(url, {
      headers: httpOptions.headers,
      params: params,
      observe: 'response',
    });
  }
}