import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, SimpleChanges } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder, FormArray, ValidatorFn } from '@angular/forms';
import { IRespondent } from 'src/app/shared/user';
import { ProjectsService } from 'src/app/services/projects.service';
import { MAX_LENGTHS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { Subscription } from 'rxjs';
import { NoWhitespaceValidator } from 'src/app/shared/no-whitespace.validator';
import { HostListener } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { CommitmentDelegateDetailsComponent } from 'src/app/SharedModule/Components/commitment-delegate-details/commitment-delegate-details.component';
import { CommitmentDelegateService } from 'src/app/services/commitment-delegate.service';
import { ICommitmentDelegate } from 'src/app/shared/commitment-delegate';
import { ISuggestions } from 'src/app/shared/suggestion';
import { formatDate } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-commitment-delegate-select-popup',
  templateUrl: './commitment-delegate-select-popup.component.html',
  styleUrls: ['./commitment-delegate-select-popup.component.css']
})
export class CommitmentDelegateSelectPopupComponent implements OnInit, OnDestroy {
  @HostListener('click', ['$event'])
  clickEvent(event) {
    if (event.target.type != 'date' && event.target.attributes.name && event.target.attributes.name.value != 'respondentType') {
      event.preventDefault();
      event.stopPropagation();
      event.currentTarget.disabled = true;
    }
  }
  @Input() delegateFormData: any;
  @Input() type: string;
  @Input() mode: string = "delegate";
  @Input() userSuggestions: ISuggestions;
  @Output() successDelegate = new EventEmitter();
  @ViewChild('commitmentDelegateDetailsComponent') commitmentDelegateDetailsComponent: CommitmentDelegateDetailsComponent;

  delegateForm: FormGroup;
  assignedToId;
  isError: boolean = false;
  errorMsg: string;
  errMsg: string;
  isClicked: boolean = false;
  delegatedSuccessMsg: string = '';
  respondentUsers: IRespondent;
  respondentUsers2: any;
  todaysdate: string;
  _maxLength: any;
  Subscription: Subscription;
  isDelegate: boolean = true;
  filedformApproverDetails: any;
  fieldformApproverJson: any;
  isDuplicate: boolean;
  isReady: boolean = false;
  isSurvey: boolean = false;
  isUserLoaded: boolean = false;
  isSubmitted: boolean = false;
  respondentType: any;
  regexValidationMessage: any;
  isReadOnly = false;
  isEdit = false;
  isCreate = false;
  

  constructor(
    private projectsService: ProjectsService,
    private fb: FormBuilder,
    public _userService: UserService,
    private commitmentDelegateService: CommitmentDelegateService,) { }

  ngOnChanges(data: SimpleChanges) {
    this.isDelegate = (this.mode == "delegate");

    if (data.userSuggestions && !data.userSuggestions.firstChange && data.userSuggestions.currentValue) {
      this.getAllUsers(data.userSuggestions.currentValue);
    }

    if (data.isCreate && !data.isCreate.firstChange && data.isCreate.currentValue) {
      this.isCreate = data.isCreate.currentValue;
    }
  }

  ngOnDestroy() {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }

  initiateForm() {
    this.delegateForm = new FormGroup({
      'respondentName': this.fb.array([]),
      'guest': this.fb.array([]),
      'userEmail': new FormControl(''),
      'dueDate': new FormControl(this.getDueDate()),
      'comments': new FormControl('', [Validators.required, Validators.maxLength(this._maxLength.Delegate.Comments), NoWhitespaceValidator.validateWhiteSpaces, Validators.pattern(REGEX_PATTERNS.description_comment)]),
      'respondentType': new FormControl('')
    });

    this.delegateForm.valueChanges.subscribe(() => {
      if (this.delegateForm.valid && this.isValidToUser()) {
        this.errorMsg = '';
      }
    });
  }

  requiredIfValidator(predicate) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    })
  }

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.todaysdate = this.projectsService.getTodaysDate();
    this._maxLength = MAX_LENGTHS;
    this.filedformApproverDetails = [
      { 'name': 'name', 'type': 'fdl', 'showEmailAsLabel': 'no', 'label': 'RM/AM/SE/AE Name', 'isRequired': true, 'fdldata': this.respondentUsers2, 'emailField': 'emails', "className": "col-md-12", "erroeMessage": { 'valid': 'Please select RM/AM/SE/AE name.' } },
      { 'name': 'emails', 'type': 'fdl', 'showEmailAsLabel': 'no', 'label': 'RM/AM/SE/AE Email', 'isRequired': true, 'fdldata': this.respondentUsers2, 'nameField': 'name', "className": "col-md-12", "erroeMessage": { 'valid': 'Please select RM/AM/SE/AE email.' } },
      { 'name': 'role', 'type': 'text', 'showEmailAsLabel': 'no', 'label': 'Roles', 'isRequired': false, 'isDisabled': 'yes', "className": "col-md-6", "erroeMessage": { 'valid': 'Please select role.' } },
      { 'name': 'dueDate', 'type': 'date', 'showEmailAsLabel': 'no', 'label': 'Expire date', 'isRequired': false, 'isDisabled': 'false', "className": "col-md-6", "erroeMessage": { 'valid': 'Please select expire date.' } },
      { 'name': 'id', 'type': 'fdlid' }];

    this.fieldformApproverJson = {
      'json': {
        name: ['', this.formValidators["name"]],
        emails: ['', this.formValidators["email"]],
        role: '',
        dueDate: null,
        id: '',
      }
    }
    this.initiateForm();

  }

  formValidators = {
    email: [this.requiredIfValidator(() => this.isSurvey === false), Validators.pattern(REGEX_PATTERNS.Email), Validators.maxLength(MAX_LENGTHS.User.Email)],
    name: [this.requiredIfValidator(() => this.isSurvey === false)]
  }

  getAllUsers(userSuggestions: any) {
    this.filedformApproverDetails = [
      { 'name': 'name', 'type': 'fdl', 'showEmailAsLabel': 'no', 'label': 'RM/AM/SE/AE Name', 'isRequired': true, 'fdldata': this.respondentUsers2, 'emailField': 'emails', "className": "col-md-12", "erroeMessage": { 'valid': 'Please select RM/AM/SE/AE user.' } },
      { 'name': 'emails', 'type': 'fdl', 'showEmailAsLabel': 'no', 'label': 'RM/AM/SE/AE Email', 'isRequired': true, 'fdldata': this.respondentUsers2, 'nameField': 'name', "className": "col-md-12", "erroeMessage": { 'valid': 'Please select RM/AM/SE/AE email.' } },
      { 'name': 'role', 'type': 'text', 'showEmailAsLabel': 'no', 'label': 'Roles', 'isRequired': false, 'isDisabled': 'yes', "className": "col-md-6", "erroeMessage": { 'valid': 'Please select Role.' } },
      { 'name': 'dueDate', 'type': 'date', 'label': 'Expire date', 'isRequired': false }],
      { 'name': 'id', 'type': 'fdlid' };

    this.isReady = true;
    this.respondentUsers2 = { 'data': userSuggestions };
    console.log('userSuggestions', userSuggestions)
  }

  reponderDataUpdate(userDelegates: any): any {
    if (!userDelegates) return;

    let responder = [];
    responder.push({
      id: userDelegates.fromUserId,
      name: userDelegates.fromUserName,
      emails: userDelegates.fromUserEmail,
      role: userDelegates.fromRoleName
    });

    userDelegates.toUsers.forEach(user => {
      responder.push({
        id: user.toUserId,
        name: user.toUserName,
        emails: user.toUserEmail,
        dueDate:  user.delegationEndDate ? formatDate(user.delegationEndDate, 'yyyy-MM-dd', 'en-US') : null,
        role: user.toUserRoleName
      });
    });
    
    if (responder.length > 0) {
      this.fieldformApproverJson = {
        "dataToEdit": responder,
        'json': {
          name: ['', Validators.required],
          emails: ['', Validators.required],
          dueDate: null,
          role: '',
          id: ''
        }
      }
    }


    if (this.commitmentDelegateDetailsComponent) {
      this.commitmentDelegateDetailsComponent.initForm();
    }
    this.delegateForm.controls['comments'].setValue(userDelegates.comment);
      
  }

  cancelDelegateForm(): any {
    $('#delegateForms').modal('hide');
    this.isError = false;
    this.isClicked = false;
    this.isSubmitted = false;
    this.isReadOnly = false;
    this.isCreate = false;
    this.isEdit = false;

    //this.reponderDataUpdate();
    if (this.commitmentDelegateDetailsComponent) {
      this.commitmentDelegateDetailsComponent.initForm(true);
      this.delegateForm.controls['comments'].setValue(null);
    }
  }

  checkDate(dueDate): any {
    if (dueDate.value < this.todaysdate) {
      this.delegateForm.controls['comments'].invalid;
      return false;
    }
    else {
      this.delegateForm.controls['comments'].valid;
      return true;
    }
  }

  isValidToUser(): boolean {
    const isValid = this.commitmentDelegateDetailsComponent && this.commitmentDelegateDetailsComponent.isInputValid();
    return isValid;
  }

  doDelegate(): any {
    this.errorMsg = '';

    if (!this.delegateForm.valid || !this.isValidToUser()) {
      this.errorMsg = 'Please correct all required fields before delegate. User name, email and comment are required. You must add at least one user to delegate.';
      this.isClicked = true;
      if (this.commitmentDelegateDetailsComponent) {
        this.commitmentDelegateDetailsComponent.isErrorOn(true);
      }
      return;
    }
    else {
      this.isSubmitted = true;
      const command = this.getDelegateUsersCommand(this.delegateForm.value['respondentName']['respondentName'])
      if (command) {
        this.callDelegateService(command);
      }
    }
  }

  getDelegateUsersCommand(users: any[]): any {
    // At least 2 users in the list
    if (!users || users.length < 2) return null;

    let command : ICommitmentDelegate[] = [];
    const fromUser = users[0];
    for (let index = 0; index < users.length; index++) {
      const currentUser = users[index];
      let currentCommand: any = {};
      if (index === 0) {
        currentCommand.isFromUser = true;
        currentCommand.delegationEndDate = null;
      } else {
        currentCommand.delegationEndDate = currentUser.dueDate ? currentUser.dueDate : null;
      }

      currentCommand.fromUserId = fromUser.id;
      currentCommand.fromUserName = fromUser.name;
      currentCommand.fromEmailId = fromUser.emails;
      currentCommand.fromRoleName = fromUser.role;
      currentCommand.assignToUserId = currentUser.id;
      currentCommand.assignToUserName = currentUser.name,
      currentCommand.assignToUserEmailId = currentUser.emails,
      currentCommand.assignToUserRoleName = currentUser.role,
     
      currentCommand.isActive = true,
      currentCommand.comment = this.delegateForm.value.comments

      command.push(currentCommand);
    }

    return command;
  }

  callDelegateService(data: ICommitmentDelegate[]): any {
    if (this.isCreate) {
      this.commitmentDelegateService.create(data).subscribe(
        _ => {
          this.isError = false;
          this.cancelDelegateForm();
          this.delegatedSuccessMsg = `Your request has been created successful.`;
          this.successDelegate.emit({ delegateSuccessMsg: this.delegatedSuccessMsg });
        },
        error =>
        {
          this.isError = true,
          this.errMsg = error;
          this.isSubmitted = false;
        }
      );
    } else {
      this.commitmentDelegateService.update(this.delegateFormData.delegateGUID, data).subscribe(
        _ => {
          this.isError = false;
          this.cancelDelegateForm();
          this.delegatedSuccessMsg = `Your request has been updated successful.`;
          this.successDelegate.emit({ delegateSuccessMsg: this.delegatedSuccessMsg });
        },
        error => { this.isError = true, this.errorMsg = error; this.isSubmitted = false; }
      );
    }
  }

  getDueDate(): any {
    const todaysdate = this.projectsService.getTodaysDate();
    let result = todaysdate;
    if (this.delegateFormData && this.delegateFormData.dueDate) {
      const duedate = this.delegateFormData.dueDate.split('T')[0];
      if (new Date(duedate).getTime() > new Date(todaysdate).getTime())
        result = duedate;
    }
    return result;
  }


  /*********************Multiple Responder******************************/
  formInitializedDelegate(name: string, formSet: any,  form: any) {
    this.delegateForm.setControl(name, form);
    this.reEvaluateValidators(name, 0);
  }


  validation(controlName: string, values: FormArray): ValidatorFn {
    return (): { [key: string]: boolean } | null => {
      let array: any = [];
      if (values && values[controlName]) {

        for (let k in values[controlName]) {
          let keyName = '';
          let fieldValue = values[controlName][k];
          if (controlName == 'respondentName') {
            keyName = fieldValue.id;
          }
          else if (controlName == 'guest') {
            keyName = fieldValue.email;
            keyName = keyName.toLocaleLowerCase();
          } else {
            this.isDuplicate = false;
            return null;
          }


          if (fieldValue.name != '') {
            if (array[keyName])
              array[keyName].push({ "index": k });
            else {
              array[keyName] = [];
              array[keyName].push({ "index": k });
            }
          }
        }
      }
      for (let i in array) {
        if (array[i].length > 1) {
          this.isDuplicate = true;
          return { 'duplicate': true };
        }
      }
      this.isDuplicate = false;
      return null;
    }

  }

  /**********Removing validations on change of radio button selection **********/
  removeValidators(formGrup, fieldName) {
    for (const key in formGrup.controls) {
      if (key == 'name' && this.isSurvey) {

        formGrup.get(key).clearValidators();
        formGrup.get(key).updateValueAndValidity();
        const fieldCtrl = formGrup.controls[key];
        fieldCtrl.setValidators(this.formValidators[fieldName]);
      }

    }
  }


  /********** For revaluation of the validations applied in formFields **********/
  reEvaluateValidators(fieldName, setNo, isNested = 'no') {
    if (isNested != 'no') {
      const fieldCtrlGroup = this.delegateForm.controls[isNested]['controls'][isNested]

      if (fieldCtrlGroup) {
        for (let k in fieldCtrlGroup.controls) {
          let fieldCtrl = fieldCtrlGroup.controls[k].controls[fieldName];
          fieldCtrl.setValidators(this.formValidators[fieldName]);
        }
      }
    } else {
      const fieldCtrl = this.delegateForm.controls[fieldName];

      fieldCtrl.setValidators(this.validation(fieldName, this.delegateForm.get(fieldName).value));

      fieldCtrl.updateValueAndValidity();
    }
  }
  /*********************************************************************/
}
