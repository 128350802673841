<!-- [ngClass]="showLeftNav ? '' : 'left-nav-collapse' -->
<div class="left-nav"   role="navigation">
  <!-- (click)="toggleSideBar()" -->
    <div class="left-nav-icon cursor-default" aria-label="menu" tabindex="0" aria-expanded="false" >
        <!-- <i class="navbar-icon-menu" [ngClass]=" showLeftNav ? '' : 'navbar-icon-close' "></i> -->
        <span class="toggle-heading">Admin Settings</span>
    </div>
    <ul>
      <li>
        <a routerLink="/admin/organization" href="javascript:void(0);" routerLinkActive="active" *ngIf="showOrganizationLink">

          <span>Organizations</span>
        </a>
      </li>
      <li>
        <a routerLink="/admin/pages" href="javascript:void(0);" routerLinkActive="active" *ngIf="showOrganizationLink">

          <span>Page Templates</span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0);" routerLink="/admin/client" routerLinkActive="active" *ngIf="showClientLink">

          <span>Clients</span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0);" routerLink="/admin/custom-client/list" routerLinkActive="active" *ngIf="showCustomClientLink">
          <span>Custom Clients</span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0);" routerLink="/admin/user" routerLinkActive="active" *ngIf="showUserLink">

          <span>Users</span>
        </a>
      </li>
      <!-- <li>
        <a href="javascript:void(0);" routerLink="/admin/role" routerLinkActive="active" *ngIf="showRoleLink">

          <span>Roles</span>
        </a>
      </li> -->
      <li>
        <a href="javascript:void(0);" routerLink="/admin/usergroup" routerLinkActive="active" *ngIf="showOrganizationLink">
          <span>User Groups</span>
        </a>
      </li>
      <!-- <li>
      <a routerLink="/admin/emailGroups" href="javascript:void(0);" routerLinkActive="active" *ngIf="showGroupLink">
          <i class="material-icons">mail</i>
          <span>Email Groups</span>
      </a>
  </li> -->
      <li>
        <a href="javascript:void(0);" routerLink="/admin/product" routerLinkActive="active" *ngIf="showProductLink">

          <span>Products</span>
        </a>
      </li>
      <li>
        <a routerLink="/admin/form" href="javascript:void(0);" routerLinkActive="active" *ngIf="showFormLink">

          <span>Form Templates</span>
        </a>
      </li>
      <li>
        <a routerLink="/admin/configuration" href="javascript:void(0);" routerLinkActive="active" *ngIf="showConfigurationLink">

          <span>Configurations</span>
        </a>
      </li>
      <!-- <li>
        <a routerLink="/admin/setOrganization" href="javascript:void(0);" routerLinkActive="active" *ngIf="showOrganizationLink">

          <span>Set Org</span>
        </a>
      </li> -->
      <li>
        <a routerLink="/admin/feature/list" href="javascript:void(0);" routerLinkActive="active" *ngIf="showSelfServiceLink">

          <span>Configure Self Service</span>
        </a>
      </li>
      <li>
    </ul>
</div>