import { Component, OnInit } from '@angular/core';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from '../../../services/user.service';
import { allowedPermissions } from '../../../core/constants';
import { map, take, tap } from 'rxjs/operators';
import { ICreateTopic } from 'src/app/admin/manageForms/models/createTopic';
import { ISelfServiceFeatureList, ISelfServiceFeature, IPageTemplate } from 'src/app/shared/page-template';
import { PageTemplateService } from 'src/app/services/page-template.service';
import { ContentService } from 'src/app/services/content.service';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'self-service-side-bar',
  templateUrl: './side-bar.component.html'
})

export class SelfServiceSidebarComponent implements OnInit {
  showManageAssetLink: boolean = false;
  showFormLink: boolean = false;
  showReportLink: boolean = false;
  showWorkflowLink: boolean = false;
  showRequestExtractsLink: boolean = false;
  showformExtractionTemplateLink: boolean = false;
  showHelpCategoryLink: boolean = false;
  showHelpArticleLink: boolean = false;
  showClientCommitment: boolean;
  showMultiGroup: boolean;
  showTestOpportunity: boolean;
  isAdmin: boolean = false;
  popularTopic: ICreateTopic;
  errorMsg: string = '';
  infoMsg: string = '';
  userData: any;
  selfServiceFeatures: ISelfServiceFeatureList;
  isInfoMsg: boolean = false;
  selfServiceFeatureList: any;
  selfServiceSubFeatureList: any;
  content: any = [];
  canUploadFormExtraction: boolean =  false
  curentUserRoles: any = [];
  public pageTemplate: IPageTemplate = {};
  pageTemplateAdmin = false;
  private pageTemplateId = environment.clientCommitmentPageTemplateId;
  
  constructor(
    private permission: PermissionsService,
    private pageTemplateService: PageTemplateService,
    private userService: UserService,
    private contentService: ContentService,
    private clientCommitmentsService: ClientCommitmentsService
  ) { }

  ngOnInit(): void {
    this.getPageTemplateData();
    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
      });
    }
    this.isAdmin = this.permission.userData.isAdmin;
    this.userData = this.userService.getUserListData();
    if (!this.userData) {
      this.userService.callUserListData().pipe(map((data) => {
        this.userService.setUserListData(data);
        this.permission.setUserData(data);
        let usersPermission = [];
        data.permissions.forEach((ele) => {
          let permission: any = allowedPermissions[ele];
          for (let perm in permission) {
            if (permission[perm])
              usersPermission[perm] = permission[perm];
          }
        });

        this.setLinks();
        this.initPageTemplateLinks()
      }));
    }
    else {
      this.setLinks();
      this.initPageTemplateLinks()
    }
  }

  private setLinks() {
    this.showManageAssetLink = this.permission.showManageAssets();
    this.showFormLink = this.permission.showFormLink();
    this.showReportLink = this.permission.showReportLink();
    this.showWorkflowLink = this.permission.showWorkflowLink();
    this.showRequestExtractsLink = this.permission.showRequestExtractsLink();
    this.showformExtractionTemplateLink = this.permission.showformExtractionTemplateLink();
    this.showHelpCategoryLink = this.permission.showHelpCategoryLink();
    this.showHelpArticleLink = this.permission.showHelpArticleLink();
    this.showClientCommitment = this.permission.userData.isAdmin;
    this.showMultiGroup = this.permission.userData.isAdmin;
    this.showTestOpportunity = this.permission.userData.isAdmin;

  }

  initPageTemplateLinks() {
    this.userData.roles.forEach((role) => {
      this.curentUserRoles.push(role.roleTitle);
    });

    this.canUploadFormExtraction =
      this.curentUserRoles.includes('Admin') ||
      this.curentUserRoles.includes('Fiserv Division Admin') ||
      this.curentUserRoles.includes('Fiserv Portfolio Manager') ||
      this.curentUserRoles.includes('Fiserv Implementation Manager') ||
      this.curentUserRoles.includes('Fiserv Portfolio Manager - Read Only');

    this.pageTemplateService.getSelfServiceFeatureList()
    .pipe(take(1))
    .subscribe(
      features => {
        this.selfServiceFeatures = features.body as ISelfServiceFeatureList;
        this.selfServiceFeatureList = [];
        this.selfServiceSubFeatureList = [];
        // let dictionaryURLs = {};

        for (let i in this.selfServiceFeatures) {
          this.selfServiceFeatures[i].featureCount = 0;
          this.selfServiceFeatures[i].features.forEach(feature => {feature.viewAccess = false;});
          // dictionaryURLs = {}
          if(!this.canUploadFormExtraction){
            if(this.selfServiceFeatures[i].id == 1){
               this.selfServiceFeatures[i].features = this.selfServiceFeatures[i].features.filter(x => x.id != 2)
            }
          }
          for (let j in this.selfServiceFeatures[i].features) {
            if(this.selfServiceFeatures[i].features[j].roles.some(a => this.userData.roles.some(r => r.roleId === a.roleId))) {
              // if(!(this.selfServiceFeatures[i].features[j].selfServiceSubFeatureURL in dictionaryURLs)) {
              //   dictionaryURLs[this.selfServiceFeatures[i].features[j].selfServiceSubFeatureURL] = this.selfServiceFeatures[i].features[j].name;
                this.selfServiceFeatures[i].features[j].viewAccess = true;
                this.selfServiceFeatures[i].featureCount = this.selfServiceFeatures[i].featureCount + 1;
                this.isInfoMsg = true;
              // }
            }
          }
          if(this.selfServiceFeatures[i].name === 'Other Requests') {
            let cnt = 0;
            let len = Object.keys(this.selfServiceFeatures[i].features).length;
            if (len > 5) cnt = 5;
            else cnt = len;

            for(let k = 0; k < cnt ; k++) {
              if(!(this.selfServiceFeatures[i].features[k].isFooterLink)) {
                this.selfServiceSubFeatureList.push(this.selfServiceFeatures[i].features[k]);
                this.selfServiceFeatures[i].features[k].viewAccess = false; // Hide for NEW CCT
                this.selfServiceFeatures[i].featureCount = this.selfServiceFeatures[i].featureCount + 1;
                this.isInfoMsg = true;
              }
            }
          }
        }
      },
    (error)=> {
      this.errorMsg = error;
    });
  }

  private getPageTemplateData(): void {
    this.clientCommitmentsService
      .getPageTemplateById(this.pageTemplateId)
      .pipe(take(1))
      .subscribe(
        (page) => {
          this.pageTemplate = page.body as IPageTemplate;
          this.pageTemplateAdmin = this.pageTemplate.pageTemplateAdmins?.some(a => a.userId === this.userData.id);
          for (let i in this.selfServiceFeatures) {  
            for (let j in this.selfServiceFeatures[i].features) {
              if(this.selfServiceFeatures[i].features[j].roles.some(a => this.userData.roles.some(r => r.roleId === a.roleId))) {
                if (this.selfServiceFeatures[i].features[j].name?.includes('User Delegate')) {
                  if (this.pageTemplateAdmin) {
                    this.selfServiceFeatures[i].features[j].viewAccess = true;
                  }
                }
              }
            }
          }
        },
        (error) => {
        }
      );
  }
}
