<!--  Fluid Updates > class="container container-page" -->
<div [ngClass]="{
    'container-page get-notification-hight': (notice.showNoticeBoard && !noticeClosed),  
    'container-page': !(notice.showNoticeBoard && !noticeClosed) && !isCommitmentDashboardPage,  
    'commitment-dashboard-container-page': (isCommitmentDashboardPage )  
  }" id="containerPage" (scroll)="onContainerPageScroll()">
  <!-- <div class="container-page get-notification-hight" id="containerPage">  -->
  <div class="alert global-notification-alert" *ngIf="notice.showNoticeBoard">
    <div class="material-icons">notifications</div>
    <div class="message-content" [innerHTML]="notice.noticeBoardMessage"></div>
    <div class="action-link">
      <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="closeNotice()">
        ×
      </button>
    </div>
  </div>
  <app-header-cct *ngIf="loggedIn && !isBlankHeader && isCCT"></app-header-cct>
  <header *ngIf="loggedIn && !isBlankHeader && !isCCT"></header>
  <app-survey *ngIf="!loggedIn && !isBlankHeader"></app-survey>
  <router-outlet></router-outlet>
</div>
<app-footer [isAnonymous]="isAnonymous" [isCommitmentDashboardPage]="isCommitmentDashboardPage"></app-footer>