<div class="modal fade bd-example-modal-md" id={{componentId}} data-backdrop="static" data-keyboard="false"
  tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-md mt-120" role="document" style="max-width: 700px;">
    <div class="modal-content">
      <div class="modal-header">
        <ng-container *ngIf="type!=='attachment'; then deleteTitle else removeTitle"></ng-container>
        <ng-template #deleteTitle>
          <h4 *ngIf="!clientDashboard" class="modal-title" id="myLargeModalLabel">Delete {{type}}</h4>
          <h4 *ngIf="clientDashboard" class="modal-title" id="myLargeModalLabel">Remove {{type}}</h4>
        </ng-template>
        <ng-template #removeTitle>
          <h4 class="modal-title" id="myLargeModalLabel">Replace {{type}}</h4>
        </ng-template>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close popup" (click)="cancel()"><span
            aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <ng-container
          *ngIf="type!=='attachment'; then clientDashboard ? deleteCDMessage : deleteMessage else uploadMessage"></ng-container>
        <ng-template #deleteMessage>
          <p>Are you sure you want to delete this <b>{{type}}</b>?</p>
        </ng-template>
        <ng-template #deleteCDMessage>
          <p>Are you sure you want to Untrack commitment <b>{{type}}</b> from Client Dashboard? Please use Commitment
            Intake to track commitment for client review.</p>
        </ng-template>
        <ng-template #uploadMessage>
          <p>Are you sure you want to upload a new file and replace the existing one?</p>
        </ng-template>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal"
          (click)="cancel();">Cancel</button>
        <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" data-dismiss="modal"
          (click)="ok()">Ok</button>
      </div>
    </div>
  </div>
</div>