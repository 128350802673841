<div class="map-dw d-done">
    <div tabindex="-1" aria-labelledby="addContacts" aria-expanded="true" role="region"
        class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open">
        <div class="drawer-contents commitment-dw  col-xs-12 col-sm-12 col-md-12 ">
            <div class="form-container p-1">
                <form [formGroup]="approverFormGroup" autocomplete="off">
                    <div class="form-row" *ngIf="isEntityLink">
                        <div class="col-md-12 mb-3 pt-4">
                            <!-- <div><label class=""> Please select Organization, Product and/or client, to associate entities with this request.</label></div> -->
                            <div><label class=""> Please provide below details for {{pageTemplateNameTitle}}</label>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <div class="col-md-12 mb-3" style="margin-left: -15px">
                                <label for="clientName" class="asterisk"> Client Name/DUNS: </label>
                                <div class="pr-4 typeahead-wrapper" *ngIf="approverFormGroup">
                                    <fdl-type-ahead id="searchClientInput" [formControl]="approverFormGroup.controls.clientName"
                                        (input)="typeaheadLoadingClients($event.target.value, true)"
                                        [collection]="clientSuggestion" (onSelect)="onClientSelect($event)"
                                        optionField="titleWithDuns" placeholder="Type to search" [scrollable]="true"
                                        [optionsLimit]="500" [minLength]="0">
                                    </fdl-type-ahead>
                                    <!-- <div class="has-error">
                        <span *ngIf="!approverFormGroup.get('clientName').valid && approverFormGroup.get('clientName').errors?.pattern && (approverFormGroup.get('clientName').dirty || isSubmitted)">
                          <i class="material-icons">warning</i> Please enter a valid Client Name.</span>
                      </div> -->
                                </div>
                                <div *ngIf="selectedClientValue" class="mt-2">
                                    <!-- <span><i>{{selectedClientValue}}</i></span> -->
                                    <span><i>{{approverFormGroup.get('clientName').value}}</i></span>
                                </div>
                                <div [ngClass]="{'has-error': invalidClientName}">
                                    <span *ngIf="invalidClientName">
                                        <i class="material-icons">warning</i>Please select a client from the list.
                                    </span>
                                </div>
                                <div [ngClass]="{'has-error': invalidClientDuns}">
                                    <span *ngIf="invalidClientDuns">
                                        <i class="material-icons">warning</i>Please select a client with valid DUNS ID.
                                    </span>
                                </div>

                                <!-- <div class="pr-4 pt-2 typeahead-wrapper">
                      <fdl-type-ahead
                        label="Organization Name (BU):"
                        formControlName="organizationName"
                        [collection]="organizationSuggestion"
                        (onSelect)="onOrganizationSelect($event)"
                        optionField="title"
                        placeholder="Type to search"
                        [scrollable]="true"
                        [optionsLimit]="500"
                        [minLength]="0"
                      >
                      </fdl-type-ahead>
                    </div>
                    <div [ngClass]="{'has-error': (invalidOrganizationName || missingOrganizationName) }">
                      <span *ngIf="invalidOrganizationName">
                        <i class="material-icons">warning</i>Please select the valid organization name from the list.
                      </span>
                      <span *ngIf="missingOrganizationName">
                        <i class="material-icons">warning</i>Organization is missing for selected product.
                      </span>
                    </div>
    
                    <div class="pt-2 pr-4 typeahead-wrapper" *ngIf="selectedOrganizationName">
                      <fdl-type-ahead
                        label="Product Name:"
                        formControlName="productName"
                        [collection]="productSuggestion"
                        (onSelect)="onProductSelect($event)"
                        optionField="title"
                        placeholder="Type to search"
                        [scrollable]="true"
                        [optionsLimit]="500"
                        [minLength]="0" 
                      >
                      </fdl-type-ahead>
                    </div>
                    <div [ngClass]="{'has-error': invalidProductName}">
                      <span *ngIf="invalidProductName">
                        <i class="material-icons">warning</i>Please select the valid product name from the list.
                      </span>
                    </div> -->
                            </div>
                        </div>
                    </div>

                    <h3 *ngIf="isDynamicApprover" class="mt-0">Add Approver(s):</h3>
                    <div class="d-flex mb-12 updateApprover" *ngIf="isDynamicApprover">
                        <div class="col-md-6 mb-3 pt-1 pl-0"
                            [ngClass]="{'has-error': ( approverFormGroup.get('approver').errors?.duplicate || (!approverFormGroup.get('approver').valid && approverFormGroup.get('approver').touched)) }">
                            <div class="mb-1">
                                <label> Please select the approver(s). </label>
                            </div>
                            <app-mutipleadd #addmultiple (formReady)="formInitialized('approver', $event)"
                                buttonText="Add Approver" [isFdl]="true" [fdlData]="usersData" [filedJson]="filedJson"
                                elementName="approver" [filedDetails]="filedDetails"
                                [formControlData]="approverFormGroup">
                            </app-mutipleadd>
                            <span *ngIf="(approverFormGroup.get('approver').errors?.duplicate)">
                                <i class="material-icons">warning</i>The Approver can not be duplicated at the same
                                level.
                            </span>
                            <span *ngIf="(approverFormGroup.get('approver').errors?.invalid)">
                                <i class="material-icons">warning</i>The Level should be an integer number greater than
                                or equal to 1.
                            </span>
                        </div>

                        <div class="form-typeahead-wrapper select-approver-criteria" style="margin-left: -75px;">
                            <label class="mt-2">Select Approver Criteria: <span data-toggle="tooltip"
                                    title="Select Approval Criteria if Multiple Approvers at same level."
                                    class="material-icons btn-secondary f-18"> info</span></label>
                            <div>
                                <div class="fdl-select">
                                    <select class="form-control" formControlName="approvalSchema" name="approvalSchema">
                                        <option value="OR">ANY</option>
                                        <option value="AND">ALL</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div *ngIf="isEntityLink"><p><b>*Note:</b> All users who are assigned to an entity, can view the request if it is linked with organization/product/client from this screen. <br> Please click on 'Continue' button to proceed. </p></div>     -->
                    <div class="fdl-2 button-container">
                        <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal"
                            (click)="cancelApproverForm()"> Cancel </button>
                        <div>
                            <button type="button" class=" btn fdl-btn btn-primary-orange ml-2"
                                (click)="assignApprover()" [disabled]="((selectedClientId < 1) || invalidClientDuns)">
                                Continue </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="backdrop-space">
        <div class="backdrop"></div>
    </div>
</div>