import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ClientNoteInput } from '../self-service/components/commitment-dashboard/models/client-note-input';

@Injectable({
  providedIn: 'root'
})
export class ClientNoteService {
  private _clientNoteBaseUrl: string = "clientnote";

  constructor(private http: HttpClient) { }

  public createOrUpdateClientNote(clientNoteInput: ClientNoteInput) {
    const options = { 'refresh': 'true' };

    return this.http.post(`${this._clientNoteBaseUrl}`, clientNoteInput, { headers: options, observe: 'response' });
  }

  public getClientNote(clientId: number) {
    const options = { 'refresh': 'true' };
    return this.http.get(`${this._clientNoteBaseUrl}/${clientId}`, { headers: options, observe: 'response' });
  }

  public getClientNoteReviewTrail(clientId: number) {
    const options = { 'refresh': 'true' };
    return this.http.get(`${this._clientNoteBaseUrl}/GetClientNoteReviewTrail/${clientId}`, { headers: options, observe: 'response' });
  }
}