<app-preference-setting [listname]="listname" *ngIf="listname!=''" (refereshColums)="refereshColums($event)" [addViewProjectClass]="addViewProjectClass"></app-preference-setting>

<table class="table table-hover  table-label f-12 {{customClasses}}" aria-describedby="tblDesc">
  <thead>
    <tr role="row">
      <th role="columnheader" scope="col" *ngIf="showCheckbox && !hideAllCheck">
        <div class="checkbox">
          <input *ngIf="!showLaunchButton && !hideAllCheck && !isEmailTemplateList" type="checkbox" [(ngModel)]="allSelected" id="selectedAll" (change)="allFormsSelected();">
          <input *ngIf="isEmailTemplateList" type="checkbox" [(ngModel)]="allSelected" id="selectedAll" (change)="allEmailsSelected();">
          <label for="selectedAll">
            <span class="invisible">d</span>
          </label>
        </div>
      </th>
      <th role="columnheader" scope="col" *ngIf="showCheckbox && hideAllCheck">
        <span>Select</span>
      </th>
      <th *ngFor="let colums of customFormFieldListJson">{{colums.formfieldlabel}}</th>
      <th role="columnheader" scope="col" *ngIf="showRadio">
        <div class="checkbox">
          <label for="selectedAll">
            <span class="invisible">d</span>
          </label>
        </div>
      </th>

      <th role="columnheader" scope="col" *ngIf="showFavoriteIcon">
        <span class="d-none">star</span>
      </th>
      <th role="columnheader" scope="col" *ngFor="let column of displayColumns; let i = index;">
        <a (click)="sort(keys[i])" *ngIf="applySorting(keys[i]) && shouldShowColum(null, column)" href="javascript:void(0);">
          {{column}}
          <i class="material-icons msort14" aria-hidden="true"
             *ngIf="isAsc && filterColumn == keys[i]">arrow_upward</i>
          <i class="material-icons msort14" aria-hidden="true"
             *ngIf="!isAsc && filterColumn == keys[i]">arrow_downward</i>
        </a>
        <span *ngIf="!applySorting(keys[i]) && shouldShowColum(null, column)">
          {{column}}
        </span>
      </th>
      <th role="columnheader" scope="col" *ngIf="showAttachment"></th>
      <th class="actions" role="columnheader" scope="col" *ngIf="drawers || showMenu || formFetchAction || showMyAssignedActionButton || showMyDeleteActionButton || showViewButton || showOrganizationViewButton || showLaunchButton">{{content['dataTableActionsButton']}}</th>
    </tr>
  </thead>

  <tbody class="data-table-body" cdkDropList [cdkDropListData]="rows" (cdkDropListDropped)="onDrop($event)" [cdkDropListDisabled]="!isDragable">
    <ng-container
      *ngFor="let row of rows | filter: searchStringFieldName: searchString | filter:'responseStatusTitle': status  | filter:'assignedToName': respondentName | filter:'idString':id | filter:'clientTitle':clientTitle | filter:'category':category | filter:'package': package | filter:'formName': formName;  let i = index;">
      <tr [cdkDragDisabled]="!row['isDragable']" [ngClass]="{ collapse: !row['isGroup'] && row.isCollapsed }" cdkDrag cdkDragLockAxis="y" [cdkDragData]="row" role="row">
      <td role="cell" *ngIf="showCheckbox">
        <div class="checkbox">
          <input *ngIf="!isEmailTemplateList" type="checkbox" [(ngModel)]="row.selected" id="{{'checkbox'+ i}}" (change)="formSelected();" [disabled]="row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == true || row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true' || row['accessLevel'] == 'Read'">
          <input *ngIf="isEmailTemplateList" type="checkbox" [(ngModel)]="row.selected" id="{{'checkbox'+ i}}" (change)="emailsSelected();">
          <label for="{{'checkbox'+ i}}"></label>
        </div>
      </td>

      <td *ngFor="let col of customFormFieldListJson">
        <div class="checkbox" *ngIf="col.formfieldtype == 'checkbox'">
          <input id="{{'dataCheckbox'+ i}}" *ngIf="!(row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == true || row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true' || row['accessLevel'] == 'Read') && col.formfieldtype == 'checkbox' && col.isEditable && !row.isLinked && (col.dependentChildField == '' || col.dependentChildField == undefined)" value="true" type="checkbox" [(ngModel)]="row.customeFormFieldListJsonAnswer[col.formfieldapikey]" (change)="updateDependentField(row, col.formfieldapikey)">
          <input id="{{'dataCheckbox'+ i}}" *ngIf="col.formfieldtype == 'checkbox' && (row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == true || row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true' || row['accessLevel'] == 'Read' || !col.isEditable || row.isLinked) && (col.dependentChildField == '' || col.dependentChildField == undefined)" disabled=true type="checkbox" [(ngModel)]="row.customeFormFieldListJsonAnswer[col.formfieldapikey]" (change)="updateDependentField(row, col.formfieldapikey)">
          <input id="{{'dataCheckbox'+ i}}" *ngIf="!(row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == true || row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true' || row['accessLevel'] == 'Read') && col.formfieldtype == 'checkbox' && col.isEditable && !row.isLinked && col.dependentChildField != ''" value="true" type="checkbox" [(ngModel)]="row.customeFormFieldListJsonAnswer[col.formfieldapikey]" (change)="updateDependentField(row, col.formfieldapikey, col.dependentChildField)">
          <input id="{{'dataCheckbox'+ i}}" *ngIf="col.formfieldtype == 'checkbox' && (row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == true || row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true' || row['accessLevel'] == 'Read' || !col.isEditable || row.isLinked) && col.dependentChildField != ''" disabled=true type="checkbox" [(ngModel)]="row.customeFormFieldListJsonAnswer[col.formfieldapikey]" (change)="updateDependentField(row, col.formfieldapikey, col.dependentChildField)">
          <label for="{{'dataCheckbox'+ i}}"></label>
        </div>
        
        <input 
          [ngStyle]="{'color': col.isHighlight == true && row?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor != '' ? row?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor : '',
                    'border': (row.customeFormFieldListJsonAnswer[col.dependentParentField] == true || row.customeFormFieldListJsonAnswer[col.dependentParentField] == 'true') 
                    && (row.customeFormFieldListJsonAnswer[col.formfieldapikey] == '' || row.customeFormFieldListJsonAnswer[col.formfieldapikey] == undefined || row.customeFormFieldListJsonAnswer[col.formfieldapikey] == null)
                    && col.dependentParentField != undefined && col.dependentParentField != '' && col.isMandatory == true ? 'red 1px solid' : ''}"
          class="data-input form-control"
          title="{{row.customeFormFieldListJsonAnswer[col.formfieldapikey]}}"
          *ngIf="!(row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == true || row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true' || row['accessLevel'] == 'Read') && col.formfieldtype == 'textbox' && col.isEditable && !row.isLinked" 
          type="text" 
          [(ngModel)]="row.customeFormFieldListJsonAnswer[col.formfieldapikey]"
          (change)="formFieldChanged(row, col.formfieldapikey)"
        >
        
        <textarea 
          [ngStyle]="{'color': col.isHighlight == true && row?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor != '' ? row?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor : '',
                      'border': (row.customeFormFieldListJsonAnswer[col.dependentParentField] == true || row.customeFormFieldListJsonAnswer[col.dependentParentField] == 'true') 
                      && (row.customeFormFieldListJsonAnswer[col.formfieldapikey] == '' || row.customeFormFieldListJsonAnswer[col.formfieldapikey] == undefined || row.customeFormFieldListJsonAnswer[col.formfieldapikey] == null)
                      && col.dependentParentField != undefined && col.dependentParentField != '' && col.isMandatory == true ? 'red 1px solid' : ''
                    }"
          class="data-input form-control"
          title="{{row.customeFormFieldListJsonAnswer[col.formfieldapikey]}}"
          *ngIf="!(row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == true || row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true' || row['accessLevel'] == 'Read') && col.formfieldtype == 'textarea' && col.isEditable && !row.isLinked" 
          [(ngModel)]="row.customeFormFieldListJsonAnswer[col.formfieldapikey]"
          (change)="formFieldChanged(row, col.formfieldapikey)"
        >
        </textarea>

        <div class="fdl-select" 
          *ngIf="!(row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == true || row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true' || row['accessLevel'] == 'Read') 
          && col.formfieldtype == 'select' && col.isEditable && !row.isLinked"
          [ngClass]=
          "{'has-error'
            : (row.customeFormFieldListJsonAnswer[col.dependentParentField] == true || row.customeFormFieldListJsonAnswer[col.dependentParentField] == 'true') 
              && (row.customeFormFieldListJsonAnswer[col.formfieldapikey] == '' || row.customeFormFieldListJsonAnswer[col.formfieldapikey] == undefined || row.customeFormFieldListJsonAnswer[col.formfieldapikey] == null)
              && col.dependentParentField != undefined && col.dependentParentField != '' && col.isMandatory == true
          }"
        >
          <select 
            *ngIf="col.dependentParentField != undefined && col.dependentParentField != ''"
            [disabled]="col.dependentParentField != undefined && col.dependentParentField != '' && !(row.customeFormFieldListJsonAnswer[col.dependentParentField] == true || row.customeFormFieldListJsonAnswer[col.dependentParentField] == 'true')"
            [ngStyle]="{'color': col.isHighlight == true && row?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor != '' ? row?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor : ''}"
            class="data-input form-control" 
            type="text" 
            [(ngModel)]='row.customeFormFieldListJsonAnswer[col.formfieldapikey]' 
            title="{{row.customeFormFieldListJsonAnswer[col.formfieldapikey]}}"
            (change)="formFieldChanged(row, col.formfieldapikey)"
          >
            <option value=""> </option>
            <option *ngFor="let item of col.value" [value]="item.value"> {{item.value}} </option>
          </select>
          <select 
            *ngIf="!(col.dependentParentField != undefined && col.dependentParentField != '')"
            [ngStyle]="{'color': col.isHighlight == true && row?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor != '' ? row?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor : ''}"
            class="data-input form-control" 
            type="text" 
            [(ngModel)]='row.customeFormFieldListJsonAnswer[col.formfieldapikey]' 
            title="{{row.customeFormFieldListJsonAnswer[col.formfieldapikey]}}"
            (change)="formFieldChanged(row, col.formfieldapikey)"
          >
            <option value=""> </option>
            <option *ngFor="let item of col.value" [value]="item.value"> {{item.value}} </option>
          </select>
          <input 
            [ngStyle]="{'border': row.customeFormFieldListJsonAnswer[col.formfieldapikey] == 'other' && (row.customeFormFieldListJsonAnswer[col.otherOptionFieldApiKey] == undefined || row.customeFormFieldListJsonAnswer[col.otherOptionFieldApiKey] == '')? 'red 1px solid' : ''}"
            class="data-input form-control"
            *ngIf="row.customeFormFieldListJsonAnswer[col.formfieldapikey] == 'other' && col.showOtherOptionField == true" 
            type="text" 
            [(ngModel)]="row.customeFormFieldListJsonAnswer[col.otherOptionFieldApiKey]"
            title="{{row.customeFormFieldListJsonAnswer[col.otherOptionFieldApiKey]}}"
            (change)="formFieldChanged(row, col.otherOptionFieldApiKey)"
          >
        </div>

        <label 
          [ngStyle]="{'color': col.isHighlight == true && row?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor != '' ? row?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor : ''}"
          title="{{row.customeFormFieldListJsonAnswer[col.formfieldapikey]}}"
          *ngIf="(row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == true || row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true' || row['accessLevel'] == 'Read' || !col.isEditable || row.isLinked) && col.formfieldtype != 'checkbox'"
        > {{row.customeFormFieldListJsonAnswer[col.formfieldapikey]}}
        </label>
        <label 
          [ngStyle]="{'color': col.isHighlight == true && row?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor != '' ? row?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor : ''}"
          *ngIf="(row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == true || row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true' || row['accessLevel'] == 'Read' || !col.isEditable || row.isLinked) && col.formfieldtype != 'checkbox' && row.customeFormFieldListJsonAnswer[col.formfieldapikey] == 'other' && col.showOtherOptionField == true"
          title="{{row.customeFormFieldListJsonAnswer[col.otherOptionFieldApiKey]}}"
        > {{row.customeFormFieldListJsonAnswer[col.otherOptionFieldApiKey]}}
        </label>
      </td>

      <td role="cell" *ngIf="showRadio">
        <div class="checkbox">
          <input type="radio" [(ngModel)]="selectedRadioValue.value" value="{{row.id}}" id="{{'checkbox'+ i}}">
          <label for="{{'checkbox'+ i}}"></label>
        </div>
      </td>
      <!-- <td role="cell" *ngIf="showSelect">
        <div class="select">
          <select [(ngModel)]="row.extractionType" id="{{'select'+ i}}" (change)="extractionTypeSelected();">
            <option value="pdf">PDF</option>
            <option value="csv">CSV</option>
          </select>
        </div>
      </td> -->
      <td role="cell" *ngIf="showFavoriteIcon" class="favorite-table-data">
        <button type="button" aria-hidden="true" class="btn fdl-btn btn-primary-dark btn-addcomment mr-1 add-to-favorite " (click)="onFavoriteClick(row['id'], 'delete')">
          <span aria-hidden="true" class="star fa fa-star"></span>
        </button>
      </td>

      <td role="cell" *ngFor="let key of keys; let i = index;">
        <label *ngIf="row['imageIcon'] && i === 0" class="icon-cell">
          <div class="folder-wrapper" *ngIf="row['isGroup']">
            <div *ngIf="row['hasSub'] === true" class="folder-icon-set" (click)="toggleFolder(row)">
                <!-- for expand and collapse change material icon value from "remove" to "add" -->
                <i class="material-icons">{{ row["isCollapsed"] ? 'add' : 'remove' }}</i>
                <i class="{{ row['imageIcon'] }}"></i>
            </div>
            <div *ngIf="!row['hasSub']" class="folder-icon-set">
              <i class="{{ row['imageIcon'] }}"></i>
            </div>

            <label for="myCheckboxZero" data-toggle="tooltip" data-placement="top" class="text-truncate">
              {{key == 'visibility' ? (row[key] == true ? 'Yes' : 'No') : key == 'isPublishable' ? (row[key] == true ? 'Published' : 'UnPublished')  : key == 'parentId' ? (row[key] == '0' ? '' : row[key]) : key == 'isActive' ? (row[key] == true ? 'Enabled' : 'Disabled') : key == 'projectId' ? (row[key] == 0 ? ' ' : row[key]) : key == 'isAdmin' ? (row[key] == true ? 'Yes' : 'No'): (key == 'surveyId' && row[key] == '00000000-0000-0000-0000-000000000000') ? '' : row[key]}}
            </label>
          </div>
          <div class="icon-wrapper" *ngIf="!row['isGroup']" [ngClass]="{'icon-indent': row['isSub']}">
            <i class="{{ row['imageIcon'] }}"></i>

            <label for="myCheckboxZero" data-toggle="tooltip" data-placement="top" class="text-truncate" title="{{key == 'visibility' ? (row[key] == true ? 'Yes' : 'No') : key == 'isPublishable' ? (row[key] == true ? 'Published' : 'UnPublished')  : key == 'parentId' ? (row[key] == '0' ? '' : row[key]) : key == 'isActive' ? (row[key] == true ? 'Enabled' : 'Disabled') : key == 'projectId' ? (row[key] == 0 ? ' ' : row[key]) : key == 'isAdmin' ? (row[key] == true ? 'Yes' : 'No'): (key == 'surveyId' && row[key] == '00000000-0000-0000-0000-000000000000') ? '' : row[key]}}">
              {{key == 'visibility' ? (row[key] == true ? 'Yes' : 'No') : key == 'isPublishable' ? (row[key] == true ? 'Published' : 'UnPublished')  : key == 'parentId' ? (row[key] == '0' ? '' : row[key]) : key == 'isActive' ? (row[key] == true ? 'Enabled' : 'Disabled') : key == 'projectId' ? (row[key] == 0 ? ' ' : row[key]) : key == 'isAdmin' ? (row[key] == true ? 'Yes' : 'No'): (key == 'surveyId' && row[key] == '00000000-0000-0000-0000-000000000000') ? '' : row[key]}}
            </label>
          </div>
        </label>
        <label for="myCheckboxZero" *ngIf="(!row['imageIcon'] || i > 0)
          && !isGroup(key)
          && !isImageIcon(key)
          && !isStatus(key)
          && !isFlag(key)
          && !isNotificationState(key)
          && !isPhone(key) && !isDate(key)
          && !isDateOnly(key)
          && !isText(key)
          && row[key]!='01/01/0001'
          && row[key]!='0001-01-01T00:00:00Z'
          && row[key]!='12/31/0001'
          && !editableRows" data-toggle="tooltip" data-placement="top" title="{{key == 'visibility' ? (row[key] == true ? 'Yes' : 'No') : key == 'isPublishable' ? (row[key] == true ? 'Published' : 'UnPublished')  : key == 'parentId' ? (row[key] == '0' ? '' : row[key]) : key == 'isActive' ? (row[key] == true ? 'Enabled' : 'Disabled') : key == 'isAdmin' ? (row[key] == true ? 'Yes' : 'No') : (key == 'surveyId' && row[key] == '00000000-0000-0000-0000-000000000000') ? '' : row[key] }}"
          [innerHtml]="(key == 'visibility' ? (row[key] == true ? 'Yes' : 'No') : key == 'isPublishable' ? (row[key] == true ? 'Published' : 'UnPublished')  : key == 'parentId' ? (row[key] == '0' ? '' : row[key]) : key == 'isActive' ? (row[key] == true ? 'Enabled' : 'Disabled') : key == 'projectId' ? (row[key] == 0 ? ' ' : row[key]) : key == 'isAdmin' ? (row[key] == true ? 'Yes' : 'No'): (key == 'surveyId' && row[key] == '00000000-0000-0000-0000-000000000000') ? '' : row[key]) | encodeSpace">
        </label>
        <label for="myCheckboxZero" *ngIf="shouldShowColum(key, null) && !isStatus(key) && !isFlag(key) && isPhone(key) && !isDate(key) && !isDateOnly(key) && !isText(key) && row[key]!='01/01/0001' && row[key]!='0001-01-01T00:00:00Z' && row[key]!='12/31/0001'" data-toggle="tooltip" data-placement="top" title="{{row[key] | phone}}">{{row[key] | phone}}</label>
        <label for="myCheckboxZero" *ngIf="shouldShowColum(key, null) && !isStatus(key) && !isFlag(key) && !isPhone(key) && isDate(key) && !isDateOnly(key) && !isText(key) && row[key]!='01/01/0001' && row[key]!='0001-01-01T00:00:00Z' && row[key]!='12/31/0001'" data-toggle="tooltip" data-placement="top" title="{{row[key] | date: content['dateHourFormat']}}">{{row[key] | date: content['dateHourFormat']}}</label>
        <label for="myCheckboxZero" *ngIf="shouldShowColum(key, null) && !isStatus(key) && !isFlag(key) && !isPhone(key) && !isDate(key) && !isText(key) && row[key]!='01/01/0001' && row[key]!='12/31/0001' && row[key]!='0001-01-01T00:00:00Z'  && isDateOnly(key) && !editableRows" data-toggle="tooltip" data-placement="top" title="{{row[key] | date: content['dateFormat']}}">{{row[key] | date: content['dateFormat']}}</label>
        <label for="myCheckboxZero" *ngIf="shouldShowColum(key, null) && !isStatus(key) && !isFlag(key) && isText(key) && isStep(key) && row[key]!='01/01/0001' && row[key]!='12/31/0001' && row[key]!='0001-01-01T00:00:00Z'" data-toggle="tooltip" data-placement="top" title="{{row[key]}}">{{row[key]}}</label>
        <input type="text" class="form-control" *ngIf="shouldShowColum(key, null) && isText(key) && isPage(key) && row[key]!='01/01/0001' && row[key]!='12/31/0001'" [(ngModel)]="row.pageid">
        <label class="badge-table" for="myCheckboxZero" *ngIf="shouldShowColum(key, null) && isStatus(key)" data-toggle="tooltip" data-placement="top" title="{{row[key]}}"> <span [ngClass]="getStatusCSSClass(row[key])"></span> {{row[key]}}</label>
        <label class="badge-table" for="myCheckboxZero" *ngIf="shouldShowColum(key, null) && isFlag(key)" data-toggle="tooltip" data-placement="top" title="{{row[key]}}">
          <span *ngIf="getFlagClasses(row,key)!='blank'" class="badge-table-status {{getFlagClasses(row,key)}}"></span>
        </label>
        <label class="badge-table" for="myCheckboxZero" *ngIf="shouldShowColum(key, null) && isNotificationState(key)" data-toggle="tooltip" data-placement="top" title="{{row[key]}}"> <span [ngClass]="getUserNotificationStateCSSClass(row[key])"></span> {{row[key]}}</label>
        <app-editable-field *ngIf="editableRows" [value]="row[key]" [id]="row['id']" [key]="key" (editField)="editField($event)"></app-editable-field>
      </td>
      <td role="cell" *ngIf="formFetchAction && showViewEdit">
        <div class="kebab-menu dropleft">
          <a href="javascript:void(0);" data-toggle="dropdown" id="headerSwitcher" aria-expanded="false">
            <span class="material-icons">
              more_vert
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-dark top-15 header-dropdown-switcher"
               x-placement="left-start"
               style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-117px, 5px, 0px);">
            <div class="dropdown-list-option">
              <a class="dropdown-item" *ngIf="showViewEdit" href="javascript:void(0);"
                 (click)="connectedFormClick(row, 'Update',row['pageid'],row['stepinputtext'])">update</a>
              <a class="dropdown-item" *ngIf="showViewEdit" href="javascript:void(0);"
                 (click)="connectedFormClick(row, 'remove',row['pageid'],row['stepinputtext'])">remove</a>

            </div>
          </div>
        </div>
      </td>
      <td role="cell" *ngIf="formFetchAction && !showViewEdit">
        <div>
          <a style="cursor:pointer" *ngIf="!showViewEdit" href="javascript:void(0);"
             (click)="connectedFormClick(row, 'remove')"><span class="material-icons" style="font-size: 25px;color:red;cursor:pointer">clear</span></a>
        </div>
      </td>

      <td *ngIf="!showMenu && showLaunchButton">

        <div class="action-link-container" *ngIf="showLaunchButtons(i)">
          <a (click)="onClick_launch(row,'Initiate')" style="font-size:16px;">{{content['initiateIntakeRequest']}}</a>
          <span class="hr" *ngIf="(!hidePartialLaunchButton)"></span>
          <a class="font-weight-normal" *ngIf="(!hidePartialLaunchButton)" href="javascript:void(0);" routerLink="/intake/view/{{row.id}}" routerLinkActive="active">{{content['dataTableButtonPreview']}}</a>

          <span class="hr" *ngIf="!isClient && isRespondentRoleOnly()"></span>
          <a class="font-weight-normal" *ngIf="!isClient && isRespondentRoleOnly()" (click)="onClick_launch(row,'User')">{{content['dataTableButtonAssign']}}</a>
        </div>
      </td>
      <td *ngIf="showMyAssignedActionButton">
        <!--<div role="cell" class="fdl-2" *ngFor="let key of keys">-->
        <div role="cell"  class="fdl-2">
          <a *ngIf="row['responseStatusTitle'] != 'Under Review'" class="btn btn-primary-dark" href="javascript:void(0);" routerLink="/response/view/responses/{{row.id}}">{{content['dataTableButtonRespond']}}</a>
          <a *ngIf="row['responseStatusTitle'] == 'Under Review'" class="btn btn-primary-dark" href="javascript:void(0);" routerLink="/response/view/responses/{{row.id}}">{{content['dataTableButtonReview']}}</a>
        </div>
        <!--</div>-->


      </td>

      <td *ngIf="showMyDeleteActionButton">
        <div role="cell"  class="fdl-2">
          <span class="material-icons ml-2 mt-1" style="cursor: pointer;"  (click)="deleteIntakeClicked.emit(row)"> cancel </span>
        </div>
      </td>
      <td *ngIf="showViewButton">
        <div role="cell" class="fdl-2">
          <a class="btn btn-primary-dark" href="javascript:void(0);" (click)="onClick(row['clientId'], 'view')">{{content['dataTableViewAction']}}</a>
        </div>
      </td>

      <td *ngIf="showOrganizationViewButton">
        <div role="cell" class="fdl-2">
          <a class="btn btn-primary-dark" href="javascript:void(0);" (click)="onClick(row['organizationId'], 'view')">{{content['dataTableViewAction']}}</a>
        </div>
      </td>

      <td *ngIf="!showMenu && showActionButton">
        <button (click)="onClick_Action(row, 'action')" class="btn btn-primary">{{actionName}}</button>
      </td>

      <td role="cell" *ngIf="showAttachment">
        <button class="btn btn-link p-1 pb-0" *ngIf="row.attachment" type="button" aria-hidden="true" title="{{row.attachment.split('/').pop().replace('%20',' ')}}"
                (click)="onAttachmentClick(row.attachment, 'download')">
          <span class="f-22 material-icons blue-color"> attach_file </span>
        </button>
      </td>

      <td (click)="clickedRow(i)" [attr.id]="'actions-' + i" class="actions" role="cell" *ngIf="showMenu">
        <div (click)="clickedRow(i)" class="kebab-menu dropleft" *ngIf="!showAttachmentOptions">
          <a (click)="clickedRow(i)" href="javascript:void(0);" data-toggle="dropdown" id="headerSwitcher" aria-expanded="false">
            <span class="material-icons">
              more_vert
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-dark top-15 header-dropdown-switcher"
               x-placement=" left-start"
               style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-117px, 5px, 0px);">
            <div class="dropdown-list-option" *ngIf="!showViewEdit && type!=='CommitmentDelegate'">
              <a class="dropdown-item" *ngIf="type=='Request' && row['accessLevel'] == 'Write'" href="javascript:void(0);"
                 (click)="onClick(row['id'], 'view')">View & Edit</a>
              <a class="dropdown-item" *ngIf="showView && type=='Request' && row['accessLevel'] == 'Read'" href="javascript:void(0);"
                 (click)="onClick(row['id'], 'view')">{{content['dataTableViewAction']}}</a>
              <a class="dropdown-item" *ngIf="showView && type!='Request' && !isHistory && isUserList && row['isActive'] && link==''" href="javascript:void(0);"
                 (click)="onClick(row['id'], 'view')">{{content['dataTableViewAction']}}</a>
              <a class="dropdown-item" *ngIf="showView && type!='Request' && !isHistory && !isUserList && link==''" href="javascript:void(0);"
                 (click)="onClick(row['id'], 'view')">{{content['dataTableViewAction']}}</a>
              <a class="dropdown-item" *ngIf="showView && type!='Request' && !isHistory && link!=''" href="javascript:void(0);"
                 routerLink="{{link}}{{row.id}}">{{content['dataTableViewAction']}}</a>
              <a class="dropdown-item" *ngIf="showView && type!='Request' && isHistory" href="javascript:void(0);"
                 (click)="onClick(row, 'view')">{{content['dataTableViewAction']}}</a>
              <a class="dropdown-item" *ngIf="showEdit && !isUserList" href="javascript:void(0);"
                (click)="onClick(row['id'], 'edit')">{{content['dataTableEditAction']}}</a>
                <a class="dropdown-item" *ngIf="isRoleList && showClone" href="javascript:void(0);"
                  (click)="onClick(row['id'], 'clone')">Clone</a>
              <a class="dropdown-item" *ngIf="showEdit && isUserList && row['isActive']" href="javascript:void(0);"
                (click)="onClick(row['id'], 'edit')">{{content['dataTableEditAction']}}</a>
              <a class="dropdown-item" *ngIf="isAdmin && isUserList && !row['isActive']" href="javascript:void(0);"
                (click)="onClick(row, 'enable')">Enable</a>
              <!--<a class="dropdown-item" *ngIf="isUserList && row['isActive']" href="javascript:void(0);"
              (click)="onClick(row['id'], 'disable')">Disable</a>-->
              <a class="dropdown-item" *ngIf="isUserList && showClone && row['isActive']" href="javascript:void(0);"
                 (click)="onClick(row['id'], 'clone')">Clone</a>
              <a id="data-table-actions-edit-form" class="dropdown-item" *ngIf="showEditFormDetails && isCreateProjectAllowed"
                 (click)="onClick(row['id'], 'editFormDetails')">{{content['dataTableEditFormAction']}}</a>
              <a class="dropdown-item" *ngIf="downloadFormFields"
                 (click)="onClick(row['id'], 'downloadFormFields')">Download Form Fields</a>

              <a class="dropdown-item" *ngIf="showDelegate && row['delegateLink'] && (type == 'Response' || type == 'Responses')"
                 (click)="onClick(row['id'], 'delegate')">{{content['dataTableDelegateAction']}}</a>
              <a class="dropdown-item" *ngIf="showDelegate && type != 'Request' && type != 'Response' && type != 'Responses'"
                 (click)="onClick(row['id'], 'delegate')">{{content['dataTableDelegateAction']}}</a>
              <a class="dropdown-item" *ngIf="!(row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == true) && type == 'Request' && row['accessLevel'] == 'Write' && (showDelegate || userId == row['submittedById']) && (row['pageTemplateResponseStatusId'] == 1 || row['pageTemplateResponseStatusId'] == 2)"
                 (click)="onClick(row['id'], 'delegate')">{{content['dataTableDelegateAction']}}</a>

              <a class="dropdown-item" *ngIf="type != 'Request' && showChangeApprover && row['updateApproverLink'] && row['responseStatusTitle']!='On Hold' && row['responseStatusTitle']!='Cancel' && row['responseStatusTitle']!='Rejected'"
                 (click)="onClick(row['id'], 'hold')">{{content['dataTablePutOnHoldAction']}}</a>
              <a class="dropdown-item" *ngIf="type != 'Request' && showChangeApprover && row['updateApproverLink'] && row['responseStatusTitle']!='Cancel' && row['responseStatusTitle']!='On Hold' && row['responseStatusTitle']!='Rejected'"
                 (click)="onClick(row['id'], 'cancel')">{{content['dataTableCancelAction']}}</a>
              <a class="dropdown-item" *ngIf="row['projectShowOnUi'] == 'True' && row['showViewProject']  == true"
                 (click)="onClick(row['projectId'], 'showProject')">Show Project</a>

              <a class="dropdown-item" *ngIf="type != 'Request' && row['responseStatusTitle']!='Cancel' && showChangeApprover && row['updateApproverLink']"
                 (click)="onClick(row['id'], 'changeApprover')">Change Approver</a>
              <a class="dropdown-item" *ngIf="type == 'Request' && row['accessLevel'] == 'Write' && (showChangeApprover || row['updateApproverLink']) && row['pageTemplateResponseStatusId'] == 3"
                 (click)="onClick(row['id'], 'changeApprover')">Change Approver</a>
              <a class="dropdown-item" *ngIf="showReinitiate && row['reInitiateLink']" [class.disabled]="row['disableReinstate']" href="javascript:void(0);"
                (click)="onClick(row['id'], 'reInitiate')">Re-Initiate</a>
              <a class="dropdown-item" *ngIf="showDelink && row['isIntakeForm']" href="javascript:void(0);"
                 (click)="onClick(row['id'], 'delink')">De-Link Intake</a>

              <a class="dropdown-item" *ngIf="!checkDeleteForEachRow && type!='Request' && isUserList && row['isActive'] && (showDelete || allowDelete(row) )" href="javascript:void(0);"
                 data-toggle="modal" [attr.data-target]="'#deleteModal_' + tableId" (click)="deleteBtnClickHandler(row)">Delete</a>
              <a class="dropdown-item" *ngIf="!checkDeleteForEachRow && type!='Request' && !isUserList && (showDelete || allowDelete(row))" href="javascript:void(0);"
                 data-toggle="modal" [attr.data-target]="'#deleteModal_' + tableId" (click)="deleteBtnClickHandler(row)">Delete</a>
              <a class="dropdown-item" *ngIf="!checkDeleteForEachRow && !(row?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == true) && type=='Request' && row['accessLevel'] == 'Write' && (showDelete || row['pageTemplateAdmin'])" href="javascript:void(0);"
                 data-toggle="modal" [attr.data-target]="'#deleteModal_' + tableId" (click)="deleteBtnClickHandler(row)">Delete</a>
                 <a class="dropdown-item" *ngIf="checkDeleteForEachRow && showDelete && allowDelete(row)" href="javascript:void(0);"
                 data-toggle="modal" [attr.data-target]="'#deleteModal_' + tableId" (click)="deleteBtnClickHandler(row)">Delete</a>

              <a class="dropdown-item" *ngIf="showViewHistory" (click)="onClick(row['id'], 'viewHistory')">{{content['dataTableViewResponseHistoryAction']}}</a>
              <a class="dropdown-item" *ngIf="showRevert" (click)="onClick(row, 'revert')">Restore</a>
              <a class="dropdown-item" *ngIf="showViewCompare" (click)="onClick(row, 'viewCompare')">Compare With Current</a>
              <a class="dropdown-item" *ngIf="showViewTimeline" (click)="onClick(row['id'], 'viewTimeline')">{{content['dataTableViewResponseTimelineAction']}}</a>
              <a class="dropdown-item" *ngIf="showViewProjectTimeline && row['projectShowOnUi'] == 'True' && type == 'Response' && row['canViewProjectTimeline']"
                 (click)="onClick(row['projectId'], 'viewProjectTimeline')">View Project Timeline</a>
              <a class="dropdown-item" *ngIf="showViewProjectTimeline && type == 'Project'"
                 (click)="onClick(row['id'], 'viewProjectTimeline')">View Project Timeline</a>
              <a class="dropdown-item" *ngIf="showExtractionTemplate" href="javascript:void(0);"
                 (click)="onClick(row['id'], 'extractionTemplate')">Upload Extraction Template</a>
              <a class="dropdown-item" *ngIf="showUpdate"
                 (click)="onClick(row['id'], 'update')">Update</a>
              <a class="dropdown-item" *ngIf="showDownload"
                 (click)="onClick(row['id'], 'download')">Download</a>
              <a class="dropdown-item" *ngIf="showRequestExtract && row.responseStatusTitle=='Completed'" href="javascript:void(0);"
                 (click)="onClick(row, 'requestExtract')">Request Extract</a>
              <!-- <a class="dropdown-item" *ngIf="showExtractionTemplate" href="javascript:void(0);" (click)="onClick(row, 'updateInflightForms')">Update Inflight Forms</a> -->
              <div *ngIf="type == 'userNotification'">
                <a *ngIf="row['state'] !== 'Read'" class="dropdown-item" href="javascript:void(0);" (click)="onClick(row['id'], 'markAsRead')">Mark as read</a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="onClick(row['id'], 'dismiss')">Dismiss</a>
              </div>
            </div>
            <div class="dropdown-list-option" *ngIf="showViewEdit && !showAssetOptions && !showAttachmentOptions">
              <a class="dropdown-item" href="javascript:void(0);" *ngIf="type == 'Product' && (row['isFiservProduct'] == false || row['isFiservProduct'] == 'false' || isAdmin) && !isArticle && link=='' && !isClientList"
                 (click)="onClick(row['id'], 'view')">View & Edit </a>
              <a class="dropdown-item" href="javascript:void(0);" *ngIf="type == 'Product' && row['isFiservProduct'] == true && !isAdmin && !isArticle && link=='' && !isClientList"
                 (click)="onClick(row['id'], 'view')">View </a>

              <a class="dropdown-item" href="javascript:void(0);" *ngIf="type != 'Product' && !isArticle && link=='' && !isClientList"
                 (click)="onClick(row['id'], 'view')">View & Edit </a>
              <a class="dropdown-item" href="javascript:void(0);" *ngIf="type != 'Product' && isArticle && link=='' && isEdit"
                 (click)="onClick(row['id'], 'view')">View & Edit </a>
              <a class="dropdown-item" href="javascript:void(0);" *ngIf="showView && isArticle && link=='' && !isEdit || showView && isClientList && link==''"
                 (click)="onClick(row['id'], 'view')">View</a>

              <!-- PBI 246886 -->
              <a class="dropdown-item" href="javascript:void(0);" *ngIf="showCopy"
                 (click)="onClick(row['id'], 'makeCopy')">Make a Copy</a>
                 <a class="dropdown-item" href="javascript:void(0);" *ngIf="showMoveToPhase"
                    (click)="onClick(row['id'], 'moveToPhase')">Move To Phase</a>
              <!-- PBI 246886 -->

              <a class="dropdown-item" href="javascript:void(0);" *ngIf="link!=''"
                 routerLink="{{link}}{{row.id}}">View & Edit</a>


              <a class="dropdown-item" *ngIf="!(type == 'Request' || type == 'Product') && !isArticle && (showDelete || allowDelete(row)) && !isClientList" href="javascript:void(0);" data-toggle="modal"
                 [attr.data-target]="'#deleteModal_' + tableId" (click)="deleteBtnClickHandler(row)">{{content['dataTableDeleteAction']}}</a>
              <a class="dropdown-item" *ngIf="!(type == 'Request' || type == 'Product') && isArticle && (showDelete || allowDelete(row)) && row['showDeleteButton']" href="javascript:void(0);" data-toggle="modal"
                 [attr.data-target]="'#deleteModal_' + tableId" (click)="deleteBtnClickHandler(row)">{{content['dataTableDeleteAction']}}</a>
              <a class="dropdown-item" *ngIf="!(type == 'Request' || type == 'Product') && showViewEdit && showTemplateDelete && row['isDelete']" href="javascript:void(0);" data-toggle="modal"
                 [attr.data-target]="'#deleteModal_' + tableId" (click)="deleteBtnClickHandler(row)">{{content['dataTableDeleteAction']}}</a>
              <a class="dropdown-item" *ngIf="type == 'Request' && (showDelete || (userId == row['submittedById'] && (row['pageTemplateResponseStatusId'] == 1 || row['pageTemplateResponseStatusId'] == 2)))" href="javascript:void(0);" data-toggle="modal"
                 [attr.data-target]="'#deleteModal_' + tableId" (click)="deleteBtnClickHandler(row)">{{content['dataTableDeleteAction']}}</a>
              
              <a class="dropdown-item" *ngIf="type == 'Product' && showDelete && (row['isFiservProduct'] == false || row['isFiservProduct'] == 'false' || isAdmin)" href="javascript:void(0);" data-toggle="modal"
                 [attr.data-target]="'#deleteModal_' + tableId" (click)="deleteBtnClickHandler(row)">{{content['dataTableDeleteAction']}}</a>
                 
              <a class="dropdown-item" *ngIf="showDownload" (click)="onClick(row['id'], 'download')">Download</a>
              <a class="dropdown-item" *ngIf="showViewTimeline" (click)="onClick(row['id'], 'viewTimeline')">View Response Timeline</a>
              <a class="dropdown-item" *ngIf="showViewProjectTimeline && row['projectShowOnUi'] == 'True' && type == 'Response' && (row['organizationId'] == userOrgId || isAdmin)" (click)="onClick(row['projectId'], 'viewProjectTimeline')">View Project Timeline</a>
              <a class="dropdown-item" *ngIf="showViewProjectTimeline && type == 'Project' && allowUpdateProjectStatus(row, 'On Hold')" (click)="onClick(row['id'], 'onHold')">Put On Hold</a>
              <a class="dropdown-item" *ngIf="showViewProjectTimeline && type == 'Project' && row['statusTitle'] != 'On Hold' && allowUpdateProjectStatus(row, 'Rejected')" (click)="onClick(row['id'], 'reject')">Reject</a>
              <a class="dropdown-item" *ngIf="showViewProjectTimeline && type == 'Project'" (click)="onClick(row['id'], 'viewProjectTimeline')">View Project Timeline</a>
              <a class="dropdown-item" *ngIf="!(type == 'Request') && showViewEdit && showTemplateSend" href="javascript:void(0);"
                 (click)="onClick(row['id'], 'sendEmail')">Send Test Email</a>
            </div>
            <div class="dropdown-list-option" *ngIf="showViewEdit && showAssetOptions">
              <a class="dropdown-item" href="javascript:void(0);" *ngIf="row.url"
                 (click)="onClick(row['id'], 'copy')">Copy Link</a>
              <a class="dropdown-item" href="javascript:void(0);" *ngIf="!showDownload"
                 (click)="onClick(row['id'], 'preview')">Preview</a>
              <a class="dropdown-item" href="javascript:void(0);" *ngIf="!showDownload"
                 (click)="onClick(row['id'], 'replace')">Replace</a>
              <a class="dropdown-item" href="javascript:void(0);" [class.disabled]="row.status != 'Completed'" *ngIf="showDownload"
                 (click)="onClick(row['id'], 'download')">Download</a>
              <a class="dropdown-item" href="javascript:void(0);" *ngIf="type=='Response Extract' && showDownload"
                 (click)="onClick(row['id'], 'viewResponseDetails')">View Response Details</a>
              <a class="dropdown-item" href="javascript:void(0);"
                 data-toggle="modal" [attr.data-target]="'#deleteModal_' + tableId"
                 (click)="deleteBtnClickHandler(row)">{{content['dataTableDeleteAction']}}</a>

            </div>

            <div class="dropdown-list-option" *ngIf="type=='CommitmentDelegate'">
              <a class="dropdown-item" href="javascript:void(0);" (click)="onClick(row['delegateGUID'], 'view')">View</a>
              <a class="dropdown-item" href="javascript:void(0);" (click)="onClick(row['delegateGUID'], 'edit')">Edit</a>
              <a class="dropdown-item" href="javascript:void(0);" (click)="onClick(row['delegateGUID'], 'delete')">Delete</a>
            </div>
          </div>
        </div>

        <div class="kebab-menu dropleft" *ngIf="showViewEdit && showAttachmentOptions && (userId == row['cretedById'] || isAdmin)">
          <a href="javascript:void(0);" data-toggle="dropdown" id="headerSwitcher" aria-expanded="false">
            <span class="material-icons"> more_vert </span>
          </a>
          <div class="dropdown-menu dropdown-menu-dark top-15 header-dropdown-switcher"
               x-placement="left-start"
               style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-117px, 5px, 0px);">
            <div class="dropdown-list-option" *ngIf="showViewEdit && showAttachmentOptions && (userId == row['cretedById'] || isAdmin)">
              <a *ngIf="row.attachment" class="dropdown-item" href="javascript:void(0);"
                 (click)="onClick(row['id'], 'replace')">Replace File</a>
              <a *ngIf="row.attachment" class="dropdown-item" href="javascript:void(0);"
                 (click)="onClick(row['id'], 'remove')">Remove File</a>
              <a class="dropdown-item" href="javascript:void(0);"
                 (click)="onClick(row['id'], 'delete')">{{content['dataTableDeleteAction']}}</a>
            </div>
          </div>
        </div>

      </td>

      <td role="cell" *ngIf="drawers">
        <div class="kebab-menu dropleft">
          <a href="javascript:void(0);" data-toggle="dropdown" id="headerSwitcher" aria-expanded="false">
            <span class="material-icons">
              more_vert
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-dark top-15 header-dropdown-switcher"
               x-placement="left-start"
               style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-117px, 5px, 0px);">
            <div class="dropdown-list-option">
              <ng-container *ngFor="let drawer of drawers; let i = index;">
                <a *ngIf="!checkHide(drawers[i], row)" (click)="onClick(row, drawer.eventName)" class="dropdown-item" href="javascript:void(0);">{{ drawer.title }}</a>
              </ng-container>
            </div>
          </div>
        </div>
      </td>
      </tr>
      <tr *ngIf="row.commitmentNote">
        <th style="border-top:none !important; color: #00000099; font-size: 14px; font-weight: 600;">Note: </th>
        <td style="border-top:none;" [attr.colspan]="displayColumns.length - 1">
          <pre style="white-space: pre-wrap;word-wrap: break-word; color: #212529; font-weight: 400; font: normal normal normal 14px/14px Segoe UI;">{{row.commitmentNote}}</pre>         
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="rows?.length === 0">
    <tr>
      <td [attr.colspan]="displayColumns.length + 2" class="noRecordsAvailable">{{content['dataTableNoRecords']}}</td>
    </tr>
  </tbody>
</table>


<!--Delete Confimation Modal: START-->

<div class="modal fade bd-example-modal-md" id="deleteModal_{{tableId}}" data-backdrop="static" data-keyboard="false"
     tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-md mt-120" role="document" style="max-width: 700px;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">{{content['dataTableDeleteAction']}} {{type}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close popup"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to delete {{type.toLowerCase()}} <b>{{targetDeletableRow?.customeFormFieldListJsonAnswer?.commitmentTitleIntake || targetDeletableRow['title'] || targetDeletableRow['templateName'] || targetDeletableRow['name']  || targetDeletableRow['workflowName'] || targetDeletableRow['extractRequestName']}}</b>? </p>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal">Cancel</button>
        <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" data-dismiss="modal" (click)="onClick_delete()">Ok</button>
      </div>
    </div>
  </div>
</div>

<!--Delete Confimation Modal: END-->
