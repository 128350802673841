<div class="heading-container">
    <div class="back-action-container">
        <div class="back-button"><a href="javascript:void(0);" (click)="backToList()"><span
                    class="material-icons">arrow_back </span>
                Commitments </a></div>
    </div>
</div>

<div class="main-container-sub responsive-container separate-form-container m-0">
    <div class="container">
        <div>
            <p>Please complete this form to request a non-standard client commitment.</p>
            <br>
            The turnaround time for a response to the commitment request may vary by product, business unit, or request.
            Commitment requests involving multiple BUs may not be “approved” at the same time. Updates to the Commitment
            Request Status will be reflected within the request.
            <br><br>
            <b>Commitment Requests Requiring Approval:</b>
            <br><br>
            Commitment requests need to be reviewed by designated approvers and should not be considered approved until
            the status of the request has been changed to “approved” in CCT. Requests requiring multiple approvals may
            not all be completed at the same time.
            <br>
            Product solution commitments requiring approvals from multiple business units must all be “approved” before
            commitment is made to a client or included in a client contract.

        </div>
        <hr>

        <form [formGroup]="commitmentForm" class="mt-3">
            <div class="form-group">
                <label class="col-form-label asterisk">Commitment Title </label>
                <div class="">
                    <input formControlName="title" type="text" class="form-control">
                </div>
            </div>

            <!--Fiserv Contacts -->
            <div class="card">
                <div class="card-header">
                    <b>Fiserv Contacts</b>
                </div>
                <div class="card-body">
                    <div formArrayName="fsContacts" *ngFor="let control of fsContacts.controls; let i = index">
                        <ng-container [formGroup]="control">
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="name" class="col col-form-label asterisk">Name
                                        </label>
                                        <!-- <div class="col">
                                            <ng-select (change)="contactNameChanged($event, i)" formControlName="name" [items]="userOptions" [virtualScroll]="true"
                                                [clearOnBackspace]="false" placeholder="Type to search" bindLabel="name"
                                                bindValue="name" class="ng-select">
                                                <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$"
                                                    let-index="index">
                                                    <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                                                </ng-template>
                                            </ng-select>
                                        </div> -->
                                        <div class="col">
                                            <input formControlName="name" type="text" class="form-control" id="role">
                                        </div>
                                    </div>
                                </div>
            
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="email" class="col col-form-label asterisk">Email
                                        </label>
                                        <!-- <div class="">
                                            <ng-select (change)="contactEmailChanged($event, i)" formControlName="email" [items]="userOptions" [virtualScroll]="true"
                                                [clearOnBackspace]="false" placeholder="Type to search" bindLabel="email"
                                                bindValue="email" class="ng-select">
                                                <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$"
                                                    let-index="index">
                                                    <span title="{{itemTemp.email}}"> {{itemTemp.email}} </span>
                                                </ng-template>
                                            </ng-select>
                                        </div> -->
                                        <div class="col">
                                            <input formControlName="email" type="text" class="form-control" id="role">
                                        </div>
                                    </div>
                                </div>
            
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="role" class="col col-form-label asterisk">Role
                                        </label>
                                        <div class="col">
                                            <input formControlName="role" type="text" class="form-control" id="role">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <!-- <button title="Add new contact" class="ml-3 btn btn-info" (click)="addContact()">+ Add</button> -->
                </div>
               
            </div>
            <br>

            <!--Client Details -->
            <app-manual-commitment-client-details [ecrmClients]="ecrmClientOptions" formGroupName="clientDetails"></app-manual-commitment-client-details>

            <!--Commitment Details -->
            <div class="card">
                <div class="card-header">
                    <b>Commitment Details</b>
                </div>
                <div class="card-body">
                    <div *ngIf="!isView" class="d-flex justify-content-between">
                        <div>
                            <label><strong style="font-size: 13.5px;">Below products are added for this commitment. Please review and submit.</strong> </label>
                        </div>
                        <div class="d-flex justify-content-start fdl-2">
                            <button (click)="openModalForm()" class="btn fdl-btn btn-secondary-light mr-1">Add Product</button>
                        </div>
                    </div>
                   
                    <div class="row">
                        <div class="col">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Product Name</th>
                                            <th scope="col">eCRM Opportunity ID </th>
                                            <th scope="col">eCRM Opportunity TCV</th>
                                            <th scope="col">eCRM Opportunity ACV</th>
                                            <th scope="col">BU(s)</th>
                                            <th scope="col">Platform</th>
                                            <th scope="col">Commitment Type</th>
                                            <th scope="col">F.A.P</th>
                                            <th scope="col">Obligation Type</th>
                                            <th scope="col">Client Requested Commitment Date</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Executive Leader Name</th>
                                            <th scope="col">Executive Leader Email</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container formArrayName="commitmentDetails">
                                            <tr *ngFor="let item of commitmentDetails.controls; index as i">
                                                <td>{{getProductNameToDisplay(item.controls) }}</td>
                                                <td>{{item.controls?.ecrmOppId.value}}</td>
                                                <td>{{item.controls?.ecrmOppTCV.value}}</td>
                                                <td>{{item.controls?.ecrmOppACV.value}}</td>
                                                <td>{{item.controls?.buName.value}}</td>
                                                <td>{{getMultipleSelectedNames(platformOptions, item.controls?.platform.value) }}</td>
                                                <td>{{getSingleSelectedName(commitmentTypeOptions, item.controls?.commitmentType.value)}}</td>
                                                <td>{{getSingleSelectedName(fapOptions, item.controls?.fap.value)}}</td>
                                                <td>{{getSingleSelectedName(obligationTypeOptions, item.controls?.obligationType.value)}}</td>
                                                <td>{{formatDate(item.controls?.clientRequestedDate.value, 'MM/dd/yyyy')}}</td>
                                                <td>{{getSingleSelectedName(allRequestStatusesOptions, item.controls?.requestStatus.value)}}</td>
                                                <td>{{item.controls?.execLeaderName.value}}</td>
                                                <td>{{item.controls?.execLeaderEmail.value}}</td>
                                                <td>
                                                    <div *ngIf="!isView" style="font-size: 20px;" class="d-flex justify-content-center">
                                                        <div title="Edit" class="mr-2" style="cursor: pointer; color:dodgerblue;" (click)="onEditItem(item)"><i class="fa fa-pencil" aria-hidden="true"></i></div>
                                                        <div title="Delete" style="cursor: pointer;  color: red;" (click)="onDeleteItem(item.controls?.rowId.value)"><i class="fa fa-trash" aria-hidden="true"></i></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br>

            <div class="d-flex justify-content-end fdl-2">
                <button (click)="backToList()" class="btn fdl-btn btn-secondary-light mr-1">Cancel</button>
                <button (click)="submit()" [disabled]="this.commitmentForm.invalid || this.isView"
                    class="btn btn-primary-orange m-0"> {{isEdit ? 'Update': 'Submit'}}</button>
            </div>
            <!-- <div *ngIf="isSubmitted">
                <pre>{{commitmentForm.value | json}}</pre>
            </div> -->
        </form>
    </div>
</div>


<!-- modal form -->
<div class="modal fade" id="productModal" tabindex="-1" aria-labelledby="exampleModalLabel" data-backdrop="false"
    aria-hidden="true">
    <div style="max-width: 1110px;" class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Commitment details</h5>
                <button type="button" class="close" (click)="onCloseModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form [formGroup]="commitmentDetailsModalForm" class="modal-body">
                <div>
                    <div class="alert alert-dismissible alert-danger" *ngIf="errorMessage">
                        <!-- <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="errorMessage=''">×</button> -->
                        <i class="material-icons">warning</i>
                        <p [innerHtml]="errorMessage"></p>
                    </div>

                    <!-- BU and Product -->
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <!--left side -->
                                <div class="col-6">
                                    <div class="col form-group">
                                        <label class="col-form-label asterisk">BU(s)</label>
                                        <div>
                                            <ng-select formControlName="buNameOption"  [items]="buOptions"
                                                [virtualScroll]="true" [clearOnBackspace]="false" 
                                                placeholder="Type to search" bindLabel="name" bindValue="value"
                                                class="ng-select">
                                                <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$" let-index="index">
                                                    <!-- <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> -->
                                                    <span title="{{itemTemp.name}}">{{itemTemp.name}}</span>
                                                </ng-template>
                                                <ng-template ng-label-tmp let-itemTemp="item">
                                                    <div class="ng-value">
                                                        <span class="ng-value-label">{{itemTemp.name}}</span>
                                                    </div>
                                                </ng-template>

                                                <!-- For Multiple Select -->
                                                <!-- <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                    <div class="ng-value" *ngFor="let item of items">
                                                        <span class="ng-value-label" title="{{item.name}}">{{item.name}} </span>
                                                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                                    </div>
                                                </ng-template> -->
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="col form-group">
                                        <label class="col-form-label asterisk">Product</label>
                                        <div>
                                            <ng-select formControlName="productNameOption" [items]="productOptions" [virtualScroll]="true"
                                                [clearOnBackspace]="false" placeholder="Type to search" bindLabel="name"
                                                bindValue="value">
                                                <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$" let-index="index">
                                                    <span title="{{itemTemp.name}}">{{itemTemp.name}}</span>
                                                </ng-template>

                                                <ng-template ng-label-tmp let-itemTemp="item">
                                                    <div class="ng-value">
                                                        <span class="ng-value-label">{{itemTemp.name}}</span>
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>

                    <div *ngIf="isDisplayFormDetails">
                        <div class="card">
                            <div class="card-header">
                                <b>General Information</b>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <!-- <div class="col-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Product Name
                                            </label>
                                            <div>
                                                <textarea formControlName="productName" rows="3" type="text" class="form-control">
                                                </textarea>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div *ngIf="isDisplayOtherProduct" class="col">
                                        <div class="form-group">
                                            <label class="col-form-label asterisk">Other Product Name
                                            </label>
                                            <div>
                                                <input formControlName="otherProductName" type="text" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-4">
                                        <div class="form-group">
                                            <label class="col-form-label asterisk">Business Unit
                                            </label>
                                            <div>
                                                <textarea formControlName="buName" rows="3" type="text" class="form-control">
                                                </textarea>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
    
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label class="col-form-label asterisk">Commitment Type</label>
                                            <div>
                                                <ng-select formControlName="commitmentType" [items]="commitmentTypeOptions"
                                                    [virtualScroll]="true" [clearOnBackspace]="false"  
                                                    placeholder="Type to search" bindLabel="name" bindValue="value"
                                                    class="ng-select">
                                                    <!-- <ng-option value="">All</ng-option> -->
                                                    <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$"
                                                        let-index="index">
                                                        <!-- <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> -->
                                                        <span title="{{getCommitmentTypeTooltip(itemTemp.name)}}"> {{itemTemp.name}} </span>
                                                    </ng-template>
                                                    <ng-template ng-label-tmp let-itemTemp="item">
                                                        <div class="ng-value">
                                                            <span class="ng-value-label">{{itemTemp.name}}</span>
                                                        </div>
                                                    </ng-template>
    
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Client Requested Commitment Date
                                                <span style="font-size: 14px;" data-toggle="tooltip" data-placement="bottom"
                                                    class="material-icons"
                                                    title="The date the client has requested Fiserv to meet the commitment. (For example, the client requested implementation date of a product/solution, the client requested date to provide a dedicated resource, etc.)">info</span>
                                            </label>
                                            <div>
                                                <input type="date" class="form-control" [min]="todaysdate"
                                                    formControlName="clientRequestedDate" id="dueDate">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label class="col-form-label ">Please provide additional details
                                                <span style="font-size: 14px;" data-toggle="tooltip" data-placement="left"
                                                    class="material-icons" title="Including details regarding what the client asked for, products requested, client relationship and/ or reason for the Client Requested Commitment Date.">info</span>
                                            </label>
                                            <div>
                                                <textarea formControlName="clientRequestedDateComment" rows="1" type="text"
                                                    class="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label class="col-form-label asterisk">Obligation Type</label>
                                            <div>
                                                <ng-select formControlName="obligationType" [items]="obligationTypeOptions"
                                                    [virtualScroll]="true" [clearOnBackspace]="false"  
                                                    placeholder="Type to search" bindLabel="name" bindValue="value"
                                                    class="ng-select">
                                                    <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$"
                                                        let-index="index">
                                                        <span title="{{getObligationTypeTooltip(itemTemp.name)}}"> {{itemTemp.name}} </span>
                                                    </ng-template>
                                                    <ng-template ng-label-tmp let-itemTemp="item">
                                                        <div class="ng-value">
                                                            <span class="ng-value-label">{{itemTemp.name}}</span>
                                                        </div>
                                                    </ng-template>
    
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isDisplayExecSection" class="col-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Executive Leader Name</label>
                                            <div>
                                                <ng-select formControlName="execLeaderName"
                                                    [items]="executiveLeaderNameOptions" [virtualScroll]="true"
                                                    [clearOnBackspace]="false" placeholder="Type to search" bindLabel="name"
                                                    bindValue="value" class="ng-select">
                                                    <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$"
                                                        let-index="index">
                                                        <span title="{{itemTemp.tooltip}}"> {{itemTemp.name}} </span>
                                                    </ng-template>
                                                    <ng-template ng-label-tmp let-itemTemp="item">
                                                        <div class="ng-value">
                                                            <span class="ng-value-label">{{itemTemp.name}}</span>
                                                        </div>
                                                    </ng-template>
    
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isDisplayExecSection" class="col-4">
                                        <div class="form-group">
                                            <label  [ngClass]="{'asterisk': isRequiredExecEmail }" class="col-form-label">Executive Leader Email
                                            </label>
                                            <div>
                                                <input formControlName="execLeaderEmail" type="text" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div *ngIf="!isNAProduct" class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label [ngClass]="{'asterisk': !isNAProduct }" class="col-form-label">eCRM
                                                Opportunity
                                                ID
                                            </label>
                                            <div>
                                                <input formControlName="ecrmOppId" type="text" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label [ngClass]="{'asterisk': !isNAProduct }" class="col-form-label">eCRM
                                                Opportunity
                                                TCV
                                            </label>
                                            <div>
                                                <input formControlName="ecrmOppTCV" type="text" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label [ngClass]="{'asterisk': !isNAProduct }" class="col-form-label">eCRM
                                                Opportunity
                                                ACV
                                            </label>
                                            <div>
                                                <input formControlName="ecrmOppACV" type="text" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col-4" *ngIf="isDisplayPlatformSection">
                                        <div class="form-group">
                                            <label class="col-form-label asterisk">Platform(s)
                                            </label>
                                            <div>
                                                <ng-select formControlName="platform" [multiple]="true"
                                                    [items]="platformOptions" [closeOnSelect]="false" [virtualScroll]="true"
                                                     [clearOnBackspace]="false" placeholder="Type to search" bindLabel="name"
                                                    bindValue="value" class="ng-select">
                                                    <!-- <ng-option value="">All</ng-option> -->
                                                    <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$"
                                                        let-index="index">
                                                        <input id="item-{{index}}" type="checkbox"
                                                            [checked]="item$.selected" />
                                                        <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                                                    </ng-template>
                                                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                        <div class="ng-value" *ngFor="let item of items">
                                                            <span class="ng-value-label" title="{{item.name}}">{{item.name}} </span>
                                                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                                        </div>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4" *ngIf="isDisplayFAPSection">
                                        <div class="form-group">
                                            <label class="col-form-label">Fiserv Account Processor
                                            </label>
                                            <div>
                                                <ng-select formControlName="fap" [items]="fapOptions" [virtualScroll]="true"
                                                    [clearOnBackspace]="false" placeholder="Type to search" bindLabel="name"
                                                    bindValue="value" class="ng-select">
                                                    <!-- <ng-option value="">All</ng-option> -->
                                                    <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$"
                                                        let-index="index">
                                                        <!-- <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> -->
                                                        <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                                                    </ng-template>
                                                    <ng-template ng-label-tmp let-itemTemp="item">
                                                        <div class="ng-value">
                                                            <span class="ng-value-label">{{itemTemp.name}}</span>
                                                        </div>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 d-flex justify-content-center" *ngIf="isDisplayFAPTypeSection">
                                        <div class="form-check form-check-inline">
                                            <input formControlName="fapType" class="form-check-input" type="radio"
                                                id="OnPrem" value="onPrem">
                                            <label class="form-check-label mt-2" for="OnPrem">On-Prem</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input formControlName="fapType" class="form-check-input" type="radio" id="ServiceBureau"
                                                value="ServiceBureau">
                                            <label class="form-check-label mt-2" for="ServiceBureau">Service Bureau</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
    
                        <div class="card" *ngIf="isDisplayRMOnlySection">
                            <div class="card-header">
                                <b>Current Commitments Request Status (RM Only)</b>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="col-form-label asterisk">Current Commitment Request Status</label>
                                            <div>
                                                <ng-select formControlName="requestStatus" [items]="commitmentStatusOptions"
                                                    [virtualScroll]="true" [clearOnBackspace]="false"
                                                    placeholder="Type to search" bindLabel="name" bindValue="value"
                                                    class="ng-select">
                                                    <!-- <ng-option value="">All</ng-option> -->
                                                    <ng-template class="col" ng-option-tmp let-itemTemp="item"
                                                        let-item$="item$" let-index="index">
                                                        <!-- <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> -->
                                                        <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                                                    </ng-template>
                                                    <ng-template ng-label-tmp let-itemTemp="item">
                                                        <div class="ng-value">
                                                            <span class="ng-value-label">{{itemTemp.name}}</span>
                                                        </div>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
    
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="col-form-label">Additional Comments related to the client request
                                            </label>
                                            <div class="">
                                                <input formControlName="requestStatusRMComment" type="text"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
    
                        <div class="card" *ngIf="!isDisplayRMOnlySection">
                            <div class="card-header">
                                <b>Current Commitments Request Status (CCT Coordinators Only)</b>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="col-form-label asterisk">Current Commitment Request Status</label>
                                            <div>
                                                <ng-select formControlName="requestStatus" [items]="commitmentStatusOptions"
                                                    [virtualScroll]="true" [clearOnBackspace]="false"
                                                    placeholder="Type to search" bindLabel="name" bindValue="value"
                                                    class="ng-select">
                                                    <!-- <ng-option value="">All</ng-option> -->
                                                    <ng-template class="col" ng-option-tmp let-itemTemp="item"
                                                        let-item$="item$" let-index="index">
                                                        <!-- <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> -->
                                                        <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                                                    </ng-template>
                                                    <ng-template ng-label-tmp let-itemTemp="item">
                                                        <div class="ng-value">
                                                            <span class="ng-value-label">{{itemTemp.name}}</span>
                                                        </div>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
    
                                    <!--right side -->
                                    <div class="col-6" *ngIf="isDisplayExplainForCCTStatusSection">
                                        <div class="form-group">
                                            <label class="col-form-label asterisk ">
                                                <span>{{noteExplainForCCTStatus}}</span>
                                            </label>
                                            <div class="">
                                                <textarea formControlName="explainForStatusChangedCCT" rows="3" type="text"
                                                    class="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <!--left side -->
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="col-form-label">Additional Information Required from the
                                                Requestor?
                                            </label>
                                            <div style="width: 160px;">
                                                <ng-select formControlName="isAdditionalInfoRequiredCCT"
                                                    [items]="yesNoOptions" [virtualScroll]="true" [clearOnBackspace]="false"
                                                    placeholder="Type to search" bindLabel="name" bindValue="value"
                                                    class="ng-select">
                                                    <ng-template class="" ng-option-tmp let-itemTemp="item"
                                                        let-item$="item$" let-index="index">
                                                        <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                                                    </ng-template>
                                                    <ng-template ng-label-tmp let-itemTemp="item">
                                                        <div class="ng-value">
                                                            <span class="ng-value-label">{{itemTemp.name}}</span>
                                                        </div>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div *ngIf="isDisplayApproverEmailSection" class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="col-form-label asterisk">Approver Email
                                            </label>
                                            <div class="">
                                                <input formControlName="approverEmailCCT" type="text" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row" *ngIf="isDisplayRequestorResponseCCTSection">
                                    <div class="col">
                                        <div class="form-group">
                                            <label class="col-form-label asterisk">Requestor Response for Additional
                                                Information
                                            </label>
                                            <div class="">
                                                <input formControlName="requestorResponseCCT" type="text"
                                                    class="form-control">
                                            </div>
                                        </div>
    
                                        <div class="form-group">
                                            <label class="col-form-label"><b>Note to Requestors:</b> Please provide
                                                additional
                                                details
                                                regarding the commitment request, including details regarding what the
                                                client asked
                                                for, products requested, client relationship and/ or reason for the Client
                                                Requested
                                                Commitment Date.
                                            </label>
                                            <div class="">
                                                <textarea formControlName="noteToRequestorCCT" rows="3" type="text"
                                                    class="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
    
                        <!-- Fiserv Commitments SLA by CCT Coordinators -->
                        <div class="card" *ngIf="isDisplaySLASection" formGroupName="commitmentSLACCT">
                            <div class="card-header">
                                <b>Fiserv Commitments (CCT Coordinators Only)</b>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <!--left side -->
                                    <div class="col">
                                        <div class="form-group">
                                            <label class="col col-form-label">Provide the date that Fiserv agreed to meet
                                                the
                                                commitment as it relates to the request. For example: Enter the client’s
                                                implementation date for the requested product/solution or the date that
                                                Fiserv will
                                                provide a dedicated resource or the date the RM agreed to deliver on the
                                                client’s
                                                ask for requests where an approval is not required.
                                                <b>MM/DD/YYYY</b> <br> <br>
                                                Please provide additional details relevant to the date Fiserv committed to
                                                deliver
                                                on the commitment request</label>
                                            <div class="col">
                                                <textarea formControlName="additionalInfoDateCommitted" rows="3" type="text"
                                                    class="form-control"></textarea>
                                            </div>
                                        </div>
    
                                        <div class="form-group">
                                            <label class="col col-form-label">
                                                <p style="font-size: 22px;"><b>Service Level Agreements</b> </p>
                                                <p>Select the covered services associated with your SLA commitment Request.
                                                </p>
                                                <p>Requests for SLAs outside of the covered services indicated in the
                                                    drop-down
                                                    list,
                                                    should be addressed directly with the Business/Product Owner for
                                                    feasibility and
                                                    will not be managed through CCT.</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label class="col col-form-label"><b>Has the client requested to include
                                                    termination
                                                    rights in their contract?</b>
                                            </label>
                                            <div style="margin-left: 15px; width: 160px;">
                                                <ng-select formControlName="isClientRequested" [items]="yesNoOptions"
                                                    [virtualScroll]="true" [clearOnBackspace]="false"
                                                    placeholder="Type to search" bindLabel="name" bindValue="value"
                                                    class="ng-select">
                                                    <ng-template class="col" ng-option-tmp let-itemTemp="item"
                                                        let-item$="item$" let-index="index">
                                                        <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                                                    </ng-template>
                                                    <ng-template ng-label-tmp let-itemTemp="item">
                                                        <div class="ng-value">
                                                            <span class="ng-value-label">{{itemTemp.name}}</span>
                                                        </div>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col-5">
                                        <div class="form-group">
                                            <label class="col col-form-label"><b>Was a consultant involved in this SLA
                                                    Request? </b>
                                            </label>
                                            <div style="margin-left: 15px; width: 160px;">
                                                <ng-select formControlName="isConsultant" [items]="yesNoOptions"
                                                    [virtualScroll]="true" [clearOnBackspace]="false"
                                                    placeholder="Type to search" bindLabel="name" bindValue="value"
                                                    class="ng-select">
                                                    <ng-template class="col" ng-option-tmp let-itemTemp="item"
                                                        let-item$="item$" let-index="index">
                                                        <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                                                    </ng-template>
                                                    <ng-template ng-label-tmp let-itemTemp="item">
                                                        <div class="ng-value">
                                                            <span class="ng-value-label">{{itemTemp.name}}</span>
                                                        </div>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col" *ngIf="isDisplayConsultantInfoSection">
                                        <div class="row">
                                            <div class="col">
                                                <div class="form-group">
                                                    <label class="col col-form-label asterisk"> Consultant Company Name
                                                    </label>
                                                    <div class="col">
                                                        <input formControlName="consultantCompanyName" type="text"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="col">
                                                <div class="form-group">
                                                    <label class="col col-form-label asterisk"> Consultant Name
                                                    </label>
                                                    <div class="col">
                                                        <input formControlName="consultantName" type="text"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group">
                                            <label class="col col-form-label"><b>Covered Services</b>
                                            </label>
                                            <div style="margin-left: 15px;">
                                                <ng-select formControlName="coveredService" [items]="coveredServiceOptions"
                                                    [virtualScroll]="true" [clearOnBackspace]="false"
                                                    placeholder="Type to search" bindLabel="name" bindValue="value"
                                                    class="ng-select">
                                                    <!-- <ng-option value="">All</ng-option> -->
                                                    <ng-template class="col" ng-option-tmp let-itemTemp="item"
                                                        let-item$="item$" let-index="index">
                                                        <!-- <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> -->
                                                        <span  title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                                                    </ng-template>
                                                    <ng-template ng-label-tmp let-itemTemp="item">
                                                        <div class="ng-value">
                                                            <span class="ng-value-label">{{itemTemp.name}}</span>
                                                        </div>
                                                    </ng-template>
    
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label class="col col-form-label asterisk">Please provide the details on the
                                                specific SLA Metrics
                                                Requested by the client.
                                            </label>
                                            <div class="col">
                                                <textarea formControlName="detailsSLA" rows="3" type="text"
                                                    class="form-control"></textarea>
                                            </div>
                                        </div>
    
                                        <div class="form-group">
                                            <label class="col col-form-label">Please provide any additional details related
                                                to this
                                                SLA Commitment Request
                                            </label>
                                            <div class="col">
                                                <textarea formControlName="additionalInfoSLA" rows="3" type="text"
                                                    class="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>
    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    (click)="onCloseModal()">Close</button>
                <button [disabled]="!!errorMessage || this.commitmentDetailsModalForm.invalid || isDuplicateProduct" type="button"
                    class="btn btn-primary" (click)="onSaveModalChanges()">Save changes</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-labelledby="deleteConfirmModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Delete commitment</h5>
          <button type="button" class="close" (click)="onCloseDeleteModal()" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p class="text-center p-3">
                Are you sure you want to delete selected item?
            </p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDeleteModal()">Cancel</button>
            <button  type="button" class="btn btn-danger" (click)="deleteCommitmentDetail()">Delete</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Notify -->
  <div [ngStyle]="{'background-color': hasError ? '#b02e2b' : '#059862' }" id="notify">
    <div *ngIf="hasError">
        <span><i class="material-icons" aria-hidden="true">warning</i> Some thing went wrong. Please contact system administrator</span>
    </div>
    <div *ngIf="!hasError">
        <span><i class="material-icons" aria-hidden="true">check_circle</i> {{successMsg}}</span>
    </div>
</div>