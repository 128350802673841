import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormioModule } from 'angular-formio';
import { DataTableComponent } from './Components/data-table/data-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarnavComponent } from './Components/sidebarnav/sidebarnav.component';
import { SubheaderComponent } from './Components/subheader/subheader.component';
import { PaginationComponent } from './Components/pagination/pagination.component';
import { DropdownComponent } from './Components/dropdown/dropdown.component';
import { FooterComponent } from './Components/footer/footer.component';
import { FilterPipe } from './Pipes/filter.pipe';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from '../formui/breadcrumb/breadcrumb.component';
import { PhonePipe } from './Pipes/phone.pipe';
import { DateFormatPipe } from './Pipes/date-format.pipe';
import { EncodeSpacePipe } from './Pipes/encode-space.pipe';
import { FormatDateTimePipe } from './Pipes/format-date-time.pipe';
import { DelegateComponent } from './Components/delegate/delegate.component';
import { UpdateApproverComponent } from './Components/update-approver/update-approver.component';
import { FdlDataGridModule, FdlTypeaheadModule } from 'fdl-ngx11-bootstrap4';
import { FormFilterPipe } from './Pipes/form-filter.pipe';
import { ProjectDrawerContainerComponent } from './Components/project-drawer-container/project-drawer-container.component';
import { ProjectDrawerHostDirective } from './Components/project-drawer-container/project-drawer-host.directive';
import { MutipleaddComponent } from './Components/mutipleadd/mutipleadd.component';
import { PutOnHoldComponent } from './Components/put-on-hold/put-on-hold.component';
import { ReplaceAttachmentComponent } from './Components/replace-attachment/replace-attachment.component';
import { HelpFilterPipe } from './Pipes/help.pipe';
import { EditFormDetailsComponent } from './Components/edit-form-details/edit-form-details.component';
import { PreferenceSettingComponent } from 'src/app/SharedModule/Components/preference-setting/preference-setting.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PrefernceIconComponent } from './Components/prefernce-icon/prefernce-icon.component';
import { DeleteWarningComponent } from './Components/delete-warning/delete-warning.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToggleButtonComponent } from './Components/toggle-button/toggle-button.component';
import { PutProjectStatusComponent } from './Components/put-project-status/put-project-status.component';
import { TypeAheadComponent } from './Components/type-ahead/type-ahead.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DataTableUpdatedComponent } from './Components/data-table-updated/data-table-updated.component';
import { PdfGenrateComponent } from '../pdf-genrate/pdf-genrate.component';
import { EditFormsComponent } from './Components/edit-forms/edit-forms.component';
import { EditableFieldComponent } from './Components/editable-field/editable-field.component';
import { SidebarnavCctComponent } from './Components/sidebarnav-cct/sidebarnav-cct.component';
import { CommitmentDelegateDetailsComponent } from './Components/commitment-delegate-details/commitment-delegate-details.component';

@NgModule({
  declarations: [
    ReplaceAttachmentComponent,
    DataTableComponent,
    SidebarnavComponent,
    SubheaderComponent,
    PaginationComponent,
    DropdownComponent,
    FooterComponent,
    FilterPipe,
    EncodeSpacePipe,
    BreadcrumbComponent,
    PhonePipe,
    DelegateComponent,
    DateFormatPipe,
    FormFilterPipe,
    ProjectDrawerContainerComponent,
    ProjectDrawerHostDirective,
    UpdateApproverComponent,
    MutipleaddComponent,
    PutOnHoldComponent,
    PutProjectStatusComponent,
    FormatDateTimePipe,
    HelpFilterPipe,
    EditFormDetailsComponent,
    PreferenceSettingComponent,
    PrefernceIconComponent,
    DeleteWarningComponent,
    ToggleButtonComponent,
    TypeAheadComponent,
    DataTableUpdatedComponent,
    PdfGenrateComponent,
    EditFormsComponent,
    EditableFieldComponent,
    SidebarnavCctComponent,
    CommitmentDelegateDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FdlTypeaheadModule,
    NgSelectModule,
    DragDropModule,
    TypeaheadModule,
    FormioModule,
    FdlDataGridModule
  ],
  providers: [],
  exports: [
    DataTableComponent,
    SidebarnavComponent,
    SubheaderComponent,
    DropdownComponent,
    FooterComponent,
    PaginationComponent,
    FilterPipe,
    EncodeSpacePipe,
    BreadcrumbComponent,
    PhonePipe,
    DelegateComponent,
    DateFormatPipe,
    FormFilterPipe,
    ProjectDrawerContainerComponent,
    UpdateApproverComponent,
    MutipleaddComponent,
    PutOnHoldComponent,
    FormatDateTimePipe,
    HelpFilterPipe,
    ReplaceAttachmentComponent,
    EditFormDetailsComponent,
    PrefernceIconComponent,
    DeleteWarningComponent,
    ToggleButtonComponent,
    PutProjectStatusComponent,
    TypeAheadComponent,
    DataTableUpdatedComponent,
    PdfGenrateComponent,
    NgSelectModule,
    EditFormsComponent,
    SidebarnavCctComponent,
    CommitmentDelegateDetailsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
