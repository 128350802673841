import { Component, OnInit } from '@angular/core';
import { SubHeaderService } from '../../services/subHeader.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from '../../../services/user.service';
import { allowedPermissions } from '../../../core/constants';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-sidebarnav-cct',
  templateUrl: './sidebarnav-cct.component.html',   
})

export class SidebarnavCctComponent implements OnInit {

  isRequestExtract: boolean = false;
  showLeftNav: boolean = true;
  showUserLink: boolean = false;
  // Bug 249350 - START
  showRoleLink: boolean = false;
  // Bug 249350 - END
  showFormLink: boolean = false;
  showClientLink: boolean = false;
  showCustomClientLink: boolean = false;
  showProductLink: boolean = false;
  showManageAssetLink: boolean = false;
  showOrganizationLink:boolean = false;
  showHelpCategoryLink:boolean = false;
  showGroupLink: boolean = false;
  showPowerBILink: boolean = false;
  showWorkflowLink: boolean = false;
  showRequestExtractsLink: boolean = false;
  showformExtractionTemplateLink: boolean = false;
  showSelfServiceLink: boolean = false;
  showConfigurationLink: boolean = false;
  
  constructor(private _subHeaderService: SubHeaderService,
    private permission: PermissionsService,
    private userService: UserService) { }

  ngOnInit(): void {
    const user = this.userService.getUserListData();
    if (!user) {
      this.userService.callUserListData().pipe(map((data) => {
        this.userService.setUserListData(data);
        this.permission.setUserData(data);
        let usersPermission = [];
        data.permissions.forEach((ele) => {
          let permission: any = allowedPermissions[ele];
          for (let perm in permission) {
            if (permission[perm])
              usersPermission[perm] = permission[perm];
          }
        });

        this.setLinks();
      }));
    }
    else {
      this.setLinks();
    }
  }

  requestExtractLinks():void {
    this.isRequestExtract = !this.isRequestExtract;
  }

  toggleSideBar() {
    this.showLeftNav = !this.showLeftNav;
    this._subHeaderService.updateSideBarNavigation(this.showLeftNav);
  }
  private setLinks() {
    this.showUserLink = this.permission.showUserLink();
    // Bug 249350 - START
    this.showRoleLink = this.permission.showRoleLink();
    // Bug 249350 - END
    this.showFormLink = this.permission.showFormLink();
    this.showClientLink = this.permission.showClientLink();
    this.showCustomClientLink = this.permission.userData.isAdmin;
    this.showProductLink = this.permission.showProductLink();
    this.showManageAssetLink = this.permission.showManageAssets();
    this.showWorkflowLink = this.permission.showWorkflowLink();
    this.showRequestExtractsLink = this.permission.showRequestExtractsLink();
    this.showformExtractionTemplateLink = this.permission.showformExtractionTemplateLink();
    this.showOrganizationLink = this.permission.userData.isAdmin;
    this.showHelpCategoryLink = this.permission.userData.isAdmin;
    this.showGroupLink = this.permission.isCreateGroupAllowed();
    this.showPowerBILink = this.permission.userData.isAdmin;
    this.showSelfServiceLink = this.permission.userData.isAdmin;
    this.showConfigurationLink = this.permission.showConfigurationLink();
    this._subHeaderService.currentSideBarNav.subscribe(
      data => {
        this.showLeftNav = data;
      }
    );
  }
}

