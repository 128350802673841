import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../SharedModule/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormioModule } from 'angular-formio';
import { FdlDataGridModule, FdlTypeaheadModule } from 'fdl-ngx11-bootstrap4';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SelfServiceRoutingModule } from './self-service-routing.module';
import { SelfServiceComponent } from './self-service.component';
import { SelfServiceSidebarComponent } from './components/side-bar/side-bar.component';
import { SelfServiceHomeComponent } from './components/home/home.component';
import { CreateWorkflowComponent } from './components/Workflow/create-workflow/create-workflow.component';
import { CreateWorkflowContextComponent } from './components/Workflow/create-workflow-context/create-workflow-context.component';
import { ViewWorkflowContextComponent } from './components/Workflow/view-workflow-context/view-workflow-context.component';
import { WorkflowComponent } from './components/Workflow/workflow/workflow.component';
import { WorkflowExecutionComponent } from './components/Workflow/workflow-execution/workflow-execution.component';
import { WorkFlowIntegrationsComponent } from './components/Workflow/workflow-execution-integrations/workflow-integrations.component';
import { FormExtractsComponent } from './components/form-extraction-template/form-extracts/form-extracts.component';
import { FormExtractionTemplateComponent } from './components/form-extraction-template/form-template/form-template.component';
import { FormTemplateViewComponent } from './components/form-extraction-template/form-view/form-template-view.component';
import { RequestExtractsComponent } from './components/request-extracts/request-extracts/request-extracts.component';
import { ExtractionRequestComponent } from './components/request-extracts/extraction-request/extraction-request.component';
import { ReportViewComponent } from './components/manageReports/report-view/report-view.component';
import { ReportListComponent } from './components/manageReports/report-list/report-list.component';
import { ReportFormComponent } from './components/manageReports/report-form/report-form.component';
import { ReportEditComponent } from './components/manageReports/report-edit/report-edit.component';
import { ReportCreateComponent } from './components/manageReports/report-create/report-create.component';
import { EmailTemplateCreateComponent } from './components/manageEmailTemplates/email-template-create/email-template-create.component';
import { EmailTemplateListComponent } from './components/manageEmailTemplates/email-template-list/email-template-list.component';
import { EmailTemplateViewComponent } from './components/manageEmailTemplates/email-template-view/email-template-view.component';
import { HelpCategoryListComponent } from './components/manageHelpCategory/components/helpCategory-list/helpCategory-list.component';
import { HelpCategoryFormComponent } from './components/manageHelpCategory/components/helpCategory-form/helpCategory-form.component';
import { ViewHelpCategoryComponent } from './components/manageHelpCategory/components/view-helpCategory/view-helpCategory.component';
import { EditHelpCategoryComponent } from './components/manageHelpCategory/components/edit-helpCategory/edit-helpCategory.component';
import { BulkUploadComponent } from './components/bulk-upload/bulk-upload.component';
import { ViewResponsesComponent } from './components/request-extracts/view-responses/view-responses.component';
import { HelpArticleListComponent } from './components/manageHelpArticle/components/helpArticle-list/helpArticle-list.component';
import { CreateTopicComponent } from './components/manageHelpArticle/components/create-Article/create-topic.component';
import { EditHelpArticleComponent } from './components/manageHelpArticle/components/edit-helpArticle/edit-helpArticle.component';
import { ViewHelpArticleComponent } from './components/manageHelpArticle/components/view-helpArticle/view-helpArticle.component';
import { PageTemplateResponseComponent } from './components/page-template-response/page-template-response/page-template-response.component';
import { CreateTopicTemplateComponent } from './components/manageHelpArticle/components/create-topic-template/create-topic-template.component';
import { ViewPageTemplateComponent } from './components/page-template-response/view-page/view-page.component';
import { PageContainerComponent } from './components/page-template-response/page-container/page-container.component';
import { PageTemplateListComponent } from './components/page-template-list/page-template-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RefereshComponent } from './components/referesh/referesh.component';
import { CommitmentDashboardComponent } from './components/commitment-dashboard/commitment-dashboard.component';
import { NgxTypeaheadModule } from 'ngx-typeahead';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CommitmrntsByProductsComponent } from './components/commitmrnts-by-products/commitmrnts-by-products.component';
import { ReviewCommitmentModalComponent } from './components/review-commitment-modal/review-commitment-modal.component';
import { UndoCommitmentModalComponent } from './components/undo-commitment-modal/undo-commitment-modal.component';
import { CommitmentDashboardExportingComponent } from './components/commitment-dashboard-exporting/commitment-dashboard-exporting.component';
import { CommitmentsIntakeListComponent } from './components/commitments-intake/commitments-intake-list/commitments-intake-list.component';
import { CommitmentsIntakeComponent } from './components/commitments-intake/commitments-intake.component';
import { CommitmentsIntakeSearchComponent } from './components/commitments-intake/commitments-intake-search/commitments-intake-search.component';
import { CommitmentCopyComponent } from './components/commitments-intake/commitment-copy/commitment-copy.component';
import { MapFulfilmentComponent } from './components/commitments-intake/map-fulfilment/map-fulfilment.component';
import { CommitmentsIntakeDeleteModalComponent } from './components/commitments-intake/commitments-intake-delete-modal/commitments-intake-delete-modal.component';
import { UnmapFulfillmentModalComponent } from './components/commitments-intake/unmap-fulfillment-modal/unmap-fulfillment-modal.component';
import { CommitmentsDelegateComponent } from './components/commitments-intake/commitments-delegate/commitments-delegate.component';
import { CommitmentsUpdateModalComponent } from './components/commitments-intake/commitments-update-modal/commitments-update-modal.component';
import { CommitmentsAssignUsersComponent } from './components/commitments-intake/commitments-assignusers/commitments-assignusers.component';
import { CommitmentsEditAssignUsersComponent } from './components/commitments-intake/commitments-edit-assignusers/commitments-edit-assignusers.component';
import { CommitmentDashboardEditAssignUsersComponent } from './components/commitment-dashboard-edit-assignusers/commitment-dashboard-edit-assignusers.component';
import { NewCommitmentsIntakeDrawerComponent } from './components/commitments-intake/new-commitments-intake-drawer/new-commitments-intake-drawer.component';
import { CommitmentDelegateListComponent } from './components/commitment-delegate-list/commitment-delegate-list.component';
import { CommitmentDelegateSelectPopupComponent } from './components/commitment-delegate-list/commitment-delegate-select-popup/commitment-delegate-select-popup.component';
import { ClientReviewTrailComponent } from './components/client-review-trail/client-review-trail.component';
import { ClientReviewTrailOverallComponent } from './components/client-review-trail-overall/client-review-trail-overall.component';
import { ManualCommitmentComponent } from './components/manual-commitment/manual-commitment.component';
import { ManualCommitmentListComponent } from './components/manual-commitment/manual-commitment-list/manual-commitment-list.component';
import { ManualCommitmentFiservContactComponent } from './components/manual-commitment/manual-commitment-fiserv-contact/manual-commitment-fiserv-contact.component';
import { ManualCommitmentClientDetailsComponent } from './components/manual-commitment/manual-commitment-client-details/manual-commitment-client-details.component';
import { ManualCommitmentCommitmentDetailsComponent } from './components/manual-commitment/manual-commitment-commitment-details/manual-commitment-commitment-details.component';
import { CommitmentDashboardContactsComponent } from './components/commitment-dashboard-contacts/commitment-dashboard-contacts.component';

@NgModule({
  declarations: [
    SelfServiceComponent,
    SelfServiceSidebarComponent,
    SelfServiceHomeComponent,
    CreateWorkflowComponent,
    CreateWorkflowContextComponent,
    ViewWorkflowContextComponent,
    WorkflowComponent,
    WorkflowExecutionComponent,
    WorkFlowIntegrationsComponent,
    FormExtractsComponent,
    FormExtractionTemplateComponent,
    FormTemplateViewComponent,
    ExtractionRequestComponent,
    RequestExtractsComponent,
    ReportCreateComponent,
    ReportEditComponent,
    ReportFormComponent,
    ReportListComponent,
    ReportViewComponent,
    EmailTemplateCreateComponent,
    EmailTemplateListComponent,
    EmailTemplateViewComponent,
    HelpCategoryListComponent,
    HelpCategoryFormComponent,
    ViewHelpCategoryComponent,
    EditHelpCategoryComponent,
    BulkUploadComponent,
    ViewResponsesComponent,
    HelpArticleListComponent,
    CreateTopicComponent,
    EditHelpArticleComponent,
    ViewHelpArticleComponent,
    PageTemplateResponseComponent,
    CreateTopicTemplateComponent,
    ViewPageTemplateComponent,
    PageContainerComponent,
    PageTemplateListComponent,
    RefereshComponent,
    CommitmentDashboardComponent,
    CommitmrntsByProductsComponent,
    ReviewCommitmentModalComponent,
    CommitmentDashboardContactsComponent,
    UndoCommitmentModalComponent,
    CommitmentDashboardExportingComponent,
    CommitmentsIntakeComponent,
    CommitmentsIntakeSearchComponent,
    CommitmentsIntakeListComponent,
    CommitmentCopyComponent,
    MapFulfilmentComponent,
    CommitmentsIntakeDeleteModalComponent,
    UnmapFulfillmentModalComponent,
    CommitmentsDelegateComponent,
    CommitmentsUpdateModalComponent,
    CommitmentsDelegateComponent,
    NewCommitmentsIntakeDrawerComponent,
    CommitmentDelegateListComponent,
    CommitmentDelegateSelectPopupComponent,
    ClientReviewTrailComponent,
    ClientReviewTrailOverallComponent,
    ManualCommitmentComponent,
    ManualCommitmentListComponent,
    ManualCommitmentFiservContactComponent,
    ManualCommitmentClientDetailsComponent,
    ManualCommitmentCommitmentDetailsComponent,
    CommitmentsAssignUsersComponent,
    CommitmentsEditAssignUsersComponent,
    CommitmentDashboardEditAssignUsersComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SelfServiceRoutingModule,
    FormioModule,
    FormsModule,
    ReactiveFormsModule,
    FdlTypeaheadModule,
    AngularEditorModule,
    FdlDataGridModule,
    NgSelectModule,
    NgxTypeaheadModule,
    NgxChartsModule,
    TypeaheadModule.forRoot(),
  ],
  exports: [],
})
export class SelfServiceModule { }
