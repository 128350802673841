<div [formGroup]="commitmentForm" class="card">
    <div class="card-header">
        <b>Fiserv Contacts</b>
    </div>
    <div  class="card-body">
        <div class="row">
            <!--left side -->
            <div class="col-6">
                <div class="form-group">
                    <label for="rmName" class="col-sm-6 col-form-label asterisk">Relationship Manager Name
                    </label>
                    <div class="col">
                        <input formControlName="rmName" type="text" class="form-control" id="rmName">
                    </div>
                </div>

                <div class="form-group">
                    <label for="amName" class="col-sm-6 col-form-label asterisk">Account Manager Name
                    </label>
                    <div class="col">
                        <input  formControlName="amName" type="text" class="form-control" id="amName">
                    </div>
                </div>

                <div class="form-group">
                    <label for="seName" class="col-sm-6 col-form-label asterisk">Sales Executive Name
                    </label>
                    <div class="col">
                        <input  formControlName="seName" type="text" class="form-control" id="seName">
                    </div>
                </div>

                <div class="form-group">
                    <label for="aeName" class="col-sm-6 col-form-label asterisk">Account Executive Name
                    </label>
                    <div class="col">
                        <input  formControlName="aeName" type="text" class="form-control" id="aeName">
                    </div>
                </div>

                <div class="form-group">
                    <label for="aeName" class="col-sm-6 col-form-label asterisk">CCT Admin Name
                    </label>
                    <div class="col">
                        <input  formControlName="cctAdmName" type="text" class="form-control" id="cctName">
                    </div>
                </div>
            </div>

            <!--right side -->
            <div class="col-6">
                <div class="form-group">
                    <label for="email" class="col-sm-6 col-form-label asterisk">Email Address </label>
                    <div class="col">
                        <input  formControlName="rmEmail" type="text" class="form-control" value="">
                    </div>
                </div>
                <div class="form-group">
                    <label for="email" class="col-sm-6 col-form-label asterisk">Email Address </label>
                    <div class="col">
                        <input  formControlName="amEmail" type="text" class="form-control" value="">
                    </div>
                </div>
                <div class="form-group">
                    <label for="email" class="col-sm-6 col-form-label asterisk">Email Address </label>
                    <div class="col">
                        <input  formControlName="seEmail" type="text" class="form-control" value="">
                    </div>
                </div>
                <div class="form-group">
                    <label for="email" class="col-sm-6 col-form-label asterisk">Email Address </label>
                    <div class="col">
                        <input  formControlName="aeEmail" type="text" class="form-control" value="">
                    </div>
                </div>

                <div class="form-group">
                    <label for="email" class="col-sm-6 col-form-label asterisk">Email Address </label>
                    <div class="col">
                        <input  formControlName="cctAdmEmail" type="text" class="form-control" value="">
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<br>