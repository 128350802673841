import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { EndSessionRequest } from '@azure/msal-browser';
import { UserFilterService } from 'src/app/services/user.filter.service';
import { LocationStrategy } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {
  constructor(
    private authService: MsalService,
    private userFilterService: UserFilterService,
    private location: LocationStrategy
  ) { }


  signOut(correlationId?: string) {
    localStorage.removeItem("drmsToken");
    sessionStorage.removeItem("surveyToken");
    const logOutReq: EndSessionRequest = {
      postLogoutRedirectUri: environment.msalbc2Config.redirectUri + "access-denied.html",
    }
    this.authService.logoutRedirect(logOutReq);
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
    //this.signOutAsync(correlationId);
  }
  private async signOutAsync(correlationId?: string): Promise<void> {
    const requestCorrelationId = correlationId || '000-0000-00000000';
    // const apiEvent = this.telemetryManager.createAndStartApiEvent(requestCorrelationId, API_EVENT_IDENTIFIER.Logout);
    localStorage.removeItem("drmsToken");
    this.userFilterService.clearFilter();
    sessionStorage.removeItem('');

    // this.account = null;
    try {
      let urlNavigate: string;
      let postLogoutQueryParam: string;
      postLogoutQueryParam = "";
      urlNavigate = `https://login.microsoftonline.com/common/oauth2/logout?${postLogoutQueryParam}`;
      this.navigate(urlNavigate);
    } catch (error) {

    }
  }

  navigate(urlNavigate: string, popupWindow?: Window) {
    // Navigate if valid URL
    if (urlNavigate && !this.isEmpty(urlNavigate)) {
      const navigateWindow: Window = popupWindow ? popupWindow : window;
      const logMessage: string = popupWindow ? "Navigated Popup window to:" + urlNavigate : "Navigate to:" + urlNavigate;
      navigateWindow.location.assign(urlNavigate);
    }
  }

  isEmpty(str: string): boolean {
    return (typeof str === "undefined" || !str || 0 === str.length);
  }

  signOutAccessDeniedUser() {
    //this.clearCache();
    localStorage.removeItem("drmsToken");
    sessionStorage.removeItem("surveyToken");
    const logOutReq: EndSessionRequest = {
      postLogoutRedirectUri: "/access-denied.html",
    }

    this.authService.logoutRedirect(logOutReq);
  }

  signOutAccessDeniedMigratedUser() {
    //this.clearCache();
    localStorage.removeItem("drmsToken");
    sessionStorage.removeItem("surveyToken");
    const logOutReq: EndSessionRequest = {
      postLogoutRedirectUri: "/access-denied-migrated-user.html",
    }
    this.authService.logoutRedirect(logOutReq);
  }
}
