<div class="heading-container">
  <h1>{{ content["initiateIntakeRequest"] }}</h1>
</div>

<div class="main-container-sub" style="position: static">
  <div class="form-container" style="min-height: 700px">
    <div class="alert alert-success alert-dismissible" *ngIf="showSuccessMsg">
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="close"
        (click)="showSuccessMsg = false"
      >
        ×
      </button>
      <span class="iconLabel" aria-label="sucess"></span>
      <i class="material-icons" aria-hidden="true">check_circle</i>
      <p>
        {{ successMsg }}
      </p>
    </div>

    <div class="row">
      <section class="col-lg-12 position-static">
        <form
          id="submit-request-search"
          class="form-border mb-3 box collapse-view"
          [formGroup]="assignIntakeRequestForm"
          autocomplete="off"
        >
          <h2
            class="h4 collapse-arrow collapsed"
            data-toggle="collapse"
            href="#collapseFilter"
          >
            {{ content["searchFormResponse"] }}
          </h2>
          <div class="filter-form-container collapse" id="collapseFilter">

            <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="isAdmin">
              <fdl-type-ahead label="Organization:"
                              formControlName="organizationTitle"
                              [collection]="orgSuggestion"
                              optionField="title"
                              [placeholder]="content['placeholderSearchFormResponse']"
                              [scrollable]="true"
                              [optionsLimit]="100"
                              [minLength]="0"
                              [(ngModel)]="orgText"
                              class="filter-typeahead">
              </fdl-type-ahead>
            </div>

            <div class="filter-form-control pr-4 typeahead-wrapper">
              <fdl-type-ahead label="Form Name:"
                              formControlName="title"
                              [collection]="submitRequestList"
                              optionField="title"
                              [placeholder]="content['placeholderSearchFormResponse']"
                              [scrollable]="true"
                              [optionsLimit]="500"
                              [minLength]="0">
              </fdl-type-ahead>
              <!--<div class="has-error">
        <span *ngIf="assignIntakeRequestForm.get('title').errors?.pattern">
            <i class="material-icons">warning</i> {{regexValidationMessage.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE}}
        </span>
    </div>-->
            </div>

            <div class="filter-form-control pr-4 typeahead-wrapper">
              <fdl-type-ahead label="Product Name:"
                              formControlName="productTitle"
                              [collection]="productSuggestion"
                              optionField="title"
                              [placeholder]="content['placeholderSearchFormResponse']"
                              [scrollable]="true"
                              [optionsLimit]="100"
                              [minLength]="0"
                              [(ngModel)]="productText"
                              class="filter-typeahead">
              </fdl-type-ahead>
            </div>
            <div>
              <button type="button"
                      class="btn fdl-btn btn-primary-dark ml-3"
                      aria-label="Search"
                      (click)="filterSubmitRequest(true)">
                Search
              </button>
            </div>
            <div class="ml-2" *ngIf="filtered">
              <button type="button"
                      class="btn fdl-btn btn-primary-dark ml-3"
                      aria-label="Reset"
                      (click)="clearFilterList()">
                Reset
              </button>
            </div>
          </div>
        </form>

        <div class="data-table-container">
          <div class="justify-content-between d-flex">
            <div class="headline-container">
              <h2 class="h4">{{ content["listInitiateIntakeRequest"] }}</h2>
              <p>
                Total {{ pagerObject?.totalCount }}
                {{ content["initiateIntakeRequests"] }}
              </p>
            </div>
            <div class="d-flex">
              <!-- add class of 'fa-rotation' on click of the refresh icon -->
              <div class="action-container fdl-2" style="display: flex; align-items: self-start;"><button type="button" class="btn fdl-btn btn-primary-orange mr-2" (click)="initiateInakeButton('initiate')" [disabled]="(formSets[0].assignedFormsList.length) == 0">
  Initiate Intake Request
</button>
  <button *ngIf="!isClient" type="button" class="btn fdl-btn btn-primary-orange mr-2"  [disabled]="(formSets[0].assignedFormsList.length) == 0" (click)="initiateInakeButton('User')">
    Assign
  </button> 
  <app-prefernce-icon></app-prefernce-icon>
</div>

            </div>
          </div>
          <div class="table-responsive form-responces checkbox-table">
            <app-data-table
              *ngIf="submitRequestList"
              [rows]="filteredSubmitRequestList"
              [keys]="keys"
              [isClient]="isClient"
              [displayColumns]="displayColumns"
              (sortingClicked)="applySorting($event)"
              [showMenu]="false"
              (launchBtnClicked)="takeAction($event)"
              [showLaunchButton]="true"
              [showCheckbox]="true"
              [enableSorting]="true"
              [formSets]="formSets"
              [hidePartialLaunchButton]="
                !(formSets[0].assignedFormsList.length < 1)
              "
              [counter]="0"
              [listname]="listname"
            >
            </app-data-table>

            <div>
              <app-pagination
                *ngIf="
                  pagerObject &&
                  pagerObject.totalPages > 1 &&
                  filteredSubmitRequestList &&
                  filteredSubmitRequestList.length > 0
                "
                [pagerObject]="pagerObject"
                (pagingChanged)="changePage($event)"
              ></app-pagination>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <!-- Drawer Container -->
  <div *ngIf="loadDrawer">
    <app-project-drawer-container
      componentName="client"
      CancelText="Back"
      [isChannelPartner]="isChannelPartner"
      state="CLIENT"
    ></app-project-drawer-container>
  </div>
  <div *ngIf="initatorDrawer">
    <div
      class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open"
      tabindex="-1"
      aria-labelledby="addNewAccount"
      aria-expanded="true"
      role="region"
    >
      <div
        class="drawer-contents col-xs-12 col-sm-12 col-md-12 project-drawer p1"
      >
        <h3>{{ content["initiateIntakeRequest"] }}</h3>

        <div class="">
          <div class="form-container p-1">
            <!--<app-mutipleadd #addmultiple [fb]="fb" [formControlData]="initiateIntakeRequestForm" formElementName="requestor"  formArrayName="requestor"></app-mutipleadd>-->

            <div class="alert alert-dismissible alert-danger" *ngIf="isError">
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="close"
                (click)="isError = false"
              >
                ×
              </button>
              <i class="material-icons">warning</i>
              <p [innerHtml]="errorMsg"></p>
            </div>
            <div
              class="alert alert-success alert-dismissible"
              *ngIf="showSuccessMsgClient"
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="close"
                (click)="showSuccessMsgClient = false"
              >
                ×
              </button>
              <span class="iconLabel" aria-label="sucess"></span>
              <i class="material-icons" aria-hidden="true">check_circle</i>
              <p>
                {{ successMsgClient }}
              </p>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-1">
                <p>{{ content["initiateIntakeRequestMessage"] }}</p>
              </div>
            </div>
            <form [formGroup]="initiateIntakeRequestForm" autocomplete="off">
              <div
                class="col-md-10 mb-3"
                id="submit-request-group-question"
                style="margin-left: -17px"
              >
                <div class="col-md12">
                  <label class=""
                    >Do you want to group this request into a package?<span
                      _ngcontent-sva-c167=""
                      data-toggle="tooltip"
                      title="Group Intake Requests in a bundle and tags it using a package name. You can search these bundled requests using the package name."
                      class="material-icons btn-secondary f-18"
                    >
                      info</span
                    ></label
                  >
                </div>
                
                <fieldset>
                  <div class="radio-group d-flex">
                    <div class="radio mr-3">
                      <input
                        type="radio"
                        id="packageTwo"
                        name="package"
                        value="yes"
                        [attr.disabled]="
                          formSets[0].assignedFormsList.length > 1 == true
                            ? true
                            : null
                        "
                        formControlName="package"
                      />
                      <label for="packageTwo"><span>Yes</span></label>
                    </div>
                    <div class="radio">
                      <input
                        type="radio"
                        id="packageOne"
                        name="package"
                        value="no"
                        [attr.disabled]="
                          formSets[0].assignedFormsList.length > 1 == true
                            ? true
                            : null
                        "
                        formControlName="package"
                      />

                      <label
                        for="packageOne"
                        (click)="onInitiateIntakeRequestorPackageChanges('parent')"
                        ><span>No </span></label
                      >
                    </div>
                  </div>
                </fieldset>
              </div>
              <div
                class="sddsd"
                *ngIf="
                  formSets[0].assignedFormsList.length > 1 ||
                  initiateIntakeRequestForm.controls['package'].value == 'yes'
                "
              >
                <div class="col-md-10 mb-3" style="margin-left: -17px">
                  <!--  <div class="col-md12"><label class="strong">Is this need to part of existing package?</label></div>-->
                  <fieldset>
                    <div class="radio-group d-flex">
                      <div class="radio mr-3">
                        <input
                          type="radio"
                          id="requestorPackageTwo"
                          name="requestorPackage"
                          value="yes"
                          (click)="onInitiateIntakeRequestorPackageChanges('yes')"
                          formControlName="requestorPackage"
                        />
                        <label for="requestorPackageTwo"
                          ><span>Existing Package</span></label
                        >
                      </div>
                      <div class="radio">
                        <input
                          type="radio"
                          id="requestorPackageOne"
                          name="requestorPackage"
                          value="no"
                          formControlName="requestorPackage"
                        />

                        <label
                          for="requestorPackageOne"
                          (click)="onInitiateIntakeRequestorPackageChanges('no')"
                          ><span>New Package</span></label
                        >
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div
                  class="row"
                  *ngIf="
                    initiateIntakeRequestForm.controls['requestorPackage']
                      .value == 'yes'
                  "
                >
                  <div
                    class="col-md-7 mb-3"
                    [ngClass]="{ 'has-error': batchrequesterror }"
                  >
                    <div><label class="asterisk"> Package Name</label></div>
                    <div>
                      <fdl-type-ahead
                        formControlName="batchrequest"
                        [collection]="allPackagesData"
                        (ngModelChange)="onInitiateIntakePackageChange($event)"
                        optionField="title"
                        [placeholder]="content['placeholderSearchFormResponse']"
                        [scrollable]="true"
                        [optionsLimit]="500"
                        (onSelect)="onInitiateIntakePackageSelect($event)"
                        [minLength]="0"
                      >
                      </fdl-type-ahead>
                    </div>

                    <span *ngIf="batchrequesterror">
                      <i class="material-icons">warning</i>{{ batchNameError }}
                    </span>
                  </div>
                </div>
                <div
                  class="row"
                  *ngIf="
                    initiateIntakeRequestForm.controls['requestorPackage']
                      .value == 'no'
                  "
                >
                  <div
                    class="col-md-7 mb-3"
                    [ngClass]="{ 'has-error': batchrequesterror || (!initiateIntakeRequestForm.get('batchrequest').valid && initiateIntakeRequestForm.get('batchrequest').errors?.pattern 
                    && (initiateIntakeRequestForm.get('batchrequest').dirty || isSubmitted))}"
                  >
                    <div><label class="asterisk"> Package Name</label></div>
                    <div>
                      <div>
                        <input
                          type="text"
                          class="form-control"
                          id="batchrequest"
                          name="batchrequest"
                          value="user"
                          formControlName="batchrequest"
                        />
                      </div>
                    </div>
                    
                    <span *ngIf="(!initiateIntakeRequestForm.get('batchrequest').valid && initiateIntakeRequestForm.get('batchrequest').errors?.pattern 
                    && (initiateIntakeRequestForm.get('batchrequest').dirty || isSubmitted))">
                      <i class="material-icons">warning</i>{{regexValidationMessage.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE}}</span>

                    <span *ngIf="batchrequesterror">
                      <i class="material-icons">warning</i> {{ batchNameError }}
                    </span>
                  </div>
                </div>
              </div>

              <div
                id="submit-request-form-name"
                class="form-row"
                *ngIf="!(formSets[0].assignedFormsList.length > 1)"
              >
                <div
                  class="col-md-7 mb-3"
                  [ngClass]="{
                    'has-error':
                      !initiateIntakeRequestForm.get('formName').valid &&
                      (initiateIntakeRequestForm.get('formName').touched ||
                        isSubmitted)
                  }"
                >
                  <div><label class="asterisk">Request/Form Name</label></div>

                  <div>
                    <div>
                      <input
                        type="text"
                        class="form-control"
                        id="formName"
                        name="formName"
                        value="user"
                        formControlName="formName"
                      />
                    </div>
                  </div>

                  <span
                    *ngIf="
                      !initiateIntakeRequestForm.get('formName').valid &&
                      (initiateIntakeRequestForm.get('formName').touched ||
                        isSubmitted)
                    "
                  >
                    <i class="material-icons">warning</i> {{regexValidationMessage.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE}}
                  </span>
                </div>
              </div>
              <div
                class="form-row"
                style="padding-left: 5px"
                *ngIf="formSets[0].assignedFormsList.length > 1"
              >
                <div class="col-md-12 p0 mt-3">
                  <p
                    class="mb-0"
                    *ngIf="formSets[0].assignedFormsList.length > 1"
                  >
                    <b>Note:</b> To edit the Form Name or Description,
                    double-click the respective record.
                  </p>

                  <div [ngClass]="{ 'has-error': formSets[0].assignedFormsList.length <= 0 }">
                    <span *ngIf="formSets[0].assignedFormsList.length <= 0">
                      Please select at least one form to assign.
                    </span>
                  </div>
                  <app-data-table [rows]="formSets[0].assignedFormsList" 
                                [keys]="intakeKeys" 
                                [displayColumns]="columns" 
                                [showCheckbox]="false" 
                                [showMenu]="false"
                                [staticColumns]="true"
                                (deleteIntakeClicked)="myAssignNavigate($event)"
                                [showMyDeleteActionButton]="true"
                                [editableRows]="true"
                                [listname]="listname"
                                [formSets]="formSets"
                                type="Configurations"
                                selectionType = 'single'
                                (rowsChanged)="rowsChanged($event)">
                </app-data-table>
                </div>
              </div>
              <div class="form-row" id="submit-request-client-name" style="display: flex;">
                <div
                  class="col-md-7 mb-3"
                  [ngClass]="{
                    'has-error':
                      invalidClientName ||
                      invalidClientName 
                        || (!initiateIntakeRequestForm.get('clientName').valid && initiateIntakeRequestForm.get('clientName').errors?.pattern && initiateIntakeRequestForm.get('clientName').dirty) 
                  }"
                >
                  <div><label class="">Client Name/DUNS</label></div>
                  <div
                    class="form-row align-items-end"
                    style="
                      margin-left: 0px;
                      margin-right: 0px;
                      margin-top: 0px;
                    "
                    *ngIf="
                      (isFiservUser == true || HaveClientPartnerRole()) &&
                      initiateIntakeRequestForm.controls['requestorPackage']
                        .value != 'yes'
                    "
                  >
                    <div class="typeahead-wrapper" style="width: 100%">
                      <fdl-type-ahead
                        [(ngModel)]="clientText"
                        formControlName="clientName"
                        [collection]="clientSuggestion"
                        (onSelect)="onInitiateIntakeClientSelect($event)"
                        optionField="titleWithDuns"
                        [placeholder]="content['placeholderSearchFormResponse']"
                        [scrollable]="true"
                        [optionsLimit]="100"
                        [minLength]="0"
                        (input)="typeaheadLoadingClients()"
                      >
                      </fdl-type-ahead>

                      <div class="has-error">
                        <span
                        *ngIf="invalidClientName 
                        || (!initiateIntakeRequestForm.get('clientName').valid && initiateIntakeRequestForm.get('clientName').errors?.pattern && initiateIntakeRequestForm.get('clientName').dirty) 
                        "
                        >
                        <i class="material-icons">warning</i> Please enter a valid Client Name
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                      *ngIf="
                        (isFiservUser == false && !HaveClientPartnerRole()) ||
                        initiateIntakeRequestForm.controls['requestorPackage']
                          .value == 'yes'
                      "
                    >
                      <input
                        type="text"
                        class="form-control"
                        disabled="disabled"
                        formControlName="clientName"
                      />
                  </div>
                </div>

                <div
                  class="col-md-1 pt-1 align-self-center fdl-2"
                  *ngIf="
                    (!isClient || isChannelPartner) &&
                    initiateIntakeRequestForm.controls['requestorPackage']
                      .value != 'yes'
                  "
                  style="
                    padding-right: 216px;
                  "
                >
                  <button
                    (click)="openAddClientDrawer()"
                    class="btn fdl-btn btn-primary-orange formio-button-add-row"
                  >
                    <i class="fa fa-plus"></i>&nbsp; Add Client
                  </button>
                  <!--<a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Add New Client" (click)="openAddClientDrawer()"><i class="material-icons f-30">add_circle_outline</i></a>-->
                </div>

              </div>

              <div class="form-row"
                  style="
                  display: flex;
                  justify-content: space-between;
                  margin-left: 6px;
                  margin-right: 0px;
                  margin-top: 0px;
                  margin-bottom: 14px;
                "
              >
              </div>

              <div class="form-row" id="submit-request-client-description">
                <div class="col-md-7 mb-3">
                  <label for="description">Client Description</label>
                  <textarea
                    disabled
                    class="form-control"
                    name="description"
                    formControlName="description"
                  ></textarea>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-7 mb-3">
                  <div>
                    <label class="">
                      {{ content["formRequestInitiatorName"] }}</label
                    >
                  </div>

                  <div>
                    <div>
                      <input
                        type="text"
                        class="form-control"
                        disabled="disabled"
                        id="initiator"
                        name="initiator"
                        value=""
                        formControlName="initiatorName"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-7 mb-3">
                  <div>
                    <label class="">
                      {{ content["formRequestInitiateOn"] }}</label
                    >
                  </div>

                  <div>
                    <div>
                      <input
                        type="text"
                        class="form-control"
                        disabled="disabled"
                        id="initiator"
                        name="initiateDate"
                        value=""
                        formControlName="initiateDate"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="fdl-2 button-container">
          <button
            type="button"
            class="btn fdl-btn btn-secondary-light"
            data-dismiss="modal"
            (click)="clearForm()"
          >
            {{ content["cancelIntakeRequest"] }}
          </button>
          <div>
            <button
              type="button"
              class="btn fdl-btn btn-primary-orange ml-2"
              [disabled]="(formSets[0].assignedFormsList.length) == 0 && !initiateIntakeRequestForm.get('formName').valid" 
              (click)="onInitatedIntakeRequest()"
            >
              {{ content["initiateIntakeRequest"] }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="backdrop-space">
      <div class="backdrop"></div>
    </div>
  </div>
  <div *ngIf="registerDrawer">
    <div
      class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-7 fold open"
      tabindex="-1"
      aria-labelledby="addNewAccount"
      aria-expanded="true"
      role="region"
    >
      <div class="drawer-contents col-xs-12 col-sm-12 col-md-12 project-drawer">
        <h3 class="modal-title" id="myLargeModalLabel">
          Assign Intake Request
        </h3>

        <div class="requset-form-container">
          <div class="alert alert-dismissible alert-danger" *ngIf="isError">
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="close"
              (click)="isError = false"
            >
              ×
            </button>
            <i class="material-icons">warning</i>
            <p [innerHtml]="errorMsg"></p>
          </div>
          <div class="row">
            <div class="col-md-12 mb-1">
              <p>Please provide details below to initiate the request.</p>
            </div>
          </div>
          <form [formGroup]="assignIntakeRequestForm" autocomplete="off">
            <div class="col-md-10 mb-3" style="margin-left: -17px">
              <div class="col-md12">
                <label class=""
                  >Do you want to group this request into a package?<span
                    _ngcontent-sva-c167=""
                    data-toggle="tooltip"
                    title="Group Intake Requests in a bundle and tags it using a package name. You can search these bundled requests using the package name."
                    class="material-icons btn-secondary f-18"
                  >
                    info</span
                  ></label
                >
              </div>
              <fieldset>
                <div class="radio-group d-flex">
                  <div class="radio mr-3">
                    <input
                      type="radio"
                      id="packageTwo"
                      name="package"
                      value="yes"
                      [attr.disabled]="
                        formSets[0].assignedFormsList.length > 1 == true
                          ? true
                          : null
                      "
                      formControlName="package"
                    />
                    <label for="packageTwo"><span>Yes</span></label>
                  </div>
                  <div class="radio">
                    <input
                      type="radio"
                      id="packageOne"
                      name="package"
                      value="no"
                      [attr.disabled]="
                        formSets[0].assignedFormsList.length > 1 == true
                          ? true
                          : null
                      "
                      formControlName="package"
                    />

                    <label for="packageOne" (click)="onAssignIntakeRequestPackageChanges('parent')"><span>No </span></label>
                  </div>
                </div>
              </fieldset>
            </div>
            <div
              class="sddsd"
              *ngIf="
                formSets[0].assignedFormsList.length > 1 ||
                assignIntakeRequestForm.controls['package'].value == 'yes'
              "
            >
              <div class="col-md-10 mb-3" style="margin-left: -17px">
                <fieldset>
                  <div class="radio-group d-flex">
                    <div class="radio mr-3">
                      <input
                        type="radio"
                        id="requestorPackageTwo"
                        name="requestorPackage"
                        value="yes"
                        (click)="onAssignIntakeRequestPackageChanges('yes')"
                        formControlName="requestorPackage"
                      />
                      <label for="requestorPackageTwo"
                        ><span>Existing Package</span></label
                      >
                    </div>
                    <div class="radio">
                      <input
                        type="radio"
                        id="requestorPackageOne"
                        name="requestorPackage"
                        value="no"
                        (click)="onAssignIntakeRequestPackageChanges('yes')"
                        formControlName="requestorPackage"
                      />

                      <label for="requestorPackageOne"
                        ><span>New Package</span></label
                      >
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="row" *ngIf="assignIntakeRequestForm.controls['requestorPackage'].value == 'yes'">
                <div class="col-md-5 mb-3" [ngClass]="{ 'has-error': batchrequesterror }">
                  <div><label class="asterisk">Package Name</label></div>

                  <div style="max-width:98%">
                    <fdl-type-ahead
                      formControlName="batchrequest"
                      (ngModelChange)="onAssignIntakePackageChange($event)"
                      [collection]="allPackagesData"
                      optionField="title"
                      [placeholder]="content['placeholderSearchFormResponse']"
                      [scrollable]="true"
                      [optionsLimit]="500"
                      [minLength]="0"
                      (onSelect)="onAssignIntakePackageSelect($event)"
                    >
                    </fdl-type-ahead>
                  </div>

                  <span *ngIf="batchrequesterror">
                    <i class="material-icons">warning</i> {{ batchNameError }}
                  </span>
                </div>
              </div>
              <div class="row" *ngIf="assignIntakeRequestForm.controls['requestorPackage'].value == 'no'">
                <div class="col-md-5 mb-3"
                  [ngClass]="{ 'has-error':
                                batchrequesterror ||
                                assignIntakeRequestForm.get('batchrequest').errors?.pattern 
                            }"
                >
                  <div><label class="asterisk"> Package Name</label></div>

                  <div>
                    <div>
                      <input
                        type="text"
                        class="form-control"
                        id="batchrequest"
                        name="batchrequest"
                        value="user"
                        formControlName="batchrequest"
                      
                      />
                    </div>
                  </div>

                  <span *ngIf="batchrequesterror">
                    <i class="material-icons">warning</i>{{ batchNameError }}
                  </span>
                  <span *ngIf="
                    !batchrequesterror &&
                    !assignIntakeRequestForm.get('batchrequest').valid &&
                    assignIntakeRequestForm.get('batchrequest').errors?.pattern &&
                    (assignIntakeRequestForm.get('batchrequest').touched)">
                    <i class="material-icons">warning</i> {{regexValidationMessage.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE}}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="row"
              *ngIf="!(formSets[0].assignedFormsList.length > 1)"
            >
              <div
                class="col-md-5 mb-3"
                [ngClass]="{
                  'has-error':
                    !assignIntakeRequestForm.get('formName').valid &&
                    (assignIntakeRequestForm.get('formName').touched || isSubmitted)
                }"
              >
                <div><label class="asterisk">Request/Form Name</label></div>

                <div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      id="formName"
                      name="formName"
                      value="user"
                      formControlName="formName"
                           
                    />
                  </div>
                </div>
                <span *ngIf="!assignIntakeRequestForm.get('formName').valid &&
                    (assignIntakeRequestForm.get('formName').touched &&
                    assignIntakeRequestForm.get('formName').errors?.required)">
                  <i class="material-icons">warning</i> Please Enter form Name.
                </span>

                <span *ngIf="
                !assignIntakeRequestForm.get('formName').valid &&
                (assignIntakeRequestForm.get('formName').touched || isSubmitted) &&
                assignIntakeRequestForm.get('formName').errors?.pattern
                ">
                  <i class="material-icons">warning</i> {{regexValidationMessage.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE}}
                </span>
              </div>
            </div>
            <div class="form-row" *ngIf="formSets[0].assignedFormsList.length > 1">
              <div class="col-md-12 p- mt-3">
                <p
                  class="mb-0"
                  *ngIf="formSets[0].assignedFormsList.length > 1"
                >
                  <b>Note:</b> To edit the Form Name, Description or Due Date,
                  double-click the respective record.
                </p>

                <div [ngClass]="{ 'has-error': formSets[0].assignedFormsList.length <= 0 }">
                  <span *ngIf="formSets[0].assignedFormsList.length <= 0">
                     Please select at least one form to assign.
                  </span>
                </div>
                <app-data-table [rows]="formSets[0].assignedFormsList" 
                                [keys]="intakeKeys" 
                                [displayColumns]="columns" 
                                [showCheckbox]="false" 
                                [showMenu]="false"
                                [staticColumns]="true"
                                (deleteIntakeClicked)="myAssignNavigate($event)"
                                [showMyDeleteActionButton]="true"
                                [editableRows]="true"
                                [listname]="listname"
                                [formSets]="formSets"
                                type="Configurations"
                                selectionType = 'single'
                                (rowsChanged)="rowsChanged($event)">
                </app-data-table>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-5 mb-3"
                [ngClass]="{
                  'has-error': invalidClientName || (!assignIntakeRequestForm.get('clientName').valid && assignIntakeRequestForm.get('clientName').errors?.pattern && assignIntakeRequestForm.get('clientName').dirty) 
                }"
              >
                <div><label>Client Name</label></div>
                <div class="form-row align-items-end" style="margin-left: 0px; margin-right: 0px; margin-top: 0px;"
                  *ngIf="(isFiservUser == true || HaveClientPartnerRole())
                    && assignIntakeRequestForm.controls['package'].value === 'no'
                    || assignIntakeRequestForm.controls['requestorPackage'].value === 'no'"
                >
                  <div class="typeahead-wrapper" style="width: 100%">
                    <fdl-type-ahead
                      [(ngModel)]="clientText"
                      (ngModelChange)="onClientNameChange($event)"
                      formControlName="clientName"
                      [collection]="clientSuggestion"
                      (onSelect)="onAssignIntakeClientSelect($event)"
                      optionField="titleWithDuns"
                      [placeholder]="content['placeholderSearchFormResponse']"
                      [scrollable]="true"
                      [optionsLimit]="100"
                      [minLength]="0"
                      (input)="typeaheadLoadingClients()"
                    >
                    </fdl-type-ahead>

                    <div class="has-error">
                      <span *ngIf="invalidClientName || (!assignIntakeRequestForm.get('clientName').valid && assignIntakeRequestForm.get('clientName').errors?.pattern && assignIntakeRequestForm.get('clientName').dirty)">
                      <i class="material-icons">warning</i> Please enter a valid Client Name
                      </span>
                    </div>
                  </div>
                </div>

                <div *ngIf="(isFiservUser == false && !HaveClientPartnerRole()) || (assignIntakeRequestForm.controls['package'].value === 'yes' && assignIntakeRequestForm.controls['requestorPackage'].value === 'yes')">
                    <input type="text" class="form-control" disabled="disabled" formControlName="clientName" />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div
                class="col-md-10 mb-3 mt-2"
                [ngClass]="{
                  'has-error':
                    !assignIntakeRequestForm.get('requestorType').valid &&
                    (assignIntakeRequestForm.get('requestorType').touched ||
                      isSubmitted)
                }"
              >
                <div class="col-md12">
                  <label class="strong"> Respondent Type</label>
                </div>
                <fieldset>
                  <div class="radio-group d-flex">
                    <div class="radio mr-3">
                      <input
                        type="radio"
                        id="requestorTypeRadioTwo"
                        (change)="changeUserType()"
                        name="requestorType"
                        value="user"
                        formControlName="requestorType"
                      />
                      <label for="requestorTypeRadioTwo"
                        ><span>Registered User</span></label
                      >
                    </div>
                    <div class="radio">
                      <input
                        type="radio"
                        id="requestorTypeRadioOne"
                        (change)="changeUserType()"
                        name="requestorType"
                        value="guest"
                        formControlName="requestorType"
                      />

                      <label for="requestorTypeRadioOne"
                        ><span>Guest</span></label
                      >
                    </div>
                  </div>
                </fieldset>
                <span
                  *ngIf="
                    !assignIntakeRequestForm.get('requestorType').valid &&
                    (assignIntakeRequestForm.get('requestorType').touched ||
                      isSubmitted)
                  "
                >
                  <i class="material-icons">warning</i> Please select user type.
                </span>
              </div>
            </div>

            <div class="form-row"
                 *ngIf="
                assignIntakeRequestForm.controls['requestorType'].value == 'user'
              ">
              <div class="col-md-10 pr-5 mb-3">
                <div [ngClass]="{'has-error': ( assignIntakeRequestForm.get('requestor').errors?.duplicate) }">
                  <app-mutipleadd #addmultiple
                                  [cssWidth]="'67%'"
                                  [isAddButtonEnable]="true"
                                  (formReady)="formInitialized('requestor', $event)"
                                  [isFdl]="true"
                                  [fdlData]="orgUsersList"
                                  [filedJson]="filedJson"
                                  elementName="requestor"
                                  [filedDetails]="filedDetails"
                                  [formControlData]="assignIntakeRequestForm"
                                  [isSubmitted]="isSubmitted"
                                  (onClear)="onRequesterClear($event)"></app-mutipleadd>
                  <span *ngIf="assignIntakeRequestForm.get('requestor').errors?.duplicate">
                    <i class="material-icons">warning</i>Duplicate respondent not allowed.
                  </span>
                </div>
              </div>
            </div>

            <div
              class="form-row"
              *ngIf="
                assignIntakeRequestForm.controls['requestorType'].value == 'guest'
              "
            >
              <div class="col-md-12 mb-3">
                <div [ngClass]="{'has-error': ( assignIntakeRequestForm.get('guestRequestor').errors?.duplicate) }">
                  <app-mutipleadd #addmultiple2
                                  [isAddButtonEnable]="true"
                                  (formReady)="formInitialized('guestRequestor', $event)"
                                  [filedJson]="guestfieldJson"
                                  class="col-md-4"
                                  elementName="guestRequestor"
                                  [filedDetails]="guestUserForm"
                                  [formControlData]="assignIntakeRequestForm"></app-mutipleadd>
                  <span *ngIf="assignIntakeRequestForm.get('guestRequestor').errors?.duplicate">
                    <i class="material-icons">warning</i>Duplicate respondent not allowed.
                  </span>
                </div>
              </div>
            </div>
            <br />
            <div
              class="form-row"
              *ngIf="!(formSets[0].assignedFormsList.length > 1)"
            >
              <div class="col-md-5 mb-3" style="margin-top: -20px;">
                <label for="dueDate">Due Date</label>
                <input  style="max-width:98%"
                  type="date"
                  onkeydown="return false"
                  [min]="todaysdate"
                  class="form-control"
                  formControlName="dueDate"
                  id="dueDate"
                />
              </div>
            </div>
          </form>
        </div>

        <div class="fdl-2 button-container">
          <button
            type="button"
            class="btn fdl-btn btn-secondary-light"
            data-dismiss="modal"
            (click)="clearForm()"
          >
            Cancel
          </button>
          <div>
            <button
              type="button"
              class="btn fdl-btn btn-primary-orange ml-2"
              [disabled]="(formSets[0].assignedFormsList.length) == 0 && !initiateIntakeRequestForm.get('formName').valid"
              (click)="onAssignedIntakeRequest()"
            >
              Assign
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="backdrop-space">
      <div class="backdrop"></div>
    </div>
  </div>
</div>
