<div class="heading-container fdl-2">
  <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="material-icons"> arrow_back </span>
        {{returnPage}}
      </a>
    </div>
    <h1>{{pageTemplateNameTitle}}</h1>
  </div>
  <div>
    <button *ngIf="isBulkUploadAllowed || isAdmin" type="button" class="btn fdl-btn btn-primary-orange mr-2 mt-1" (click)="prePopulateRequests()">
      <span class="material-icons">add</span>
      Pre-populate Requests
    </button>
    <button *ngIf="submissionFromUI || isAdmin" type="button" class="btn fdl-btn btn-primary-orange mt-1" (click)="showApproverDrawer()">
        <span class="material-icons">add</span>
        Submit New {{pageTemplateNameTitle}}
    </button>
  </div>
</div>

<div class="form-container">
  <!-- Error Msg Alert -->
  <div class="alert alert-dismissible alert-danger" *ngIf="errorMsg">
    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="errorMsg=''">×</button>
    <i class="material-icons">warning</i>
    <p [innerHtml]="errorMsg"></p>
  </div>
  <!-- Success Msg Alert -->
  <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="setSuccessMsg()">×</button>
    <i class="material-icons" aria-hidden="true">check_circle</i>
    <p [innerHtml]="successMsg"></p>
  </div>
  <!-- Update Approver Success Msg Alert -->
  <div class="alert alert-success alert-dismissible" *ngIf="updateApproverMsg">
    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="updateApproverMsg=''">×</button>
    <i class="material-icons" aria-hidden="true">check_circle</i>
    <p [innerHtml]="updateApproverMsg"></p>
  </div>

  <form class="form form-border mb-3 collapse-view" autocomplete="off" [formGroup]="filterForm" *ngIf="isSearchAllowed || searchByFormFieldJson.length > 0">
    <h2 class="h4 collapse-arrow collapsed" [ngClass]="[filterExists ? '':'collapsed']" data-toggle="collapse" href="#collapseFilter">Search</h2>
    <div class="filter-form-container-column2 search-filter collapse multi-form-container" [ngClass]="[filterExists ? 'show':'']" id="collapseFilter">
      <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="isSearchAllowed && isEntityLink" [ngClass]="{'has-error': !filterForm.get('clntName').valid && (filterForm.get('clntName').touched || filterForm.get('clntName').dirty || isSubmitted)}">
        <fdl-type-ahead  [(ngModel)]="clientText" label="Client Name" formControlName="clntName" [collection]="clntSuggestion" optionField="title" 
          (input)="typeaheadLoadingClients($event.target.value, false)" placeholder="Type to search" [scrollable]="true" [optionsLimit]="100" [minLength]="0">
        </fdl-type-ahead>
      </div>
      
      <!-- 
      <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="isSearchAllowed && isEntityLink">
        <fdl-type-ahead 
          label="BU Name" 
          formControlName="orgName" 
          [collection]="orgSuggestion" 
          optionField="title"
          placeholder="Type to search" 
          [scrollable]="true" 
          [optionsLimit]="500" 
          [minLength]="0" 
          (onSelect)="onOrgSelect($event)"
        >
        </fdl-type-ahead>
      </div>

      <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="isSearchAllowed && isEntityLink">
        <fdl-type-ahead 
          label="Product Name"
          formControlName="prodName" 
          [collection]="prodSuggestion" 
          optionField="title"
          placeholder="Type to search" 
          [scrollable]="true" 
          [optionsLimit]="500" 
          [minLength]="0"
        >
        </fdl-type-ahead>
      </div> 
      -->

      <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="isSearchAllowed" [ngClass]="{'has-error': !filterForm.get('submittedBy').valid && (filterForm.get('submittedBy').touched || filterForm.get('submittedBy').dirty || isSubmitted)}">
        <fdl-type-ahead 
          label="Submitted By" 
          formControlName="submittedBy" 
          [collection]="submittedBySuggestion" 
          optionField="name"
          placeholder="Type to search" 
          [scrollable]="true" 
          [optionsLimit]="500" 
          [minLength]="0"
        >
        </fdl-type-ahead>
      </div>

      <div class="filter-form-control typeahead-wrapper pr-4" *ngIf="isSearchAllowed">
        <label for="Status">ID:</label>
        <div class="">
          <input type="number" min="0" name="id" placeholder="Type to search" class="form-control" aria-invalid="false" formControlName="id" />
        </div>
      </div>

      <!-- PBI-233834 - Form Field Label multi-select Code Starts -->
        <!-- <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="searchByFormFieldJson.length > 0">
          <label for="formFieldLabel"> Search By: </label>
          <div class="fdl-select">
            <select id="formFieldLabel" class="form-control" aria-invalid="false" formControlName="formFieldLabel" [(ngModel)]='selectedFormFieldApiKey' (change)="onFormFieldSelect($event)">
              <option value="">Please select</option>
              <option *ngFor="let level of searchByFormFieldJson" [value]="level.formfieldapikey">
                {{level.formfieldlabel}}
              </option>
            </select>
          </div>
        </div>

        <div class="filter-form-control pr-4 typeahead-wrapper typeahead-menu-min-height" *ngIf="searchByFormFieldJson.length > 0 && selectedFormFieldLabel">
          <fdl-type-ahead label="{{selectedFormFieldLabel}}" formControlName="formFieldValue" [collection]="formFieldSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
          </fdl-type-ahead>
        </div> -->
      <!-- PBI-233834 - Form Field Label multi-select Code Ends -->

      <div *ngIf="searchByFormFieldJson.length > 0" class="advance-search-filter">
        <h2 *ngIf="isSearchAllowed" class="h4 collapse-arrow mt-2 collapsed" data-toggle="collapse" href="#collapseFormFieldFilter">Advance Search</h2>
        <div class="filter-form-container-column2 ml-0 search-filter collapse multi-form-container" [ngClass]="[filterExists || !isSearchAllowed ? 'show mt-0':'']" id="collapseFormFieldFilter">
          <div class="filter-form-control pr-4 typeahead-wrapper typeahead-menu-min-height" *ngFor="let formField of searchByFormFieldJson" [ngClass]="{'has-error': !filterForm.get(formField.formfieldapikey).valid && (filterForm.get(formField.formfieldapikey).touched || filterForm.get(formField.formfieldapikey).dirty || isSubmitted)}">
            <fdl-type-ahead 
              label="{{formField.formfieldlabel}}"
              formControlName="{{formField.formfieldapikey}}"
              [collection]="formField.formFieldSuggestion" 
              optionField="title" 
              placeholder="Type to search"
              [scrollable]="true"
              [optionsLimit]="500"
              [minLength]="0"
            >
            </fdl-type-ahead>
          </div>
        </div>
      </div>

      <!-- <div class="multi-form-btn"> -->
        <div class="buttom-wrapper mb-1" style="padding-bottom: 6px;">
          <button type="button" class="btn fdl-btn btn-primary-dark " aria-label="Search" (click)="filterList()">Search</button>
        </div>
        <div class="ml-2 buttom-wrapper mb-1" style="padding-bottom: 6px;" *ngIf="filtered">
          <button *ngIf="filtered" type="button" class="btn fdl-btn btn-primary-dark ml-2" aria-label="Reset" (click)="clearFilterList()">Reset</button>
        </div>
      <!-- </div> -->
    </div>
  </form>

  <div class="data-table-container">
    <div class="justify-content-between d-flex">
      <div class="headline-container mb-1">
        <h2 class="h4">{{pageTemplateNameTitle}}</h2>
        <p *ngIf="pagerObject">Total {{pagerObject.totalCount}} Items</p>
      </div>

      <div class="d-flex">
        <div class="action-container fdl-2" style="display: flex; align-items: self-start;">
          <div class="nav-link">
            <a *ngIf="pageTemplateName.title == 'Commitments Intake'" href="javascript:void(0);" (click)="navigateToDataDict(commitmentIntakeDictUrl)">Data Dictionary</a>
            <a *ngIf="pageTemplateName.title == 'Clarity Feed'" href="javascript:void(0);" (click)="navigateToDataDict(clarityFeedDictUrl)">Data Dictionary</a>
          </div>
          <div class="nav-link">
            <a *ngIf="pageTemplateName && pageTemplateName.dependentPageTemplateTitle" href="javascript:void(0);" (click)="navigateToPageResponse(pageTemplateName.dependentPageTemplateId)">{{pageTemplateName.dependentPageTemplateTitle}}</a>
          </div>
          <div class="nav-link" *ngFor="let page of pageTemplateName?.parentPageTemplateList">
            <a *ngIf="page.pageTemplateTitle == 'Commitments Intake'" href="javascript:void(0);" (click)="navigateToPageResponse(page.pageTemplateId)">{{page.pageTemplateTitle}}</a>
          </div>
        </div>

        <div class="action-container fdl-2 mr-2 mt-2 mr-2" style="display: flex; align-items: self-start;" *ngIf="isLinkingAllowed && linkedToggle">
          <div class="filter-control-wrapper">
            <div class="favorite-switcher-wrapper switcher-wrapper" title="{{linkedToggle ? ('Linked ' + pageTemplateNameTitle) : ('De-Linked ' + pageTemplateNameTitle)}}">
              <div class="fdl-switch fdl-2">
                <input id="toggleSwitch" name="toggleSwitch" type="checkbox"  aria-labelledby="desc12" (click)="toggleLinked()" checked>
                <label for="toggleSwitch"> </label>
              </div>
            </div>
          </div>
        </div>

        <div class="action-container fdl-2 mr-2 mt-2 mr-2" style="display: flex; align-items: self-start;" *ngIf="isLinkingAllowed && !linkedToggle">
          <div class="filter-control-wrapper">
            <div class="favorite-switcher-wrapper switcher-wrapper" title="{{linkedToggle ? ('Linked ' + pageTemplateNameTitle) : ('De-Linked ' + pageTemplateNameTitle)}}">
              <div class="fdl-switch fdl-2">
                <input id="toggleSwitch" name="toggleSwitch" type="checkbox"  aria-labelledby="desc12" (click)="toggleLinked()">
                <label for="toggleSwitch"> </label>
              </div>
            </div>
          </div>
        </div>

        <div class="action-container fdl-2" style="display: flex; align-items: self-start;">
            <button *ngIf="isUpdateUIListPage && !linkedToggle" type="button" (click)="takeAction(false)" [disabled]="(baseSelectedForms.length - formSets[0].assignedFormsList.length) == 0" class="btn fdl-btn btn-primary-orange mr-2">Save</button>
            <button *ngIf="isSubmitUIListPage && linkedToggle" type="button" (click)="takeAction(true)" [disabled]="(baseSelectedForms.length - formSets[0].assignedFormsList.length) == 0" class="btn fdl-btn btn-primary-orange mr-2">Submit</button>
            <button *ngIf="isSubmitUIListPage && !linkedToggle" type="button" (click)="takeAction(true)" [disabled]="!(formSets[0].assignedFormsList.length > 0)" class="btn fdl-btn btn-primary-orange mr-2">Submit</button>
        </div>
        <div class="refresh-icon-link mt-1">
          <!-- add class of 'fa-rotation' on click of the refresh icon -->
          <a title="Refresh {{pageTemplateNameTitle}} List" id="refreshTask">
            <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
          </a>
        </div>

        <div class="dropdown downloadDropdownMenuButton mt-0">
          <button type="button" title="Download Extract" class="btn btn fdl-btn btn-primary-dark btn-addcomment" id="downloadDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            <span class="material-icons material-icons-wrapper"> get_app </span>
          </button>
          <div class="dropdown-menu" aria-labelledby="downloadDropdownMenuButton" x-placement="bottom-start">
            <a class="dropdown-item" (click)="exportExcel()">Export List</a>
          </div>
        </div>

        <div class="refresh-icon-link mt-1">
          <a href="javascript:void(0);" data-toggle="modal" class="clickable" (click)="showColumnSettings()">
            <span class="material-icons">settings</span>
          </a>
        </div>
      </div>
    </div>
    <p>{{pageTemplateNameDescription}}</p>

    <div class="legend-color" *ngIf="legendListJson?.length > 0">
      <label>Legend:</label>
      <div class="color" *ngFor="let legend of legendListJson">
        <span class="code" attr.style="background-color: {{legend.legendcolor}}"></span>
        <span class="text"> {{legend.legendname}} </span>
      </div>
    </div>
    
    <div class="table-responsive overflow-auto">
      <app-data-table 
        *ngIf="pageResponses"
        [rows]="pageResponses"
        [keys]="keys"
        [displayColumns]="displayColumns"
        (sortingClicked)="applySorting($event)"
        [showCheckbox]="isSelectMultipleResponseAllowed"
        [showDelete]="isDeleteAllowed"
        [showEdit]="isAdmin"
        [hideAllCheck]="true"
        [showViewEdit]="false"
        [showDelegate]="canRequest"
        [showChangeApprover]="canChangeApprover"
        [ignoreSortingColumns]="ignoreSortingColumns"
        [showLaunchButton]="false"
        [hidePartialLaunchButton]="true"
        (deleteBtnClicked)="navigate($event)"
        type="Request"
        [formSets]="formSets"
        [counter]="0"
        [hideAssignLaunch]="true"
        [checkDeleteForEachRow]="true"
        [canDeleteEntity]="canDelete"
        [customFormFieldListJson]="customFormFieldListJson"
        (buttonClicked)="navigate($event)"
      >
      </app-data-table>
      <app-pagination 
        *ngIf="pagerObject && pagerObject.totalPages > 1 && pageResponses && pageResponses.length > 0"
        [pagerObject]="pagerObject"
        (pagingChanged)="changePage($event)"
      ></app-pagination>
    </div>
    <app-update-approver type="viewPageResponses" (successUpdateApprover)="successUpdateApproverClick($event)" [updateApproverFormData]="responseData"></app-update-approver>
  </div>
</div>

<!-- start of column settings changes-->
<div *ngIf="showColumnSettingsDrawer">
  <div class="modal full-width fade position-right no-backdrop show" id="columnSettings" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style="display: block;" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header white-modal-header">
          <h4 class="modal-title right-arrow-image"><span>Column Settings</span></h4>
          <button type="button" class="close" data-dismiss="modal" tabindex="0" autofocus=""
                  aria-label="Close popup" (click)="cancelPreference()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body pt-0">
          <div class="row tag-line-wrapper">
            <div class="col-md-12">
              <div class="tag-line">
                <p>
                  Add or remove columns.
                </p>
                <p class="mb-0">Please note that you can add up to {{maxColumnCount}} columns at a time.</p>
              </div>
            </div>
          </div>
          <div class="alert alert-dismissible alert-danger" *ngIf="columnSettingErrorMsg">
            <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="columnSettingErrorMsg=''">×</button>
            <i class="material-icons">warning</i>
            <p [innerHtml]="columnSettingErrorMsg"></p>
          </div>
          <!-- Column Field starts -->
          <!-- Column Field starts -->
          <div class="row column-field-wrapper" *ngFor="let columnPref of columnPreferences; index as counter;">
            <!-- Refer https://dev.azure.com/Fiserv-FTS/Impl360/_workitems/edit/226535/ -->
            <div class="col-lg-11 form-field-container">
              <div class="filter-form-control">
                <ng-select [(ngModel)]="columnPref.id" class="ng-select">
                  <ng-option *ngFor="let column of prefColumnOptions" [value]="column.id">{{column.title}}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="col-lg-1 cross-icon-container">
              <button type="button" data-dismiss="modal" tabindex="0" autofocus="" aria-label="Close popup"
                      class="close" (click)="removeColumn(counter)">
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
          <div class="row column-field-wrapper" *ngIf="showAddColumnOptions">
            <div class="col-lg-12 ">
              <a class="add-col-link" (click)="addColumn()">
                <span class="material-icons">add</span>
                <span>Add a Column</span>
              </a>
            </div>
          </div>
        </div>
        <div class="modal-footer fdl-2">
          <button class="btn fdl-btn btn-secondary-light" (click)="cancelPreference()">Cancel</button>
          <button class="btn btn-primary-orange m-0" (click)="savePreference()">Save</button>
        </div>
      </div>
    </div>
  </div>
  <!-- end of column settings changes-->
</div>

<!-- Assign Approver Code Changes -->
<div *ngIf="initiateApproverDrawer">
  <div class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open" tabindex="-1" aria-labelledby="addDynamicApprovers" aria-expanded="true" role="region">
    <div class="drawer-contents col-xs-12 col-sm-12 col-md-12 approver-drawer p1">
      <div class="form-container p-1">
        <form [formGroup]="approverFormGroup" autocomplete="off">
          <div class="form-row" *ngIf="isEntityLink">
            <div class="col-md-12 mb-3 pt-4">
              <!-- <div><label class=""> Please select Organization, Product and/or client, to associate entities with this request.</label></div> -->
              <div><label class=""> Please provide below details for {{pageTemplateNameTitle}}</label></div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="col-md-12 mb-3" style="margin-left: -15px">
                <label for="clientName" class="asterisk"> Client Name/DUNS: </label>
                <div class="pr-4 typeahead-wrapper">
                  <fdl-type-ahead 
                    formControlName="clientName"
                    (input)="typeaheadLoadingClients($event.target.value, true)"
                    [collection]="clientSuggestion"
                    (onSelect)="onClientSelect($event)"
                    optionField="titleWithDuns"
                    placeholder="Type to search"
                    [scrollable]="true"
                    [optionsLimit]="500"
                    [minLength]="0"
                  >
                  </fdl-type-ahead>
                  <!-- <div class="has-error">
                    <span *ngIf="!approverFormGroup.get('clientName').valid && approverFormGroup.get('clientName').errors?.pattern && (approverFormGroup.get('clientName').dirty || isSubmitted)">
                      <i class="material-icons">warning</i> Please enter a valid Client Name.</span>
                  </div> -->
                </div>
                <div *ngIf="selectedClientValue" class="mt-2"><span><i>{{selectedClientValue}}</i></span></div>
                <div [ngClass]="{'has-error': invalidClientName}">
                  <span *ngIf="invalidClientName">
                    <i class="material-icons">warning</i>Please select a client from the list.
                  </span>
                </div>
                <div [ngClass]="{'has-error': invalidClientDuns}">
                  <span *ngIf="invalidClientDuns">
                    <i class="material-icons">warning</i>Please select a client with valid DUNS ID.
                  </span>
                </div>

                <!-- <div class="pr-4 pt-2 typeahead-wrapper">
                  <fdl-type-ahead
                    label="Organization Name (BU):"
                    formControlName="organizationName"
                    [collection]="organizationSuggestion"
                    (onSelect)="onOrganizationSelect($event)"
                    optionField="title"
                    placeholder="Type to search"
                    [scrollable]="true"
                    [optionsLimit]="500"
                    [minLength]="0"
                  >
                  </fdl-type-ahead>
                </div>
                <div [ngClass]="{'has-error': (invalidOrganizationName || missingOrganizationName) }">
                  <span *ngIf="invalidOrganizationName">
                    <i class="material-icons">warning</i>Please select the valid organization name from the list.
                  </span>
                  <span *ngIf="missingOrganizationName">
                    <i class="material-icons">warning</i>Organization is missing for selected product.
                  </span>
                </div>

                <div class="pt-2 pr-4 typeahead-wrapper" *ngIf="selectedOrganizationName">
                  <fdl-type-ahead
                    label="Product Name:"
                    formControlName="productName"
                    [collection]="productSuggestion"
                    (onSelect)="onProductSelect($event)"
                    optionField="title"
                    placeholder="Type to search"
                    [scrollable]="true"
                    [optionsLimit]="500"
                    [minLength]="0" 
                  >
                  </fdl-type-ahead>
                </div>
                <div [ngClass]="{'has-error': invalidProductName}">
                  <span *ngIf="invalidProductName">
                    <i class="material-icons">warning</i>Please select the valid product name from the list.
                  </span>
                </div> -->
              </div>
            </div>
          </div>

          <h3 *ngIf="isDynamicApprover" class="mt-0">Add Approver(s):</h3>
          <div class="d-flex mb-12 updateApprover" *ngIf="isDynamicApprover">
            <div class="col-md-6 mb-3 pt-1 pl-0"
                 [ngClass]="{'has-error': ( approverFormGroup.get('approver').errors?.duplicate || (!approverFormGroup.get('approver').valid && approverFormGroup.get('approver').touched)) }">
              <div class="mb-1">
                <label> Please select the approver(s). </label>
              </div>
              <app-mutipleadd 
                #addmultiple
                (formReady)="formInitialized('approver', $event)"
                buttonText="Add Approver"
                [isFdl]="true"
                [fdlData]="usersData"
                [filedJson]="filedJson"
                elementName="approver"
                [filedDetails]="filedDetails"
                [formControlData]="approverFormGroup"
              >
              </app-mutipleadd>
              <span *ngIf="(approverFormGroup.get('approver').errors?.duplicate)">
                <i class="material-icons">warning</i>The Approver can not be duplicated at the same level.
              </span>
              <span *ngIf="(approverFormGroup.get('approver').errors?.invalid)">
                <i class="material-icons">warning</i>The Level should be an integer number greater than or equal to 1.
              </span>
            </div>

            <div class="form-typeahead-wrapper select-approver-criteria" style="margin-left: -75px;">
              <label class="mt-2">Select Approver Criteria: <span data-toggle="tooltip" title="Select Approval Criteria if Multiple Approvers at same level." class="material-icons btn-secondary f-18"> info</span></label>
              <div>
                <div class="fdl-select">
                  <select class="form-control" formControlName="approvalSchema" name="approvalSchema">
                    <option value="OR">ANY</option>
                    <option value="AND">ALL</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <!-- <div *ngIf="isEntityLink"><p><b>*Note:</b> All users who are assigned to an entity, can view the request if it is linked with organization/product/client from this screen. <br> Please click on 'Continue' button to proceed. </p></div>     -->
          <div class="fdl-2 button-container">
            <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal" (click)="cancelApproverForm()"> Cancel </button>
            <div>
              <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" (click)="assignApprover()" [disabled]="((selectedClientId < 1) || invalidClientDuns)"> Continue </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="backdrop-space">
    <div class="backdrop"></div>
  </div>
</div>
<!-- Assign Approver Code Changes -->

<!-- Multiple Page Drawer-->
<div *ngIf="initiatorDrawer">
  <div class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open" tabindex="-1" aria-labelledby="addNewAccount" aria-expanded="true" role="region">
    <div class="drawer-contents col-xs-12 col-sm-12 col-md-12 project-drawer p1">
      <h3 *ngIf="isMultipleResponseSubmit">Add/Remove {{pageTemplateNameTitle}}</h3>
      <p *ngIf="isMultipleResponseSubmit && isLinkingAllowed && !linkedToggle">The list below displays the {{pageTemplateNameTitle}} response(s) that would be linked to {{pageTemplateName.dependentPageTemplateTitle}}. Please review and submit.</p>
      <p *ngIf="isMultipleResponseSubmit && isLinkingAllowed && linkedToggle">The list below displays the {{pageTemplateNameTitle}} response(s) that would be de-linked from {{pageTemplateName.dependentPageTemplateTitle}}. Please review and submit.</p>
      <p *ngIf="isMultipleResponseSubmit && !isLinkingAllowed">The list below displays the {{pageTemplateNameTitle}} response(s) that will be submitted. Please review and submit.</p>

      <h3 *ngIf="!isMultipleResponseSubmit">Update {{pageTemplateNameTitle}}</h3>
      <p *ngIf="!isMultipleResponseSubmit">The list below displays updated response(s) for the {{pageTemplateNameTitle}}. Please review and submit.</p>
      <div class="form-container p-1">
        <div class="form-row" style="padding-left: 5px;">
          <div class="col-md-12 p0 pl-2  data-company-table">
            <table class="table table-hover table-label" aria-describedby="tblDesc" tabindex="0">
              <thead>
                <tr role="row">
                  <th role="columnheader" tabindex="0" scope="col">ID</th>
                  <th role="columnheader" scope="col">
                    <a href="#" tabindex="0">
                      Title
                    </a>
                  </th>
                  <th role="columnheader" tabindex="0" scope="col">Date/Time</th>
                  <th role="columnheader" tabindex="0" scope="col">Status</th>
                </tr>
              </thead>
              <tbody>

                <tr role="row" *ngFor="let row of addedPageTemplateTolink" [ngClass]="getStatusCSSClass(row)" class="">
                  <td role="cell" tabindex="0">{{row.id}}</td>
                  <td role="cell" tabindex="0">{{row.customeFormFieldListJsonAnswer.commitmentTitleIntake}}</td>
                  <td role="cell" tabindex="0">{{row.createdOn| date: 'MM/dd/yyyy HH:mm'}}</td>
                  <td role="cell" tabindex="0" *ngIf="row.statusA == 'new'"><i _ngcontent-vhd-c195="" class="material-icons" aria-hidden="true">check_circle</i> Added</td>
                  <td role="cell" tabindex="0" *ngIf="row.statusA != 'new'"><i _ngcontent-vhd-c195="" class="material-icons" aria-hidden="true">check_circle</i> Retained</td>
                </tr>
                <tr role="row" *ngFor="let row of removedPageTemplateTolink" class="alert-message">
                  <td role="cell" tabindex="0">{{row.id}}</td>
                  <td role="cell" tabindex="0">{{row.customeFormFieldListJsonAnswer.commitmentTitleIntake}}</td>
                  <td role="cell" tabindex="0">{{row.createdOn| date: 'MM/dd/yyyy HH:mm'}}</td>
                  <td role="cell" tabindex="0"><i _ngcontent-vhd-c195="" class="material-icons" aria-hidden="true">warning</i> Removed</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="fdl-2 button-container">
          <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal" (click)="cancelDrawer()">
            Cancel
          </button>
          <div>
            <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" (click)="submitRequest()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>   
  <div class="backdrop-space">
    <div class="backdrop"></div>
  </div>
</div>

<!-- Multiple Page Drawer-->
<!---  DELEGATE MODAL STARTS -------->
<div class="modal fade bd-example-modal-lg" id="delegateResponse" tabindex="-1" role="dialog" aria-hidden="true" #content>
  <div class="modal-dialog modal-lg mt-120" role="document" style="max-width: 700px;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          Select Respondent to Delegate
        </h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-dismissible alert-danger" *ngIf="isDelegateError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isDelegateError=false">×</button>
          <i class="material-icons">warning</i>
          <p [innerHtml]="delegateErrorMsg"></p>
        </div>
        <form [formGroup]="delegateForm" autocomplete="off">
          <div class="row ml-0">
            <div class="typeahead-wrapper">
              <label for="respondentName" class="asterisk">Respondent Name: </label>
              <fdl-type-ahead formControlName="respondentName"
                              [collection]="respondentUserList"
                              (onSelect)="onRespondentSelect($event)"
                              optionField="name"
                              placeholder="Type to search"
                              [scrollable]="true"
                              [optionsLimit]="500"
                              [minLength]="0"> </fdl-type-ahead>
            </div>
          </div>
          <div>
            <span *ngIf="respondentEmail" style="display: block;padding-top: 5px;color: #4b4a4a;padding-bottom: 5px;padding-left: 10px;font-weight: 500;font-size: 13px;">
              {{ respondentEmail }}
            </span>
          </div>
          <div [ngClass]="{'has-error': (invalidRespondent || delegateForm.get('respondentName').value == '') && (delegateForm.get('respondentName').touched || isDelegateSubmitted)}">
            <span *ngIf="(invalidRespondent || delegateForm.get('respondentName').value == '') && (delegateForm.get('respondentName').touched || isDelegateSubmitted)">
              <i class="material-icons">warning</i>Please select respondent.
            </span>
          </div>

          <div class="row mt-4">
            <div class="col-md-12" [ngClass]="{'has-error': !delegateForm.get('comments').valid && (delegateForm.get('comments').touched || isDelegateSubmitted)}">
              <label for="comments" class="asterisk">Comments: </label>
              <textarea class="form-control" id="comments" formControlName="comments"></textarea>
              <span *ngIf="!delegateForm.get('comments').valid && (delegateForm.get('comments').errors?.required || delegateForm.get('comments').errors?.validateWhiteSpaces) && isDelegateSubmitted">
                <i class="material-icons">warning</i> Please enter comments.
              </span>
              <span *ngIf="!delegateForm.get('comments').valid && delegateForm.get('comments').errors?.maxlength && (delegateForm.get('comments').touched || isDelegateSubmitted)">
                <i class="material-icons">warning</i> Maximum input character limit is {{_maxLengths.Delegate.Comments}}, please correct it.
              </span>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal" (click)="cancelDelegate()"> Cancel </button>
        <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" (click)="submitDelegate()"> Delegate </button>
      </div>
    </div>
  </div>
</div>
<!---  DELEGATE MODAL ENDS -------->
