<div class="modal fade bd-example-modal-lg" data-backdrop="static" id="delegateCommitment" tabindex="-1" role="dialog"
    style="display: none;" aria-hidden="true" #content>
    <div class="modal-dialog modal-lg mt-120" role="document" style="max-width: 700px;">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    Select Respondent to Delegate
                </h4>
            </div>
            <div class="modal-body">
                <!-- <div class="alert alert-dismissible alert-danger" *ngIf="isDelegateError">
                    <button type="button" class="close" data-dismiss="alert" aria-label="close"
                        (click)="isDelegateError=false">×</button>
                    <i class="material-icons">warning</i>
                    <p [innerHtml]="delegateErrorMsg"></p>
                </div>
                <div class="alert alert-success alert-dismissible" *ngIf="isDelegateSuccess">
                    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isDelegateSuccess=false">×</button>
                    <i class="material-icons" aria-hidden="true">check_circle</i>
                    <p [innerHtml]="successMsg"></p>
                </div> -->
                <form [formGroup]="delegateForm" autocomplete="off">
                    <div class="row ml-0">
                        <div class="typeahead-wrapper">
                            <label for="respondentName" class="asterisk">Respondent Name: </label>
                            <fdl-type-ahead *ngIf="!isLoading" formControlName="respondentName"
                                [collection]="respondentUserList" (onSelect)="onRespondentSelect($event)"
                                optionField="name" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500"
                                [minLength]="0">
                            </fdl-type-ahead>

                            <fdl-type-ahead *ngIf="isLoading" placeholder="Loading respondents ..." [scrollable]="true"
                                [optionsLimit]="500" [minLength]="0">
                            </fdl-type-ahead>
                        </div>
                    </div>
                    <div>
                        <span *ngIf="respondentEmail"
                            style="display: block;padding-top: 5px;color: #4b4a4a;padding-bottom: 5px;padding-left: 10px;font-weight: 500;font-size: 13px;">
                            {{ respondentEmail }}
                        </span>
                    </div>
                    <div
                        [ngClass]="{'has-error': (invalidRespondent || delegateForm.get('respondentName').value == '') && (delegateForm.get('respondentName').touched || isDelegateSubmitted)}">
                        <span
                            *ngIf="(invalidRespondent || delegateForm.get('respondentName').value == '') && (delegateForm.get('respondentName').touched || isDelegateSubmitted)">
                            <i class="material-icons">warning</i>Please select respondent.
                        </span>
                    </div>

                    <div class="row mt-4">
                        <div class="col-md-12"
                            [ngClass]="{'has-error': !delegateForm.get('comments').valid && (delegateForm.get('comments').touched || isDelegateSubmitted)}">
                            <label for="comments" class="asterisk">Comments: </label>
                            <textarea class="form-control" id="comments" formControlName="comments"></textarea>
                            <span
                                *ngIf="!delegateForm.get('comments').valid && (delegateForm.get('comments').errors?.required || delegateForm.get('comments').errors?.validateWhiteSpaces) && isDelegateSubmitted">
                                <i class="material-icons">warning</i> Please enter comments.
                            </span>
                            <span
                                *ngIf="!delegateForm.get('comments').valid && delegateForm.get('comments').errors?.maxlength && (delegateForm.get('comments').touched || isDelegateSubmitted)">
                                <i class="material-icons">warning</i> Maximum input character limit is
                                {{_maxLengths.Delegate.Comments}}, please correct it.
                            </span>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer fdl-2">
                <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal"
                    (click)="cancelDelegate()"> Cancel </button>
                <button type="button" [disabled]="isLoading || !delegateForm.valid"
                    class=" btn fdl-btn btn-primary-orange ml-2" (click)="submitDelegate()"> Delegate </button>
            </div>
        </div>
    </div>
</div>