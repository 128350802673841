import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IPageTemplate, IPageTemplateResponse } from 'src/app/shared/page-template';
import { PageTemplateService } from 'src/app/services/page-template.service';
import { take } from 'rxjs/operators';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';

@Component({
  selector: 'view-page',
  templateUrl: './view-page.component.html'
})
export class ViewPageTemplateComponent implements OnInit, AfterViewChecked {
  errorMsg: string = "";
  formJson: any;
  answerJson: any;
  pageTemplateId: number;
  pageTemplateResponseId: number;
  pageResponse: IPageTemplateResponse;
  pageTemplate: IPageTemplate;
  reservedKeysData: any;
  isReady:boolean = false;
  readOnly:boolean = false;
  isAdmin:boolean = false;
  isRestrictedAdmin:boolean = false;
  userData: any;
  pageTemplateResponseFormValue: IPageTemplateResponse;
  isPageTemplateAdmin = false;
  shouldEnableSaveButtonForSpecialCase = false;  
  
  constructor(
    private route: ActivatedRoute,
    private permission: PermissionsService,
    private router: Router,
    private pageTemplateService: PageTemplateService,
    private clientCommitmentService: ClientCommitmentsService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.isAdmin = this.permission.userData.isAdmin;
    this.isRestrictedAdmin = this.permission.userData.isRestrictedAdmin;
    this.userData = this.userService.getUserListData();
    this.pageTemplateResponseFormValue = this.pageTemplateService.formVal;
    this.route.params
    .pipe(take(1))
    .subscribe((params) => {
      if (this.router.url.indexOf('response') !== -1){
        this.pageTemplateResponseId = params['id'];        
      }        
      else
        this.pageTemplateId = params['id'];

      if (this.pageTemplateResponseId > -1)
        this.getPageResponseData();
      if (this.pageTemplateId > -1)
        this.getPageTemplateData();
    });
  }

  ngAfterViewChecked(): void {
    this.checkDisableControl();
  }

  getPageTemplateData() {
    this.clientCommitmentService.getPageTemplateById(this.pageTemplateId)
    .pipe(take(1))
      .subscribe(
        response => {
          this.pageTemplate = response.body as IPageTemplate;
          this.formJson = JSON.parse(this.pageTemplate.formJSON);
          this.isReady = true;          
          this.populateReservedKeysData();

          // Enable projectManagerServicenow when create a new commitment
          this.setDisableProjectManagerServicenowControl();
        },
      (error)=> { this.errorMsg = error; });
  }

  populateReservedKeysData() {
    const reservedData: {[key: string]: any} = {} 
    if(this.pageTemplateResponseFormValue) {
      if(this.pageTemplateResponseFormValue?.clientTitle)
        reservedData['drmsReservedClientName'] = this.pageTemplateResponseFormValue.clientTitle;
      if(this.pageTemplateResponseFormValue?.productTitle)
        reservedData['drmsReservedProductName'] = this.pageTemplateResponseFormValue.productTitle;
      if(this.pageTemplateResponseFormValue?.organizationTitle)
        reservedData['drmsReservedOrganizationName'] = this.pageTemplateResponseFormValue.organizationTitle;
      if(this.pageTemplateResponseFormValue?.mdmClientMasterId)
        reservedData['drmsReservedMdmClientMasterId'] = this.pageTemplateResponseFormValue.mdmClientMasterId;
      if(this.pageTemplateResponseFormValue?.clientDunsId)
        reservedData['drmsReservedClientDunsId'] = this.pageTemplateResponseFormValue.clientDunsId;
      if(this.pageTemplateResponseFormValue?.relationshipManagerName)
        reservedData['drmsReservedRelationshipManagerName'] = this.pageTemplateResponseFormValue.relationshipManagerName;
      if(this.pageTemplateResponseFormValue?.relationshipManagerEmail)
        reservedData['drmsReservedRelationshipManagerEmail'] = this.pageTemplateResponseFormValue.relationshipManagerEmail;
      if(this.pageTemplateResponseFormValue?.clientGudId)
        reservedData['drmsReservedClientGudId'] = this.pageTemplateResponseFormValue.clientGudId;
    }
    this.reservedKeysData = reservedData;
  }

  getPageResponseData() {    
    this.clientCommitmentService.getPageTemplateResponseById(this.pageTemplateResponseId)
    .pipe(take(1))
      .subscribe(
        response => {
          this.pageResponse = response as IPageTemplateResponse;
          this.formJson = JSON.parse(this.pageResponse.formJSON);
          this.answerJson = JSON.parse(this.pageResponse.answerJSON);
          this.pageTemplateId = this.pageResponse.pageTemplateId;
          this.isReady = true;
          this.isPageTemplateAdmin =  this.pageResponse?.pageTemplateAdmins.some(user => user.userId === this.userData.id && user.isActive);
          this.populateReservedKeysData();                      
          
          // if (localStorage.getItem('commitments-intake-tab-name') === 'Presale'){
          //   this.readOnly = true;
          //   this.formJson['isReadOnly'] = true;
          // }

          if (this.pageResponse?.accessLevel === 'Read' || this.answerJson?.drmsReservedIsReadOnlyResponse?.toString() === 'true')
          {
            this.readOnly = true;
            this.formJson['isReadOnly'] = true;
          }

          /* -- Commented due to PBI-260094
          this.pageResponse.accessLevel = 'Write';
          this.updateAccessLevel();

          if (this.pageResponse?.pageTemplateResponseStatusId === 6 || this.answerJson?.drmsReservedIsReadOnlyResponse?.toString() === 'true')
          {
            this.readOnly = true;
            this.formJson['isReadOnly'] = true;
          }
          else if (!(this.isAdmin || this.pageResponse?.pageTemplateAdmins?.some(a => a.userId === this.userData.id && a.isReadAccess == false) || (this.isRestrictedAdmin && this.pageResponse?.accessLevel === 'Write')))
          {         
            if (this.pageResponse?.pageTemplateResponseStatusId === 5
              || (
                    this.pageResponse?.pageTemplateResponseStatusId === 3
                    && !(this.pageResponse?.pageTemplateApprovers?.some(a => a.approverId === this.userData.id && a.level === this.pageResponse?.currentLevel && a.isReviewComplete === false))
                  )
              || (
                    (this.pageResponse?.pageTemplateResponseStatusId === 1 || this.pageResponse?.pageTemplateResponseStatusId === 2 || this.pageResponse?.pageTemplateResponseStatusId === 4) 
                    && !(this.pageResponse?.submittedById === this.userData.id)
                  )
              ) {
              this.readOnly = true;
              this.formJson['isReadOnly'] = true;
            }
            else if(
               this.pageResponse?.pageTemplateReadAdmins?.some(a => a.userId === this.userData.id && a.isReadAccess)
            && !(this.pageResponse?.submittedById === this.userData.id)
            && (
                this.pageResponse?.pageTemplateResponseStatusId === 3
                && !(this.pageResponse?.pageTemplateApprovers?.some(a => a.approverId === this.userData.id && a.level === this.pageResponse?.currentLevel && a.isReviewComplete === false))
              )
            )
            {
              this.readOnly = true;
              this.formJson['isReadOnly'] = true;
            }
          }
          -- Commented due to PBI-260094 */
        },
      (error)=> { this.errorMsg = error; });
  }

  updateAccessLevel() {
    if(this.userData.isRestrictedAdmin === true){
      let isExists: boolean = false;
      let isApprover: boolean = false;
      let sortOrder = ['Read','Write'];
      let groups = this.userData.groups.sort((a,b)=>{return sortOrder.indexOf(a.accessLevel) - sortOrder.indexOf(b.accessLevel);});
     
      isApprover = this.pageResponse.pageTemplateApprovers.some(a => a.approverId === this.userData.id && a.level === this.pageResponse.currentLevel) && this.pageResponse.pageTemplateResponseStatusId === 3;

      if(!isApprover && this.pageResponse.submittedById !== this.userData.id)
      { 
        for (let g in groups) {
          isExists = false;
          if(this.pageResponse.organizationId > 0) {
            if(groups[g].organizations.some(org => org.organizationId === this.pageResponse.organizationId)) {
              isExists = true;
            }
          }

          if(this.pageResponse.productId > 0) {
            if(groups[g].products.some(prd => prd.productId === this.pageResponse.productId)) {
              isExists = true;
            }
          }

          if(this.pageResponse.clientId > 0) {
            if(groups[g].clients.some(clnt => clnt.clientId === this.pageResponse.clientId)) {
              isExists = true;
            }
          }

          if(isExists) {
            this.pageResponse.accessLevel = groups[g].accessLevel;
          }
        }
      }
    }
  }

  setDisableProjectManagerServicenowControl (isDisabled = false): any {
    this.setDisableControlByName('data[projectManagerServicenow]', isDisabled);
  }

  setDisableIsRiskControl (isDisabled = false): any {
    this.setDisableControlByName('data[isRisk]', isDisabled);
    this.setDisableControlByName('data[riskReason]', isDisabled);
    this.setDisableControlByName('data[riskDescription]', isDisabled);
  }

  setDisableIsTrackedControl(isDisabled = false): any {
    this.setDisableControlByName('data[isTracked]', isDisabled);
    this.shouldEnableSaveButtonForSpecialCase = !isDisabled;
  }

  setDisableControlByName(name: string, isDisabled = false): any {
    const control = document.getElementsByName(name);
      if (control && control.length > 0) {
        (control[0] as any).disabled = isDisabled;
        if (name === 'data[riskReason]' && isDisabled) {
          control[0].parentElement.parentElement.style.pointerEvents = 'none';
        }
      }
  }

  checkDisableControl(): any {
    if (this.answerJson) {
      this.checkForDisableProjectManagerServicenow();
      this.checkForDisableIsRisk();
      this.checkForDisableIsTrack();
    }
  }

  public isCCTAdminWriteAccess(): boolean {
   
    const result = (this.permission.isCCTAdmin() && this.isPageTemplateAdmin);
    return result;
  }

  public isRestrictedAdminWriteAccess(): boolean {
    const result = (this.permission.isRestrictedAdmin() && !this.permission.isRestrictedAdminReadOnly());
    return result;
  }

  public onSaveFormChanged(emitValue: any): any {
    if (!emitValue) return;

    this.answerJson.isTracked = emitValue.isTracked;
    this.answerJson.statusIntake = emitValue.statusIntake;
  }

  public isCancelledCommitment(): boolean {
    if (this.answerJson) {
      const result = (this.answerJson.statusIntake === 'Fulfillment - Canceled' || this.answerJson.statusIntake === 'Canceled');
      return result;
    }

    return false;
  }

  public isReadOnlyCommitment(): boolean {
    if (this.answerJson) {     
      const isReadOnly = (this.answerJson.drmsReservedIsReadOnlyResponse 
        || this.answerJson.drmsReservedIsReadOnlyResponse == 'true'
        || !this.isUserWriteAccess());

        return isReadOnly;
     }
    
   return false;
 }

 isUserWriteAccess(): boolean {
  const isWriteAccess = this.pageResponse?.accessLevel == 'Write';
  return isWriteAccess;
}

 public isCancelledOrReadOnlyCommitment(): boolean {
  const isReadOnlyCommiment = this.isReadOnlyCommitment();
  const isCancelledCommitment = this.isCancelledCommitment();
  const result = isReadOnlyCommiment || isCancelledCommitment || !this.isUserWriteAccess();

  return result;
}

public isMappedCommitment(): boolean {
  const isMapped = this.answerJson.mappedCommitmentDetails ? true : false
  return isMapped;
}

public checkForDisableProjectManagerServicenow(): any {
  const shouldDisabled = this.answerJson.datasource !== 'manual';
    this.setDisableProjectManagerServicenowControl(shouldDisabled);
}

public checkForDisableIsRisk(): any {
  // Always disable IsRisk if commitment is mapped. Only CCT Admin Write have full permission for ECRM
  if (this.answerJson.datasource === 'ecrm') {
    const shouldDisabledIsRisk = this.isCCTAdminWriteAccess() ? false : this.shouldDisableIsRiskCheckbox();
    this.setDisableIsRiskControl(shouldDisabledIsRisk);
  } else {
    const shouldDisabledIsRisk = this.shouldDisableIsRiskCheckbox();
    this.setDisableIsRiskControl(shouldDisabledIsRisk);
  }
}

public checkForDisableIsTrack(): any {
  if (this.answerJson.datasource === 'ecrm') {
    // For Ecrm source. Only CCT Admin Write access can edit istrack value
    const shouldDisabledIsTrack = !this.isCCTAdminWriteAccess();
    this.setDisableIsTrackedControl(shouldDisabledIsTrack);
  } else {
    if (this.isMappedCommitment()) {
      // Always disable IsTrack if commitment is mapped
      this.setDisableIsTrackedControl(true);
      return;
    }

    // For other sources only user write access can UN-TRACK with cancelled commitments
    if (this.isCancelledCommitment() && this.isUserWriteAccess()) {
      const shouldDisabledIsTrack = !this.answerJson.isTracked;
      this.setDisableIsTrackedControl(shouldDisabledIsTrack);
    } else {
      const shouldDisabledIsTrack = this.shouldDisableIsTrackCheckboxControl();
      this.setDisableIsTrackedControl(shouldDisabledIsTrack);
    }
  }
}

public shouldDisableIsRiskCheckbox(): boolean {
  const shouldDisabledIsRisk = this.isCancelledOrReadOnlyCommitment() || this.isMappedCommitment();
  return shouldDisabledIsRisk;
}

public shouldDisableIsTrackCheckboxControl(): boolean {
  const shouldDisable = this.isCancelledOrReadOnlyCommitment() || this.isMappedCommitment();

  return shouldDisable;
}

}