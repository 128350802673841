import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { IUser } from 'src/app/shared/user';
import { UsersDTOService } from 'src/app/shared/usersDTO.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { MasterService } from 'src/app/services/master.service';
import { PageTemplateAccessType } from '../user-form/models/page-template-access-type.enum';
import { PageTemplateAccess } from '../user-form/models/page-template-access';
import { PageTemplateAdminDTO } from '../user-form/models/page-template-admin-d-t-o';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.css']
})
export class ViewUserComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userDto: UsersDTOService,
    private userService: UserService,
    private permission: PermissionsService,
    private masterService: MasterService) { }

  userId: number;
  userData: IUser = {
    userId: '',
    name: '',
    email: '',
    organization: 0,
    clientOrgId: 0,
    clientId: 0,
    type: '',
    isAdmin: false,
    isRestrictedAdmin: false,
    isSurveyRespondent: false,
    isFiservDivisionAdmin: false,
    permissions: [],
    permissionsForUI: [],
    phone: '',
    organizationTitle: '',
    clientTitle: '',
    clientTitleWithDuns: '',
    roles: [],
    id: '',
    products: [],
    clients: [],
    groups: [],
    isActive: false,
    isDistributionUser: false,
    pageTemplates: undefined,
    userRoles: [],
    crossOrganizations: [],
    pageTemplateAdminList: [],
    pageTemplateReadAllList: [],
    restrictedUserGroup: ''
  };
  public pageTemplateDataForDisplay: PageTemplateAccess[] = [];

  displayColumns = ['Role Name', 'Description'];
  keys = ['roleTitle', 'roleDescription'];
  productDisplayColumns = ['Product Name', 'Description'];
  productKeys = ['productTitle', 'productDescription'];
  showUpdationMessage: boolean = false;
  showEditButton: boolean = false;
  clientKeys = ['clientTitleWithDuns', 'clientDescription'];
  clientDisplayColumns = ['Client Name', 'Description'];
  groupKeys = ['groupTitle', 'groupDescription'];
  groupDisplayColumns = ['Group Name', 'Description'];

  ngOnInit(): void {
    this.showEditButton = this.permission.isCreateUserAllowed();
    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.userId = params['id'];
        if (this.userId > -1) {
          this.getUserData();
        }
      });
    this.showUpdationMessage = this.userDto._isUserUpdated;
  }

  getUserData() {
    this.userService.getUserById(this.userId)
      .pipe(take(1))
      .subscribe(
        data => {
          this.userData = data;

          this.pageTemplateDataForDisplay = [];
          if (this.userData !== null &&
            this.userData !== undefined &&
            this.userData.pageTemplateAdminList !== null &&
            this.userData.pageTemplateAdminList !== undefined &&
            this.userData.pageTemplateAdminList.length > 0) {
            this.loadPageTemplateAdminList(this.userData.pageTemplateAdminList, PageTemplateAccessType.Admin);
          }

          if (this.userData !== null &&
            this.userData !== undefined &&
            this.userData.pageTemplateReadAllList !== null &&
            this.userData.pageTemplateReadAllList !== undefined &&
            this.userData.pageTemplateReadAllList.length > 0) {
            this.loadPageTemplateAdminList(this.userData.pageTemplateReadAllList, PageTemplateAccessType.ReadAll);
          }
        }
      );
  }

  goToEdit() {
    this.userDto._isEditMode = true;
    this.userDto.setUserData(this.userData);
    this.router.navigate(['/admin/user/edit']);
  }

  ngOnDestroy(): void {
    this.userDto._isUserUpdated = false;
  }

  goBack() {
    this.masterService.isCancelledClicked(true);
    this.router.navigate(['/admin/user/list']);
  }

  private loadPageTemplateAdminList(pageTemplateAdminList: PageTemplateAdminDTO[], pageTemplateAccessType: PageTemplateAccessType): void {
    pageTemplateAdminList.forEach(item => {
      const data: PageTemplateAccess = {
        id: item.pageTemplateId,
        accessType: pageTemplateAccessType,
        title: item.pageTemplateName
      };
      this.pageTemplateDataForDisplay.push(data);
    });
  }

  public translatePageTemplateAccessType(pageTemplateAccessType: PageTemplateAccessType): string {
    if (pageTemplateAccessType === PageTemplateAccessType.Admin) {
      return 'Admin';
    } else if (pageTemplateAccessType === PageTemplateAccessType.ReadAll) {
      return 'Read all';
    } else if (pageTemplateAccessType === PageTemplateAccessType.View) {
      return 'View';
    } else {
      return '';
    }
  }
}
